import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
// import NumberFormat from 'react-number-format';
import Select from 'react-select';
import { Animated } from 'react-animated-css';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import MobileToggle from '../mobileToggles/mobileToggles';
import Leftbar from './leftbar/Leftbar';
import SidebarAnnouncement from '../sidebarAnnouncement/SidebarAnnouncement';
import { callApi, setCategoryColor, setEmployeeDetails } from '../../store/reducers/commonFunction'
import errorMessages from '../../store/reducers/errorMessages'
import common from '../../store/reducers/commons'

import swal from 'sweetalert'
import ntc from "ntc"

const colorSelector = [
    { value: 'FFCF00', label: <div className="select-color"><span className="color-dot yellow"></span><span className="color-name">yellow</span></div> },
    { value: '00DAFF', label: <div className="select-color"><span className="color-dot deep-sky-blue"></span><span className="color-name">deep Sky Blue</span></div> },
    { value: 'FF0000', label: <div className="select-color"><span className="color-dot red"></span><span className="color-name">red</span></div> },
    { value: '02DB91', label: <div className="select-color"><span className="color-dot caribbean-green"></span><span className="color-name">Caribbean Green</span></div> },
    { value: 'A500FF', label: <div className="select-color"><span className="color-dot purple"></span><span className="color-name">Purple</span></div> },
    { value: '1700FF', label: <div className="select-color"><span className="color-dot blue"></span><span className="color-name">Blue</span></div> },
    { value: '2E1C4B', label: <div className="select-color"><span className="color-dot violet"></span><span className="color-name">Violet</span></div> },
    { value: 'FF9300', label: <div className="select-color"><span className="color-dot orange"></span><span className="color-name">Orange</span></div> },
    { value: 'FF00F7', label: <div className="select-color"><span className="color-dot magenta"></span><span className="color-name">Magenta</span></div> },
    { value: '00FF00', label: <div className="select-color"><span className="color-dot lime"></span><span className="color-name">Lime</span></div> },
    { value: '42ACD2', label: <div className="select-color"><span className="color-dot summer-sky"></span>Summer Sky<span className="color-name"></span></div> },
    { value: '776D98', label: <div className="select-color"><span className="color-dot scampi"></span><span className="color-name">Scampi</span></div> },
    { value: '9E5BC5', label: <div className="select-color"><span className="color-dot deep-lilac"></span><span className="color-name">Deep Lilac</span></div> },
    { value: 'EC82DB', label: <div className="select-color"><span className="color-dot pale-magenta"></span><span className="color-name">Pale Magenta</span></div> },
    { value: '9D4545', label: <div className="select-color"><span className="color-dot roof-terracotta"></span><span className="color-name">Roof Terracotta</span></div> },
    { value: '008000', label: <div className="select-color"><span className="color-dot green"></span><span className="color-name">Green</span></div> },
];

class FeedbackCategory extends Component {

    /**
    |--------------------------------------------------
    | constructor
    |--------------------------------------------------
    */

    constructor(props) {
        super(props);
        this.state = {
            viewCategory: true,
            editCategory: false,
            categories: [],
            addEditCategoryModalVisible: false,
            name: '',
            color_code: 'FF0000',
            errors: {
                name: ''
            },
            employeeList: [],
            isLoading: false
        }
    }

    /**
    |--------------------------------------------------
    | componentDidMount
    |--------------------------------------------------
    */

    async componentDidMount() {
        this.getEmployeeDetails()
    }

    /**
    |--------------------------------------------------
    | getEmployeeDetails
    |--------------------------------------------------
    */

    getEmployeeDetails = async (user_id) => {

        let company_id = this.props.userData.company_id ? this.props.userData.company_id : '';
        let body = {
            company_id
        }
        const response = await this.props.callApi('/getEmployee', body, 'post', null, true);

        if (response) {
            // console.log('getEmployee-->', JSON.stringify(response));
            let employeeList = response.data && Array.isArray(response.data) && response.data.map((values) => {
                return (
                    { label: values.first_name + " " + values.last_name, value: values.user_id }
                )
            })
            this.setState({
                employeeList
            })
            this.props.setEmployeeDetails(response.data);
        }
    }

    /**
    |--------------------------------------------------
    | viewCategoryBtn
    |--------------------------------------------------
    */

    viewCategoryBtn = () => {
        this.setState({ viewCategory: true, editCategory: false, addEditCategoryModalVisible: false })
    }

    /**
    |--------------------------------------------------
    | editCategoryBtn
    |--------------------------------------------------
    */

    editCategoryBtn = () => {
        const { categories } = this.state;
        this.setState({
            addEditCategoryModalVisible: true,
            editCategory: true,
            name: categories.name,
            color_code: categories.color_code,
            category_id: categories.id
        })
    }

    /**
    |--------------------------------------------------
    | onPressAddEditCategoryModal
    |--------------------------------------------------
    */

    onPressAddEditCategoryModal = () => {
        this.setState({
            editCategory: false,
            addEditCategoryModalVisible: true,
            name: '',
            color_code: 'FF0000',
            adminId: undefined
        })
    }

    /**
    |--------------------------------------------------
    | setParentStatus
    |--------------------------------------------------
    */

    setParentStatus = (categories) => {
        console.log('categories-->', JSON.stringify(categories));
        this.setState({
            categories: categories,
        })
    }

    /**
    |--------------------------------------------------
    | updateCategory
    |--------------------------------------------------
    */

    updateCategory = async (e) => {
        e.preventDefault();
        try {
            if (this.validateForm()) {
                this.setState({
                    isLoading: true
                })
                let { name, color_code, category_id } = this.state
                let body = { name, color_code, category_id }
                const response = await this.props.callApi('/updateCategory', body, 'post', null, true);
                if (response.data.code === 200) {
                    common.showSuccessMessage(response.data.message);
                    this.setState({
                        name: '',
                        color_code: '',
                        category_id: undefined,
                        addEditCategoryModalVisible: false,
                        isLoading: false
                    })
                    this.leftBarRef && this.leftBarRef.getAllCategories(category_id)
                } else {
                    this.setState({
                        isLoading: false
                    })
                }
            }
        } catch (error) {
            throw (error)
        }
    }

    /**
    |--------------------------------------------------
    | AddCategory
    |--------------------------------------------------
    */

    AddCategory = async (e) => {
        e.preventDefault();
        try {
            if (this.validateForm()) {
                this.setState({
                    isLoading: true
                })
                let { name, color_code, adminId } = this.state
                let body = { name, color_code, user_id: adminId }
                const response = await this.props.callApi('/addCategory', body, 'post', 'ADMIN_LOGIN', true);
                if (response.data.code === 200) {
                    common.showSuccessMessage(response.data.message);
                    this.setState({
                        name: '',
                        color_code: '',
                        category_id: undefined,
                        addEditCategoryModalVisible: false,
                        isLoading: false
                    })
                    this.leftBarRef && this.leftBarRef.getAllCategories()
                } else {
                    this.setState({
                        isLoading: false
                    })
                }
            }
        } catch (error) {
            throw (error)
        }
    }

    /**
    |--------------------------------------------------
    | validateForm
    |--------------------------------------------------
    */

    validateForm() {
        let { name, isFormValid, errors } = this.state;
        if (!name.trim()) {
            errors.name = errorMessages.PROVIDE_NAME;
        } else {
            errors.name = ""
        }
        if (errors.name !== '') isFormValid = false;
        else isFormValid = true;
        this.setState({ errors, isFormValid })
        return isFormValid
    }

    /**
    |--------------------------------------------------
    | addningCategory
    |--------------------------------------------------
    */

    /**
    |--------------------------------------------------
    | addningCategory
    |--------------------------------------------------
    */

    addningCategory = (e) => {
        this.setState({ categoryVal: e, color_code: e.value })
    }

    /**
    |--------------------------------------------------
    | deleteCategory
    |--------------------------------------------------
    */

    deleteCategory = async () => {
        const { categories } = this.state
        swal({
            title: "Are you sure want to delete this Category ?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async willDelete => {
                if (willDelete) {
                    let body = {
                        category_id: categories.id
                    }
                    const response = await this.props.callApi('/removeCategory', body, 'post', null, true);
                    if (response.data.code === 200) {
                        common.showSuccessMessage(response.data.message);
                        let categoryColor = this.props.categoryColor;
                        let selectedIndex = this.props.categoryColor.findIndex(x => x.id == categories.id);
                        categoryColor.splice(selectedIndex, 1);
                        this.setParentStatus(categoryColor[selectedIndex]);
                        this.props.setCategoryColor([...categoryColor]);
                    }
                }
            });
    }

    /**
    |--------------------------------------------------
    handleChange = (event) => {
    | 
    |--------------------------------------------------
    */

    handleChange = (event) => {

        this.setState({ [event.target.name]: event.target.value })
        if (event.target.value) {
            this.setState({
                errors: Object.assign(this.state.errors, { [event.target.name]: "" })
            });
        } else {
            this.setState({
                errors: Object.assign(this.state.errors, { [event.target.name]: errorMessages.REQUIRED })
            })
        }
    }

    /**
    |--------------------------------------------------
    | selectAdmin
    |--------------------------------------------------
    */

    selectAdmin = (e) => {
        this.setState({ adminVal: e, adminId: e.value })
    }

    /**
    |--------------------------------------------------
    | render
    |--------------------------------------------------
    */

    render() {

        const { categories, viewCategory, errors, editCategory, name, color_code, adminVal, employeeList, isLoading } = this.state;
        var color_name = categories.color_code ? categories.color_code : ''

        var match = ntc.name("#" + color_name);
        var names = match[1];

        return (
            <div className="main-page-wrapper feedback-category-page">
                <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={true} animationInDelay={500}>
                    <MobileToggle {...this.props} />
                    <Leftbar onRef={ref => this.leftBarRef = ref} {...this.props} getChildEvent={this.setParentStatus} onPressAddEditCategoryModal={this.onPressAddEditCategoryModal} selectedCategory={categories} />
                    <SidebarAnnouncement {...this.props} />
                    <div className="content-area-wrapper">
                        <div className="content-area-inner">
                            {
                                viewCategory === true ?
                                    <div className="view-category">
                                        <h6>Category</h6>

                                        <div className="row mt-lg-5 mt-4">
                                            <div className="col-xl-6 col-lg-12 col-md-6 mb-4">
                                                <input
                                                    type="text"
                                                    name="name"
                                                    className="form-control"
                                                    value={categories.name}
                                                    readOnly
                                                />
                                            </div>
                                            <div className="col-xl-6 col-lg-12 col-md-6 mb-4">
                                                <Select
                                                    value={{
                                                        label: <div className="select-color">
                                                            <span className="color-dot" style={{ color: "#" + categories.color_code }}></span>
                                                            <span className="color-name " style={{ color: "#" + categories.color_code }}>{names}</span>
                                                        </div>
                                                    }}
                                                    // value={{ value: '#FFCF00', label: <div className="select-color"><span className="color-dot yellow"></span><span className="color-name">yellow</span></div> }}
                                                    onChange={this.handleChange}
                                                    options={colorSelector}
                                                    className="common-select"
                                                    classNamePrefix="form-control"
                                                    isDisabled
                                                />
                                            </div>

                                            <div className="col-12">
                                                <hr className="mt-1 mb-4" />
                                            </div>

                                            <div className="col-12 mb-4 text-right">
                                                <Button variant="outline-primary" onClick={() => this.deleteCategory()}>delete</Button>
                                                <Button onClick={this.editCategoryBtn} className="ml-3">edit</Button>
                                            </div>
                                        </div>
                                    </div> : null
                            }

                        </div>
                    </div>

                    <Modal className="common-modal small" centered show={this.state.addEditCategoryModalVisible} onHide={() => this.setState({ addEditCategoryModalVisible: false })}>
                        <button type="button" className="close fixed-close-btn" onClick={() => this.setState({ addEditCategoryModalVisible: false })}>
                            <span aria-hidden="true">×</span>
                        </button>
                        <Modal.Body className="">

                            <div className="">
                                <h6>{editCategory ? "Edit Category" : "Add New Category"}</h6>

                                <div className="row mt-lg-5 mt-4">
                                    <div className="col-xl-6 col-lg-12 col-md-6 mb-4">
                                        <input
                                            type="text"
                                            name="name"
                                            className="form-control"
                                            defaultValue="Admin"
                                            placeholder="Give it a name"
                                            value={name}
                                            onChange={this.handleChange}
                                        />
                                        <span className='error-msg'>{errors.name}</span>
                                    </div>
                                    <div className="col-xl-6 col-lg-12 col-md-6 mb-4">
                                        <Select
                                            // defaultValue={{ value: '#FFCF00', label: <div className="select-color"><span className="color-dot yellow"></span><span className="color-name">yellow</span></div> }}
                                            // onChange={this.handleChange}
                                            options={colorSelector}
                                            className="common-select"
                                            classNamePrefix="form-control"
                                            isSearchable={false}
                                            placeholder="Choose Color"
                                            value={{
                                                label: <div className="select-color"><span className="color-dot" style={{ color: "#" + color_code }}></span><span className="color-name " style={{ color: "#" + color_code }}>{color_code != "" ? ntc.name("#" + color_code)[1] : "Select Color"}</span></div>
                                            }}
                                            // value={this.state.categoryVal}
                                            // defaultValue={{ value: this.state.categoryVal ? this.state.categoryVal : '', label: <div className="select-color"><span className="color-dot" style={{ color: "#" + category_color }}></span><span className="color-name" style={{ color: "#" + category_color }}>{ntc.name("#" + category_color)[1]}</span></div> }}
                                            onChange={(e) => this.addningCategory(e)}
                                        />
                                    </div>

                                    {!editCategory && <div className="col-xl-6 col-lg-12 col-md-6 mb-4">
                                        <Select
                                            options={employeeList}
                                            className="common-select"
                                            classNamePrefix="form-control"
                                            isSearchable={false}
                                            placeholder="Select Admin"
                                            value={adminVal}
                                            onChange={(e) => this.selectAdmin(e)}
                                        />
                                    </div>}

                                    <div className="col-12">
                                        <hr className="mt-1 mb-4" />
                                    </div>

                                    <div className="col-12 mb-4 text-right">
                                        <Button variant="outline-primary" onClick={this.viewCategoryBtn}>Cancel</Button>
                                        <Button className="ml-3" onClick={editCategory ? this.updateCategory : this.AddCategory}>{isLoading ? "Loading..." : editCategory ? "Update" : "Create"}</Button>
                                    </div>
                                </div>
                            </div>

                        </Modal.Body>
                    </Modal>
                </Animated>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        userData: state.getUserData,
        categoryColor: state.categoryColor,
        employeeList: state.employeeList,
    }

}
export default withRouter(connect(mapStateToProps, { callApi, setCategoryColor, setEmployeeDetails })(FeedbackCategory))