import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Tab, Nav, Dropdown, Button } from 'react-bootstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { callApi, setCategoryList, setAllFeedBackData, setSentFeedBackData, setReceivedFeedBackData } from '../../../store/reducers/commonFunction'
import Pagination from 'rc-pagination'
import { Spinner } from 'react-activity';
import helper from '../../../services/helper';
import 'react-activity/dist/react-activity.css';
import 'rc-pagination/assets/index.css';
import * as moment from 'moment';
import _ from 'lodash';
import appConstant from '../../../constant/appConstant'

class Leftbar extends Component {

    /**
    |--------------------------------------------------
    | constructor
    |--------------------------------------------------
    */

    constructor(props) {
        super(props);
        this.state = {
            categoryList: [],
            receivedSuggestions: [],
            mySuggestion: [],
            multipleSelect: [],
            current_page: 1,
            per_page: 10,
            total: 1,
            last_page: false,
            total_pages: null,
            isFormValid: true,
            leftbar: false,
            userDetails: '',
            status: '',
            categories: '',
            visible: false,
            is_public: false,
            is_open: false,
            is_close: false,
            show: false,
            visibles: false,

            allPageLoading: false,
            current_all_page: 1,
            last_all_page: 1,

            sentPageLoading: false,
            current_sent_page: 1,
            last_sent_page: 1,

            receivedPageLoading: false,
            current_received_page: 1,
            last_received_page: 1,

            activeTab: 'all'
        }
    }

    /**
    |--------------------------------------------------
    | componentDidMount
    |--------------------------------------------------
    */

    async componentDidMount() {
        this.props.onRef && this.props.onRef(this)
        await this.getAllCategory();
        await this.getSuggestions();
    }

    /**
    |--------------------------------------------------
    | componentWillUnmount
    |--------------------------------------------------
    */

    componentWillUnmount() {
        this.props.onRef && this.props.onRef(undefined)
    }

    /**
    |--------------------------------------------------
    | getAllCategory
    |--------------------------------------------------
    */

    getAllCategory = async () => {

        let body = {}
        const response = await this.props.callApi('/getCategory', body, 'post', 'ADMIN_LOGIN', true);
        //  if(response.data !==undefined){
        this.props.setCategoryList(response.data || []);
        this.setState({ categories: response.data })
        //  }

    }

    /**
    |--------------------------------------------------
    | getSuggestions
    |--------------------------------------------------
    */

    getSuggestions = async (isAppend = true) => {
        this.setState({
            allPageLoading: true
        })
        let { current_all_page, multipleSelect, is_public, is_open, is_close } = this.state;
        let email = this.props.userData.email ? this.props.userData.email : '';
        let user_id = this.props.userData.user_id ? this.props.userData.user_id : '';
        let company_id = this.props.userData.company_id ? this.props.userData.company_id : '';


        let anonymous_token = helper.generateAnonymousKey(user_id, email)
        let body = {
            user_id, company_id, anonymous_token, page: current_all_page, category_id: multipleSelect.toString() != "" ? multipleSelect.toString() : undefined, is_public: is_public && is_public || undefined, is_open: is_open && is_open || undefined, is_close: is_close && is_close || undefined
        }
        console.log("getSuggestions body-->", JSON.stringify(body));
        console.log("checking45", is_public, is_open, is_close)
        const response = await this.props.callApi('/getSuggestions', body, 'post', null, true);
        console.log("getSuggestions response-->", JSON.stringify(response));

        if (response) {
            this.props.setAllFeedBackData(isAppend ? [...this.props.allFeedbackData, ...response.data] : response.data)
            this.setState({
                per_page: response.meta.per_page,
                total: response.meta.total,
                last_all_page: response.meta.last_page,
                show: false,
                visibles: false,
                allPageLoading: false
            }, () =>
                current_all_page == 1 && this.props.displayFeedBackData && this.props.displayFeedBackData())
            if (is_open || is_close || is_public || body.category_id) {
                console.log("checking453", is_public, is_open, is_close)
                this.setState({
                    visibles: true,
                })
            } else {
                this.setState({
                    visibles: false,
                })
            }
        }
    }

    /**
    |--------------------------------------------------
    | getCategoryId
    |--------------------------------------------------
    */

    getCategoryId = (catId) => {
        if (window.location.pathname !== '/feedback') {
            this.props.history.push({ pathname: '/feedback', feedbackId: catId })
        }
        if (this.props && this.props.getChildEvent) {
            this.props.getChildEvent(catId);
        }
        // this.props.getChildEvent(catId);
    }

    /**
    |--------------------------------------------------
    | updateStatus
    |--------------------------------------------------
    */

    updateStatus = (feedback_id, status, isType) => {

        let { allFeedbackData, sentFeedbackData, receivedFeedbackData } = this.props;
        let categoryListIndex = allFeedbackData.findIndex(x => x.feedback_id == feedback_id)
        let mySuggestionIndex = sentFeedbackData.findIndex(x => x.feedback_id == feedback_id)
        let receivedSuggestionsIndex = receivedFeedbackData.findIndex(x => x.feedback_id == feedback_id)

        console.log('categoryListIndex-->', categoryListIndex);
        console.log('mySuggestionIndex-->', mySuggestionIndex);
        console.log('receivedSuggestionsIndex-->', receivedSuggestionsIndex);

        if (categoryListIndex != -1) {
            let selectedInAllSuggestionData = allFeedbackData[categoryListIndex];
            console.log('selectedInAllSuggestionData-->', JSON.stringify(selectedInAllSuggestionData))
            if (selectedInAllSuggestionData) {
                if (isType) {
                    selectedInAllSuggestionData['type'] = status;
                } else {
                    selectedInAllSuggestionData['status'] = status;
                }

                allFeedbackData.splice(categoryListIndex, 1, selectedInAllSuggestionData);
            }
        }

        if (mySuggestionIndex != -1) {
            let selectedInMySuggestion = sentFeedbackData[mySuggestionIndex]
            console.log('selectedInMySuggestion-->', JSON.stringify(selectedInMySuggestion))
            if (selectedInMySuggestion) {
                if (isType) {
                    selectedInMySuggestion['type'] = status;
                } else {
                    selectedInMySuggestion['status'] = status;
                }

                sentFeedbackData.splice(mySuggestionIndex, 1, selectedInMySuggestion);
            }
        }
        if (receivedSuggestionsIndex != -1) {
            let selectedInReceivedSuggestionData = receivedFeedbackData[receivedSuggestionsIndex]
            console.log('selectedInReceivedSuggestionData-->', JSON.stringify(selectedInReceivedSuggestionData))
            if (selectedInReceivedSuggestionData) {
                if (isType) {
                    selectedInReceivedSuggestionData['type'] = status;
                } else {
                    selectedInReceivedSuggestionData['status'] = status;
                }
                receivedFeedbackData.splice(receivedSuggestionsIndex, 1, selectedInReceivedSuggestionData);
            }
        }
        this.props.setAllFeedBackData(allFeedbackData);
        this.props.setSentFeedBackData(sentFeedbackData);
        this.props.setReceivedFeedBackData(receivedFeedbackData);
    }

    /**
    |--------------------------------------------------
    | updateCategory
    |--------------------------------------------------
    */

    updateCategory = (feedback_id, category) => {
        let { allFeedbackData, sentFeedbackData, receivedFeedbackData } = this.props;
        let categoryListIndex = allFeedbackData && allFeedbackData.findIndex(x => x.feedback_id == feedback_id)
        let mySuggestionIndex = sentFeedbackData && sentFeedbackData.findIndex(x => x.feedback_id == feedback_id)
        let receivedSuggestionsIndex = receivedFeedbackData && receivedFeedbackData.findIndex(x => x.feedback_id == feedback_id)

        if (categoryListIndex != -1) {
            let selectedInAllSuggestionData = allFeedbackData[categoryListIndex];
            if (selectedInAllSuggestionData) {
                selectedInAllSuggestionData['category_id'] = category.id;
                selectedInAllSuggestionData['category_name'] = category.name;
                selectedInAllSuggestionData['category_color_code'] = category.color_code;
                allFeedbackData.splice(categoryListIndex, 1, selectedInAllSuggestionData);
            }
        }

        if (mySuggestionIndex != -1) {
            let selectedInMySuggestion = sentFeedbackData[mySuggestionIndex]
            if (selectedInMySuggestion) {

                selectedInMySuggestion['category_id'] = category.id;
                selectedInMySuggestion['category_name'] = category.name;
                selectedInMySuggestion['category_color_code'] = category.color_code;

                sentFeedbackData.splice(mySuggestionIndex, 1, selectedInMySuggestion);
            }
        }
        if (receivedSuggestionsIndex != -1) {
            let selectedInReceivedSuggestionData = receivedFeedbackData[receivedSuggestionsIndex]
            if (selectedInReceivedSuggestionData) {

                selectedInReceivedSuggestionData['category_id'] = category.id;
                selectedInReceivedSuggestionData['category_name'] = category.name;
                selectedInReceivedSuggestionData['category_color_code'] = category.color_code;

                receivedFeedbackData.splice(receivedSuggestionsIndex, 1, selectedInReceivedSuggestionData);
            }
        }
        this.props.setAllFeedBackData([...allFeedbackData]);
        this.props.setSentFeedBackData([...sentFeedbackData]);
        this.props.setReceivedFeedBackData([...receivedFeedbackData]);
    }

    /**
    |--------------------------------------------------
    | closeModal
    |--------------------------------------------------
    */

    closeModal = () => {
        this.setState({
            visible: !this.state.visible,
        })
    }

    /**
    |--------------------------------------------------
    | getMySuggestions
    |--------------------------------------------------
    */

    getMySuggestions = async () => {
        this.setState({
            sentPageLoading: true
        })
        let { current_sent_page, multipleSelect, is_public, is_open, is_close } = this.state;
        let email = this.props.userData.email ? this.props.userData.email : '';
        let user_id = this.props.userData.user_id ? this.props.userData.user_id : '';
        let company_id = this.props.userData.company_id ? this.props.userData.company_id : '';


        let sug_token = helper.generateAnonymousKey(user_id, email);
        let body = {
            user_id, company_id, anonymous_token: sug_token, page: current_sent_page, category_id: multipleSelect.toString() != '' ? multipleSelect.toString() : undefined, is_public: is_public && is_public || undefined, is_open: is_open && is_open || undefined, is_close: is_close && is_close || undefined
        }
        console.log('getMySuggestions body-->', JSON.stringify(body));
        const response = await this.props.callApi('/getMySuggestions', body, 'post', null, true);
        console.log('getMySuggestions response-->', JSON.stringify(response));

        if (response) {
            this.props.setSentFeedBackData([...this.props.sentFeedbackData, ...response.data])
            this.setState({
                per_page: response.meta.per_page,
                total: response.meta.total,
                last_sent_page: response.meta.last_page,
                sentPageLoading: false,
                show: false,
                visibles: false,
            }, () => {
                current_sent_page == 1 && this.props.displayFeedBackData && this.props.displayFeedBackData()
            })
            if (is_open || is_close || is_public || body.category_id) {
                this.setState({
                    visibles: true,
                    // receivedSuggestions:'',
                    // categoryList:'',
                })
            } else {
                this.setState({
                    visibles: false,
                    // is_open:false,
                })
            }
        }
    }

    /**
    |--------------------------------------------------
    | filter
    |--------------------------------------------------
    */

    filter = () => {
        this.props.setAllFeedBackData([])
        this.props.setSentFeedBackData([])
        this.props.setReceivedFeedBackData([])
        this.setState({
            current_all_page: 1,
            current_sent_page: 1,
            current_received_page: 1

        }, () => this.getMySuggestions(false), this.getReceivedSuggestions(false), this.getSuggestions(false))
    }

    /**
    |--------------------------------------------------
    | getReceivedSuggestions
    |--------------------------------------------------
    */

    getReceivedSuggestions = async () => {
        this.setState({
            receivedPageLoading: true
        })
        let { multipleSelect, is_public, is_open, is_close, current_received_page } = this.state;
        let user_id = this.props.userData.user_id ? this.props.userData.user_id : '';
        let company_id = this.props.userData.company_id ? this.props.userData.company_id : '';
        let body = {
            user_id, company_id, page: current_received_page, category_id: multipleSelect.toString() != '' ? multipleSelect.toString() : undefined, is_public: is_public && is_public || undefined, is_open: is_open && is_open || undefined, is_close: is_close && is_close || undefined
        }
        console.log('getReceivedSuggestions body-->', JSON.stringify(body))
        const response = await this.props.callApi('/getReceivedSuggestions', body, 'post', null, true);
        console.log('getReceivedSuggestions response-->', JSON.stringify(response))

        if (response) {
            this.props.setReceivedFeedBackData([...this.props.receivedFeedbackData, ...response.data])
            this.setState({
                per_page: response.meta.per_page,
                total: response.meta.total,
                last_received_page: response.meta.last_page,
                receivedPageLoading: false,
                show: false,
                visibles: false,
            }, () => {
                current_received_page == 1 && this.props.displayFeedBackData && this.props.displayFeedBackData()
            })
            if (is_open || is_close || is_public || body.category_id) {
                this.setState({
                    visibles: true,
                    // mySuggestion:'',
                    // categoryList:'',
                })
            } else {
                this.setState({
                    visibles: false,
                    // is_open:false,
                })
            }
        } else {
            this.setState({
                receivedPageLoading: false,
            })
        }
    }

    /**
    |--------------------------------------------------
    | LeftbarToggle
    |--------------------------------------------------
    */

    LeftbarToggle = () => {
        this.setState({ leftbar: !this.state.leftbar },
            function () {
                document.body.classList.remove('leftbar-open');
            });
    }

    /**
    |--------------------------------------------------
    | onPublic
    |--------------------------------------------------
    */

    onPublic() {
        this.setState({
            is_public: !this.state.is_public
        })
    }

    /**
    |--------------------------------------------------
    | onOpen
    |--------------------------------------------------
    */

    onOpen() {
        this.setState({
            is_open: !this.state.is_open
        })
    }

    /**
    |--------------------------------------------------
    | onClose
    |--------------------------------------------------
    */

    onClose() {
        console.log("checking")
        this.setState({
            is_close: !this.state.is_close
        })
    }

    /**
    |--------------------------------------------------
    | checkArray
    |--------------------------------------------------
    */

    checkArray = (id) => {

        let { multipleSelect } = this.state;
        if (multipleSelect.includes(id)) return true;
        else return false;
    }

    /**
    |--------------------------------------------------
    | onCheckbox
    |--------------------------------------------------
    */

    onCheckbox(id) {

        var delarray = this.state.multipleSelect;
        if (!delarray.includes(id)) {
            delarray.push(id);
        }
        else {
            delarray.splice(delarray.indexOf(id), 1);
        }
        if (delarray.length !== this.state.categories.length) {
            this.setState({ checked: false });
        }
        if (this)
            this.setState({ multipleSelect: delarray })
    }

    /**
    |--------------------------------------------------
    | dropdownToggle
    |--------------------------------------------------
    */

    dropdownToggle = () => {
        this.setState({ show: !this.state.show });
    }

    /**
    |--------------------------------------------------
    | closeMenu
    |--------------------------------------------------
    */

    closeMenu = () => {

        var x = document.getElementsByClassName("checkbox");

        for (let i = 0; i <= x.length; i++) {
            if (x[i]) {
                x[i].checked = false;
            }
        }

        this.setState({
            // categories:[],
            show: false,
            checked: false,
            visibles: false,
            multipleSelect: [],
            is_close: false,
            is_open: false,
            is_public: false,
            current_all_page: 1,
            current_sent_page: 1,
            current_received_page: 1
        }, () => {
            this.getSuggestions(false);
            this.getMySuggestions(false);
            this.getReceivedSuggestions(false);
        })

        // }
    }

    /**
    |--------------------------------------------------
    | getUserName
    |--------------------------------------------------
    */

    getUserName = (data) => {
        const { userData } = this.props;
        if (data.is_anonymous === 1) {
            return (helper.generateAnonymousKey(userData.user_id, userData.email) == data.user_id || _.get(this.props, 'userData.user_id') == data.user_id) ? "You" : "Anonymous"
        } else {
            return (_.get(this.props, 'userData.user_id') == data.user_id) ? "You" : _.get(data, 'user_first_name') || ''
        }
    }

    /**
    |--------------------------------------------------
    | handleAllScroll
    |--------------------------------------------------
    */

    handleAllScroll = (e) => {
        const { activeTab } = this.state;
        let element = e.target
        if (element.scrollTop + element.offsetHeight >= element.scrollHeight) {
            if (activeTab === 'all' && this.state.current_all_page != this.state.last_all_page) {
                this.setState({ current_all_page: this.state.current_all_page + 1 }, () => {
                    this.getSuggestions()
                })
            } else if (activeTab === 'sent' && this.state.current_sent_page != this.state.last_sent_page) {
                this.setState({ current_sent_page: this.state.current_sent_page + 1 }, () => {
                    // alert(this.state.current_sent_page)
                    this.getMySuggestions()
                })
            } else if (activeTab === 'received' && this.state.current_received_page != this.state.last_received_page) {
                this.setState({ current_received_page: this.state.current_received_page + 1 }, () => {
                    this.getReceivedSuggestions()
                })
            }
        }
    }

    /**
    |--------------------------------------------------
    | renderNoRecordFound
    |--------------------------------------------------
    */

    renderNoRecordFound = () => {
        let title = (this.state.visibles) ? "Try Another Filter" : "Share anything you feel!"
        let subTitle = (this.state.visibles) ? "We did not find any records matching with you filter.Try something different!" : "Select topic and send suggestions regarding\nanything with your name or anonymously"
        return (
            <div className="empty-no-data">
                <img src="images/img-empty-suggestions.png" alt="no data found" />
                <h6>{title}</h6>
                <span>{subTitle}</span>
            </div>
        )
    }

    /**
    |--------------------------------------------------
    | render
    |--------------------------------------------------
    */

    render() {
        // let feedback_id = this.props.feedbackslist.data.feedback_id?this.props.feedbackslist.data.feedback_id:'';
        const { allFeedbackData, sentFeedbackData, receivedFeedbackData } = this.props;
        let role = _.get(this.props, 'userData.role') || '';
        var { mySuggestion, categoryList, receivedSuggestions, total, current_page, per_page, categories, visible } = this.state;

        return (
            <div className="leftbar">

                <span className="leftbar-toggler" onClick={this.LeftbarToggle}>
                    <span className="material-icons">arrow_back</span>
                </span>

                {/* <Link to="/newFeedback" className="btn plus-btn">+</Link> */}
                <div className="btn plus-btn" onClick={() => this.props.openAddFeedbackModal()}>+</div>

                <Dropdown alignRight className="d-inline-block filter-dropdown" show={this.state.show} onToggle={this.dropdownToggle}>
                    <Dropdown.Toggle id="dropdown-basic" className="filter-btn position-relative">
                        <span className="material-icons">filter_list</span>
                        {
                            this.state.visibles ?
                                <span className="red-dot bg-danger position-absolute rounded-circle"></span>
                                : null
                        }
                    </Dropdown.Toggle>
                    {/* <PerfectScrollbar> */}
                    <Dropdown.Menu visible={visible} className="">
                        <Dropdown.Header className="px-0">Status</Dropdown.Header>
                        <div className="d-block">
                            <div className="checkbox-radio">
                                <label>
                                    <input className="checkbox" type="checkbox" value="" onChange={() => this.onOpen()} />
                                    <span>
                                        <span className="checkbox-style" value="open" ></span>
                                        <span className="text tnc-check" >Open
                                        </span>
                                    </span>
                                </label>
                            </div>
                        </div>
                        <div className="d-block">
                            <div className="checkbox-radio">
                                <label>
                                    <input className="checkbox" type="checkbox" value="" onChange={() => this.onClose()} ref="minus" />
                                    <span>
                                        <span className="checkbox-style"></span>
                                        <span className="text tnc-check">Close
                                        </span>
                                    </span>
                                </label>
                            </div>
                        </div>
                        <div className="d-block">
                            <div className="checkbox-radio">
                                <label>
                                    <input className="checkbox" type="checkbox" value="" onChange={() => this.onPublic()} ref="minus" />
                                    <span>
                                        <span className="checkbox-style" value="open"></span>
                                        <span className="text tnc-check">Public
                                        </span>
                                    </span>
                                </label>
                            </div>
                        </div>
                        <Dropdown.Header className="px-0">Category</Dropdown.Header>
                        <div className="d-block">
                            {
                                categories && Array.isArray(categories) && categories.map((category, key) => {
                                    return (
                                        <div className="checkbox-radio w-100" key={key}>
                                            <label>
                                                <input className="checkbox" type="checkbox" value="" checked={(this.checkArray(category.id)) ? true : false} onChange={() => this.onCheckbox(category.id)} />
                                                <span>
                                                    <span className="checkbox-style"></span>
                                                    <span className="text tnc-check" style={{ color: "#" + category.color_code }}>{category.name}
                                                    </span>
                                                </span>
                                            </label>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="d-flex justify-content-between align-items-center py-2" >
                            <Button onClick={this.filter} className="px-3">Filter</Button>
                            {/* ,is_open:false,is_public:false,is_close:false,multipleSelect:'' */}
                            <Button variant="outline-primary" className="px-2" onClick={this.closeMenu}>Clear All</Button>
                        </div>
                    </Dropdown.Menu>
                    {/* </PerfectScrollbar> */}

                </Dropdown>

                <Tab.Container id="left-tabs-example" defaultActiveKey="all" onSelect={(activeTab) => this.setState({ activeTab })}>
                    <Nav variant="tabs" className="">
                        <Nav.Item>
                            <Nav.Link eventKey="all" onClick={() => {
                                this.setState({
                                    current_all_page: 1
                                }, () => {
                                    this.props.setAllFeedBackData([]);
                                    this.getSuggestions()
                                })
                            }}>ALL</Nav.Link>
                        </Nav.Item>

                        <Nav.Item>
                            <Nav.Link eventKey="sent" onClick={() => {
                                this.setState({
                                    current_sent_page: 1
                                }, () => {
                                    this.props.setSentFeedBackData([]);
                                    this.getMySuggestions()
                                })
                            }}>sent</Nav.Link>
                        </Nav.Item>
                        {(role === appConstant.ROLE.COMPANY_ADMIN || role === appConstant.ROLE.ADMIN) && <Nav.Item>
                            <Nav.Link eventKey="received" onClick={() => {
                                this.setState({
                                    current_received_page: 1
                                }, () => {
                                    this.props.setReceivedFeedBackData([]);
                                    this.getReceivedSuggestions();
                                })
                            }}>RECEIVED</Nav.Link>
                        </Nav.Item>}
                    </Nav>
                    <Tab.Content>

                        <Tab.Pane eventKey="all">
                            <ul className="leftbar-list feedback-list">
                                <PerfectScrollbar onScroll={this.handleAllScroll}>
                                    {
                                        allFeedbackData && Array.isArray(allFeedbackData) && allFeedbackData.map((category, key) => {
                                            let titleText = category.first_message
                                            let subTitleText = category.last_message
                                            let date = helper.getFormateDate(category.last_message_date);
                                            let listItemClassName = (category.feedback_id == _.get(this.props, 'selected_feedback.feedback_id')) ? "leftbar-list-item active" : "leftbar-list-item"
                                            return (
                                                <li className={listItemClassName} key={key} onClick={() => { this.getCategoryId(category.feedback_id) }}>
                                                    <div className="leftbar-list-item-inner" >
                                                        <div className="user-img">
                                                            <div className="user-img-wrapper">
                                                                {
                                                                    (category.is_anonymous === 1) ? <img src="images/annonymous.jpg" alt="" /> :

                                                                        <img src={category.image} alt="images/may-tucker.jpg" />
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="user-data added-icons">
                                                            <div className="user-name">
                                                                <span>{this.getUserName(category)}</span>
                                                                <span className="user-category" style={{ color: "#" + category.category_color_code }}>{category.category_name}</span>
                                                                <span className="category-icons-block">
                                                                    {
                                                                        (category.status === "close") &&
                                                                        <span className="icons-block">
                                                                            <span className="material-icons">lock</span>
                                                                        </span>
                                                                    }
                                                                    {
                                                                        ((role === appConstant.ROLE.COMPANY_ADMIN || role === appConstant.ROLE.ADMIN || role === appConstant.ROLE.CATEGORY_ADMIN) && category.type === "public") &&
                                                                        <span className="icons-block">
                                                                            <span className="material-icons">people</span>
                                                                        </span>
                                                                    }

                                                                </span>
                                                            </div>
                                                            <div className="user-data-inner">
                                                                <div className="data-message">
                                                                    <div className="data-text black-text">{titleText}</div>
                                                                    {subTitleText && subTitleText != '' && <div className="data-text">{subTitleText}</div>}
                                                                </div>
                                                                <div className="date-wrapper">
                                                                    <div className="user-date-inner">
                                                                        <span className="date">{date}</span>
                                                                        <span className="material-icons" onClick={() => { this.getCategoryId(category.feedback_id) }}>keyboard_arrow_right</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            )
                                        })
                                    }
                                    {
                                        (!this.state.allPageLoading && allFeedbackData && allFeedbackData.length === 0) && this.renderNoRecordFound()
                                    }

                                    {this.state.allPageLoading && <div className={'loader-block'}>
                                        <Spinner />
                                    </div>}
                                </PerfectScrollbar>
                            </ul>
                        </Tab.Pane>

                        <Tab.Pane eventKey="sent">
                            <ul className="leftbar-list feedback-list">
                                <PerfectScrollbar onScroll={this.handleAllScroll}>
                                    {
                                        sentFeedbackData && Array.isArray(sentFeedbackData) && sentFeedbackData.map((suggestion, key) => {
                                            let date = helper.getFormateDate(suggestion.last_message_date);
                                            let listItemClassName = (suggestion.feedback_id == _.get(this.props, 'selected_feedback.feedback_id')) ? "leftbar-list-item leftbar-sent-item active" : "leftbar-list-item leftbar-sent-item"
                                            return (
                                                <li className={listItemClassName} key={key} onClick={() => { this.getCategoryId(suggestion.feedback_id) }}>
                                                    <div className="leftbar-list-item-inner" >
                                                        <div className="user-img">
                                                            <div className="user-img-wrapper">
                                                                {
                                                                    (suggestion.is_anonymous === 1) ? <img src="images/annonymous.jpg" alt="" /> :

                                                                        <img src={suggestion.image} alt="images/may-tucker.jpg" />
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="user-data added-icons">
                                                            <div className="user-name">
                                                                {/* {
                                                                    (suggestion.is_anonymous === 1) ? <span>Anonymous</span> :
                                                                        <span>{suggestion.user_first_name}</span>
                                                                } */}
                                                                <span className="user-category" style={{ color: "#" + suggestion.category_color_code }}>{suggestion.category_name}</span>
                                                                <span className="category-icons-block">
                                                                    {
                                                                        (suggestion.status == "close") &&
                                                                        <span className="icons-block">
                                                                            <span className="material-icons">lock</span>
                                                                        </span>
                                                                    }
                                                                    {
                                                                        ((role === appConstant.ROLE.COMPANY_ADMIN || role === appConstant.ROLE.ADMIN || role === appConstant.ROLE.CATEGORY_ADMIN) && suggestion.type === "public") &&
                                                                        <span className="icons-block">
                                                                            <span className="material-icons">people</span>
                                                                        </span>
                                                                    }
                                                                </span>
                                                            </div>
                                                            <div className="user-data-inner">
                                                                <div className="data-message">
                                                                    <div className="data-text black-text">{suggestion.first_message}</div>
                                                                    {suggestion.last_message && suggestion.last_message != '' && <div className="data-text">{suggestion.last_message}</div>}
                                                                </div>
                                                                <div className="date-wrapper">
                                                                    <div className="user-date-inner">
                                                                        <span className="date">{date}</span>
                                                                        <span className="material-icons" onClick={() => { this.getCategoryId(suggestion.feedback_id) }}>keyboard_arrow_right</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            )
                                        })
                                    }
                                    {
                                        (!this.state.sentPageLoading && sentFeedbackData && sentFeedbackData.length === 0) && this.renderNoRecordFound()

                                    }
                                    {this.state.sentPageLoading && <div className={'loader-block'}>
                                        <Spinner />
                                    </div>}
                                </PerfectScrollbar>
                            </ul>
                        </Tab.Pane>

                        {(role === appConstant.ROLE.COMPANY_ADMIN || role === appConstant.ROLE.ADMIN) && <Tab.Pane eventKey="received">
                            <ul className="leftbar-list feedback-list">
                                <PerfectScrollbar onScroll={this.handleAllScroll}>
                                    {
                                        receivedFeedbackData && Array.isArray(receivedFeedbackData) && receivedFeedbackData.map((recivedsuggestion, key) => {
                                            let listItemClassName = (recivedsuggestion.feedback_id == _.get(this.props, 'selected_feedback.feedback_id')) ? "leftbar-list-item active" : "leftbar-list-item"
                                            return (
                                                <li className={listItemClassName} key={key} onClick={() => { this.getCategoryId(recivedsuggestion.feedback_id) }}>
                                                    <div className="leftbar-list-item-inner" >
                                                        <div className="user-img">
                                                            <div className="user-img-wrapper">
                                                                {
                                                                    (recivedsuggestion.is_anonymous === 1) ? <img src="images/annonymous.jpg" alt="" /> :

                                                                        <img src={recivedsuggestion.image} alt="images/may-tucker.jpg" />
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="user-data added-icons">
                                                            <div className="user-name">
                                                                {
                                                                    (recivedsuggestion.is_anonymous === 1) ? <span>Anonymous</span> :
                                                                        <span>{this.getUserName(recivedsuggestion)}</span>
                                                                }
                                                                <span className="user-category" style={{ color: "#" + recivedsuggestion.category_color_code }}>{recivedsuggestion.category_name}</span>
                                                                <span className="category-icons-block">
                                                                    {
                                                                        (recivedsuggestion.status === "close") &&
                                                                        <span className="icons-block">
                                                                            <span className="material-icons">lock</span>
                                                                        </span>
                                                                    }
                                                                    {
                                                                        ((role === appConstant.ROLE.COMPANY_ADMIN || role === appConstant.ROLE.ADMIN || role === appConstant.ROLE.CATEGORY_ADMIN) && recivedsuggestion.type === "public") &&
                                                                        <span className="icons-block">
                                                                            <span className="material-icons">people</span>
                                                                        </span>
                                                                    }
                                                                </span>
                                                            </div>
                                                            <div className="user-data-inner">
                                                                <div className="data-message">
                                                                    <div className="data-text black-text">{recivedsuggestion.first_message}</div>
                                                                    {recivedsuggestion.last_message && recivedsuggestion.last_message != '' && <div className="data-text">{recivedsuggestion.last_message}</div>}
                                                                </div>
                                                                <div className="date-wrapper">
                                                                    <div className="user-date-inner">
                                                                        <span className="date">{helper.getFormateDate(recivedsuggestion.last_message_date)}</span>
                                                                        <span className="material-icons" onClick={() => { this.getCategoryId(recivedsuggestion.feedback_id) }}>keyboard_arrow_right</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            )
                                        })
                                    }
                                    {
                                        (!this.state.receivedPageLoading && receivedFeedbackData && receivedFeedbackData.length === 0) && this.renderNoRecordFound()
                                    }

                                    {this.state.receivedPageLoading && <div className={'loader-block'}>
                                        <Spinner />
                                    </div>}
                                </PerfectScrollbar>
                            </ul>
                        </Tab.Pane>}
                    </Tab.Content>
                </Tab.Container>

            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        userData: state.getUserData,
        categories: state.allCategoriesDetails,
        feedbackslist: state.feedbackList,
        allFeedbackData: state.allFeedbackData,
        sentFeedbackData: state.sentFeedbackData,
        receivedFeedbackData: state.receivedFeedbackData,
    }
}
export default withRouter(connect(mapStateToProps, { callApi, setCategoryList, setAllFeedBackData, setSentFeedBackData, setReceivedFeedBackData })(Leftbar))