export default class DI {

    constructor() {
        this.registry = {};
        this.recipes = {};
    }

    register(name, recipe) {
        this.recipes[name] = recipe;
    }

    make(name) {
        if (typeof this.registry[name] !== 'undefined') {
            return this.registry[name];
        }
        return this.recipes[name]();
    }
}