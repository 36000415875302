import * as moment from 'moment';
import crypto from 'crypto';
import appConstant from '../constant/appConstant';
var CryptoJS = require("crypto-js");

var md5 = require("md5");
var sha1 = require('sha1');
export default {
    eventDate: function (dateString) {
        return moment(dateString).format('MMMM D, YYYY');
    },
    formatTimeAgo: function (createDate) {
        let date = moment(createDate).format()
        let fromNow = moment(date).fromNow()
        let today = moment(new Date()).format();
        let diff = moment(today).diff(date, 'hours')
        let yesterday = moment(new Date()).subtract(1, 'day').format('DD-MM-YYYY')

        let date1 = moment(createDate).format('DD-MM-YYYY')
        let today1 = moment(new Date()).format('DD-MM-YYYY')

        if (date1 === today1) {
            if (fromNow === 'a few seconds ago') {
                return 'just now'
            } else if (fromNow === 'an hour ago') {
                return 'last hour'
            } else {
                return fromNow
            }
        } else if (date1 === yesterday) {
            return `Yesterday, ${moment(date).format('hh:mm A')}`
        } else {
            if (new Date(createDate).getFullYear() === new Date().getFullYear()) {
                return moment(date).format('MMM DD, hh:mm A')
            } else {
                return moment(date).format('MMM DD, YYYY hh:mm A')
            }
        }
    },
    formatDetailsDate: function (createDate) {
        let date = moment(createDate).format()

        let yesterday = moment(new Date()).subtract(1, 'day').format('DD-MM-YYYY')

        let date1 = moment(createDate).format('DD-MM-YYYY')
        let today1 = moment(new Date()).format('DD-MM-YYYY')

        if (date1 === today1) {
            return moment(createDate).format('hh:mm A')
        } else if (date1 === yesterday) {
            return `Yesterday, ${moment(date).format('hh:mm A')}`
        } else {
            if (new Date(createDate).getFullYear() === new Date().getFullYear()) {
                return moment(date).format('MMM DD, hh:mm A')
            } else {
                return moment(date).format('MMM DD, YYYY hh:mm A')
            }
        }
    },
    getFormateDate: function (createDate) {
        if (moment(new Date()).format('DD-MM-YYYY') == moment(createDate).format('DD-MM-YYYY')) {
            return moment(new Date(createDate)).format('h:MM A')
        } else {
            return moment(new Date(createDate)).format('MMM DD')
        }
    },
    isValidUrl: function isValidURL(string) {
        var res = string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
        return (res !== null)
    },
    isValidPhoneNumber: function isValidPhoneNumber(string) {
        var res = string.match(/^(\+\d{1,3}[- ]?)?\d{10}$/);
        return (res !== null)
    },
    isValidEmail: function isValidEmail(string) {
        let reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        var res = reg.test(string);
        return (res == null)
    },
    getRoleName: function getRoleName(role) {
        if (role === appConstant.ROLE.COMPANY_ADMIN) {
            return "Company Admin"
        } else if (role === appConstant.ROLE.ADMIN) {
            return "Admin"
        } else if (role === appConstant.ROLE.CATEGORY_ADMIN) {
            return "Category Admin"
        } else {
            return "Employee"
        }
    },
    generateAnonymousKey: function generateAnonymousKey(user_id, email){
        
        let token1 = btoa(btoa(btoa(btoa(user_id)))) + "DQau01uxK5Pe47f19EcxBxZHo1HzsiAgv3MbMih4C8QYG9wFYXHOeYrq5WxIOYCd" + btoa(btoa(btoa(btoa(email))))
        let anonymous_token = btoa(btoa(btoa(btoa(token1))))
        let strToken = btoa(md5(email));
        let strToken1 = btoa(sha1(email));
        let iv = strToken.substring(0, 16);
        let key = strToken1.substring(0, 32);
        let cryptoCipherIV= crypto.createCipheriv('aes-256-cbc', key, iv);
        var cipherText = cryptoCipherIV.update(anonymous_token,'utf8','Base64')
        // var cipherText = CryptoJS.AES.encrypt(anonymous_token,key, {iv: iv}).toString();
        cipherText += cryptoCipherIV.final("Base64");
        // console.log("@anonymous_token@",cipherText)
        return cipherText
    }
};
