import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Modal, Dropdown, } from 'react-bootstrap';
// import PerfectScrollbar from 'react-perfect-scrollbar';
import Select from 'react-select';
import { Animated } from 'react-animated-css';
import common from '../../store/reducers/commons';
import { callApi, categoryDetails, allCategoryDetails, setAllFeedBackData } from '../../store/reducers/commonFunction'
import MobileToggle from '../mobileToggles/mobileToggles';
import { connect } from 'react-redux';
import SidebarAnnouncement from '../sidebarAnnouncement/SidebarAnnouncement';
import errorMessages from '../../store/reducers/errorMessages'
import { withRouter } from 'react-router';
import swal from 'sweetalert'
import Leftbar from './leftbar/Leftbar';
class NewFeedback extends Component {

    /**
    |--------------------------------------------------
    | constructor
    |--------------------------------------------------
    */

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            filename: '',
            file: '',
            errors: {
                message: '',
                type: ''
            },
            categoryList: '',
            isFormValid: true,
            message: '',
            category_id: 0,
            // status:true,
            contentArea: false,
            anonymousModal: false,
            show: false,
            showMessage: false,
            imagePreviewUrl: '',
            categoryVal: undefined,
            isLoading: false
        }
    }

    /**
    |--------------------------------------------------
    | handleClose
    |--------------------------------------------------
    */

    handleClose = (modelName) => {
        this.setState({ show: false });
    }

    /**
    |--------------------------------------------------
    | handleShow
    |--------------------------------------------------
    */

    handleShow = (modelName) => {
        this.setState({ show: true });
    }

    /**
    |--------------------------------------------------
    | componentDidMount
    |--------------------------------------------------
    */

    async componentDidMount() {
        // this.setState({category_id:this.props.})   
        await this.getAllCategory();
    }

    /**
    |--------------------------------------------------
    | getAllCategory
    |--------------------------------------------------
    */

    getAllCategory = async () => {

        let body = {}
        const response = await this.props.callApi('/getCategory', body, 'post', 'ADMIN_LOGIN', true);
        if (response) {
            this.props.allCategoryDetails(response.data || [])
            this.setState({ categoryList: response.data })
        }

    }

    /**
    |--------------------------------------------------
    | handleChange
    |--------------------------------------------------
    */

    handleChange = (event) => {

        this.setState({ [event.target.name]: event.target.value })
        if (event.target.value) {
            this.setState({
                errors: Object.assign(this.state.errors, { [event.target.name]: "" })
            });
        } else {
            this.setState({
                errors: Object.assign(this.state.errors, { [event.target.name]: errorMessages.REQUIRED })
            })
        }
    }

    /**
    |--------------------------------------------------
    | changeCategory
    |--------------------------------------------------
    */

    changeCategory = (e) => {
        this.setState({ categoryVal: e.name, category_id: e.id }, () => this.forceUpdate())
    }

    /**
    |--------------------------------------------------
    | AddFeedback
    |--------------------------------------------------
    */

    AddFeedback = async () => {
        try {
            var { message, category_id, file, thumbimage, image_width, image_height, duration } = this.state;
            let user_id = this.props.userData.user_id ? this.props.userData.user_id : '';
            let company_id = this.props.userData.company_id ? this.props.userData.company_id : '';

            if (this.validateForm()) {
                this.setState({
                    isLoading: true
                })
                const formData = new FormData()
                if (file) {
                    if (file.type === 'video/mp4') {
                        formData.append('video', file)
                        formData.append('image', thumbimage)
                        formData.append('image_width', image_width)
                        formData.append('image_height', image_height)
                        formData.append('video_duration', duration)
                    } else {
                        formData.append('image', file)
                    }
                }
                formData.append('company_id', company_id)
                formData.append('message', message)
                formData.append('category_id', category_id)
                formData.append('user_id', user_id)

                const response = await this.props.callApi('/newSuggestion', formData, 'post', null, true);
                if (response) {
                    common.showSuccessMessage("Thank you so much for your suggestion/feedback. We will get back to you very soon");
                    if (this.leftBarRef) {
                        this.leftBarRef.setState({
                            current_all_page: 1,
                            isLoading: false
                        }, () => {
                            this.props.setAllFeedBackData([])
                            this.leftBarRef.getSuggestions(false)
                        })
                    }
                    this.props.history.push("/feedback")

                }
            }
        } catch (error) {
            this.setState({
                isLoading: false
            })
            throw (error)
        }
    }

    /**
    |--------------------------------------------------
    | AddAnonymousFeedback
    |--------------------------------------------------
    */

    AddAnonymousFeedback = async (e) => {
        e.preventDefault();
        try {
            var { message, category_id, file } = this.state;
            let email = this.props.userData.email ? this.props.userData.email : '';
            let user_id = this.props.userData.user_id ? this.props.userData.user_id : '';
            let company_id = this.props.userData.company_id ? this.props.userData.company_id : '';

            let token1 = btoa(btoa(btoa(btoa(user_id)))) + "DQau01uxK5Pe47f19EcxBxZHo1HzsiAgv3MbMih4C8QYG9wFYXHOeYrq5WxIOYCd" + btoa(btoa(btoa(btoa(email))))
            let anonymous_token = btoa(btoa(btoa(btoa(token1))))
            console.log("@@", anonymous_token)
            var md5 = require("md5");
            var sha1 = require('sha1');
            let strtoken = btoa(md5(email));
            let strtoken1 = btoa(sha1(email));
            let iv = strtoken.substring(0, 16);
            let key = strtoken1.substring(0, 32);
            var text = anonymous_token;
            var CryptoJS = require("crypto-js");
            var ciphertext = CryptoJS.AES.encrypt(key, iv, text);

            if (this.validateForm()) {
                const formData = new FormData()
                if (file) {
                    if (file.type === 'video/mp4') {
                        formData.append('video', file)
                    } else {
                        formData.append('image', file)
                    }
                }
                formData.append('company_id', company_id)
                formData.append('message', message)
                formData.append('category_id', category_id)
                formData.append('anonymous_token', ciphertext)

                const response = await this.props.callApi('/newAnnonymousSuggestion', formData, 'post', null, true);
                if (response) {
                    common.showSuccessMessage("Thank you so much for your suggestion/feedback. We will get back to you very soon");
                    this.props.history.push("/feedback")
                }
            }
        } catch (error) {
            throw (error)
        }
    }

    /**
    |--------------------------------------------------
    | fileChangedHandlerImg
    |--------------------------------------------------
    */

    async fileChangedHandlerImg(event) {
        let file = event.target.files[0];
        let filename = event.target.files[0].name
        if (event.target.files[0].type.match('image')) {
            let reader = new FileReader()
            // let file = event.target.files[0]
            // let filename = event.target.files[0].name

            reader.onloadend = () => {
                this.setState({
                    file: file,
                    filename: filename,
                    imagePreviewUrl: reader.result,
                    fileVisible: true
                })
            }
            reader.readAsDataURL(file)
        } else {
            let fileReader = new FileReader()
            var _that = this;
            fileReader.onloadend = function () {
                var blob = new Blob([fileReader.result], { type: file.type });
                var url = URL.createObjectURL(blob);
                var video = document.createElement('video');

                var timeupdate = function () {
                    if (snapImage()) {
                        video.removeEventListener('timeupdate', timeupdate);
                        video.pause();
                    }
                };
                video.addEventListener('loadeddata', function () {
                    if (snapImage()) {
                        video.removeEventListener('timeupdate', timeupdate);
                    }
                });
                var snapImage = () => {

                    var canvas = document.createElement('canvas');
                    canvas.width = video.videoWidth;
                    canvas.height = video.videoHeight;

                    canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
                    var image = canvas.toDataURL();
                    let block = image && image.split(";");
                    let contentType = block[0] && block[0].split(":")[1];
                    let realData = block[1] && block[1].split(",")[1];// In this case "iVBORw0KGg...."
                    if (realData !== undefined) {
                        let sliceSize = 512;
                        let byteCharacters = atob(realData, contentType);
                        let byteArrays = [];
                        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                            let slice = byteCharacters.slice(offset, offset + sliceSize);
                            let byteNumbers = new Array(slice.length);
                            for (let i = 0; i < slice.length; i++) {
                                byteNumbers[i] = slice.charCodeAt(i);
                            }
                            let byteArray = new Uint8Array(byteNumbers);
                            byteArrays.push(byteArray);
                        }

                        let blob = new Blob(byteArrays, { contentType })
                        /**************video duriatio *************/
                        var duration = video.duration;

                        /*********video end*********/
                        _that.setState({
                            file: file,
                            thumbimage: blob,
                            duration: duration,
                            image_width: canvas.width,
                            image_height: canvas.height,
                            url: url,
                            fileVisible: true
                        })
                        // video.ondurationchange = function() {
                        //     alert(this.duration);
                        // };
                        var success = image.length > 100000;
                        if (success) {
                            // var img = document.createElement('img');
                            // img.src = image;
                            // document.getElementsByTagName('div')[0].appendChild(img);
                            URL.revokeObjectURL(url);
                        }
                        return success;
                    }
                };
                video.addEventListener('timeupdate', timeupdate);
                video.preload = 'metadata';
                video.src = url;
                video.ondurationchange = function () {
                    if (this.duration > 45) {
                        swal("", "Currently, you can only upload files smaller than 10 MB (a 45 sec video for instance).", "warning")

                    }
                    //   alert(this.duration);
                };
                // Load video in Safari / IE11
                video.muted = true;
                video.playsInline = true;
                video.play();
            };
            fileReader.readAsArrayBuffer(file);
        }
    }

    /**
    |--------------------------------------------------
    | validateForm
    |--------------------------------------------------
    */

    validateForm() {
        let { message, category_id, errors, isFormValid } = this.state;

        if (category_id == 0) {
            errors.message = errorMessages.PROVIDE_CATEGORY;
            errors.type = 'CATEGORY'
        } else if (!message.trim()) {
            errors.message = errorMessages.PROVIDE_MESSAGE;
            errors.type = 'MESSAGE'
        } else {
            errors.message = '';
            errors.type = ''
        }

        if (errors.message !== '') isFormValid = false;
        else isFormValid = true;
        this.setState({ errors, isFormValid })
        return isFormValid
    }

    /**
    |--------------------------------------------------
    | changeStatus
    |--------------------------------------------------
    */

    changeStatus = () => {
        this.setState((prevState) => ({ showMessage: !prevState.showMessage }))
    }

    /**
    |--------------------------------------------------
    | render
    |--------------------------------------------------
    */

    render() {
        let { errors, message, categoryList, imagePreviewUrl, fileVisible, file, thumbimage, categoryVal, isLoading } = this.state;

        var str = message;

        return (
            <div className="main-page-wrapper new-feedback-page">
                <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={true} animationInDelay={500}>
                    <MobileToggle {...this.props} />
                    <SidebarAnnouncement {...this.props} />
                    <Leftbar onRef={ref => this.leftBarRef = ref} {...this.props} />

                    <div className={this.state.contentArea === false ? "content-area-wrapper" : "content-area-wrapper close-sidebar"}>

                        <div className="content-area-header">
                            <h6 className="">Add New Feedback</h6>
                        </div>

                        <div className="content-area-inner">
                            <div className="new-topic-select">

                                {/* <Select
                                    showSearch
                                    placeholder="select "
                                    value={this.state.categoryVal?this.state.categoryVal:''}
                                    optionFilterProp="children"
                                    className="common-select"
                                    classNamePrefix="form-control"  
                                    options={getCategoryList}                          
                                    //  onSelect={(value) =>{this.setState({category_id:value})}} 
                                    onChange={(e) => this.changeCategory(e)}   
                                    >
                               </Select>  */}


                                <Dropdown alignRight className="d-inline-block filter-dropdown">
                                    <Dropdown.Toggle variant="" id="dropdown-basic" className="text-btn">
                                        {categoryVal ? categoryVal.toLocaleUpperCase() : 'Select Category'}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {categoryList && categoryList.map((category, key) => {
                                            return (
                                                <Dropdown.Item onClick={() => this.changeCategory(category)}>
                                                    <div className="checkbox-radio w-100" key={key}>
                                                        <label>
                                                            <span>
                                                                <span className="checkbox-style"></span>
                                                                <span className="text tnc-check" style={{ color: "#" + category.color_code }}>{category.name}
                                                                </span>
                                                            </span>
                                                        </label>
                                                    </div>
                                                </Dropdown.Item>
                                            )
                                        })}
                                    </Dropdown.Menu>
                                </Dropdown>
                                {errors.type == 'CATEGORY' && <span className="error-block"> {errors.message} </span>}
                            </div>

                            <div className="suggestion-block">
                                {/* <textarea name="suggestion" id="" placeholder="Describe your suggestion here" className="form-control"></textarea> */}
                                <textarea
                                    className='form-control'
                                    type='text'
                                    name="message"
                                    placeholder='Write your suggestion here...'
                                    value={message}
                                    onChange={this.handleChange}
                                    maxLength="1000"
                                ></textarea>
                                {errors.type == 'MESSAGE' && <span className="error-block"> {errors.message} </span>}
                                <div className="d-flex justify-content-end">
                                    <span className="remaining-words">{str.length}/1000</span>
                                </div>
                            </div>
                            <div className="add-img add-video my-3">
                                <span className="camera-icon">
                                    <img src="images/camera.svg" alt="" />
                                </span>
                                <input type="file"
                                    className="input-file"
                                    onChange={this.fileChangedHandlerImg.bind(this)} />
                            </div>

                            {
                                (this.state.duration > 45) ? <span></span> :
                                    (file.type === 'video/mp4') ?
                                        <li class="li-image-wrap">
                                            <span class="remove-image" onClick={() => this.setState({ fileVisible: !this.state.fileVisible, file: '' })}>
                                                X
                                        </span>
                                            <video width="320" height="240" controls >
                                                <source src={this.state.url} type="video/mp4" />
                                            </video>
                                        </li>
                                        :
                                        fileVisible ?
                                            <ul className="modal-uploaded-img-block">
                                                <li class="li-image-wrap">
                                                    <span class="remove-image" onClick={() => this.setState({ fileVisible: !this.state.fileVisible, file: '' })}>
                                                        X
                                                </span>
                                                    <img src={imagePreviewUrl} width="100" alt="" />
                                                </li>
                                            </ul> : null
                            }
                        </div>

                        <div className="send-anonymous">
                            <div className="checkbox-radio">
                                <label className="mb-0">
                                    <input type="checkbox" value="" onClick={this.changeStatus} />
                                    <span>
                                        <span className="checkbox-style"></span>
                                        <span className="text">Send as anonymous</span>
                                    </span>
                                    <p className="common-text">Sure, No one will be able to track your name, not even developers.</p>
                                </label>
                                <svg className="what-is-anonymous" style={{ cursor: "pointer" }} width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" onClick={() => this.handleShow("anonymousModal")}>
                                    <title>What is anonymous ?</title>
                                    <desc>Created with Sketch.</desc>
                                    <defs>
                                        <path d="M10,14 C10.553,14 11,14.447 11,15 C11,15.553 10.553,16 10,16 C9.447,16 9,15.553 9,15 C9,14.447 9.447,14 10,14 Z M10,4 C11.93,4 13.5,5.57 13.5,7.5 C13.5,9.08 12.44,10.403 11,10.837 L11,10.837 L11,12 C11,12.553 10.553,13 10,13 C9.447,13 9,12.553 9,12 L9,12 L9,10 C9,9.447 9.447,9 10,9 C10.827,9 11.5,8.327 11.5,7.5 C11.5,6.673 10.827,6 10,6 C9.173,6 8.5,6.673 8.5,7.5 C8.5,8.053 8.053,8.5 7.5,8.5 C6.947,8.5 6.5,8.053 6.5,7.5 C6.5,5.57 8.07,4 10,4 Z M10,18 C5.589,18 2,14.411 2,10 C2,5.589 5.589,2 10,2 C14.411,2 18,5.589 18,10 C18,14.411 14.411,18 10,18 M10,0 C4.486,0 0,4.486 0,10 C0,15.514 4.486,20 10,20 C15.514,20 20,15.514 20,10 C20,4.486 15.514,0 10,0" id="path-1"></path>
                                    </defs>
                                    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                        <g id="New-Feedback" transform="translate(-612.000000, -483.000000)">
                                            <g id="27)-Icon/question-mark-circle" transform="translate(612.000000, 483.000000)">
                                                <mask id="mask-2" fill="white">
                                                    <use xlinkHref="#path-1"></use>
                                                </mask>
                                                <use id="🎨-Icon-Сolor" fill="#3366FF" xlinkHref="#path-1"></use>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                        </div>

                        <div className="text-right mb-4">
                            <Link to="/feedback" className="btn btn-outline-primary">cancel</Link>
                            {/* <Button className="ml-3">send</Button> */}
                            {this.state.showMessage ?
                                <Button className="ml-3" onClick={this.AddAnonymousFeedback}>Send as anonymous</Button> :
                                <Button className='ml-3'
                                    disabled={isLoading}
                                    onClick={!isLoading ? this.AddFeedback : null}> {isLoading ? 'Loading…' : 'Send'}</Button>
                            }
                        </div>
                    </div>
                </Animated>

                <Modal className="common-modal small" centered show={this.state.show} onHide={() => this.handleClose("anonymousModal")}>
                    <button type="button" className="close fixed-close-btn" onClick={() => this.handleClose("anonymousModal")}>
                        <span aria-hidden="true">×</span>
                    </button>
                    <Modal.Body className="p-md-5 p-3 pt-5">
                        <h4 className="mb-3">What is Anonymous?</h4>
                        <h5>Be Assured, Your Identity Remians <span className="text-danger">Unknown Forever!</span></h5>
                        <p className="common-text">When you post any EchoLoud selecting as Anonymous, it will be posted with a “Ghost” identity which is created when you logged in.</p>
                        <p className="common-text">We do not store or link this “Ghost” identity anywhere in our system with your actual/real identity. This will remain stored encrypted only on your iPhone/iPad as long as you’re logged in and will be removed permanently upon logging out and can never be trace backed as long as the Earth exists.</p>
                        <p className="common-text mb-0">The system is designed and developed in such a way that not even the creator of this system can trace who sent those anonymous EchoLoud/Suggestions/Messages from anywhere.</p>
                    </Modal.Body>
                </Modal>

            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        userData: state.getUserData,
    }

};
export default withRouter(connect(mapStateToProps, { callApi, categoryDetails, allCategoryDetails, setAllFeedBackData })(NewFeedback))