import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
// import PerfectScrollbar from 'react-perfect-scrollbar';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { Animated } from 'react-animated-css';
import Leftbar from './leftbar/Leftbar';

import MobileToggle from '../mobileToggles/mobileToggles';
import SidebarAnnouncement from '../sidebarAnnouncement/SidebarAnnouncement';
import { callApi } from '../../store/reducers/commonFunction';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import errorMessages from '../../store/reducers/errorMessages'
import swal from 'sweetalert'
class NewIdea extends Component {

    /**
    |--------------------------------------------------
    | constructor
    |--------------------------------------------------
    */

    constructor(props) {
        super(props);
        this.state = {
            contentArea: false,
            anonymousModal: false,
            show: false,
            tags: '',
            tagvalues: '',
            width: '',
            height: '',
            multipleSelect: [],
            loadedFiles: [],
            message: '',
            errors: {
                message: '',
                type: ''
            },
            tagVal: [],
            isLoading: false
        }
    }

    /**
    |--------------------------------------------------
    | handleClose
    |--------------------------------------------------
    */

    handleClose = (modelName) => {
        this.setState({ show: false });
    }

    /**
    |--------------------------------------------------
    | handleShow
    |--------------------------------------------------
    */

    handleShow = (modelName) => {
        this.setState({ show: true });
    }

    /**
    |--------------------------------------------------
    | componentDidMount
    |--------------------------------------------------
    */

    async componentDidMount() {
        await this.getTags();
    }

    /**
    |--------------------------------------------------
    | getTags
    |--------------------------------------------------
    */

    getTags = async () => {

        let company_id = this.props.userData.company_id ? this.props.userData.company_id : '';

        let body = { company_id }
        const response = await this.props.callApi('/getTags', body, 'post', 'ADMIN_LOGIN', true);
        if (response) {
            this.setState({ tags: response })
        }
    }

    /**
    |--------------------------------------------------
    | tagValue
    |--------------------------------------------------
    */

    tagValue = async (e) => {
        this.setState({
            tagVal: e,
            errors: {
                message: '',
                type: ''
            }
        })
    }

    /**
    |--------------------------------------------------
    | validateForm
    |--------------------------------------------------
    */

    validateForm() {
        let { message, errors, isFormValid, tagVal } = this.state;
        if (tagVal.length == 0) {
            errors.message = errorMessages.PROVIDE_IDEA_TAG;
            errors.type = 'TAG';
        } else if (!message.trim()) {
            errors.message = errorMessages.PROVIDE_IDEA_MESSAGE;
            errors.type = 'MESSAGE';
        } else {
            errors.message = '';
            errors.type = '';
        }
        if (errors.message !== '') isFormValid = false;
        else isFormValid = true;
        this.setState({ errors, isFormValid })
        return isFormValid
    }

    /**
    |--------------------------------------------------
    | AddIdea
    |--------------------------------------------------
    */

    AddIdea = async () => {

        try {
            var { image_width, image_height, message, tagVal, duration, file, width, height, thumbimage } = this.state;
            let tagsarray = [];
            let tagsvalue = tagVal.map((i) => {
                return (
                    i.value
                )
            })
            tagsarray.push(tagsvalue.toString())
            if (this.validateForm()) {
                this.setState({
                    isLoading: true
                })
                const formData = new FormData()
                if (file) {
                    if (file.type === 'video/mp4') {
                        formData.append('type', "video")
                        formData.append('video', file)
                        formData.append('image', thumbimage)
                        formData.append('image_width', image_width)
                        formData.append('image_height', image_height)
                        formData.append('video_duration', duration)
                    } else {
                        formData.append('type', "image")
                        formData.append('image', file)
                        formData.append('image_width', width)
                        formData.append('image_height', height)
                    }
                } else {
                    formData.append('type', "text")
                }
                formData.append('tags', tagsarray)
                formData.append('text', message)

                const response = await this.props.callApi('/newIdea', formData, 'post', null, true);
                if (response) {
                    this.setState({
                        newidea: response.data,
                        isLoading: false
                    })
                    this.props.history.push("/ideaSharing")
                }
            }
        } catch (error) {
            this.setState({
                isLoading: false
            })
            throw (error)
        }
    }

    /**
    |--------------------------------------------------
    | fileChangedHandlerImg
    |--------------------------------------------------
    */

    fileChangedHandlerImg(event) {
        let file = event.target.files[0];
        let filename = event.target.files[0].name
        if (event.target.files[0].type.match('image')) {
            var img = new Image();
            img.src = window.URL.createObjectURL(event.target.files[0]);

            img.onload = () => {
                let width = img.naturalWidth,
                    height = img.naturalHeight;
                this.setState({
                    width: width,
                    height: height,
                })

                let reader = new FileReader()
                // let file = event.target.files[0];
                // let filename = event.target.files[0].name 
                reader.onloadend = () => {
                    this.setState({
                        file: file,
                        filename: filename,
                        imagePreviewUrl: reader.result,
                        fileVisible: true
                    })
                }
                reader.readAsDataURL(file);
            }
        } else {
            let fileReader = new FileReader()
            // let file = event.target.files[0];
            // let filename = event.target.files[0].name 
            var _that = this;

            fileReader.onloadend = function () {
                var blob = new Blob([fileReader.result], { type: file.type });
                var url = URL.createObjectURL(blob);
                var video = document.createElement('video');
                var timeupdate = function () {
                    if (snapImage()) {
                        video.removeEventListener('timeupdate', timeupdate);
                        video.pause();
                    }
                };
                video.addEventListener('loadeddata', function () {
                    if (snapImage()) {
                        video.removeEventListener('timeupdate', timeupdate);
                    }
                });
                var snapImage = () => {

                    var canvas = document.createElement('canvas');
                    canvas.width = video.videoWidth;
                    canvas.height = video.videoHeight;

                    canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
                    var image = canvas.toDataURL();
                    let block = image && image.split(";");
                    let contentType = block[0] && block[0].split(":")[1];
                    let realData = block[1] && block[1].split(",")[1];// In this case "iVBORw0KGg...."
                    if (realData !== undefined) {
                        let sliceSize = 512;
                        let byteCharacters = atob(realData, contentType);
                        let byteArrays = [];
                        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                            let slice = byteCharacters.slice(offset, offset + sliceSize);

                            let byteNumbers = new Array(slice.length);
                            for (let i = 0; i < slice.length; i++) {
                                byteNumbers[i] = slice.charCodeAt(i);
                            }
                            let byteArray = new Uint8Array(byteNumbers);
                            byteArrays.push(byteArray);
                        }

                        let blob = new Blob(byteArrays, { contentType })
                        /**************video duriatio *************/
                        var duration = video.duration;
                        /*********video end*********/
                        _that.setState({
                            file: file,
                            thumbimage: blob,
                            duration: duration,
                            image_width: canvas.width,
                            image_height: canvas.height,
                            url: url,
                            fileVisible: true
                        })
                        var success = image.length > 100000;
                        if (success) {
                            // var img = document.createElement('img');
                            // img.src = image;
                            // document.getElementsByTagName('div')[0].appendChild(img);
                            // URL.revokeObjectURL(url);
                        }
                        return success;
                    }
                };

                video.addEventListener('timeupdate', timeupdate);
                video.preload = 'metadata';
                video.src = url;
                video.ondurationchange = function () {
                    if (this.duration > 45) {
                        swal("", "Currently, you can only upload files smaller than 10 MB (a 45 sec video for instance).", "warning")
                        _that.setState({
                            file: '',
                            url: '',
                        })
                    }

                }
                // Load video in Safari / IE11
                video.muted = true;
                video.playsInline = true;
                video.play();
            };
            fileReader.readAsArrayBuffer(file);
        }
    }

    /**
    |--------------------------------------------------
    | handleChange
    |--------------------------------------------------
    */

    handleChange = (event) => {

        this.setState({ [event.target.name]: event.target.value })
        if (event.target.value) {
            this.setState({
                errors: Object.assign(this.state.errors, { [event.target.name]: "" })
            });
        } else {
            this.setState({
                errors: Object.assign(this.state.errors, { [event.target.name]: errorMessages.REQUIRED })
            })
        }
    }

    /**
    |--------------------------------------------------
    | render
    |--------------------------------------------------
    */

    render() {
        var { tags, imagePreviewUrl, message, errors, fileVisible, thumbimage, file, isLoading } = this.state;
        var strmessage = message;
        let tagsList = tags && Array.isArray(tags.data) && tags.data.map((values) => {
            return (
                { label: values, value: values }
            )
        })
        return (

            <div className="main-page-wrapper new-feedback-page">
                <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={true} animationInDelay={500}>
                    <MobileToggle {...this.props} />
                    <Leftbar {...this.props} />

                    <SidebarAnnouncement {...this.props} />
                    <div className={this.state.contentArea === false ? "content-area-wrapper" : "content-area-wrapper close-sidebar"}>

                        <div className="content-area-header">
                            <h6 className="">Share New Idea</h6>
                        </div>

                        <div className="content-area-inner">
                            <div className="new-topic-select">
                                {/* <Select
                                    isMulti
                                    name="addTag"
                                    options={tagsList}
                                    // className="basic-multi-select"
                                    // classNamePrefix="select"
                                    className="common-select"
                                    classNamePrefix="form-control"  
                                    value={this.state.tagVal?this.state.tagVal:''}
                                    onChange={(e) => this.tagValue(e)}   
                                    placeholder={"Type to add tag"}
                               /> */}
                                <CreatableSelect
                                    isMulti
                                    name="addTag"
                                    options={tagsList}
                                    className="common-select"
                                    classNamePrefix="form-control"
                                    value={this.state.tagVal ? this.state.tagVal : ''}
                                    onChange={(e) => this.tagValue(e)}
                                    placeholder={"Type to add tag"}
                                />
                                {errors.type == "TAG" && <span className="error-block"> {errors.message} </span>}
                            </div>



                            <div className="suggestion-block">
                                {/* <textarea name="suggestion" id="" placeholder="Describe your suggestion here" className="form-control"></textarea> */}
                                <textarea
                                    className='form-control'
                                    type='text'
                                    name="message"
                                    placeholder='Write your idea here...'
                                    value={message}
                                    onChange={this.handleChange}
                                    maxLength="3000"
                                ></textarea>
                                {errors.type == "MESSAGE" && <span className="error-block"> {errors.message} </span>}
                                <div className="d-flex justify-content-end">
                                    <span className="remaining-words"><span>{strmessage.length}</span>/3000</span>
                                </div>
                            </div>

                            {/* <div className="company-logo-block add-picture new-idea my-3">
                                <input
                                    type="file"
                                    multiple
                                    accept="image/*,video/*,"
                                    className="company-logo-changer new-idea"
                                    onChange={this.fileChangedHandlerImg.bind(this)} />
                            </div> */}
                            <div className="add-img add-video my-3">
                                <span className="camera-icon">
                                    <img src="images/camera.svg" alt="" />
                                </span>
                                <input type="file"
                                    className="input-file"
                                    onChange={this.fileChangedHandlerImg.bind(this)} />
                            </div>
                            {
                                (this.state.duration > 45) ? <span></span> :
                                    (file && file.type === 'video/mp4') ?
                                        <li class="li-image-wrap">
                                            <span class="remove-image" onClick={() => this.setState({ fileVisible: !this.state.fileVisible, file: '' })}>
                                                X
                                    </span>
                                            <video width="320" height="240" controls>
                                                <source src={this.state.url} type="video/mp4" />
                                            </video>
                                        </li> :
                                        fileVisible ?
                                            <ul className="modal-uploaded-img-block">
                                                <li class="li-image-wrap">
                                                    <span class="remove-image" onClick={() => this.setState({ fileVisible: !this.state.fileVisible, file: '' })}>
                                                        X
                                        </span>
                                                    <img src={imagePreviewUrl} alt="" width="100" /><span> <i className='fa fa-times-circle-o text-danger fa-2x' onClick={() => this.setState({ fileVisible: !this.state.fileVisible, file: '' })} /></span>
                                                </li>
                                            </ul> : null

                            }

                            <div className="text-right mb-4">
                                <Link to="/ideaSharing" className="btn btn-outline-primary">cancel</Link>
                                <Button className="ml-3"
                                    disabled={isLoading}
                                    onClick={!isLoading ? this.AddIdea : null}> {isLoading ? 'Loading…' : 'Send'}</Button>
                            </div>
                        </div>
                    </div>
                </Animated>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        userData: state.getUserData,
    }
}
export default withRouter(connect(mapStateToProps, { callApi })(NewIdea))