export default {
    CONFIG: function () {
      return require("../../config/");
    },
    AXIOS: function () {
      const config = this["CONFIG"]();
      console.log(config, "config");
      let AxiosInterceptor = require("../AxiosInterceptor").default;
      return new AxiosInterceptor(config).axios();
    },
    USER_SERVICE: function () {
      const UserService = require("../apis/UserService").default;
      return new UserService(this["CONFIG"](), this["AXIOS"]());
    },
    EVENT_SERVICE: function () {
      const EventService = require("../apis/EventService").default;
      return new EventService(this["CONFIG"](), this["AXIOS"]());
    },
  };
  
