import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import { Button } from 'react-bootstrap';
import { Animated } from "react-animated-css";
import { connect } from 'react-redux';

class EmailVerified extends Component {

    /**
    |--------------------------------------------------
    | render
    |--------------------------------------------------
    */

    render() {
        let contact_email = this.props.registerData.data.contact_email ? this.props.registerData.data.contact_email : '';

        return (
            <div className="login-register-wrapper email-varifiied-page">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 ml-auto d-md-block d-none">
                            <div className="high-five-img-block">
                                <img src="images/email-verified-high-five-bgi-img.svg" alt="" />
                                <div className="high-five-right-img-block">
                                    <Animated animationIn="bounce" animationOut="fadeOut" isVisible={true} animationInDelay={500}>
                                        <img src="images/email-verified-high-five-right-img.svg" alt="" />
                                    </Animated>
                                </div>
                                <div className="high-five-left-img-block">
                                    <Animated animationIn="fadeInLeft" animationOut="fadeOut" isVisible={true} animationInDelay={1600}>
                                        <img src="images/email-verified-high-five-left-img.svg" alt="" />
                                    </Animated>
                                </div>
                            </div>
                            {/* <img src="images/high-five-img.png" alt=""/> */}
                        </div>
                        <div className="col-lg-4 col-md-6 mr-auto d-flex flex-column justify-content-center align-items-start">
                            <h4 className="mb-2 mt-auto">High Five!</h4>
                            <p className="para"><span>{contact_email}</span> is verified successfully.</p>
                            <Link className="btn btn-primary mb-auto" to="/login">next</Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        registerData: state.getRegisterData
    }
}
export default connect(mapStateToProps, {})(EmailVerified)