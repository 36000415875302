import React, { Component } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { monokai } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { withRouter } from 'react-router';
import { callApi, setKnowledgeBankCodeData } from '../../store/reducers/commonFunction'
import { connect } from 'react-redux';
import 'rc-pagination/assets/index.css';

import common from '../../store/reducers/commons';
import swal from 'sweetalert';
import { Spinner } from 'react-activity';
import 'react-activity/dist/react-activity.css';
import appConstant from '../../constant/appConstant';

import _FilterComponent from './Component/_FilterComponent';
import _FloatingMenu from './Component/_FloatingMenu';
import _ from 'lodash';

class KnowledgeBankCode extends Component {

    /**
    |--------------------------------------------------
    | constructor
    |--------------------------------------------------
    */

    constructor(props) {
        super(props);
        this.state = {
            contentArea: false,
            newAnnouncement: false,
            OpenOptions: false,
            CloseOptions: false,
            shareText: false,
            shareLink: false,
            sharePhotos: false,
            shareCode: false,
            userUploadedImgs: false,
            ismodelName: "",
            current_page: 1,
            per_page: 10,
            total: 1,
            last_page: false,
            total_pages: null,
            filterArr: [],
            loadedFiles: [],
            imgArr: [],
            message: '',
            link: '',
            code: '',
            url_image: '',
            show: false,
            texts: '',
            errors: {
                message: ''
            },
            isLoading: false
        }
    }

    /**
    |--------------------------------------------------
    | componentDidMount
    |--------------------------------------------------
    */

    async componentDidMount() {
        this.props.onRef && this.props.onRef(this);
        await this.getKnowledgeCodeData(false)
    }

    /**
    |--------------------------------------------------
    | componentWillUnmount
    |--------------------------------------------------
    */

    componentWillUnmount() {
        this.props.onRef && this.props.onRef(undefined);
    }

    /**
    |--------------------------------------------------
    | setParentStatus
    |--------------------------------------------------
    */

    setParentStatus = async (id) => {
        let knowledge_id = id;
        let body = {
            knowledge_id
        }
        this.setState({ knowledge_id: knowledge_id })
        const response = await this.props.callApi('/getKnowledgeDetail', body, 'post', null, true);
        this.setState({
            Knowledge: response.data
        })
    }

    /**
    |--------------------------------------------------
    | getKnowledgeCodeData
    |--------------------------------------------------
    */

    getKnowledgeCodeData = async (isAppend = true) => {
        let { filterArr, tagVal, current_page } = this.state;
        // let tagsarray=[];
        let tagsvalue = tagVal ? tagVal.map((i) => {
            return (
                i.value
            )
        }) : '';
        // tagsarray.push(tagsvalue.toString())
        this.setState({
            isLoading: true
        })

        let filterData = _FilterComponent.getFilterValue();
        let body = { page: current_page, type: "code", tags: filterData.tagVal, filter_by: filterData.filterArr }
        const response = await this.props.callApi('/getKnowledgeList', body, 'post', 'ADMIN_LOGIN', true);
        if (response.data && response.data.tags && response.data.tags.length > 0) {
            tagVal = response.data.tags && Array.isArray(response.data.tags) && response.data.tags.map((values) => {
                return (
                    { label: values, value: values }
                )
            })
        }
        if (response) {
            this.props.setKnowledgeBankCodeData(isAppend ? [...this.props.knowledgeBankCodeData, ...response.data] : response.data);
            this.setState({
                per_page: response.meta.per_page,
                total: response.meta.total,
                show: false,
                last_page: response.meta.last_page,
                isLoading: false
            }, () => {
                if (this.props.getChildEvent && this.props.activeTab == 'code') {
                    if (response && response.data && response.data.length > 0) {
                        this.props.getChildEvent(response.data[0].knowledge_id, appConstant.TYPE.CODE);
                    } else {
                        this.props.getChildEvent('');
                    }
                }
            })
        } else {
            this.setState({
                isLoading: false
            })
        }
    }

    /**
    |--------------------------------------------------
    | getTags
    |--------------------------------------------------
    */

    getTags = async () => {
        let company_id = this.props.userData.company_id ? this.props.userData.company_id : '';
        let body = { company_id }
        const response = await this.props.callApi('/getTags', body, 'post', 'ADMIN_LOGIN', true);
        if (response) {
            this.setState({ tags: response })
        }
    }

    /**
    |--------------------------------------------------
    | tagValue
    |--------------------------------------------------
    */

    tagValue = async (e) => {
        this.setState({
            tagVal: e
        })
    }

    /**
    |--------------------------------------------------
    | addLoadedFile
    |--------------------------------------------------
    */

    addLoadedFile(file) {
        let tempPhotos = this.state.loadedFiles;
        tempPhotos.push(file);
        this.setState({
            loadedFiles: tempPhotos,
        });
    }

    /**
    |--------------------------------------------------
    | dropdownToggle
    |--------------------------------------------------
    */

    dropdownToggle = () => {
        this.setState({ show: !this.state.show });
    }

    /**
    |--------------------------------------------------
    | deleteKnowledge
    |--------------------------------------------------
    */

    deleteKnowledge = async (knowledge_id) => {

        swal({
            title: "Are you sure want to delete this Knowledge ?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async willDelete => {
                if (willDelete) {
                    let body = {
                        knowledge_id: knowledge_id
                    }
                    this.setState({
                        knowledge_id
                    })
                    const response = await this.props.callApi('/deleteKnowledge', body, 'post', null, true);
                    if (response.data.code === 200) {
                        common.showSuccessMessage(response.data.message);
                        this.getknowledgeaDetails()
                    }
                }
            });
    }

    /**
    |--------------------------------------------------
    | openModal
    |--------------------------------------------------
    */

    openModal(Knowledge) {
        let tagsvalue = Knowledge.tags.map((i) => {
            return (
                i
            )
        })
        this.setState({
            descriptions: Knowledge.text,
            texts: Knowledge.description,
            knowledge_id: Knowledge.knowledge_id,
            tagvalue: tagsvalue,
            shows: true,
        });
    }

    /**
    |--------------------------------------------------
    | updateKnowledge
    |--------------------------------------------------
    */

    updateKnowledge = async () => {
        var { descriptions, knowledge_id, tagVal, texts } = this.state;
        let tagsvalue = tagVal && tagVal.map((i) => {
            return (
                i.value
            )
        })
        //  tagsarray.push(tagsvalue.toString())
        // if (this.validateForm()) {
        const formData = new FormData()
        formData.append('tags', tagsvalue)
        formData.append('text', descriptions)
        formData.append('description', texts)
        formData.append('type', "code")
        formData.append('knowledge_id', knowledge_id)


        const response = await this.props.callApi('/updateKnowledge', formData, 'post', null, true, true);
        if (response) {
            // this.props.history.push('/announcement')
            common.showSuccessMessage("Knowlwdge details updated succesfully");
            window.location.reload();
            this.setState({ visible: false });
        }
        // }

    }

    /**
    |--------------------------------------------------
    | handleScroll
    |--------------------------------------------------
    */

    handleScroll = (e) => {
        let element = e.target
        if (element.scrollTop + element.offsetHeight >= element.scrollHeight) {
            if (this.state.current_page != this.state.last_page) {
                this.setState({ current_page: this.state.current_page + 1 }, () => {
                    this.getKnowledgeCodeData()
                })
            }
        }
    }

    /**
    |--------------------------------------------------
    | onPressFilter
    |--------------------------------------------------
    */

    onPressFilter = (tags, filter) => {
        console.log('tags-->', JSON.stringify(tags))
        console.log('filter-->', JSON.stringify(filter))
        this.setState({
            tagVal: tags,
            filterArr: filter,
            current_page: 1
        }, () => this.getKnowledgeCodeData(false));
    }

    /**
    |--------------------------------------------------
    | onItemClick
    |--------------------------------------------------
    */

    onItemClick = (knowledgeDetail) => {
        this.props.getChildEvent(knowledgeDetail.knowledge_id, appConstant.TYPE.CODE)
    }

    /**
    |--------------------------------------------------
    | render
    |--------------------------------------------------
    */

    render() {
        let user_id = this.props.userData.user_id ? this.props.userData.user_id : '';
        const { knowledgeBankCodeData } = this.props;
        var { thumbimage, file, texts, tagvalue, imgArr, Knowledgecode, total, current_page, per_page, tags, message, errors, link, code } = this.state
        var strmesaage = message;
        var strtext = texts;
        let tagsList = tags && Array.isArray(tags.data) && tags.data.map((values) => {
            return (
                { label: values, value: values }
            )
        })
        let updatetags = tagvalue && Array.isArray(tagvalue) && tagvalue.map((values) => {
            return (
                { label: values, value: values }
            )
        })

        return (
            <PerfectScrollbar onScroll={this.handleScroll}>
                {
                    knowledgeBankCodeData && Array.isArray(knowledgeBankCodeData) && knowledgeBankCodeData.map((Knowledgedetail, key) => {
                        const listItemClassName = (Knowledgedetail.knowledge_id == _.get(this.props, 'knowledgeDetail.knowledge_id')) ? "leftbar-list-item active" : "leftbar-list-item"
                        return (
                            <li className={listItemClassName} key={key} onClick={() => { this.onItemClick(Knowledgedetail) }}>
                                <div className="card">
                                    <div className="card-header">
                                        <div className="leftbar-list-item-inner py-0">
                                            <div className="user-img small">
                                                <div className="user-img-wrapper m-0">
                                                    <img src={Knowledgedetail.user_image} alt="" width="40" height="40" />
                                                </div>
                                            </div>
                                            <div className="user-data w-100">
                                                <div className="user-name">{Knowledgedetail.user_name}</div>
                                                <div className="data-text text-capitalize">{new Date(Knowledgedetail.last_updated_date).toLocaleString()}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <p className="card-text">{Knowledgedetail.description} </p>
                                        <SyntaxHighlighter language="html javascript" style={monokai}>{Knowledgedetail.text}</SyntaxHighlighter>
                                        {/* <p className="card-text">{Knowledgedetail.text}</p> */}
                                        {/* <span className="user-uploded-img">
                                                                <img className="m-0" src="images/announcement-img.jpg" alt="" />
                                                            </span> */}
                                    </div>

                                    <div className="selected-categories-block">
                                        <div className="permission-badge-block mt-0">
                                            {
                                                Knowledgedetail && Array.isArray(Knowledgedetail.tags) && Knowledgedetail.tags.map((tag, key) => {
                                                    return (
                                                        <span className="permission-badge" key={key}>{tag}</span>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                    {this.props.likeCommentView(Knowledgedetail)}
                                </div>
                            </li>
                        )
                    })
                }
                {this.state.isLoading && <div className={'loader-block'}>
                    <Spinner />
                </div>}
                {(!this.state.isLoading && knowledgeBankCodeData && knowledgeBankCodeData.length === 0) && this.props.noRecordView()}
            </PerfectScrollbar>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        userData: state.getUserData,
        knowledgeBankCodeData: state.knowledgeBankCodeData
    }
}
export default withRouter(connect(mapStateToProps, { callApi, setKnowledgeBankCodeData })(KnowledgeBankCode))
