import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import { Animated } from 'react-animated-css';
import MobileToggle from '../mobileToggles/mobileToggles';
import errorMessages from '../../store/reducers/errorMessages';
// import Leftbar from './leftbar/Leftbar';
import SidebarAnnouncement from '../sidebarAnnouncement/SidebarAnnouncement';
import { connect } from 'react-redux';
import { callApi } from '../../store/reducers/commonFunction'
import helper from '../../services/helper';
import common from '../../store/reducers/commons'
import swal from 'sweetalert'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { Spinner } from 'react-activity';
import 'react-activity/dist/react-activity.css';
import appConstant from '../../constant/appConstant';

class Company extends Component {

    /**
    |--------------------------------------------------
    | constructor
    |--------------------------------------------------
    */

    constructor(props) {
        super(props);
        this.state = {
            viewCompanyDetails: true,
            editCompanyModalVisible: false,
            company: '',
            name: '',
            phone: '',
            website: '',
            subtitle: '',
            email: '',
            employeDelete: '',
            dialcode: '',
            dial_code: '',
            rawPhone: '',
            errors: {
                name: '', phone: '', website: '', email: '',
            },
            isLoading: false
        }
    }

    /**
    |--------------------------------------------------
    | viewCompanyDetailsBtn
    |--------------------------------------------------
    */

    viewCompanyDetailsBtn = () => {
        this.setState({ editCompanyModalVisible: false })
    }

    /**
    |--------------------------------------------------
    | editCompanyDetailsBtn
    |--------------------------------------------------
    */

    editCompanyDetailsBtn = () => {
        const { company } = this.state;
        this.setState({
            editCompanyModalVisible: true,
            name: company.name,
            website: company.website,
            email: company.email,
            phone: company.phone,
            dial_code: company.dialcode,
            image: company.image,
            subtitle: company.subtitle,
            errors: {
                name: '', phone: '', website: '', email: '',
            }
        })
    }

    /**
    |--------------------------------------------------
    | componentDidMount
    |--------------------------------------------------
    */

    async componentDidMount() {
        await this.getCompanyDetails()
    }

    /**
    |--------------------------------------------------
    | getCompanyDetails
    |--------------------------------------------------
    */

    getCompanyDetails = async () => {

        let body = {}
        const response = await this.props.callApi('/getCompanyInfo', body, 'post', null, true);
        console.log('getCompanyInfo response-->', JSON.stringify(response));
        this.setState({
            company: response.data,
            name: response.data.name,
            website: response.data.website,
            email: response.data.email,
            phone: response.data.phone,
            dial_code: response.data.dialcode,
            image: response.data.image,
            subtitle: response.data.subtitle
        })

    }

    /**
    |--------------------------------------------------
    | updateCompanyDetails
    |--------------------------------------------------
    */

    updateCompanyDetails = async (e) => {
        e.preventDefault();
        var { name, file, email, rawPhone, phone, dial_code, subtitle, website } = this.state;
        let company_id = this.props.userData.company_id ? this.props.userData.company_id : '';
        if (this.validateForm()) {
            this.setState({
                isLoading: true
            })
            const formData = new FormData()
            if (file) {
                formData.append('image', file)
            }
            formData.append('name', name)
            formData.append('subtitle', subtitle)
            formData.append('email', email)
            formData.append('company_id', company_id)
            formData.append('website', website)
            formData.append('phone', phone)
            formData.append('dialcode', dial_code)
            // let body={
            //     first_name,last_name, phone,email,designation,dialcode,company_id,user_id
            // }
            const response = await this.props.callApi('/updateCompany', formData, 'post', null, true);
            console.log('response-->', JSON.stringify(response));
            if (response.data.status === true) {
                this.setState({
                    image: response.data.image,
                    editCompanyModalVisible: false,
                    isLoading: false
                })

                common.showSuccessMessage(response.data.message)
                this.getCompanyDetails()
                // window.location.reload();
            } else {
                this.setState({
                    isLoading: false
                })
            }
        }
    }

    /**
    |--------------------------------------------------
    | fileUpload
    |--------------------------------------------------
    */

    fileUpload = async (event) => {
        let reader = new FileReader()
        let file = event.target.files[0]
        reader.onloadend = () => {
            this.setState({
                file: file,
                image: reader.result,
                fileVisible: true
            })
        }
        reader.readAsDataURL(file)

    }

    /**
    |--------------------------------------------------
    | handleChange
    |--------------------------------------------------
    */

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value })
        if (event.target.value) {
            this.setState({
                errors: Object.assign(this.state.errors, { [event.target.name]: '' })
            })
        } else {
            this.setState({
                errors: Object.assign(this.state.errors, { [event.target.name]: errorMessages.REQUIRED })
            })
        }
    }

    /**
    |--------------------------------------------------
    | validateForm
    |--------------------------------------------------
    */

    validateForm() {
        let { name, website, phone, errors, isFormValid } = this.state;
        if (!name.trim()) {
            errors.name = errorMessages.PROVIDE_COMPANY_NAME;
        } else if (!website.trim()) {
            errors.website = errorMessages.PROVIDE_WEBSITE;
        } else if (!helper.isValidUrl(website.trim())) {
            errors.website = errorMessages.PROVIDE_VALID_WEBSITE;
        } else if (!phone.trim()) {
            errors.phone = errorMessages.PROVIDE_MOBILE_NUMBER;
        } else if (!helper.isValidPhoneNumber(phone.trim())) {
            errors.phone = errorMessages.PROVIDE_VALID_MOBILE_NUMBER;
        } else {
            errors.name = '';
            errors.website = '';
            errors.phone = '';
        }

        if (errors.name !== '' || errors.website !== '' || errors.phone !== '') isFormValid = false;
        else isFormValid = true;
        this.setState({ errors, isFormValid })
        return isFormValid
    }

    /**
    |--------------------------------------------------
    | deleteUser
    |--------------------------------------------------
    */

    async deleteUser(cid) {

        swal({
            title: "Are you sure you want to delete this company?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async willDelete => {
                if (willDelete) {
                    let company_id = cid;
                    var body = { company_id: company_id }

                    const response = await this.props.callApi('/removeCompany', body, 'post', null, true);
                    if (response.data.status === true) {
                        common.showSuccessMessage(response.data.status)
                        // this.getAllUsers()
                    }
                }
            });
    }

    /**
    |--------------------------------------------------
    | handleOnChange
    |--------------------------------------------------
    */

    handleOnChange = (value, data, event, formattedValu) => {
        let dialnum = data.dialCode;
        let mobilenum = value.slice(data.dialCode.length);
        this.setState({
            dialcode: dialnum,
            rawPhone: mobilenum,
            phone: mobilenum,
        })
    }

    /**
    |--------------------------------------------------
    | render
    |--------------------------------------------------
    */

    render() {
        let role = this.props.userData.role ? this.props.userData.role : '';

        const { errors, company, name, website, email, phone, dial_code, image, subtitle, isLoading } = this.state;
        // let{} = company;
        let imagePreview = null;
        if (image) {
            imagePreview = (<img src={image} alt="Icon" />);
        } else {
            imagePreview = (<img src={"/assets/images/no-image-icon-4.png"} alt="Icon" />);
        }
        return (
            <div className="main-page-wrapper company-page">
                <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={true} animationInDelay={500}>
                    <MobileToggle {...this.props} />
                    <SidebarAnnouncement {...this.props} />
                    <div className="content-area-wrapper">
                        {company ? <div className="content-area-inner">
                            <div className="employee-details company-details-view company-page-block">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-lg-5 d-lg-flex d-none justify-content-center align-items-center">
                                            <Animated animationIn="fadeInLeft" animationOut="fadeOut" isVisible={true} animationInDelay={900}>
                                                <img src="images/company-page-img.svg" alt="" />
                                            </Animated>
                                        </div>
                                        <div className="col-lg-7">

                                            <div>
                                                <div className="company-logo-block mt-0">
                                                    <span className="company-logo">
                                                        <img src={company.image} alt="" />
                                                    </span>
                                                    <div className="employee-name-designation ml-4">
                                                        <h6 className="mb-0">{company.name}</h6>
                                                        <p className="mb-0">{company.subtitle}</p>
                                                    </div>
                                                </div>

                                                <div className="company-details-list">
                                                    <ul>
                                                        <li className="company-details-list-item">
                                                            <p className="list-item-label">Website</p>
                                                            <a href={"http://" + company.website} target="_blank" rel="noopener noreferrer"><span className="list-item-value">{company.website}</span></a>
                                                        </li>
                                                        <li className="company-details-list-item">
                                                            <p className="list-item-label">Email</p>
                                                            <a href={"mailto:" + company.email} target="_blank" rel="noopener noreferrer"><span className="list-item-value">{company.email}</span></a>
                                                        </li>
                                                        <li className="company-details-list-item">
                                                            <p className="list-item-label">Phone</p>
                                                            <a href={`https://web.whatsapp.com/send?phone='${company.dialcode + "" + company.phone}'&text=`} target="_blank" rel="noopener noreferrer"><span className="list-item-value">{company.dialcode} {company.phone}</span></a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                {
                                                    (role === 'employee' || role === "categoryadmin" || role === "admin") ? <div> </div> :
                                                        <div className="pt-4 pb-3">
                                                            <Button onClick={this.editCompanyDetailsBtn}>edit</Button>
                                                            <Button variant="outline-primary" className="ml-3" onClick={() => this.deleteUser(company.company_id)}>delete</Button>
                                                        </div>
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                            :
                            <div className={'loader-block'}>
                                <Spinner />
                            </div>
                        }
                    </div>

                    <Modal className="common-modal small" centered show={this.state.editCompanyModalVisible} onHide={() => this.setState({ editCompanyModalVisible: false })}>
                        <button type="button" className="close fixed-close-btn" onClick={() => this.setState({ editCompanyModalVisible: false })}>
                            <span aria-hidden="true">×</span>
                        </button>
                        <Modal.Body className="">
                            <div className="row mx-0">
                                <div className="col-12">
                                    <h6 className="">Edit Company</h6>
                                </div>

                                <div className="col-12">
                                    <div className="company-logo-block">
                                        <span className="company-logo ">
                                            {imagePreview}
                                        </span>
                                        <input className="file-input company-logo-changer ml-3 input-file" type="file" name="profile_photo" accept="image/*" id="file" onChange={this.fileUpload.bind(this)} />

                                    </div>
                                </div>

                                <div className="col-lg-6">
                                    <div className="form-group mb-4">
                                        <input
                                            type="text"
                                            name="name"
                                            className="form-control"
                                            placeholder="Company Name"
                                            value={name}
                                            onChange={this.handleChange}
                                        />
                                        <span className='error-msg'>{errors.name}</span>

                                    </div>
                                </div>

                                <div className="col-lg-6">
                                    <div className="form-group mb-4">
                                        <input
                                            type="subtitle"
                                            name="subtitle"
                                            className="form-control"
                                            placeholder="Company Subtitle"
                                            value={subtitle}
                                            onChange={this.handleChange}

                                        />
                                        <span className='error-msg'>{errors.email}</span>

                                    </div>
                                </div>

                                <div className="col-lg-6">
                                    <div className="form-group mb-4">
                                        <input
                                            type="text"
                                            name="website"
                                            className="form-control"
                                            placeholder="Company Website"
                                            value={website}
                                            onChange={this.handleChange}

                                        />
                                        <span className='error-msg'>{errors.website}</span>

                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="input-group mobile-input mb-md-4 mb-3">
                                        <div className="input-group-prepend">

                                        </div>
                                        <PhoneInput
                                            className="form-control"
                                            name="phone"
                                            country={'us'}
                                            value={dial_code + '' + phone}
                                            onChange={this.handleOnChange}
                                        />
                                    </div>
                                    <span className='error-msg'>{errors.phone}</span>
                                </div>

                                <div className="col-12">
                                    <hr className="mt-1 mb-md-4 mb-3" />
                                </div>

                                {role === appConstant.ROLE.COMPANY_ADMIN && <div className="col-12 text-right pb-3">
                                    <Button variant="outline-primary" onClick={this.viewCompanyDetailsBtn}>cancel</Button>
                                    <Button disabled={isLoading} className="ml-3" onClick={this.updateCompanyDetails}>{isLoading ? "Loading..." : "Update"}</Button>
                                </div>}
                            </div>
                        </Modal.Body>
                    </Modal>
                </Animated>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        userData: state.getUserData
    }
}
export default connect(mapStateToProps, { callApi })(Company)