import React, { Component } from 'react';
import RegisterForm from './forms/register/Register';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

class Register extends Component {

    render() {
        return (
            <React.Fragment>
                {this.props.authenticated
                    ? <Redirect to={{ pathname: '/feedback' }} />
                    : (
                        <div className="">
                            <RegisterForm {...this.props} />
                        </div>
                    )
                }
            </React.Fragment>
        );
    }
}

const mapStatToProps = state => {
    return {
        authenticated: state.authenticated
    };
};

export default connect(mapStatToProps)(Register);
