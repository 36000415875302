import React, { Component } from 'react';
import { Button, Modal, Nav, Dropdown, Carousel } from 'react-bootstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { callApi, setKnowledgeBankPhotosData } from '../../store/reducers/commonFunction'
import Pagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';
import errorMessages from '../../store/reducers/errorMessages';
import common from '../../store/reducers/commons';
import swal from 'sweetalert';
import { Spinner } from 'react-activity';
import 'react-activity/dist/react-activity.css';
import appConstant from '../../constant/appConstant';

import _FilterComponent from './Component/_FilterComponent';
import _FloatingMenu from './Component/_FloatingMenu';
class KnowledgeBankPhotos extends Component {

    /**
    |--------------------------------------------------
    | constructor
    |--------------------------------------------------
    */

    constructor(props) {
        super(props);
        this.state = {
            contentArea: false,
            newAnnouncement: false,
            OpenOptions: false,
            CloseOptions: false,
            shareText: false,
            shareLink: false,
            sharePhotos: false,
            shareCode: false,
            userUploadedImgs: false,
            userUploadedUpt: false,
            ismodelName: "",
            current_page: 1,
            per_page: 10,
            total: 1,
            last_page: 1,
            total_pages: null,
            filterArr: [],
            loadedFiles: [],
            imgArr: [],
            message: '',
            link: '',
            code: '',
            url_image: '',
            show: false,
            shows: false,
            privew: false,
            errors: {
                message: ''
            },
            isLoading: false
        }
    }

    /**
    |--------------------------------------------------
    | openModal1
    |--------------------------------------------------
    */

    openModal1(image) {
        this.setState({
            image: image,
            shows: true
        })
    }

    /**
    |--------------------------------------------------
    | handleCloseimg
    |--------------------------------------------------
    */

    handleCloseimg = () => {
        this.setState({ shows: false });
    }

    /**
    |--------------------------------------------------
    | handleCloseupt
    |--------------------------------------------------
    */

    handleCloseupt = () => {
        this.setState({ privew: false });
    }

    /**
    |--------------------------------------------------
    | handleClose
    |--------------------------------------------------
    */

    handleClose = (modelName) => {
        this.setState({ visible: false });
    }

    /**
    |--------------------------------------------------
    | handleCloses
    |--------------------------------------------------
    */

    handleCloses = (modelName) => {
        this.setState({ visible: false });
    }

    /**
    |--------------------------------------------------
    | handleShow
    |--------------------------------------------------
    */

    handleShow = (modelName) => {
        this.setState({ visible: true, ismodelName: modelName }, () => this.CloseOptions);
    }

    /**
    |--------------------------------------------------
    | OpenOptions
    |--------------------------------------------------
    */

    OpenOptions = () => {
        this.setState({ leftbar: !this.state.OpenOptions },
            function () {
                document.body.classList.toggle('OpenOptions');
            });
    }

    /**
    |--------------------------------------------------
    | CloseOptions
    |--------------------------------------------------
    */

    CloseOptions = () => {

        this.setState({ leftbar: !this.state.CloseOptions },
            function () {
                document.body.classList.remove('CloseOptions');
            });
    }

    /**
    |--------------------------------------------------
    | componentDidMount
    |--------------------------------------------------
    */

    async componentDidMount() {
        this.props.onRef && this.props.onRef(this);
        await this.getKnowledgePhotos(false)
    }

    /**
    |--------------------------------------------------
    | componentWillUnmount
    |--------------------------------------------------
    */

    componentWillUnmount() {
        this.props.onRef && this.props.onRef(undefined);
    }

    /**
    |--------------------------------------------------
    | setParentStatus
    |--------------------------------------------------
    */

    setParentStatus = async (id) => {
        let knowledge_id = id;
        let body = {
            knowledge_id
        }
        this.setState({ knowledge_id: knowledge_id })
        const response = await this.props.callApi('/getKnowledgeDetail', body, 'post', null, true);
        this.setState({
            Knowledge: response.data
        })
    }

    /**
    |--------------------------------------------------
    | getKnowledgePhotos
    |--------------------------------------------------
    */

    getKnowledgePhotos = async (isAppend = true) => {
        let { filterArr, tagVal, current_page } = this.state;
        let tagsvalue = tagVal ? tagVal.map((i) => {
            return (
                i.value
            )
        }) : '';
        let filterData = _FilterComponent.getFilterValue();
        let body = { page: current_page, type: "file", tags: filterData.tagVal, filter_by: filterData.filterArr }
        this.setState({
            isLoading: true
        })
        const response = await this.props.callApi('/getKnowledgeList', body, 'post', 'ADMIN_LOGIN', true);

        if (response.data && response.data.tags && response.data.tags.length > 0) {
            tagVal = response.data.tags && Array.isArray(response.data.tags) && response.data.tags.map((values) => {
                return (
                    { label: values, value: values }
                )
            })
        }
        if (response) {
            this.props.setKnowledgeBankPhotosData(isAppend ? [...this.props.knowledgeBankPhotosData, ...response.data] : response.data);
            if (this.props.getChildEvent && this.props.activeTab == 'photo') {
                if (response && response.data && response.data.length > 0) {
                    this.props.getChildEvent(response.data[0].knowledge_id, appConstant.TYPE.FILE);
                } else {
                    this.props.getChildEvent('');
                }
            }
            this.setState({
                // knowledgephoto: response.data,
                per_page: response.meta.per_page,
                total: response.meta.total,
                show: false,
                isLoading: false,
                last_page: response.meta.last_page,
            })
        }
    }

    /**
    |--------------------------------------------------
    |paginationChange 
    |--------------------------------------------------
    */

    paginationChange(current_page) {
        let per_page = this.state.per_page;
        this.setState({
            current_page, per_page
        }, () => this.getKnowledgePhotos());
    }

    /**
    |--------------------------------------------------
    | getTags
    |--------------------------------------------------
    */

    getTags = async () => {
        let company_id = this.props.userData.company_id ? this.props.userData.company_id : '';
        let body = { company_id }
        const response = await this.props.callApi('/getTags', body, 'post', 'ADMIN_LOGIN', true);
        if (response) {
            this.setState({ tags: response })
        }
    }

    /**
    |--------------------------------------------------
    | tagValue
    |--------------------------------------------------
    */

    tagValue = async (e) => {
        this.setState({
            tagVal: e
        })
    }

    /**
    |--------------------------------------------------
    | addLoadedFile
    |--------------------------------------------------
    */

    addLoadedFile(file) {
        let tempPhotos = this.state.loadedFiles;
        tempPhotos.push(file);
        this.setState({
            loadedFiles: tempPhotos,
        });
    }

    /**
    |--------------------------------------------------
    | dropdownToggle
    |--------------------------------------------------
    */

    dropdownToggle = () => {
        this.setState({ show: !this.state.show });
    }

    /**
    |--------------------------------------------------
    | deleteKnowledge
    |--------------------------------------------------
    */

    deleteKnowledge = async (knowledge_id) => {

        swal({
            title: "Are you sure want to delete this Knowledge ?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async willDelete => {
                if (willDelete) {
                    let body = {
                        knowledge_id: knowledge_id
                    }
                    this.setState({
                        knowledge_id
                    })
                    const response = await this.props.callApi('/deleteKnowledge', body, 'post', null, true);
                    if (response.data.code === 200) {
                        common.showSuccessMessage(response.data.message);
                        this.getKnowledgePhotos()
                    }
                }
            });
    }

    /**
    |--------------------------------------------------
    | openModal
    |--------------------------------------------------
    */

    openModal(Knowledge) {
        let tagsvalue = Knowledge && Knowledge.tags.map((i) => {
            return (
                i
            )
        })
        let knowledgephoto = Knowledge && Knowledge.uploads.map((i) => {
            return (
                i
            )
        })
        this.setState({
            descriptions: Knowledge.description,
            knowledge_id: Knowledge.knowledge_id,
            tagvalue: tagsvalue,
            multimages: knowledgephoto,
            privew: true,
        });
    }

    /**
    |--------------------------------------------------
    | updateKnowledge
    |--------------------------------------------------
    */

    updateKnowledge = async () => {
        var { width, height, descriptions, file, thumbimage, image_width, image_height, duration, knowledge_id, tagVal } = this.state;
        let tagsvalue = tagVal && tagVal.map((i) => {
            return (
                i.value
            )
        })
        //  tagsarray.push(tagsvalue.toString())
        // if (this.validateForm()) {
        const formData = new FormData()
        if (file) {
            if (file.type === 'video/mp4') {
                formData.append('video', file)
                formData.append('image', thumbimage)
                formData.append('image_width', image_width)
                formData.append('image_height', image_height)
                formData.append('video_duration', duration)
            } else {
                formData.append('image', file)
                formData.append('image_width', width)
                formData.append('image_height', height)
            }
            formData.append('knowledge_id', knowledge_id)

            // formData.append('knowledge_upload_id', knowledge_upload_id)
            const response = await this.props.callApi('/uploadKnowledgeFile', formData, 'post', null, true, true);
            if (response) {
                this.setState({ privew: false });
            }
        }
        formData.append('knowledge_id', knowledge_id)
        formData.append('tags', tagsvalue)
        formData.append('description', descriptions)
        // formData.append('type','file')

        // replaceDeleteKnowledgeFile
        const responses = await this.props.callApi('/replaceDeleteKnowledgeFile', formData, 'post', null, true, true);
        if (responses) {
            common.showSuccessMessage("Knowledge details updated succesfully");
            this.setState({ privew: false });
            this.getKnowledgePhotos();
        }
    }

    /**
    |--------------------------------------------------
    | removeKnowledge
    |--------------------------------------------------
    */

    removeKnowledge = async (knowledge_upload_id) => {
        var { knowledge_id } = this.state;

        const formData = new FormData()
        formData.append('knowledge_id', knowledge_id)
        formData.append('knowledge_upload_id', knowledge_upload_id)

        const response = await this.props.callApi('/replaceDeleteKnowledgeFile', formData, 'post', null, true, true);
        if (response) {
            this.openModal(response.data);
        }
    }

    /**
    |--------------------------------------------------
    | handleScroll
    |--------------------------------------------------
    */

    handleScroll = (e) => {
        let element = e.target
        if (element.scrollTop + element.offsetHeight >= element.scrollHeight) {
            if (this.state.current_page != this.state.last_page) {
                this.setState({ current_page: this.state.current_page + 1 }, () => {
                    this.getKnowledgePhotos()
                })
            }
        }
    }

    /**
    |--------------------------------------------------
    | onPressFilter
    |--------------------------------------------------
    */

    onPressFilter = (tags, filter) => {
        console.log('tags-->', JSON.stringify(tags))
        console.log('filter-->', JSON.stringify(filter))
        this.setState({
            tagVal: tags,
            filterArr: filter,
            current_page: 1
        }, () => this.getKnowledgePhotos(false));
    }

    /**
    |--------------------------------------------------
    | onItemClick
    |--------------------------------------------------
    */

    onItemClick = (knowledgeDetail) => {
        this.props.getChildEvent(knowledgeDetail.knowledge_id, appConstant.TYPE.FILE)
    }

    /**
    |--------------------------------------------------
    | render
    |--------------------------------------------------
    */

    render() {
        let user_id = this.props.userData.user_id ? this.props.userData.user_id : '';
        const { knowledgeBankPhotosData } = this.props;
        const { isLoading } = this.state;
        var { thumbimage, multimages, imgArr, file, tagvalue, total, knowledgephoto, current_page, per_page, tags, message, errors, link, code } = this.state
        var strmessage = message;
        let tagsList = tags && Array.isArray(tags.data) && tags.data.map((values) => {
            return (
                { label: values, value: values }
            )
        })
        let updatetags = tagvalue && Array.isArray(tagvalue) && tagvalue.map((values) => {
            return (
                { label: values, value: values }
            )
        })


        return (
            <PerfectScrollbar onScroll={this.handleScroll}>
                {
                    knowledgeBankPhotosData && Array.isArray(knowledgeBankPhotosData) && knowledgeBankPhotosData.map((Knowledgedetail, key) => {
                        return (
                            <li className="mb-4" key={key} onClick={() => this.onItemClick(Knowledgedetail)}>
                                <div className="card">
                                    <div className="card-header">
                                        <div className="leftbar-list-item-inner py-0">
                                            <div className="user-img small">
                                                <div className="user-img-wrapper m-0">
                                                    <img src={Knowledgedetail.user_image} alt="" width="40" height="40" />
                                                </div>
                                            </div>
                                            <div className="user-data w-100">
                                                <div className="user-name">{Knowledgedetail.user_name}</div>
                                                <div className="data-text text-capitalize">{new Date(Knowledgedetail.last_updated_date).toLocaleString()}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <p className="card-text">{Knowledgedetail.description} </p>
                                        {
                                            Knowledgedetail.uploads && Knowledgedetail.uploads.map((photo, key) => {
                                                return (
                                                    <span key={key}>
                                                        { photo.subtype === 'video' ?
                                                            <video width="320" height="180" controls>
                                                                <source src={photo.video} type="video/mp4" />
                                                            </video> :
                                                            <span className="user-uploded-img" onClick={() => this.openModal1(Knowledgedetail)} key={key}>
                                                                <img src={photo.image} alt="" />
                                                            </span>
                                                        }
                                                    </span>
                                                )
                                            })

                                        }
                                    </div>

                                    <div className="selected-categories-block">
                                        <div className="permission-badge-block mt-0">
                                            {
                                                Knowledgedetail && Array.isArray(Knowledgedetail.tags) && Knowledgedetail.tags.map((tag, key) => {
                                                    return (
                                                        <span className="permission-badge" key={key}>{tag}</span>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                    {this.props.likeCommentView(Knowledgedetail)}
                                </div>
                            </li>
                        )
                    })
                }
                {this.state.isLoading && <div className={'loader-block'}>
                    <Spinner />
                </div>}
                {(!this.state.isLoading && knowledgeBankPhotosData && knowledgeBankPhotosData.length === 0) && this.props.noRecordView()}
            </PerfectScrollbar>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        userData: state.getUserData,
        knowledgeBankPhotosData: state.knowledgeBankPhotosData,
    }
}
export default withRouter(connect(mapStateToProps, { callApi, setKnowledgeBankPhotosData })(KnowledgeBankPhotos))
