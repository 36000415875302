import React, { Component } from 'react';
import { Animated } from "react-animated-css";
import { connect } from 'react-redux';
// import MobileToggle from '../mobileToggles/mobileToggles';
import 'react-phone-input-2/lib/style.css'
import 'react-image-crop/dist/ReactCrop.css';
// import PerfectScrollbar from 'react-perfect-scrollbar';

class TermsCondition extends Component {
    constructor(props) {
        super(props);
        this.state = {
                           
        }
    }


    async componentDidMount() {
    }
   
render() {

        return (
            <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={true} animationInDelay={500}>
                <div className="main-page-wrapper privacy-terms-page">
                    <div>
                        <h1><b>Terms and Conditions</b></h1>
                        <p>This is a summary of our new privacy policy which takes effect on May 25th, 2018. It covers every IndiaNIC website that links here, and all of the products and services contained on those websites. The detailed policy follows the same structure as this summary and constitutes the actual legal document.</p>

                        <h3 className="mt-4"><b>Out commitment</b></h3>
                        <p>IndiaNIC has never sold your information to any third party for advertising, monetary purpose or showing you other people’s ads and promotions, and we never will. This has been our approach and commitment for almost 20 years, and we remain the same to it. This policy explain you what information we do collect from you, what we do with it, who can access it, and what you can do about it.</p>

                        <h3 className="mt-4"><b>Part I – Information IndiaNIC collects and controls</b></h3>
                        <p>We only collect the information that we actually need and which is important to us to provide services to you. Some of that is information that you proactively give us while you contact us using email, contact forms or lead generation forms and ask for acquire our services or buy something from us. We store your name and contact information like phone, social profiles, address etc., and your own project related information.</p>

                        <p>When you visit one of our websites or use our service, we automatically log some basic information like how you got to the site, where you navigated within it, your location and what features and settings you use. We use this information to improve our websites and services and to drive serve you better while providing our services.</p>

                        <p>Sometimes we receive information indirectly. If you ask about our services through one of our referral programs or reselling partners, or sign in to one of our products through an authentication service provider like LinkedIn or Google, Facebook they’ll pass on your contact information to us. We’ll use that information to complete the request that you made. If you engage with our brand on social media (for instance, liking, commenting, retweeting, mentioning, or following us), we’ll have access to your interactions and profile information. We’ll still have that information even if you later remove it from the social media site.</p>

                        <h3 className="mt-4"><b>What we do with your information</b></h3>
                        <p>We use your information to provide the services you’ve requested, create and maintain your accounts. We also use it to communicate with you about the services you’re currently using, your customer support requests, new services you may like, chances for you to give us feedback, and policy updates. We analyze the information we collect to understand user needs and to improve our websites and services.</p>

                        <p>We are required to have a legal basis for collecting and processing your information. In most cases, we either have your consent or need the information provide the service you’ve requested from us. When that’s not the case, we must demonstrate that we have another legal basis, such our legitimate business interests.</p>

                        <p>You can decline certain kinds of information use either by not providing the information in the first place or by opting out later. You can also disable cookies to prevent your browser from giving us information, but if you do so, certain website features may not work properly. We completely disable third-party cookies from all IndiaNIC websites.</p>

                        <p>We limit access to your personal information to our employees and vendors who have a legitimate need to use it.</p>

                        <p>The European Economic Area (EEA) provides certain rights to data subjects (including access, rectification, erasure, restriction of processing, data portability, and the right to object and to complain). IndiaNIC undertakes to provide you the same rights no matter where you choose to live.</p>

                        <p>We keep your personal information for as long as it is required for the purposes stated in the Privacy Policy. When we no longer have a legitimate need to process your information, we will delete, anonymize, or isolate your information whichever is appropriate.</p>

                        <h3 className="mt-4"><b>Part II – Information that IndiaNIC processes on your behalf</b></h3>
                        <p>If you handle other people’s data using IndiaNIC services, such as information about your customers or employees, you are entrusting that data to us for processing.</p>

                        <p>Being a service providers you may share your customers, employees, vendors data as a part of project development process. You own your service data. We protect it, limit access to it, and only process it according to your instructions. You may access it, share it through third-party integrations, and request that we export or delete it.</p>

                        <p>If you are in the European Economic Area and you believe that someone has entrusted your information to us for processing (for instance, your employee, your employer or a company whose services you use), you can request certain actions from us regarding your data. To exercise those data rights, please contact the person or company that entrusted the data to us and we will work with them on your request.</p>

                        <h3 className="mt-4"><b>Part III – General</b></h3>
                        <p>There are some limitations to the privacy we can promise you. We will disclose personal information if it’s necessary to comply with a legal obligation, prevent fraud, enforce an agreement, or protect our users’ safety. We do not currently honor Do Not Track signals from internet browsers; when a universal standard for processing them emerges, we will follow it.</p>
                        <p>You can always contact us to: ask questions about our privacy practices, request a GDPR-compliant Data Processing Addendum, alert us if you believe we have collected personal data from a minor, or ask to have your personal information removed from our blogs or forums. You can also check our <b>Security Policy </b>and <b>Terms and Conditions</b>.</p>
                    </div>
                </div>
            </Animated>
        );
    }
}

export default (connect('',{})(TermsCondition))