import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import { Dropdown, Modal, Button } from 'react-bootstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Animated } from "react-animated-css";
import { callApi, setIdeaData, setTimelineData } from '../../store/reducers/commonFunction'
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Leftbar from './leftbar/Leftbar';
import MobileToggle from '../mobileToggles/mobileToggles';
import errorMessages from '../../store/reducers/errorMessages'
import SidebarAnnouncement from '../sidebarAnnouncement/SidebarAnnouncement';
import Pagination from 'rc-pagination'
import 'rc-pagination/assets/index.css';
import { Spinner } from 'react-activity';
import 'react-activity/dist/react-activity.css';
import swal from 'sweetalert'
import common from '../../store/reducers/commons'
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import helper from '../../services/helper';
import Linkify from 'react-linkify';
import _ from 'lodash';
let _this = null;
class IdeaSharing extends Component {

    /**
    |--------------------------------------------------
    | constructor
    |--------------------------------------------------
    */

    constructor(props) {
        super(props);
        _this = this;
        this.state = {
            contentArea: false,
            userUploadedImgs: false,
            userUpdateDetails: false,
            show: false,
            visible: false,
            idealist: '',
            comments: [],
            like_count: '',
            comment: '',
            name: '',
            message: '',
            errors: {
                name: '',
            },
            current_page: 1,
            last_page: 1,
            per_page: 10,
            total: 1,
            last_page: false,
            total_pages: null,
            isLoading: false,
            isUpdate: false,
            tagVal: [],
        }
    }

    /**
    |--------------------------------------------------
    | componentDidMount
    |--------------------------------------------------
    */

    async componentDidMount() {
        this.getTags()
    }

    /**
    |--------------------------------------------------
    | updateIdeaData
    |--------------------------------------------------
    */

    static updateIdeaData(idea_id) {
        const { selectedIdeaDetail } = _this.state;
        if (idea_id == selectedIdeaDetail.idea_id) {
            // _this.setParentStatus(idea_id, false);
            let tempSelectedIdeaDetail = {
                ...selectedIdeaDetail,
                total_likes: selectedIdeaDetail.is_liked === 0 ? selectedIdeaDetail.total_likes + 1 : this.state.selectedIdeaDetail.total_likes - 1,
                is_liked: selectedIdeaDetail.is_liked === 0 ? 1 : 0,
            }
            _this.setState({
                selectedIdeaDetail: tempSelectedIdeaDetail
            })
        }
    }

    /**
    |--------------------------------------------------
    | displayIdeaData
    |--------------------------------------------------
    */

    async displayIdeaData(index = 0, idea_id = undefined) {
        if (this.props && this.props.location && this.props.location.ideaId) {
            let feeduer = this.props.location.ideaId
            await this.setParentStatus(feeduer);
        } else {
            if (idea_id) {
                _this.setParentStatus(idea_id);
            } else {
                let listuser = _this.props.ideaData ? _this.props.ideaData[index] : [];
                if (listuser) {
                    await _this.setParentStatus(listuser.idea_id);
                }
            }
        }
    }

    /**
    |--------------------------------------------------
    | handleClose
    |--------------------------------------------------
    */

    handleClose = (modelName) => {
        this.setState({ show: false });
    }

    /**
    |--------------------------------------------------
    | handleShow
    |--------------------------------------------------
    */

    handleShow = (modelName) => {
        this.setState({ show: true });
    }

    /**
    |--------------------------------------------------
    | handleCloseD
    |--------------------------------------------------
    */

    handleCloseD = (modelName) => {
        this.setState({ visible: false });
    }

    /**
    |--------------------------------------------------
    | handleShowD
    |--------------------------------------------------
    */

    handleShowD = (modelName) => {
        this.setState({
            visible: true,
            isUpdate: false,
            message: '',
            idea_id: undefined,
            imagePreviewUrl: undefined,
            url: undefined,
            thumbimage: undefined,
            fileVisible: false,
            tagvalue: [],
            tagVal: [],
        });
    }

    /**
    |--------------------------------------------------
    | setParentStatus
    |--------------------------------------------------
    */

    setParentStatus = async (ideaslist, isLoading = true) => {
        // let {tagVal} = this.state;
        this.setState({
            isLoading: isLoading,
            comments: isLoading ? [] : this.state.comments
        })
        let tagVal = [];
        console.log("tagval", tagVal)
        let idea_id = ideaslist;
        let body = { idea_id }
        this.setState({ idea_id: idea_id })
        console.log("getIdeaDetail body-->", JSON.stringify(body))
        const response = await this.props.callApi('/getIdeaDetail', body, 'post', null, true);
        console.log("getIdeaDetail response-->", JSON.stringify(response))
        if (response.data && response.data.tags && response.data.tags.length > 0) {
            tagVal = response.data.tags && Array.isArray(response.data.tags) && response.data.tags.map((values) => {
                return (
                    { label: values, value: values }
                )
            })
        }

        this.setState({
            selectedIdeaDetail: response.data,
            current_page: 1,
            isLoading: false
        }, () => this.getComments(isLoading))
    }

    /**
    |--------------------------------------------------
    | handleChange
    |--------------------------------------------------
    */

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value })
        if (event.target.value) {
            this.setState({
                errors: Object.assign(this.state.errors, { [event.target.name]: "" })
            });
        } else {
            this.setState({
                errors: Object.assign(this.state.errors, { [event.target.name]: errorMessages.REQUIRED })
            })
        }
    }

    /**
    |--------------------------------------------------
    | getComments
    |--------------------------------------------------
    */

    getComments = async (isAppend = true) => {
        let { selectedIdeaDetail, current_page } = this.state;
        if (selectedIdeaDetail) {

            let body = { page: current_page, idea_id: selectedIdeaDetail.idea_id ? selectedIdeaDetail.idea_id : '' }
            console.log('ideaCommentList body-->', JSON.stringify(body));
            const response = await this.props.callApi('/ideaCommentList', body, 'post', 'ADMIN_LOGIN', true);
            console.log('ideaCommentList comment response-->', JSON.stringify(response));
            if (response) {
                this.setState({
                    comments: isAppend ? [...this.state.comments, ...response.data] : response.data || [],
                    per_page: response.meta.per_page,
                    last_page: response.meta.last_page,
                    total: response.meta.total
                })
            } else {
                this.setState({
                    comments: [],
                })
            }
        }
    }

    /**
    |--------------------------------------------------
    | handleLike
    |--------------------------------------------------
    */

    handleLike = async () => {
        let { idea_id, selectedIdeaDetail } = this.state;
        let body = { idea_id }
        this.setState({ idea_id: idea_id })
        let tempSelectedIdeaDetail = {
            ...selectedIdeaDetail,
            total_likes: selectedIdeaDetail.is_liked === 0 ? selectedIdeaDetail.total_likes + 1 : this.state.selectedIdeaDetail.total_likes - 1,
            is_liked: selectedIdeaDetail.is_liked === 0 ? 1 : 0,
        }
        this.setState({
            selectedIdeaDetail: tempSelectedIdeaDetail
        })

        //Update Sidebar announcement data
        let timelineData = this.props.timelineData
        let timelineDataSelectedIndex = timelineData.findIndex(x => x.data.idea_id == idea_id);
        _.set(timelineData, `[${timelineDataSelectedIndex}].data.total_likes`,
            _.get(timelineData, `[${timelineDataSelectedIndex}].data.is_liked`) == 0
                ? _.get(timelineData, `[${timelineDataSelectedIndex}].data.total_likes`) + 1
                : _.get(timelineData, `[${timelineDataSelectedIndex}].data.total_likes`) - 1);
        _.set(timelineData, `[${timelineDataSelectedIndex}].data.is_liked`, _.get(timelineData, `[${timelineDataSelectedIndex}].data.is_liked`) == 1 ? 0 : 1);

        this.props.setTimelineData([...timelineData]);

        const response = await this.props.callApi('/likeIdea', body, 'post', 'ADMIN_LOGIN', true);
        if (response) {
            // this.setState({ likes: response }, () => this.setParentStatus(idea_id))
        }
    }

    /**
    |--------------------------------------------------
    | addComment
    |--------------------------------------------------
    */

    addComment = async (event) => {
        event && event.preventDefault();
        let { idea_id, comment, selectedIdeaDetail } = this.state;
        let body = { idea_id, comment }
        const response = await this.props.callApi('/addIdeaComment', body, 'post', '', true);
        if (response) {
            console.log('addIdeaComment response-->', JSON.stringify(response));
            this.sidebarAnnouncementRef.getAnnouncement(false, false);
            this.setState({
                comments: [...this.state.comments, response.data], comment: '',
                selectedIdeaDetail: {
                    ...selectedIdeaDetail,
                    total_comments: selectedIdeaDetail.total_comments + 1
                }
            }, () => {
                this.scrollToEnd()
            });
        }
    }

    /**
    |--------------------------------------------------
    | paginationChange
    |--------------------------------------------------
    */

    paginationChange(current_page) {
        let per_page = this.state.per_page;
        this.setState({
            current_page, per_page
        }, () => this.getIdeaDetails());
    }

    /**
    |--------------------------------------------------
    | deleteIdea
    |--------------------------------------------------
    */

    deleteIdea = async (idea_id) => {
        swal({
            title: "Are you sure want to delete this idea ?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async willDelete => {
                if (willDelete) {
                    // let idea_id = id;
                    let body = {
                        "idea_id": idea_id + ""
                    }
                    this.setState({
                        idea_id
                    })
                    console.log('deleteidea body-->', JSON.stringify(body));
                    const response = await this.props.callApi('/deleteIdea', body, 'post', null, true);
                    console.log('deleteidea response-->', JSON.stringify(response));
                    if (response.data.code === 200) {
                        // this.setParentStatus(feedback_id)
                        common.showSuccessMessage(response.data.message);

                        let ideaData = this.props.ideaData || [];
                        let ideaIndex = ideaData.findIndex(x => x.idea_id == idea_id);
                        if (ideaIndex != -1) {
                            ideaData.splice(ideaIndex, 1)
                            this.props.setIdeaData([...ideaData]);
                            let nextIdeaId = this.props.ideaData[ideaIndex] && this.props.ideaData[ideaIndex].idea_id || undefined
                            this.displayIdeaData(ideaIndex, nextIdeaId)
                        }
                        this.sidebarAnnouncementRef.getAnnouncement(false, false);

                        // window.location.reload()
                    }
                }
            });
    }

    /**
    |--------------------------------------------------
    | openModal
    |--------------------------------------------------
    */

    openModal(idealistt) {
        let tagsvalue = idealistt.tags.map((i) => {
            return (
                i
            )
        })
        console.log('idealistt-->', JSON.stringify(idealistt));
        this.setState({
            message: idealistt.text,
            idea_id: idealistt.idea_id,
            imagePreviewUrl: idealistt.image,
            url: idealistt.video,
            fileVisible: (idealistt.image == "" || idealistt.video == "") ? false : true,
            thumbimage: idealistt.image,
            tagvalue: tagsvalue,
            tagVal: tagsvalue,
            visible: true,
            isUpdate: true
        });
    }

    /**
    |--------------------------------------------------
    | validateForm
    |--------------------------------------------------
    */

    validateForm() {
        let { message, errors, isFormValid, tagVal } = this.state;
        if (tagVal.length == 0) {
            errors.message = errorMessages.PROVIDE_IDEA_TAG;
            errors.type = 'TAG';
        } else if (!message.trim()) {
            errors.message = errorMessages.PROVIDE_IDEA_MESSAGE;
            errors.type = 'MESSAGE';
        } else {
            errors.message = '';
            errors.type = '';
        }
        if (errors.message !== '') isFormValid = false;
        else isFormValid = true;
        this.setState({ errors, isFormValid })
        return isFormValid
    }

    /**
    |--------------------------------------------------
    | addIdea
    |--------------------------------------------------
    */

    addIdea = async () => {
        try {
            var { image_width, image_height, idea_id, message, tagVal,
                duration, file, width, height, thumbimage, isUpdate } = this.state;


            let tagsvalue = tagVal && tagVal.map((i) => {
                return (
                    i.value || i
                )
            }) || []
            console.log('tagsvalue-->', JSON.stringify(tagsvalue));
            // tagsarray.push(tagsvalue.toString())
            if (this.validateForm()) {
                const formData = new FormData()
                if (file) {
                    if (file.type === 'video/mp4') {
                        formData.append('type', "video")
                        formData.append('video', file)
                        formData.append('image', thumbimage)
                        formData.append('image_width', image_width)
                        formData.append('image_height', image_height)
                        formData.append('video_duration', duration)
                    } else {
                        formData.append('type', "image")
                        formData.append('image', file)
                        formData.append('image_width', width)
                        formData.append('image_height', height)
                    }
                } else {

                    formData.append('type', "text")
                }
                formData.append('tags', tagsvalue)
                formData.append('text', message)
                console.log('updateIdea body-->', formData)
                // return;
                const response = await this.props.callApi('/newIdea', formData, 'post', null, true);
                if (response) {
                    console.log('updateIdea response-->', JSON.stringify(response))
                    this.setState({
                        newidea: response.data,
                        visible: false,
                        fileVisible: false,
                        message: '',
                    })
                    common.showSuccessMessage("IdeaSharing Added Successfully")
                    this.sidebarAnnouncementRef.getAnnouncement(false, false);
                    this.props.setIdeaData([response.data, ...this.props.ideaData]);
                    await this.setParentStatus(response.data.idea_id);
                    // this.props.history.push("/ideaSharing")
                }
            }
        } catch (error) {
            throw (error)
        }
    }

    /**
    |--------------------------------------------------
    | updateIdea
    |--------------------------------------------------
    */

    updateIdea = async () => {
        try {
            var { image_width, image_height, idea_id, message, tagVal,
                duration, file, width, height, thumbimage, isUpdate } = this.state;


            let tagsvalue = tagVal && tagVal.map((i) => {
                return (
                    i.value || i
                )
            }) || []
            console.log('tagsvalue-->', JSON.stringify(tagsvalue));
            // tagsarray.push(tagsvalue.toString())
            if (this.validateForm()) {
                const formData = new FormData()
                if (file) {
                    if (file.type === 'video/mp4') {
                        formData.append('type', "video")
                        formData.append('video', file)
                        formData.append('image', thumbimage)
                        formData.append('image_width', image_width)
                        formData.append('image_height', image_height)
                        formData.append('video_duration', duration)
                    } else {
                        formData.append('type', "image")
                        formData.append('image', file)
                        formData.append('image_width', width)
                        formData.append('image_height', height)
                    }
                } else {
                    if (this.state.imagePreviewUrl == '' || this.state.url == '') {
                        formData.append('type', "text")
                        formData.append('is_file_remove', "yes")
                    }
                }
                formData.append('tags', tagsvalue)
                formData.append('text', message)
                formData.append('idea_id', idea_id)
                console.log('updateIdea body-->', formData)
                // return;
                const response = await this.props.callApi('/updateIdea', formData, 'post', null, true);
                if (response) {
                    console.log('updateIdea response-->', JSON.stringify(response))
                    this.setState({
                        newidea: response.data,
                        visible: false,
                    })
                    common.showSuccessMessage("IdeaSharing DetailsUpdated Successfully")

                    let ideaData = this.props.ideaData || [];
                    let ideaIndex = ideaData.findIndex(x => x.idea_id == idea_id);
                    if (ideaIndex != -1) {
                        ideaData.splice(ideaIndex, 1, response.data)
                        this.props.setIdeaData([...ideaData]);
                    }
                    await this.setParentStatus(idea_id);
                    this.sidebarAnnouncementRef.getAnnouncement(false, false);
                    // this.props.history.push("/ideaSharing")
                }
            }
        } catch (error) {
            throw (error)
        }
    }

    /**
    |--------------------------------------------------
    | getTags
    |--------------------------------------------------
    */

    getTags = async () => {

        let company_id = this.props.userData.company_id ? this.props.userData.company_id : '';

        let body = { company_id }
        const response = await this.props.callApi('/getTags', body, 'post', 'ADMIN_LOGIN', true);
        if (response) {
            this.setState({ tags: response })
        }
    }

    /**
    |--------------------------------------------------
    | tagValue
    |--------------------------------------------------
    */

    tagValue = async (e) => {
        this.setState({
            tagVal: e
        })
    }

    /**
    |--------------------------------------------------
    | fileChangedHandlerImg
    |--------------------------------------------------
    */

    fileChangedHandlerImg(event) {
        let file = event.target.files[0];
        let filename = event.target.files[0].name
        if (event.target.files[0].type.match('image')) {
            var img = new Image();
            img.src = window.URL.createObjectURL(event.target.files[0]);

            img.onload = () => {
                let width = img.naturalWidth,
                    height = img.naturalHeight;
                this.setState({
                    width: width,
                    height: height,
                })

                let reader = new FileReader()
                // let file = event.target.files[0];
                // let filename = event.target.files[0].name 
                reader.onloadend = () => {
                    this.setState({
                        file: file,
                        filename: filename,
                        imagePreviewUrl: reader.result,
                        fileVisible: true
                    })
                }
                reader.readAsDataURL(file);
            }
        } else {
            let fileReader = new FileReader()
            // let file = event.target.files[0];
            // let filename = event.target.files[0].name 
            var _that = this;

            fileReader.onloadend = function () {
                var blob = new Blob([fileReader.result], { type: file.type });
                var url = URL.createObjectURL(blob);
                var video = document.createElement('video');
                var timeupdate = function () {
                    if (snapImage()) {
                        video.removeEventListener('timeupdate', timeupdate);
                        video.pause();
                    }
                };
                video.addEventListener('loadeddata', function () {
                    if (snapImage()) {
                        video.removeEventListener('timeupdate', timeupdate);
                    }
                });
                var snapImage = () => {

                    var canvas = document.createElement('canvas');
                    canvas.width = video.videoWidth;
                    canvas.height = video.videoHeight;

                    canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
                    var image = canvas.toDataURL();

                    let block = image.split(";");
                    let contentType = block[0].split(":")[1];
                    let realData = block[1].split(",")[1];// In this case "iVBORw0KGg...."

                    let sliceSize = 512;
                    let byteCharacters = atob(realData, contentType);
                    let byteArrays = [];
                    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                        let slice = byteCharacters.slice(offset, offset + sliceSize);

                        let byteNumbers = new Array(slice.length);
                        for (let i = 0; i < slice.length; i++) {
                            byteNumbers[i] = slice.charCodeAt(i);
                        }
                        let byteArray = new Uint8Array(byteNumbers);
                        byteArrays.push(byteArray);
                    }

                    let blob = new Blob(byteArrays, { contentType })
                    /**************video duriatio *************/
                    var duration = video.duration;

                    /*********video end*********/
                    _that.setState({
                        file: file,
                        thumbimage: blob,
                        duration: duration,
                        image_width: canvas.width,
                        image_height: canvas.height,
                        url: url,
                        fileVisible: true
                    })
                    var success = image.length > 100000;
                    if (success) {
                        // var img = document.createElement('img');
                        // img.src = image;
                        // document.getElementsByTagName('div')[0].appendChild(img);
                        URL.revokeObjectURL(url);
                    }
                    return success;

                };

                video.addEventListener('timeupdate', timeupdate);
                video.preload = 'metadata';
                video.src = url;
                video.ondurationchange = function () {
                    if (this.duration > 45) {
                        swal("", "Currently, you can only upload files smaller than 10 MB (a 45 sec video for instance).", "warning")
                    }
                }
                // Load video in Safari / IE11
                video.muted = true;
                video.playsInline = true;
                video.play();
            };
            fileReader.readAsArrayBuffer(file);
        }
    }

    /**
    |--------------------------------------------------
    | handleAllScroll
    |--------------------------------------------------
    */

    handleAllScroll = (e) => {
        let element = e.target
        if (element.scrollTop + element.offsetHeight >= element.scrollHeight) {
            if (this.state.current_page != this.state.last_page) {
                this.setState({ current_page: this.state.current_page + 1 }, () => {
                    this.getComments()
                })
            }
        }
    }

    /**
    |--------------------------------------------------
    | deleteComment
    |--------------------------------------------------
    */

    deleteComment = async (comment, index) => {
        let { comments, selectedIdeaDetail } = this.state;
        swal({
            title: "Are you sure want to delete this comment ?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async willDelete => {
                if (willDelete) {

                    let body = {
                        "idea_id": this.state.selectedIdeaDetail.idea_id,
                        "idea_comment_id": comment.comment_id
                    }
                    console.log('deleteIdeaComment body-->', JSON.stringify(body));
                    const response = await this.props.callApi('/deleteIdeaComment', body, 'post', null, true);
                    if (response.data.code === 200) {
                        this.sidebarAnnouncementRef.getAnnouncement(false, false);
                        common.showSuccessMessage(response.data.message);
                        // let messagelist = this.state.suggestionList.filter(list=>list.feedback_message_id !== this.state.feedback_message_id)
                        comments.splice(index, 1)
                        this.setState({
                            comments: comments,
                            selectedIdeaDetail: {
                                ...selectedIdeaDetail,
                                total_comments: selectedIdeaDetail.total_comments - 1
                            }
                        })
                    }
                }
            });
    }

    /**
    |--------------------------------------------------
    | scrollToEnd
    |--------------------------------------------------
    */

    scrollToEnd() {
        setTimeout(() => {
            const container = document.querySelector(".content-area-wrapper .scrollbar-container");
            if (container && container.scrollHeight) {
                container.scrollTop = container.scrollHeight;
            }
        }, 100);
    }

    /**
    |--------------------------------------------------
    | render
    |--------------------------------------------------
    */

    render() {

        var { url, thumbimage, selectedIdeaDetail, tagvalue, message,
            imagePreviewUrl, tags, comments, comment, total, current_page,
            per_page, likes, isLoading, isUpdate, errors, file } = this.state;
        const { userData } = this.props;
        var str = message;
        let tagsList = tags && Array.isArray(tags.data) && tags.data.map((values) => {
            return (
                { label: values, value: values }
            )
        })
        let updatetags = tagvalue && Array.isArray(tagvalue) && tagvalue.map((values) => {
            return (
                { label: values, value: values }
            )
        })

        return (
            <div className="main-page-wrapper idea-sharing-page">
                <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={true} animationInDelay={500}>
                    <MobileToggle {...this.props} />
                    <Leftbar
                        {...this.props}
                        getChildEvent={this.setParentStatus}
                        selectedIdeaDetail={selectedIdeaDetail}
                        displayIdeaData={this.displayIdeaData}
                        handleShowD={this.handleShowD}
                    />
                    <SidebarAnnouncement {...this.props} onRef={ref => this.sidebarAnnouncementRef = ref} />

                    <div className={this.state.contentArea === false ? "content-area-wrapper" : "content-area-wrapper close-sidebar"}>

                        {!isLoading && selectedIdeaDetail ? <PerfectScrollbar onScroll={this.handleAllScroll}>
                            <div className="idea-content-block">
                                <ul className="content-list">
                                    <li className="content-list-item pb-0">
                                        <div className="leftbar-list-item-inner">
                                            <div className="user-img small">
                                                <div className="user-img-wrapper">
                                                    {
                                                        (selectedIdeaDetail && selectedIdeaDetail.user_image !== '') ? <img src={selectedIdeaDetail.user_image} alt="" /> :
                                                            <img src="images/noimage.jpeg" alt="" />

                                                    }
                                                    {/* <img src={selectedIdeaDetail.user_image} alt="" width="40" height="40" /> */}
                                                </div>
                                            </div>
                                            <div className="user-data">
                                                <span className="user-name">{selectedIdeaDetail.user_name}</span>
                                                <span className="date-time">{helper.formatTimeAgo(selectedIdeaDetail.created_at)}</span>
                                            </div>
                                            {(selectedIdeaDetail.user_id === userData.user_id) && <Dropdown className="dots-dropdown ml-auto">
                                                <Dropdown.Toggle variant="" id="dropdown-basic" className="material-icons">
                                                    more_vert
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu alignRight>
                                                    {/* <Dropdown.Item onClick={() => this.openModal(selectedIdeaDetail)}>Share</Dropdown.Item> */}
                                                    <Dropdown.Item onClick={() => this.openModal(selectedIdeaDetail)}>Edit</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => this.deleteIdea(selectedIdeaDetail.idea_id)}>Delete</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>}
                                        </div>
                                        <div className="card">
                                            <div className="card-body">
                                                <Linkify><p className="card-text">{selectedIdeaDetail.text}</p></Linkify>

                                                {
                                                    (selectedIdeaDetail.video !== undefined && selectedIdeaDetail.video !== '') ?
                                                        <video width="320" height="180" controls>
                                                            <source src={selectedIdeaDetail.video} type="video/mp4" />
                                                        </video>
                                                        : selectedIdeaDetail.image != '' && <span className="user-uploded-img" onClick={() => this.handleShow("userUploadedImgs")}>
                                                            <img src={selectedIdeaDetail.image} alt="" />
                                                        </span>}
                                            </div>
                                        </div>
                                        <div className="idea-post-control-panel">
                                            <div className="selected-categories-block">
                                                <div className="permission-badge-block mt-0">
                                                    {
                                                        selectedIdeaDetail && Array.isArray(selectedIdeaDetail.tags) && selectedIdeaDetail.tags.map((ideas, key) => {
                                                            return (
                                                                <span className="permission-badge" key={key}>{ideas}</span>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                            <div className="control-panel-block idea-like-control-panel">
                                                <div className="likes-comments-block">
                                                    <div className="control-panel-options likes-block">
                                                        <div className="icon-outer likes-icon">
                                                            <div className="likes-checkbox">
                                                                <label htmlFor="like">
                                                                    <input type="checkbox" className="like-input" defaultChecked={selectedIdeaDetail.is_liked ? true : false} onClick={this.handleLike} />
                                                                    {
                                                                        (selectedIdeaDetail.is_liked === 1) ?
                                                                            <img src="/images/like-icon-pink.svg" alt="liked post" className="purple-icon" /> :
                                                                            <img src="/images/like-icon-gray.svg" alt="like post" className="gray-icon" />
                                                                    }
                                                                </label>
                                                            </div>
                                                        </div>
                                                        {/* active-likes-label */}
                                                        <div className="counter-label-block">

                                                            {(selectedIdeaDetail.total_likes === 0) ? <span className="likes-label">No Likes</span> :
                                                                (selectedIdeaDetail.is_liked === 0 && selectedIdeaDetail.total_likes === 1) ? <span className="likes-label">{selectedIdeaDetail.total_likes || 0} Like</span> :
                                                                    (selectedIdeaDetail.is_liked === 1 && selectedIdeaDetail.total_likes === 1) ? <span className="active-likes-label">{selectedIdeaDetail.total_likes || 0} Like</span> :
                                                                        (selectedIdeaDetail.is_liked === 1 && selectedIdeaDetail.total_likes >= 1) ? <span className="active-likes-label">{selectedIdeaDetail.total_likes || 0} Likes</span> :
                                                                            <span className="likes-label">{selectedIdeaDetail.total_likes} Likes</span>}
                                                        </div>
                                                    </div>
                                                    <div className="control-panel-options comments-block">
                                                        <div className="icon-outer">
                                                            <img src="images/comments-icon.svg" alt="" width="15" />
                                                        </div>
                                                        <div className="counter-label-block">
                                                            {
                                                                (selectedIdeaDetail.total_comments === 0) ? <span className="label">no comments</span> :
                                                                    (selectedIdeaDetail.total_comments === 1) ? <span className="label">{selectedIdeaDetail.total_comments} comment</span> :
                                                                        <span className="label">{selectedIdeaDetail.total_comments} comments</span>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>


                                                {/* {
                                                    idealist&& Array.isArray(selectedIdeaDetail.tags)&&selectedIdeaDetail.tags.map((ideas,key)=>{
                                                    return(
                                                        <p className="permission-badge" key={key}>{ideas}</p>                                                       
                                                    )
                                                    })
                                                    } */}

                                            </div>
                                        </div>
                                    </li>
                                </ul>


                            </div>


                            <div className="idea-content-comments-block">

                                <div className="comments-block">
                                    {comments.length > 0 ? <ul className="comments-block-outer">
                                        {
                                            comments && Array.isArray(comments) && comments.map((comment, key) => {

                                                return (
                                                    <li className="comment-wrapper" key={key}>
                                                        <div className="leftbar-list-item-inner">
                                                            <div className="user-img small">
                                                                <div className="user-img-wrapper">
                                                                    <img src={comment.user_image} alt="" />
                                                                </div>
                                                            </div>
                                                            <div className="user-data">
                                                                <span className="user-name">{comment.user_name}</span>
                                                                <span className="date-time">{helper.formatTimeAgo(comment.created_at)}</span>
                                                            </div>

                                                            {comment.user_id == userData.user_id && <Dropdown className="dots-dropdown ml-auto">
                                                                <Dropdown.Toggle variant="" id="dropdown-basic" className="material-icons">
                                                                    more_vert
                                                                </Dropdown.Toggle>

                                                                <Dropdown.Menu alignRight>
                                                                    <Dropdown.Item onClick={() => this.deleteComment(comment, key)}>delete message</Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>}

                                                        </div>
                                                        <div className="card">
                                                            <div className="card-body">
                                                                <Linkify><p className="card-text">{comment.comment}</p></Linkify>
                                                            </div>
                                                        </div>
                                                    </li>

                                                )
                                            })
                                        }
                                    </ul> :
                                        <ul className="empty-comments-block">
                                            <div className="icon-outer">
                                                <img src="images/comments-icon.svg" alt="" width="15" />
                                            </div>
                                            <span className="label">{"Be the first to add a Comment..."}</span>
                                        </ul>}

                                </div>

                            </div>


                            <div className="chat-input-block for-idea-pages comment-on-idea">
                                <form className="comment-input-text" onSubmit={this.addComment}>
                                    <div className="chat-input-inner">
                                        <input
                                            type="text"
                                            name="comment"
                                            className="form-control"
                                            placeholder="Write comment"
                                            value={comment}
                                            onChange={this.handleChange}
                                        />
                                        <Button className="send-btn" onClick={this.addComment} disabled={comment == ""}>
                                            <img src="images/send-icon-purple.svg" alt="" />
                                        </Button>
                                    </div>
                                </form>
                            </div>

                        </PerfectScrollbar>
                            :
                            <div className={'loader-block'}>
                                <Spinner />
                            </div>
                        }
                    </div>
                </Animated>


                <Modal className="common-modal small transparent-modal" centered show={this.state.show} onHide={() => this.handleClose("userUploadedImgs")}>

                    <Modal.Body className="p-5">
                        <div className="modal-image">
                            {selectedIdeaDetail && <img
                                className="d-block w-100"
                                src={selectedIdeaDetail.image}
                                alt=""
                            />}
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal className="common-modal small" centered show={this.state.visible} onHide={() => this.handleCloseD("userUploadedImgs")}>
                    <button type="button" className="close fixed-close-btn" onClick={() => this.handleCloseD("userUploadedImgs")}>
                        <span aria-hidden="true">×</span>
                    </button>
                    <Modal.Body className="">

                        <h4>{isUpdate ? "Update Idea" : "Share New Idea"}</h4>
                        <span className="common-text">This idea will be displayed to all your colleague on the home screen until you do not remove it.</span>
                        {/* {idealist && arr.isArray(idealist && selectedIdeaDetail.map((idea)=>{
                           return( */}
                        <div className="content-area-inner">
                            <div className="new-topic-select">
                                <CreatableSelect
                                    isMulti
                                    name="addTag"
                                    options={tagsList}
                                    className="common-select"
                                    classNamePrefix="form-control"
                                    placeholder={"Type to add tag"}
                                    defaultValue={updatetags}
                                    onChange={(e) => this.tagValue(e)}
                                />
                                {errors.type == "TAG" && <span className="error-block"> {errors.message} </span>}
                            </div>

                            <div className="suggestion-block">
                                <textarea
                                    className='form-control'
                                    type='text'
                                    name="message"
                                    placeholder='Write your idea here...'
                                    value={message}
                                    onChange={this.handleChange}
                                    maxLength="3000"
                                ></textarea>
                                {errors.type == "MESSAGE" && <span className="error-block"> {errors.message} </span>}
                                <span className="remaining-words"><span>{str.length}</span>/3000</span>
                            </div>

                            <div className="company-logo-block add-picture new-idea my-3">
                                {!this.state.fileVisible ? <input
                                    type="file"
                                    // multiple
                                    accept="image/*,video/*"
                                    className="company-logo-changer  new-idea input-file"
                                    id="file-browser-input"
                                    name="file-browser-input"
                                    onChange={this.fileChangedHandlerImg.bind(this)} />
                                    :
                                    (url !== undefined && url !== '') ?
                                        <li className="li-image-wrap">
                                            <span className="remove-image" onClick={() => this.setState({ fileVisible: false, url: '', file: undefined, filename: undefined, })}>
                                                X
                                        </span>
                                            <video width="320" height="180" controls >
                                                <source src={url} type="video/mp4" />
                                            </video>
                                        </li>
                                        :
                                        imagePreviewUrl &&
                                        <li className="li-image-wrap">
                                            <span className="company-logo">
                                                <span className="remove-image" onClick={() => this.setState({
                                                    fileVisible: false, file: undefined,
                                                    filename: undefined,
                                                    imagePreviewUrl: undefined
                                                })}>
                                                    X
                                                </span>
                                                <img src={imagePreviewUrl} alt="" />
                                            </span>
                                        </li>
                                }
                            </div>

                        </div>
                        {/* )
                            })
                        } */}
                        <div className="text-right mb-4">
                            <Button className="btn btn-outline-primary" onClick={this.handleCloseD}>Cancel</Button>
                            <Button className="ml-3"
                                disabled={isLoading}
                                onClick={!isLoading ? isUpdate ? this.updateIdea : this.addIdea : null}> {isLoading ? 'Loading…' : isUpdate ? 'Update' : 'Send'}</Button>
                        </div>
                    </Modal.Body>
                </Modal>


            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        userData: state.getUserData,
        ideaData: state.allIdeaData,
        timelineData: state.timelineData,
    }
}
export default withRouter(connect(mapStateToProps, { callApi, setIdeaData, setTimelineData })(IdeaSharing))
