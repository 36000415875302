import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import { Animated } from "react-animated-css";
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import MobileToggle from '../mobileToggles/mobileToggles';
// import Leftbar from './leftbar/Leftbar';
import SidebarAnnouncement from '../sidebarAnnouncement/SidebarAnnouncement';
import { callApi, setProfileInformation } from '../../store/reducers/commonFunction'
import common from '../../store/reducers/commons'
import errorMessages from '../../store/reducers/errorMessages'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import helper from '../../services/helper';

class Profile extends Component {

    /**
    |--------------------------------------------------
    | constructor
    |--------------------------------------------------
    */

    constructor(props) {
        super(props);
        this.state = {
            viewCompanyDetails: true,
            editCompanyDetails: false,
            userDetails: [],
            photo_url: '',
            name: '',
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
            errors: {
                first_name: '', last_name: '', email: '', phone: ''
            },
            crop: {
                unit: '%',
                width: 30,
                aspect: 1,
            },
            isVisibleEditProfileModel: false,
            croppedImageUrl: undefined
        }
    }

    /**
    |--------------------------------------------------
    | componentDidMount
    |--------------------------------------------------
    */

    async componentDidMount() {
        await this.getUserDetails();
    }

    /**
    |--------------------------------------------------
    | getUserDetails
    |--------------------------------------------------
    */

    getUserDetails = async () => {
        let user_id = this.props.userData.user_id ? this.props.userData.user_id : '';

        let body = { user_id }
        const response = await this.props.callApi('/getUserInfo', body, 'post', null, true);
        if (response) {
            this.props.setProfileInformation(response.data);
            this.setState({
                userDetails: response.data,
                first_name: response.data.first_name, last_name: response.data.last_name,
                email: response.data.email, photo_url: response.data.image, phone: response.data.phone, user_id: response.data.user_id,
                designation: response.data.designation, company_id: response.data.company_id, dialcode: response.data.dialcode
            })
        }
    }

    /**
    |--------------------------------------------------
    | updateUserDetails
    |--------------------------------------------------
    */

    updateUserDetails = async (e) => {
        e.preventDefault();
        var { first_name, last_name, dialcode, phone, file, email, designation, company_id, user_id } = this.state;

        if (this.validateForm()) {
            const formData = new FormData()
            if (file) {
                formData.append('image', file)
            }
            // formData.append('image',file)
            formData.append('first_name', first_name)
            formData.append('last_name', last_name)
            formData.append('email', email)
            formData.append('designation', designation)
            formData.append('company_id', company_id)
            formData.append('user_id', user_id)
            formData.append('phone', phone)
            formData.append('dialcode', dialcode)

            // let body={
            //     first_name,last_name, phone,email,designation,dialcode,company_id,user_id
            // }
            const response = await this.props.callApi('/updateEmployee', formData, 'post', null, true);
            console.log('response.data-->', JSON.stringify(response));
            if (response.data.status === true) {
                this.getUserDetails();
                this.setState({
                    userName: response.data,
                    photo_url: response.data.image,
                    isVisibleEditProfileModel: false
                })
                common.showSuccessMessage(response.data.message)
            }
        }
    }

    /**
    |--------------------------------------------------
    | validateForm
    |--------------------------------------------------
    */

    validateForm() {
        let { first_name, last_name, designation, phone, errors, isFormValid } = this.state;

        if (!first_name.trim()) {
            errors.first_name = errorMessages.PROVIDE_FIRST_NAME;
        } else if (!last_name.trim()) {
            errors.last_name = errorMessages.PROVIDE_LAST_NAME;
        } else if (!designation.trim()) {
            errors.designation = errorMessages.PROVIDE_DESIGNATION;
        } else if (!phone.trim()) {
            errors.phone = errorMessages.PROVIDE_MOBILE_NUMBER;
        } else if (!helper.isValidPhoneNumber(phone)) {
            errors.phone = errorMessages.PROVIDE_VALID_MOBILE_NUMBER;
        } else {
            errors.first_name = '';
            errors.last_name = '';
            errors.designation = '';
        }

        if (errors.first_name !== '' || errors.last_name !== '' || errors.designation !== '') isFormValid = false;
        else isFormValid = true;

        this.setState({ errors, isFormValid })

        return isFormValid;
    }

    /**
    |--------------------------------------------------
    | fileUpload
    |--------------------------------------------------
    */

    fileUpload = async (event) => {
        let reader = new FileReader()
        let file = event.target.files[0]
        reader.onloadend = () => {
            this.setState({
                file: file,
                src: reader.result,
                photo_url: reader.result,
                fileVisible: true
            })
        }
        reader.readAsDataURL(file)
    }

    /**
    |--------------------------------------------------
    | handleChange
    |--------------------------------------------------
    */

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value })
        if (event.target.value) {
            this.setState({
                errors: Object.assign(this.state.errors, { [event.target.name]: '' })
            })
        } else {
            this.setState({
                errors: Object.assign(this.state.errors, { [event.target.name]: errorMessages.REQUIRED })
            })
        }
    }

    /**
    |--------------------------------------------------
    | viewCompanyDetailsBtn
    |--------------------------------------------------
    */

    viewCompanyDetailsBtn = () => {
        this.setState({
            editCompanyDetails: false,
            isVisibleEditProfileModel: false
        })
    }

    /**
    |--------------------------------------------------
    | editCompanyDetailsBtn
    |--------------------------------------------------
    */

    editCompanyDetailsBtn = () => {
        this.setState({
            editCompanyDetails: true,
            isVisibleEditProfileModel: true,
            imagePreview: undefined,
            croppedImageUrl: undefined,
            src: undefined,
        })
    }

    /**
    |--------------------------------------------------
    | handleInputChange
    |--------------------------------------------------
    */

    handleInputChange(telNumber, selectedCountry) {
        console.log('input changed. number: ', telNumber, 'selected country: ', selectedCountry);
    }

    /**
    |--------------------------------------------------
    | handleInputBlur
    |--------------------------------------------------
    */

    handleInputBlur(telNumber, selectedCountry) {
        console.log(
            'Focus off the ReactTelephoneInput component. Tel number entered is: ',
            telNumber,
            ' selected country is: ',
            selectedCountry
        )
    }

    /**
    |--------------------------------------------------
    | handleOnChange
    |--------------------------------------------------
    */

    handleOnChange = (value, data, event, formattedValu) => {
        let dialnum = data.dialCode;
        let mobilenum = value.slice(data.dialCode.length);
        //   let _that = this;
        this.setState({
            dialcode: dialnum,
            phone: mobilenum
        })
    }

    /**
    |--------------------------------------------------
    | onImageLoaded
    |--------------------------------------------------
    */

    onImageLoaded = image => {
        this.imageRef = image;
    };

    /**
    |--------------------------------------------------
    | onCropComplete
    |--------------------------------------------------
    */

    onCropComplete = (crop) => {
        this.makeClientCrop(crop);
    };

    /**
    |--------------------------------------------------
    | makeClientCrop
    |--------------------------------------------------
    */

    async makeClientCrop(crop) {
        if (this.imageRef && crop && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                this.state.profilePicName
            );
            this.setState({ croppedImageUrl });
        }
    }

    /**
    |--------------------------------------------------
    | onCropChange
    |--------------------------------------------------
    */

    onCropChange = (crop, percentCrop) => {
        this.setState({ crop });
    };

    /**
    |--------------------------------------------------
    | getCroppedImg
    |--------------------------------------------------
    */

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );
        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    common.showErrorMessage('Canvas is empty');
                    return;
                }
                this.setState({ Imgblob: blob }, () => { console.log("###", this.state.Imgblob) })
                blob.name = fileName;
                window.URL.revokeObjectURL(this.fileUrl);
                this.fileUrl = window.URL.createObjectURL(blob);
                resolve(this.fileUrl);
            }, 'image/jpeg');
        });
    }

    /**
    |--------------------------------------------------
    | render
    |--------------------------------------------------
    */

    render() {
        let role = this.props.userData.role ? this.props.userData.role : '';
        // console.log("phnumber123",this.state.dial_code,this.state.rawPhone)

        var { viewCompanyDetails, editCompanyDetails, userDetails, email, first_name, last_name, phone, designation, errors, photo_url, dialcode } = this.state;
        // let phone_number ={dialcode+''+phone};
        let imagePreview = null;
        if (photo_url) {
            imagePreview = (<img src={photo_url} alt="Icon" />);
        } else {
            imagePreview = (<img src={"/assets/images/no-image-icon-4.png"} alt="Icon" />);
        }

        return (
            <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={true} animationInDelay={500}>
                <div className="main-page-wrapper company-page">
                    <MobileToggle {...this.props} />
                    <SidebarAnnouncement {...this.props} />
                    <div className="content-area-wrapper">
                        <div className="content-area-inner">
                            <div className="employee-details company-details-view profile-page-block pt-5 pb-2">
                                <div className="container-fluid px-0">
                                    <div className="row mx-0">
                                        <div className="col-lg-5 d-lg-flex d-none justify-content-center align-items-center">
                                            <Animated animationIn="fadeInLeft" animationOut="fadeOutRight" isVisible={true} animationInDelay={1000}>
                                                <img src="images/profile-page-img.svg" alt="" />
                                            </Animated>
                                        </div>
                                        <div className="col-lg-7">
                                            {/* <Animated animationIn="fadeInLeft" animationOut="fadeOutRight" isVisible={true} animationInDelay={700}> */}
                                            {
                                                userDetails &&
                                                <div className="">
                                                    <div className="company-logo-block mt-0">
                                                        <span className="company-logo">
                                                            {
                                                                (userDetails === '') ? <img src="images/noimage.jpeg" alt="" /> :
                                                                    <img src={userDetails.image} alt="" />
                                                            }
                                                        </span>
                                                        <div className="employee-name-designation ml-4">
                                                            <h6>{userDetails.first_name} {userDetails.last_name}</h6>
                                                            <p className="common-text mb-0">{userDetails.designation}</p>
                                                        </div>
                                                    </div>

                                                    <div className="company-details-list">
                                                        <ul>
                                                            <li className="company-details-list-item">
                                                                <p className="list-item-label">Role</p>
                                                                <span className="list-item-value">{helper.getRoleName(userDetails.role)}</span>
                                                            </li>
                                                            <li className="company-details-list-item">
                                                                <p className="list-item-label">Email</p>
                                                                <span className="list-item-value">{userDetails.email}</span>
                                                            </li>
                                                            <li className="company-details-list-item">
                                                                <p className="list-item-label">Phone</p>
                                                                <span className="list-item-value">{userDetails.dialcode} {userDetails.phone}</span>
                                                            </li>
                                                            <li className="company-details-list-item">
                                                                {(role === 'admin') ? <p></p> :
                                                                    <p className="list-item-label">Feedback Category</p>
                                                                }
                                                                {
                                                                    userDetails && Array.isArray(userDetails.category) && userDetails.category.map((categore, key) => {
                                                                        return (
                                                                            // <div className="permission-badge-block" key={key}>
                                                                            <span className="permission-badge" style={{ color: "#" + categore.color_code }}>{categore.name}</span>
                                                                            // </div>
                                                                        )
                                                                    })
                                                                }
                                                            </li>
                                                        </ul>
                                                    </div>


                                                    <div className="pb-3">
                                                        <Button onClick={this.editCompanyDetailsBtn}>edit</Button>
                                                        {(role === 'employee' || role === 'categoryadmin') ? <div></div> :
                                                            <Link className="btn btn-primary ml-3" to="/admin">Admins</Link>
                                                        }
                                                    </div>
                                                </div>
                                            }
                                            {/* </Animated> */}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <Modal className="common-modal small" centered show={this.state.isVisibleEditProfileModel} onHide={() => this.setState({ isVisibleEditProfileModel: false })}>
                    <button type="button" className="close fixed-close-btn" onClick={() => this.setState({ isVisibleEditProfileModel: false })}>
                        <span aria-hidden="true">×</span>
                    </button>
                    <Modal.Body className="">
                        <form onSubmit={this.updateUserDetails}>
                            <div className="row">

                                <div className="col-12">
                                    <h6 className="">Edit Profile</h6>
                                </div>

                                <div className="col-12">
                                    <div className="company-logo-block profile-picture my-3">
                                        {/* <span className="company-logo">
                                                                {imagePreview}
                                                                </span> */}
                                        <input className="file-input company-logo-changer add-picture ml-0 mr-3 input-file" type="file" name="profile_photo" accept="image/*" id="file" onChange={this.fileUpload.bind(this)} />
                                        {this.state.src && <div className={'crop-profile-image'}>
                                            <ReactCrop
                                                src={this.state.src}
                                                crop={this.state.crop}
                                                ruleOfThirds
                                                onImageLoaded={this.onImageLoaded}
                                                onComplete={this.onCropComplete}
                                                onChange={this.onCropChange}
                                            />
                                        </div>}
                                        <span className="ml-2">
                                            {(this.state.croppedImageUrl) ?
                                                <img alt="Crop" className="company-logo" src={this.state.croppedImageUrl} />
                                                : <span className="company-logo"> {imagePreview} </span>
                                            }
                                        </span>
                                    </div>
                                </div>

                                <div className="col-lg-6">
                                    <div className="form-group mb-4">
                                        <input
                                            type="text"
                                            name="first_name"
                                            className="form-control"
                                            placeholder="First Name"
                                            value={first_name}
                                            onChange={this.handleChange}
                                        />
                                        <span className='error-msg'>{errors.first_name}</span>
                                    </div>
                                </div>

                                <div className="col-lg-6">
                                    <div className="form-group mb-4">
                                        <input
                                            name="last_name"
                                            className="form-control"
                                            placeholder="Last Name"
                                            value={last_name}
                                            onChange={this.handleChange}
                                        />
                                        <span className='error-msg'>{errors.last_name}</span>
                                    </div>
                                </div>

                                {/* <div className="col-lg-6">
                                    <div className="form-group mb-4">
                                        <input
                                            type="email"
                                            id="email"
                                            name="email"
                                            className="form-control"
                                            placeholder="Last Name"
                                            value={email}
                                            onChange={this.handleChange}
                                        />
                                        <span className='error-msg'>{errors.email}</span>

                                    </div>
                                </div> */}
                                <div className="col-lg-6">
                                    <div className="form-group mb-4">
                                        <input
                                            type="text"
                                            id="designation"
                                            name="designation"
                                            className="form-control"
                                            placeholder="Designation"
                                            value={designation}
                                            onChange={this.handleChange}
                                        />
                                        <span className='error-msg'>{errors.designation}</span>
                                    </div>
                                </div>

                                <div className="col-lg-6">
                                    <div className="input-group mobile-input mb-4">
                                        <div className="input-group-prepend">
                                            {/* <div className="input-group-text" id="basic-addon1">
                                                                        <span className="">+91</span>
                                                                    </div> */}
                                            <PhoneInput
                                                className="form-control"
                                                name="phone"
                                                country={'us'}
                                                value={dialcode + '' + phone}
                                                onChange={this.handleOnChange}
                                            //  onChange={phone => this.setState({ phone})}
                                            />
                                        </div>
                                        <span className='error-msg'>{errors.phone}</span>

                                    </div>
                                </div>

                                <div className="col-12">
                                    <hr className="mt-1 mb-4" />
                                </div>

                                <div className="col-12 text-right pb-3">
                                    <Button variant="outline-primary" onClick={this.viewCompanyDetailsBtn}>cancel</Button>
                                    <Button type='submit' className="ml-3" onClick={this.updateUserDetails}>update profile</Button>
                                </div>
                            </div>

                        </form>
                    </Modal.Body>
                </Modal>
            </Animated>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        userData: state.getUserData
    }
}
export default withRouter(connect(mapStateToProps, { callApi, setProfileInformation })(Profile))