/* eslint-disable react/jsx-pascal-case */
import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import { Button, Modal, Nav, Dropdown } from 'react-bootstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Animated } from 'react-animated-css';
import Select from 'react-select';
import MobileToggle from '../mobileToggles/mobileToggles';
import SidebarAnnouncement from '../sidebarAnnouncement/SidebarAnnouncement';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { callApi, setKnowledgeBankLinkData } from '../../store/reducers/commonFunction'
import Pagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';
import errorMessages from '../../store/reducers/errorMessages';
import common from '../../store/reducers/commons';
import swal from 'sweetalert';
import { Spinner } from 'react-activity';
import 'react-activity/dist/react-activity.css';
import _ from 'lodash';
import _FilterComponent from './Component/_FilterComponent';
import _FloatingMenu from './Component/_FloatingMenu';
import helper from '../../services/helper';
import appConstant from '../../constant/appConstant';

class KnowledgeBankLinks extends Component {

    /**
    |--------------------------------------------------
    | constructor
    |--------------------------------------------------
    */

    constructor(props) {
        super(props);
        this.state = {
            contentArea: false,
            newAnnouncement: false,
            OpenOptions: false,
            CloseOptions: false,
            shareText: false,
            shareLink: false,
            sharePhotos: false,
            shareCode: false,
            userUploadedImgs: false,
            ismodelName: "",
            current_page: 1,
            per_page: 10,
            total: 1,
            last_page: 1,
            total_pages: null,

            loadedFiles: [],
            imgArr: [],
            message: '',
            link: '',
            code: '',
            url_image: '',
            show: false,
            errors: {
                message: ''
            },
            isLoading: false,

            filterArr: [],
            tagVal: []
        }
    }

    /**
    |--------------------------------------------------
    | handleClose
    |--------------------------------------------------
    */

    handleClose = (modelName) => {
        this.setState({ visible: false });
    }

    /**
    |--------------------------------------------------
    | handleCloseimg
    |--------------------------------------------------
    */

    handleCloseimg = (modelName) => {
        this.setState({ shows: false });
    }

    /**
    |--------------------------------------------------
    | handleShow
    |--------------------------------------------------
    */

    handleShow = (modelName) => {
        this.setState({ visible: true, ismodelName: modelName }, () => this.CloseOptions);
    }

    /**
    |--------------------------------------------------
    | OpenOptions
    |--------------------------------------------------
    */

    OpenOptions = () => {
        this.setState({ leftbar: !this.state.OpenOptions },
            function () {
                document.body.classList.toggle('OpenOptions');
            });
    }

    /**
    |--------------------------------------------------
    | CloseOptions
    |--------------------------------------------------
    */

    CloseOptions = () => {

        this.setState({ leftbar: !this.state.CloseOptions },
            function () {
                document.body.classList.remove('CloseOptions');
            });
    }

    /**
    |--------------------------------------------------
    | componentDidMount
    |--------------------------------------------------
    */

    async componentDidMount() {
        await this.getKnowledgeBankLinkData(false)
        this.props.onRef && this.props.onRef(this);
        await this.getTags()
    }

    /**
    |--------------------------------------------------
    | componentWillUnmount
    |--------------------------------------------------
    */

    componentWillUnmount() {
        this.props.onRef && this.props.onRef(undefined);
    }

    /**
    |--------------------------------------------------
    | getKnowledgeBankLinkData
    |--------------------------------------------------
    */

    getKnowledgeBankLinkData = async (isAppend = true) => {
        let { filterArr, tagVal, current_link_page } = this.state;
        let tagsvalue = tagVal ? tagVal.map((i) => {
            return (
                i.value
            )
        }) : '';
        this.setState({
            linkPageLoading: true
        })
        let body = { page: current_link_page, type: "link", tags: tagsvalue.toString(), filter_by: filterArr.toString() }
        const response = await this.props.callApi('/getKnowledgeList', body, 'post', 'ADMIN_LOGIN', true);
        if (response.data && response.data.tags && response.data.tags.length > 0) {
            tagVal = response.data.tags && Array.isArray(response.data.tags) && response.data.tags.map((values) => {
                return (
                    { label: values, value: values }
                )
            })
        }
        if (response) {
            this.props.setKnowledgeBankLinkData(isAppend ? [...this.props.knowledgeBankLinkData, ...response.data] : response.data);
            this.setState({
                per_page: response.meta.per_page,
                total: response.meta.total,
                show: false,
                linkPageLoading: false,
                last_page: response.meta.last_page,
            })
            if (this.props.getChildEvent && this.props.activeTab == "links") {
                if (response && response.data && response.data.length > 0) {
                    this.props.getChildEvent(response.data[0].knowledge_id, appConstant.TYPE.LINK);
                } else {
                    this.props.getChildEvent('');
                }
            }
        }
    }

    /**
    |--------------------------------------------------
    | componentWillReceiveProps
    |--------------------------------------------------
    */

    async componentWillReceiveProps(nextProp) {
        if (this.props.history.location.view_node ? this.props.history.location.view_node : '') {
            let knowledge_id = this.props.history.location.view_node
            await this.setParentStatus(knowledge_id);
        }

        if (!_.isEqual(_.sortBy(nextProp.filterArr), _.sortBy(this.state.filterArr))) {
            this.setState({
                filterArr: nextProp.filterArr
            }, () => { })
        }

        if (!_.isEqual(_.sortBy(nextProp.tagVal), _.sortBy(this.state.tagVal))) {
            this.setState({
                tagVal: nextProp.tagVal
            }, () => this.getKnowledgeBankLinkData(false))
        }
    }

    /**
    |--------------------------------------------------
    | setParentStatus
    |--------------------------------------------------
    */

    setParentStatus = async (id) => {
        let knowledge_id = id;
        let body = {
            knowledge_id
        }
        this.setState({ knowledge_id: knowledge_id })
        const response = await this.props.callApi('/getKnowledgeDetail', body, 'post', null, true);
        this.setState({
            Knowledge: response.data
        })
    }

    /**
    |--------------------------------------------------
    | getKnowledgeBankLinkData
    |--------------------------------------------------
    */

    getKnowledgeBankLinkData = async (isAppend = true) => {
        let { filterArr, tagVal, current_page } = this.state;
        let tagsvalue = tagVal ? tagVal.map((i) => {
            return (
                i.value
            )
        }) : '';
        this.setState({
            isLoading: true
        })
        let filterData = _FilterComponent.getFilterValue();
        let body = { page: current_page, type: "link", tags: filterData.tagVal, filter_by: filterData.filterArr }
        const response = await this.props.getKnowledgeList(body);
        if (response.data && response.data.tags && response.data.tags.length > 0) {
            tagVal = response.data.tags && Array.isArray(response.data.tags) && response.data.tags.map((values) => {
                return (
                    { label: values, value: values }
                )
            })
        }
        if (response) {
            this.props.setKnowledgeBankLinkData(isAppend ? [...this.props.knowledgeBankLinkData, ...response.data] : response.data);
            this.setState({
                total: response.meta.total,
                show: false,
                isLoading: false,
                last_page: response.meta.last_page,
            })

            if (this.props.getChildEvent && this.props.activeTab == "links") {
                if (response && response.data && response.data.length > 0) {
                    this.props.getChildEvent(response.data[0].knowledge_id, appConstant.TYPE.LINK);
                } else {
                    this.props.getChildEvent('');
                }
            }
        }
    }

    /**
    |--------------------------------------------------
    | paginationChange
    |--------------------------------------------------
    */

    paginationChange(current_page) {
        let per_page = this.state.per_page;
        this.setState({
            current_page, per_page
        }, () => { });
    }

    /**
    |--------------------------------------------------
    | getTags
    |--------------------------------------------------
    */

    getTags = async () => {
        let company_id = this.props.userData.company_id ? this.props.userData.company_id : '';
        let body = { company_id }
        const response = await this.props.callApi('/getTags', body, 'post', 'ADMIN_LOGIN', true);
        if (response) {
            this.setState({ tags: response })
        }
    }

    /**
    |--------------------------------------------------
    | tagValue
    |--------------------------------------------------
    */

    tagValue = async (e) => {
        this.setState({
            tagVal: e
        })
    }

    /**
    |--------------------------------------------------
    | validateForm
    |--------------------------------------------------
    */

    validateForm() {
        let { message, errors, isFormValid } = this.state;

        if (!message.trim()) errors.message = errorMessages.PROVIDE_MESSAGE;
        else errors.message = '';

        if (errors.message !== '') isFormValid = false;
        else isFormValid = true;
        this.setState({ errors, isFormValid })
        return isFormValid
    }

    /**
    |--------------------------------------------------
    | addLoadedFile
    |--------------------------------------------------
    */

    addLoadedFile(file) {
        let tempPhotos = this.state.loadedFiles;
        tempPhotos.push(file);
        this.setState({
            loadedFiles: tempPhotos,
        });
    }

    /**
    |--------------------------------------------------
    | openModal
    |--------------------------------------------------
    */

    openModal(Knowledge) {
        // let tagVal=[];
        let tagsvalue = Knowledge.tags.map((i) => {
            return (
                i
            )
        })
        this.setState({
            descriptions: Knowledge.text,
            descriptionval: Knowledge.description,
            knowledge_id: Knowledge.knowledge_id,
            url_meta_description: Knowledge.url_meta_description,
            imagePreviewUrlg: Knowledge.url_meta_image,
            url_title: Knowledge.url_meta_title,
            tagvalue: tagsvalue,
            shows: true,
        },
            //   tagVal = this.state.tagvalue && Array.isArray(this.state.tagvalue) && this.state.tagvalue.map((values) => {  
            //      return(
            //          {label:values,value:values}
            //      )
            // })
        );
    }

    /**
    |--------------------------------------------------
    | updateKnowledge
    |--------------------------------------------------
    */

    updateKnowledge = async () => {
        var { descriptionval, descriptions, knowledge_id, tagVal, url_meta_description, url_title, imagePreviewUrlg } = this.state;
        let tagsvalue = tagVal && tagVal.map((i) => {
            return (
                i.value
            )
        })
        //  tagsarray.push(tagsvalue.toString())
        // if (this.validateForm()) {
        const formData = new FormData()
        formData.append('tags', tagsvalue)
        formData.append('text', descriptions)
        formData.append('description', descriptionval)
        formData.append('type', "link")
        formData.append('knowledge_id', knowledge_id)
        formData.append('url_meta_image', imagePreviewUrlg)
        formData.append('url_meta_title', url_title)
        formData.append('url_meta_description', url_meta_description)


        const response = await this.props.callApi('/updateKnowledge', formData, 'post', null, true, true);
        if (response) {
            // this.props.history.push('/announcement')
            common.showSuccessMessage("Knowledge details updated succesfully");
            window.location.reload();
            this.setState({ visible: false });
        }
    }

    /**
    |--------------------------------------------------
    | handleScroll
    |--------------------------------------------------
    */

    handleScroll = (e) => {
        let element = e.target
        if (element.scrollTop + element.offsetHeight >= element.scrollHeight) {
            if (this.state.current_page != this.state.last_page) {
                this.setState({ current_page: this.state.current_page + 1 }, () => {
                    this.getKnowledgeBankLinkData();
                })
            }
        }
    }

    /**
    |--------------------------------------------------
    | onItemClick
    |--------------------------------------------------
    */

    onItemClick = (knowledgeDetail) => {
        this.props.getChildEvent(knowledgeDetail.knowledge_id, appConstant.TYPE.LINK)
    }

    /**
    |--------------------------------------------------
    | render
    |--------------------------------------------------
    */

    render() {
        let user_id = this.props.userData.user_id ? this.props.userData.user_id : '';
        const { knowledgeBankLinkData, onItemClick, LeftbarToggle, knowledgeDetail, handleLinkScroll } = this.props;
        const { isLoading } = this.state;
        var { imagePreviewUrlg, thumbimage, tagvalue, file, Knowledgelink, imgArr, total, current_page, per_page, tags, message, errors, link, code } = this.state
        var strmessage = message;
        let tagsList = tags && Array.isArray(tags.data) && tags.data.map((values) => {
            return (
                { label: values, value: values }
            )
        })
        let updatetags = tagvalue && Array.isArray(tagvalue) && tagvalue.map((values) => {
            return (
                { label: values, value: values }
            )
        })

        return (
            <PerfectScrollbar onScroll={handleLinkScroll}>
                {
                    knowledgeBankLinkData && Array.isArray(knowledgeBankLinkData) && knowledgeBankLinkData.map((link, key) => {
                        const listItemClassName = (link.knowledge_id == _.get(this.props, 'knowledgeDetail.knowledge_id')) ? "leftbar-list-item active" : "leftbar-list-item"
                        return (
                            <li className={listItemClassName} key={key} onClick={() => { this.onItemClick(link) }}>
                                <div className="leftbar-list-item-inner">
                                    <div className="card-kb">
                                        <div className="card-kb-header">
                                            <div className="list-item-leftbar py-0">
                                                <div className="user-img">
                                                    <div className="user-img-wrapper">
                                                        {
                                                            (link.user_image === '') ? <img src="images/noimage.jpeg" alt="" /> :
                                                                <img src={link.user_image} alt="" />
                                                        }
                                                    </div>
                                                </div>
                                                <div className="user-data w-100">
                                                    <div className="user-name">{link.user_name}</div>
                                                    <div className="data-text text-capitalize">{helper.formatTimeAgo(link.created_at)}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-kb-body">
                                            <p className="card-kb-text"><a href={link.text} target="_blank" rel="noopener noreferrer">{link.text}</a></p>
                                            {(link.url_meta_image) && <span className="user-uploded-img">
                                                <img className="m-0" src={link.url_meta_image} alt="" />
                                            </span>}
                                            <p className="card-kb-text">{link.description}</p>
                                        </div>
                                    </div>

                                </div>
                                <div className="idea-post-control-panel">
                                    <div className="selected-categories-block">
                                        <div className="permission-badge-block mt-0">
                                            {
                                                link && Array.isArray(link.tags) && link.tags.map((tag, key) => {
                                                    return (
                                                        <span className="permission-badge" key={key}>{tag}</span>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>

                                    {this.props.likeCommentView(link)}
                                </div>
                            </li>
                        )
                    })
                }

                {this.state.isLoading && <div className={'loader-block'}>
                    <Spinner />
                </div>}

                {(!this.state.isLoading && knowledgeBankLinkData && knowledgeBankLinkData.length === 0) && this.props.noRecordView()}

            </PerfectScrollbar >
        );
    }
}
const mapStateToProps = (state) => {
    return {
        userData: state.getUserData,
        knowledgeBankLinkData: state.knowledgeBankLinkData,
    }
}
export default withRouter(connect(mapStateToProps, { callApi, setKnowledgeBankLinkData })(KnowledgeBankLinks))
