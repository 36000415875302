import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
// import { Tab, Nav, Dropdown, Button } from 'react-bootstrap';
// import PerfectScrollbar from 'react-perfect-scrollbar';


export default class MobileToggle extends Component {

    constructor(props) {
        super(props);
        this.state = {
            leftbar: false,
            announcementSidebar: false,
        }
    }

    LeftbarToggle = () => {
        this.setState({ leftbar: !this.state.leftbar },
            function () {
                document.body.classList.add('leftbar-open');
            });
    }

    AnnouncementToggle = () => {
        this.setState({ announcementSidebar: !this.state.announcementSidebar },
            function () {
                document.body.classList.add('announcement-open');
            });
    }

    render() {
        return (
            <div className="togglers-block">
                <span className="leftbar-toggler" onClick={this.LeftbarToggle}>
                    <span className="material-icons open-leftbar">more_vert</span>
                    <span className="material-icons close-leftbar">arrow_back</span>
                </span>
                <div className="expand-btn" onClick={this.AnnouncementToggle}>
                    <span className="material-icons">notifications_active</span>
                </div>
            </div>
        );
    }
}