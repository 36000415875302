
import React from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';

function ImageModal(props) {
    const { show, onHidePress, imageUrl } = props;

    return (
        <Modal
            className="common-modal small transparent-modal"
            centered
            show={show}
            onHide={() => onHidePress()}
        >
            <Modal.Body className="p-5">
                <div className="modal-image">
                    {imageUrl && <img
                        className="d-block w-100"
                        src={imageUrl}
                        alt=""
                    />}
                </div>
            </Modal.Body>
        </Modal>
    )
}

ImageModal.propTypes = {
    show: PropTypes.bool,
    onHidePress: PropTypes.func,
    imageUrl: PropTypes.any,
};

export default ImageModal;