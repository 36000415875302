/* eslint-disable react/jsx-pascal-case */
import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import { Button, Dropdown } from 'react-bootstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Animated } from 'react-animated-css';
import MobileToggle from '../mobileToggles/mobileToggles';
import LeftbarKB from './LeftbarKB/LeftbarKB';
import SidebarAnnouncement from '../sidebarAnnouncement/SidebarAnnouncement';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
    callApi, setKnowledgeBankToReadData, setKnowledgeBankLinkData,
    setKnowledgeBankCodeData, setTimelineData
} from '../../store/reducers/commonFunction'
import 'rc-pagination/assets/index.css';
import errorMessages from '../../store/reducers/errorMessages';
import common from '../../store/reducers/commons';
import swal from 'sweetalert';
import { Spinner } from 'react-activity';
import 'react-activity/dist/react-activity.css';

import _FilterComponent from './Component/_FilterComponent';
import _FloatingMenu from './Component/_FloatingMenu';
import Linkify from 'react-linkify/dist/components/Linkify';
import helper from '../../services/helper';
import ImageModal from '../../component/ImageModal/ImageModal';
import _, { debounce } from 'lodash';
import _ShareLinkModal from './Component/_ShareLinkModal';
import appConstant from '../../constant/appConstant';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { monokai } from 'react-syntax-highlighter/dist/esm/styles/hljs';

const urlMetadata = require('url-metadata')

class KnowledgeBank extends Component {

    /**
    |--------------------------------------------------
    | constructor
    |--------------------------------------------------
    */

    constructor(props) {
        super(props);
        this.state = {
            contentArea: false,
            newAnnouncement: false,
            OpenOptions: false,
            CloseOptions: false,
            shareText: false,
            shareLink: false,
            sharePhotos: false,
            shareCode: false,
            userUploadedImgs: false,
            ismodelName: "",
            current_page: 1,
            per_page: 10,
            total: 1,
            last_page: 1,
            total_pages: null,
            filterArr: [],
            loadedFiles: [],
            imgArr: [],
            code: '',
            url_image: '',
            show: false,
            shows: false,
            isLoading: false,

            knowledgeDetail: null,
            comments: [],
            visible: false,
            knowledge_id: '',
            visibleShareModal: false,
            link: '',
            tagVal: '',
            errors: {
                message: ''
            },
            message: '',
            url_meta_title: '',
            url_meta_description: '',
            url_meta_image: '',
            isMetaDataFetching: false,
            isUpdateFetching: false,

            // Text knowledge 
            knowledgeTextData: '',
            comment: '',
            isCommentLoading: false,
            modalImageUrl: undefined
        }
    }

    /**
    |--------------------------------------------------
    | handleClose
    |--------------------------------------------------
    */

    handleClose = () => {
        this.setState({ visible: false });
    }

    /**
    |--------------------------------------------------
    | handleCloseimg
    |--------------------------------------------------
    */

    handleCloseimg = () => {
        this.setState({ shows: false });
    }

    /**
    |--------------------------------------------------
    | handleShow
    |--------------------------------------------------
    */

    handleShow = (modelName) => {
        this.setState({ visible: true, ismodelName: modelName }, () => this.CloseOptions);
    }

    /**
    |--------------------------------------------------
    | OpenOptions
    |--------------------------------------------------
    */

    OpenOptions = () => {
        this.setState({ leftbar: !this.state.OpenOptions },
            function () {
                document.body.classList.toggle('OpenOptions');
            });
    }

    /**
    |--------------------------------------------------
    | CloseOptions
    |--------------------------------------------------
    */

    CloseOptions = () => {
        this.setState({ leftbar: !this.state.CloseOptions },
            function () {
                document.body.classList.remove('CloseOptions');
            });
    }

    /**
    |--------------------------------------------------
    | componentDidMount
    |--------------------------------------------------
    */

    async componentDidMount() {
        // await this.getknowledgeaDetails(false)
        await this.getTags()
    }

    /**
    |--------------------------------------------------
    | setParentStatus
    |--------------------------------------------------
    */

    setParentStatus = async (id, type) => {
        this.setState({
            isLoading: true,
        })
        let tempKnowledgeData = undefined;
        if (type == appConstant.TYPE.TEXT) {
            tempKnowledgeData = _.find(this.props.knowledgeBankToReadData, function (o) { return o.knowledge_id === id; });
        } else if (type == appConstant.TYPE.LINK) {
            tempKnowledgeData = _.find(this.props.knowledgeBankLinkData, function (o) { return o.knowledge_id === id; });
        } else if (type == appConstant.TYPE.FILE) {
            tempKnowledgeData = _.find(this.props.knowledgeBankPhotosData, function (o) { return o.knowledge_id === id; });
        } else {
            tempKnowledgeData = _.find(this.props.knowledgeBankCodeData, function (o) { return o.knowledge_id === id; });
        }

        this.setState({
            knowledge_id: id,
            knowledgeDetail: tempKnowledgeData,
            current_page: 1,
            isLoading: false,
            comments: []
        }, () => this.getComments())

    }

    /**
    |--------------------------------------------------
    | getComments
    |--------------------------------------------------
    */

    getComments = async () => {

        let { knowledgeDetail, current_page, knowledgeTextData, knowledge_id } = this.state;
        if (knowledgeDetail || knowledgeTextData) {
            this.setState({
                isCommentLoading: true
            })
            let body = { page: current_page, knowledge_id }
            console.log('knowledgeDetail body-->', JSON.stringify(body));
            const response = await this.props.callApi('/knowledgeCommentList', body, 'post', 'ADMIN_LOGIN', true);
            console.log('knowledgeDetail response-->', JSON.stringify(response));
            if (response) {
                this.setState({
                    comments: [...this.state.comments, ...response.data] || [],
                    per_page: response.meta.per_page,
                    last_page: response.meta.last_page,
                    total: response.meta.total,
                    isCommentLoading: false
                })
            } else {
                this.setState({
                    comments: [],
                    isCommentLoading: false
                })
            }
        } else {
            this.setState({
                comments: [],
                isCommentLoading: false
            })
        }
    }

    /**
    |--------------------------------------------------
    | getTags
    |--------------------------------------------------
    */

    getTags = async () => {
        let company_id = this.props.userData.company_id ? this.props.userData.company_id : '';
        let body = { company_id }
        const response = await this.props.callApi('/getTags', body, 'post', 'ADMIN_LOGIN', true);
        if (response) {
            this.setState({ tags: response })
        }
    }

    /**
    |--------------------------------------------------
    | tagValue
    |--------------------------------------------------
    */

    tagValue = async (e) => {
        this.setState({
            tagVal: e
        })
    }

    /**
    |--------------------------------------------------
    | addLoadedFile
    |--------------------------------------------------
    */

    addLoadedFile(file) {
        let tempPhotos = this.state.loadedFiles;
        tempPhotos.push(file);
        this.setState({
            loadedFiles: tempPhotos,
        });
    }

    /**
    |--------------------------------------------------
    | deleteKnowledge 
    |--------------------------------------------------
    */

    deleteKnowledge = async (knowledge_id) => {
        swal({
            title: "Are you sure You want to delete this Knowledge?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async willDelete => {
                if (willDelete) {
                    let body = {
                        knowledge_id: knowledge_id
                    }
                    this.setState({
                        knowledge_id
                    })
                    const response = await this.props.callApi('/deleteKnowledge', body, 'post', null, true);
                    if (response.data.code === 200) {
                        common.showSuccessMessage(response.data.message);

                        if (this.leftBarRef.state.activeTab == "toRead") {

                            let tempKnowledgeBankToReadData = this.props.knowledgeBankToReadData || [];
                            let tempKnowledgeBankToReadDataIndex = tempKnowledgeBankToReadData.findIndex(x => x.knowledge_id == knowledge_id);
                            if (tempKnowledgeBankToReadDataIndex != -1) {
                                tempKnowledgeBankToReadData.splice(tempKnowledgeBankToReadDataIndex, 1)
                                this.props.setKnowledgeBankToReadData([...tempKnowledgeBankToReadData]);
                                let nextKnowledegeId = tempKnowledgeBankToReadData ? tempKnowledgeBankToReadData[tempKnowledgeBankToReadDataIndex].knowledge_id : undefined
                                this.setParentStatus(nextKnowledegeId, appConstant.TYPE.TEXT);
                            }
                        } else if (this.leftBarRef.state.activeTab == "links") {
                            let tempKnowledgeBankLinkData = this.props.knowledgeBankLinkData || [];
                            let tempKnowledgeBankLinkDataIndex = tempKnowledgeBankLinkData.findIndex(x => x.knowledge_id == knowledge_id);
                            if (tempKnowledgeBankLinkDataIndex != -1) {
                                tempKnowledgeBankLinkData.splice(tempKnowledgeBankLinkDataIndex, 1)
                                this.props.setKnowledgeBankLinkData([...tempKnowledgeBankLinkData]);
                                let nextKnowledegeId = tempKnowledgeBankLinkData ? tempKnowledgeBankLinkData[tempKnowledgeBankLinkDataIndex].knowledge_id : undefined
                                this.setParentStatus(nextKnowledegeId, appConstant.TYPE.LINK);
                            }
                        } else if (this.leftBarRef.state.activeTab == "code") {
                            let tempKnowledgeBankCodeData = this.props.knowledgeBankCodeData || [];
                            let tempKnowledgeBankCodeDataIndex = tempKnowledgeBankCodeData.findIndex(x => x.knowledge_id == knowledge_id);
                            if (tempKnowledgeBankCodeDataIndex != -1) {
                                tempKnowledgeBankCodeData.splice(tempKnowledgeBankCodeDataIndex, 1)
                                this.props.setKnowledgeBankToReadData([...tempKnowledgeBankCodeData]);
                                let nextKnowledegeId = tempKnowledgeBankCodeData ? tempKnowledgeBankCodeData[tempKnowledgeBankCodeDataIndex].knowledge_id : undefined
                                this.setParentStatus(nextKnowledegeId, appConstant.TYPE.CODE);
                            }
                        } else {
                            let tempKnowledgeBankPhotosData = this.props.knowledgeBankPhotosData || [];
                            let tempKnowledgeBankPhotosDataIndex = tempKnowledgeBankPhotosData.findIndex(x => x.knowledge_id == knowledge_id);
                            if (tempKnowledgeBankPhotosDataIndex != -1) {
                                tempKnowledgeBankPhotosData.splice(tempKnowledgeBankPhotosDataIndex, 1)
                                this.props.setKnowledgeBankToReadData([...tempKnowledgeBankPhotosData]);
                                let nextKnowledegeId = tempKnowledgeBankPhotosData ? tempKnowledgeBankPhotosData[tempKnowledgeBankPhotosDataIndex].knowledge_id : undefined
                                this.setParentStatus(nextKnowledegeId, appConstant.TYPE.FILE);
                            }
                        }
                        this.sidebarAnnouncementRef.getAnnouncement(false, false);
                    }
                }
            });
    }

    /**
    |--------------------------------------------------
    | openModal
    |--------------------------------------------------
    */

    openModal(Knowledge) {
        console.log('Knowledge-->', JSON.stringify(Knowledge));
        _FloatingMenu.handleShow(Knowledge.type, Knowledge);
    }

    /**
    |--------------------------------------------------
    | tagValue
    |--------------------------------------------------
    */

    tagValue = async (e) => {
        this.setState({
            tagVal: e
        }, () => {
            const { errors } = this.state;
            if (this.state.tagVal) {
                errors.tag = ''
            } else {
                errors.tag = errorMessages.REQUIRED
            }
            this.setState({ errors })
        })
    }

    /**
    |--------------------------------------------------
    | handleScroll
    |--------------------------------------------------
    */

    handleScroll = (e) => {
        let element = e.target
        if (element.scrollTop + element.offsetHeight >= element.scrollHeight) {
            if (this.state.current_page != this.state.last_page) {
                this.setState({ current_page: this.state.current_page + 1 }, () => {
                    this.getknowledgeaDetails()
                })
            }
        }
    }

    /**
    |--------------------------------------------------
    | addComment 
    |--------------------------------------------------
    */

    addComment = async (event) => {
        event && event.preventDefault();
        let { knowledge_id, comment, knowledgeDetail } = this.state;
        let body = { knowledge_id, comment }
        const response = await this.props.callApi('/addKnowledgeComment', body, 'post', '', true);
        if (response) {
            this.setState({
                comments: [...this.state.comments, response.data], comment: '',
                knowledgeDetail: {
                    ...knowledgeDetail,
                    total_comments: knowledgeDetail.total_comments + 1
                }
            }, () => {
                this.scrollToEnd()
            });

            if (this.leftBarRef.state.activeTab == "toRead") {
                const { knowledgeBankToReadData } = this.props;
                let tempKnowledgeBankToReadData = [...knowledgeBankToReadData];
                let tempItemData = _.find(tempKnowledgeBankToReadData, function (o) { return o.knowledge_id === knowledge_id; });
                if (tempItemData) {
                    tempItemData.total_comments = knowledgeDetail.total_comments + 1;
                    this.props.setKnowledgeBankToReadData(tempKnowledgeBankToReadData);
                }

            } else if (this.leftBarRef.state.activeTab == "links") {

                const { knowledgeBankLinkData } = this.props;
                let tempKnowledgeBankLinkData = [...knowledgeBankLinkData]
                let tempItemData = _.find(tempKnowledgeBankLinkData, function (o) { return o.knowledge_id === knowledge_id; });

                if (tempItemData) {
                    tempItemData.total_comments = knowledgeDetail.total_comments + 1;
                    this.props.setKnowledgeBankLinkData(tempKnowledgeBankLinkData);
                }

            } else if (this.leftBarRef.state.activeTab == "code") {

                const { knowledgeBankCodeData } = this.props;
                let tempKnowledgeBankCodeData = [...knowledgeBankCodeData]
                let tempItemData = _.find(tempKnowledgeBankCodeData, function (o) { return o.knowledge_id === knowledgeDetail.knowledge_id; });

                if (tempItemData) {
                    tempItemData.total_comments = knowledgeDetail.total_comments + 1
                    this.props.setKnowledgeBankLinkData(tempKnowledgeBankCodeData);
                }

            } else {
                const { knowledgeBankPhotosData } = this.props;
                let tempKnowledgeBankPhotosData = [...knowledgeBankPhotosData]
                let tempItemData = _.find(tempKnowledgeBankPhotosData, function (o) { return o.knowledge_id === knowledgeDetail.knowledge_id; });
                if (tempItemData) {
                    tempItemData.total_comments = knowledgeDetail.total_comments + 1
                    this.props.setKnowledgeBankLinkData(tempKnowledgeBankPhotosData);
                }

            }

            let timelineData = this.props.timelineData
            // let selectedIndex = timelineData.findIndex(x => x.data.knowledge_id == knowledge_id);
            let tempItemData = _.find(timelineData, function (o) { return o.data.knowledge_id === knowledgeDetail.knowledge_id; });
            if (tempItemData) {
                tempItemData.data.total_comments = tempItemData.data.total_comments + 1;
                this.props.setTimelineData([...timelineData]);
            }
            this.sidebarAnnouncementRef.getAnnouncement(false, false);
        }
    }

    /**
    |--------------------------------------------------
    | handleChange
    |--------------------------------------------------
    */

    handleChange = async (event) => {
        this.setState({ [event.target.name]: event.target.value })
        if (event.target.value) {
            this.setState({
                errors: Object.assign(this.state.errors, { [event.target.name]: "" })
            });
            if (event.target.name === 'link') {
                this.setState({
                    errors: Object.assign(this.state.errors, { [event.target.name]: !helper.isValidUrl(event.target.value) ? errorMessages.PROVIDE_VALID_URL : '' })
                })
                if (helper.isValidUrl(event.target.value)) {
                    this.getMetaDataAPI(event.target.value)
                }
            }
        } else {
            this.setState({
                errors: Object.assign(this.state.errors, { [event.target.name]: errorMessages.REQUIRED })
            })
        }
    }

    /**
    |--------------------------------------------------
    | scrollToEnd
    |--------------------------------------------------
    */

    scrollToEnd() {
        setTimeout(() => {
            const container = document.querySelector(".content-area-wrapper .scrollbar-container");
            if (container && container.scrollHeight) {
                container.scrollTop = container.scrollHeight;
            }
        }, 100);
    }

    /**
    |--------------------------------------------------
    | deleteComment
    |--------------------------------------------------
    */

    deleteComment = async (comment, index) => {
        let { comments, knowledgeDetail } = this.state;
        swal({
            title: "Are you sure want to delete this comment ?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async willDelete => {
            if (willDelete) {
                let body = {
                    "knowledge_id": this.state.knowledge_id,
                    "knowledge_comment_id": comment.comment_id
                }
                console.log('deleteKnowledgeComment body-->', JSON.stringify(body));
                const response = await this.props.callApi('/deleteKnowledgeComment', body, 'post', null, true);
                if (response.data.code === 200) {
                    common.showSuccessMessage(response.data.message);
                    // let messagelist = this.state.suggestionList.filter(list=>list.feedback_message_id !== this.state.feedback_message_id)
                    comments.splice(index, 1)
                    this.setState({
                        comments: comments,
                        knowledgeDetail: {
                            ...knowledgeDetail,
                            total_comments: knowledgeDetail.total_comments - 1
                        }
                    })

                    if (this.leftBarRef.state.activeTab == "toRead") {
                        const { knowledgeBankToReadData } = this.props;
                        let tempKnowledgeBankToReadData = [...knowledgeBankToReadData];
                        let tempItemData = _.find(tempKnowledgeBankToReadData, function (o) { return o.knowledge_id === knowledgeDetail.knowledge_id; });
                        tempItemData.total_comments = knowledgeDetail.total_comments - 1;

                        this.props.setKnowledgeBankToReadData(tempKnowledgeBankToReadData);
                    } else if (this.leftBarRef.state.activeTab == "links") {

                        const { knowledgeBankLinkData } = this.props;
                        let tempKnowledgeBankLinkData = [...knowledgeBankLinkData]
                        let tempItemData = _.find(tempKnowledgeBankLinkData, function (o) { return o.knowledge_id === knowledgeDetail.knowledge_id; });
                        tempItemData.total_comments = knowledgeDetail.total_comments - 1

                        this.props.setKnowledgeBankLinkData(tempKnowledgeBankLinkData);
                    } else if (this.leftBarRef.state.activeTab == "code") {
                        const { knowledgeBankCodeData } = this.props;
                        let tempKnowledgeBankCodeData = [...knowledgeBankCodeData]
                        let tempItemData = _.find(tempKnowledgeBankCodeData, function (o) { return o.knowledge_id === knowledgeDetail.knowledge_id; });
                        tempItemData.total_comments = knowledgeDetail.total_comments - 1

                        this.props.setKnowledgeBankLinkData(tempKnowledgeBankCodeData);
                    } else {
                        const { knowledgeBankPhotosData } = this.props;
                        let tempKnowledgeBankPhotosData = [...knowledgeBankPhotosData]
                        let tempItemData = _.find(tempKnowledgeBankPhotosData, function (o) { return o.knowledge_id === knowledgeDetail.knowledge_id; });
                        tempItemData.total_comments = knowledgeDetail.total_comments - 1

                        this.props.setKnowledgeBankLinkData(tempKnowledgeBankPhotosData);
                    }
                    this.sidebarAnnouncementRef.getAnnouncement(false, false);
                }
            }
        });
    }

    /**
    |--------------------------------------------------
    | handleLike
    |--------------------------------------------------
    */

    handleLike = async (knowledgeData = undefined) => {
        let { knowledge_id, knowledgeDetail } = this.state;

        if (knowledgeData != undefined) {
            knowledge_id = knowledgeData.knowledge_id
        }


        if (knowledgeData == undefined || knowledgeData == '') {

            let tempKnowledgeDetail = {
                ...knowledgeDetail,
                total_likes: knowledgeDetail.is_liked === 0 ? knowledgeDetail.total_likes + 1 : this.state.knowledgeDetail.total_likes - 1,
                is_liked: knowledgeDetail.is_liked === 0 ? 1 : 0,
            }
            this.setState({
                knowledgeDetail: tempKnowledgeDetail
            })
        }

        if (this.leftBarRef.state.activeTab == "toRead") {
            const { knowledgeBankToReadData } = this.props;
            let tempKnowledgeBankToReadData = [...knowledgeBankToReadData]
            let tempItemData = _.find(tempKnowledgeBankToReadData, function (o) { return o.knowledge_id === knowledge_id; });
            tempItemData.total_likes = (tempItemData.is_liked === 0) ? tempItemData.total_likes + 1 : tempItemData.total_likes - 1;
            tempItemData.is_liked = (tempItemData.is_liked === 0) ? 1 : 0;

            this.props.setKnowledgeBankToReadData(tempKnowledgeBankToReadData);
        } else if (this.leftBarRef.state.activeTab == "links") {
            const { knowledgeBankLinkData } = this.props;
            let tempKnowledgeBankLinkData = [...knowledgeBankLinkData]
            let tempItemData = _.find(tempKnowledgeBankLinkData, function (o) { return o.knowledge_id === knowledge_id; });
            tempItemData.total_likes = (tempItemData.is_liked === 0) ? tempItemData.total_likes + 1 : tempItemData.total_likes - 1;
            tempItemData.is_liked = (tempItemData.is_liked === 0) ? 1 : 0;

            this.props.setKnowledgeBankLinkData(tempKnowledgeBankLinkData);
        } else if (this.leftBarRef.state.activeTab == "code") {
            const { knowledgeBankCodeData } = this.props;
            let tempKnowledgeBankCodeData = [...knowledgeBankCodeData]
            let tempItemData = _.find(tempKnowledgeBankCodeData, function (o) { return o.knowledge_id === knowledge_id; });
            tempItemData.total_likes = (tempItemData.is_liked === 0) ? tempItemData.total_likes + 1 : tempItemData.total_likes - 1;
            tempItemData.is_liked = (tempItemData.is_liked === 0) ? 1 : 0;

            this.props.setKnowledgeBankLinkData(tempKnowledgeBankCodeData);
        } else {
            const { knowledgeBankPhotosData } = this.props;
            let tempKnowledgeBankPhotosData = [...knowledgeBankPhotosData]
            let tempItemData = _.find(tempKnowledgeBankPhotosData, function (o) { return o.knowledge_id === knowledge_id; });
            tempItemData.total_likes = (tempItemData.is_liked === 0) ? tempItemData.total_likes + 1 : tempItemData.total_likes - 1;
            tempItemData.is_liked = (tempItemData.is_liked === 0) ? 1 : 0;

            this.props.setKnowledgeBankLinkData(tempKnowledgeBankPhotosData);
        }

        //Update Sidebar announcement data
        let timelineData = this.props.timelineData
        let selectedIndex = timelineData.findIndex(x => x.data.knowledge_id == knowledge_id);
        _.set(timelineData, `[${selectedIndex}].data.total_likes`,
            _.get(timelineData, `[${selectedIndex}].data.is_liked`) == 0
                ? _.get(timelineData, `[${selectedIndex}].data.total_likes`) + 1
                : _.get(timelineData, `[${selectedIndex}].data.total_likes`) - 1);
        _.set(timelineData, `[${selectedIndex}].data.is_liked`, _.get(timelineData, `[${selectedIndex}].data.is_liked`) == 1 ? 0 : 1);

        this.props.setTimelineData([...timelineData]);

        let body = { knowledge_id }
        const response = await this.props.callApi('/likeKnowledge', body, 'post', 'ADMIN_LOGIN', true);

        if (response && response.data) {

        }
    }

    /**
    |--------------------------------------------------
    | handleAllScroll
    |--------------------------------------------------
    */

    handleAllScroll = (e) => {
        const { activeTab } = this.state;
        let element = e.target
        if (element.scrollTop + element.offsetHeight >= element.scrollHeight) {
            if (this.state.current_page != this.state.last_page) {
                this.setState({ current_page: this.state.current_page + 1 }, () => {
                    this.getComments()
                })
            }
        }
    }

    /**
    |--------------------------------------------------
    | render
    |--------------------------------------------------
    */

    render() {
        let user_id = this.props.userData.user_id ? this.props.userData.user_id : '';
        const { isLoading, knowledgeDetail, comments, tags, comment, isCommentLoading, modalImageUrl } = this.state
        let tagsList = tags && Array.isArray(tags.data) && tags.data.map((values) => {
            return (
                { label: values, value: values }
            )
        })

        return (
            <div className="main-page-wrapper idea-sharing-page">
                <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={true} animationInDelay={500}>
                    <MobileToggle {...this.props} />
                    <LeftbarKB
                        {...this.props}
                        knowledgeBankRef={this}
                        onRef={ref => this.leftBarRef = ref}
                        tagsList={tagsList}
                        getChildEvent={this.setParentStatus}
                        knowledgeDetail={knowledgeDetail}
                        handleLike={this.handleLike}
                        updateKnowledgeData={this.state.updateKnowledgeData}
                        sidebarAnnouncementRef={this.sidebarAnnouncementRef}
                    />
                    <SidebarAnnouncement {...this.props} onRef={ref => this.sidebarAnnouncementRef = ref} />
                    <div className={this.state.contentArea === false ? "content-area-wrapper" : "content-area-wrapper close-sidebar"}>

                        {knowledgeDetail ?
                            <PerfectScrollbar onScroll={this.handleAllScroll}>
                                <div className="idea-content-block">
                                    <ul className="content-list">
                                        <li className="content-list-item pb-0">
                                            <div className="leftbar-list-item-inner">
                                                <div className="user-img small">
                                                    <div className="user-img-wrapper">
                                                        {
                                                            (knowledgeDetail && knowledgeDetail.user_image !== '') ? <img src={knowledgeDetail.user_image} alt="" /> :
                                                                <img src="images/noimage.jpeg" alt="" />

                                                        }
                                                        {/* <img src={knowledgeDetail.user_image} alt="" width="40" height="40" /> */}
                                                    </div>
                                                </div>
                                                <div className="user-data">
                                                    <span className="user-name">{knowledgeDetail.user_name}</span>
                                                    <span className="date-time">{helper.formatTimeAgo(knowledgeDetail.created_at)}</span>
                                                </div>
                                                {(knowledgeDetail.user_id === user_id) && <Dropdown className="dots-dropdown ml-auto">
                                                    <Dropdown.Toggle variant="" id="dropdown-basic" className="material-icons">
                                                        more_vert
                                                </Dropdown.Toggle>
                                                    <Dropdown.Menu alignRight>
                                                        {/* <Dropdown.Item onClick={() => this.openModal(knowledgeDetail)}>Share</Dropdown.Item> */}
                                                        <Dropdown.Item onClick={() => this.openModal(knowledgeDetail)}>Edit</Dropdown.Item>
                                                        <Dropdown.Item onClick={() => this.deleteKnowledge(knowledgeDetail.knowledge_id)}>Delete</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>}
                                            </div>
                                            <div className="card">
                                                <div className="card-body">
                                                    {(knowledgeDetail.type == appConstant.TYPE.CODE) ?
                                                        <SyntaxHighlighter language="html javascript" style={monokai}>{knowledgeDetail.text}</SyntaxHighlighter>
                                                        :
                                                        <Linkify properties={{ target: '_blank' }}>
                                                            <p className="card-text">{knowledgeDetail.text}</p>
                                                        </Linkify>}

                                                    {/* {
                                                        (knowledgeDetail.video !== undefined && knowledgeDetail.video !== '') ?
                                                            <video width="320" height="180" controls>
                                                                <source src={knowledgeDetail.video} type="video/mp4" />
                                                            </video>
                                                            : knowledgeDetail.url_meta_image != '' && <span className="user-uploded-img" onClick={() => this.handleShow("userUploadedImgs")}>
                                                                <img src={knowledgeDetail.url_meta_image} alt="" />
                                                            </span>} */}


                                                    <p className="card-text">{knowledgeDetail.description} </p>
                                                    {
                                                        knowledgeDetail.uploads && knowledgeDetail.uploads.map((photo, key) => {
                                                            return (
                                                                <span key={key}>
                                                                    { photo.subtype === 'video' ?
                                                                        <video width="320" height="180" controls>
                                                                            <source src={photo.video} type="video/mp4" />
                                                                        </video> :
                                                                        <span className="user-uploded-img" onClick={() => { this.setState({ modalImageUrl: photo.image }, () => { this.handleShow("userUploadedImgs") }); }} key={key}>
                                                                            <img src={photo.image} alt="" />
                                                                        </span>
                                                                    }
                                                                </span>
                                                            )
                                                        })

                                                    }
                                                    <p className="card-text">{knowledgeDetail.url_meta_title}</p>
                                                    <p className="card-text">{knowledgeDetail.url_meta_description}</p>
                                                </div>
                                            </div>

                                            <div className="idea-post-control-panel">
                                                <div className="selected-categories-block">
                                                    <div className="permission-badge-block mt-0">
                                                        {
                                                            knowledgeDetail && Array.isArray(knowledgeDetail.tags) && knowledgeDetail.tags.map((ideas, key) => {
                                                                return (
                                                                    <span className="permission-badge" key={key}>{ideas}</span>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>

                                                <div className="control-panel-block idea-like-control-panel">
                                                    <div className="likes-comments-block">
                                                        <div className="control-panel-options likes-block">
                                                            <div className="icon-outer likes-icon">
                                                                <div className="likes-checkbox">
                                                                    <label htmlFor="like">
                                                                        <input type="checkbox" className="like-input" defaultChecked={knowledgeDetail.is_liked ? true : false} onClick={() => this.handleLike()} />
                                                                        {
                                                                            (knowledgeDetail.is_liked === 1) ?
                                                                                <img src="/images/like-icon-pink.svg" alt="liked post" className="purple-icon" /> :
                                                                                <img src="/images/like-icon-gray.svg" alt="like post" className="gray-icon" />
                                                                        }
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            {/* active-likes-label */}
                                                            <div className="counter-label-block">
                                                                {(knowledgeDetail.total_likes === 0) ? <span className="likes-label">No Likes</span> :
                                                                    (knowledgeDetail.is_liked === 0 && knowledgeDetail.total_likes === 1) ? <span className="likes-label">{knowledgeDetail.total_likes || 0} Like</span> :
                                                                        (knowledgeDetail.is_liked === 1 && knowledgeDetail.total_likes === 1) ? <span className="active-likes-label">{knowledgeDetail.total_likes || 0} Like</span> :
                                                                            (knowledgeDetail.is_liked === 1 && knowledgeDetail.total_likes >= 1) ? <span className="active-likes-label">{knowledgeDetail.total_likes || 0} Likes</span> :
                                                                                <span className="likes-label">{knowledgeDetail.total_likes} Likes</span>}
                                                            </div>
                                                        </div>
                                                        <div className="control-panel-options comments-block">
                                                            <div className="icon-outer">
                                                                <img src="images/comments-icon.svg" alt="" width="15" />
                                                            </div>
                                                            <div className="counter-label-block">
                                                                {
                                                                    (knowledgeDetail.total_comments === 0) ? <span className="label">no comments</span> :
                                                                        (knowledgeDetail.total_comments === 1) ? <span className="label">{knowledgeDetail.total_comments} comment</span> :
                                                                            <span className="label">{knowledgeDetail.total_comments} comments</span>
                                                                }
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>


                                <div className="idea-content-comments-block">
                                    <div className="comments-block">
                                        {comments.length > 0 ? <ul className="comments-block-outer">
                                            {
                                                comments && Array.isArray(comments) && comments.map((comment, key) => {
                                                    return (
                                                        <li className="comment-wrapper" key={key}>
                                                            <div className="leftbar-list-item-inner">
                                                                <div className="user-img small">
                                                                    <div className="user-img-wrapper">
                                                                        <img src={comment.user_image} alt="" />
                                                                    </div>
                                                                </div>
                                                                <div className="user-data">
                                                                    <span className="user-name">{comment.user_name}</span>
                                                                    <span className="date-time">{helper.formatTimeAgo(comment.created_at)}</span>
                                                                </div>

                                                                {comment.user_id == user_id && <Dropdown className="dots-dropdown ml-auto">
                                                                    <Dropdown.Toggle variant="" id="dropdown-basic" className="material-icons">
                                                                        more_vert
                                                                </Dropdown.Toggle>

                                                                    <Dropdown.Menu alignRight>
                                                                        <Dropdown.Item onClick={() => this.deleteComment(comment, key)}>delete message</Dropdown.Item>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>}
                                                            </div>
                                                            <div className="card">
                                                                <div className="card-body">
                                                                    <Linkify><p className="card-text">{comment.comment}</p></Linkify>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul> :
                                            !isCommentLoading && <ul className="empty-comments-block">
                                                <div className="icon-outer">
                                                    <img src="images/comments-icon.svg" alt="" width="15" />
                                                </div>
                                                <span className="label">{"Be the first to add a Comment..."}</span>
                                            </ul>
                                        }
                                        {isCommentLoading && <div className={'loader-block'}>
                                            <Spinner />
                                        </div>}
                                    </div>
                                </div>

                                <div className="chat-input-block for-idea-pages comment-on-idea">
                                    <form className="comment-input-text" onSubmit={this.addComment}>
                                        <div className="chat-input-inner">
                                            <input
                                                type="text"
                                                name="comment"
                                                className="form-control"
                                                placeholder="Write comment"
                                                value={comment}
                                                onChange={this.handleChange}
                                            />
                                            <Button disabled={comment.trim() == ""} className="send-btn" onClick={this.addComment}>
                                                <img src="images/send-icon-purple.svg" alt="" />
                                            </Button>
                                        </div>
                                    </form>
                                </div>
                            </PerfectScrollbar>
                            :
                            <div className={'loader-block'}>
                                <Spinner />
                            </div>
                        }

                        {isLoading &&
                            <div className={'loader-block'}>
                                <Spinner />
                            </div>}
                    </div>

                    {modalImageUrl &&
                        <ImageModal
                            onHidePress={() => this.handleClose("userUploadedImgs")}
                            show={this.state.visible}
                            imageUrl={modalImageUrl}
                        />}

                </Animated>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        userData: state.getUserData,
        knowledgeBankToReadData: state.knowledgeBankToReadData,
        knowledgeBankLinkData: state.knowledgeBankLinkData,
        knowledgeBankCodeData: state.knowledgeBankCodeData,
        knowledgeBankPhotosData: state.knowledgeBankPhotosData,
        timelineData: state.timelineData
    }
}
export default withRouter(connect(mapStateToProps, { callApi, setKnowledgeBankToReadData, setKnowledgeBankLinkData, setKnowledgeBankCodeData, setTimelineData })(KnowledgeBank))
