import axios from 'axios';
import Token from './Token';

export default class AxiosInterceptor {
    
    constructor(config) {
        this.config = config;
    }

    axios() {
        const axiosInstance = axios.create({
            baseURL: this.config.apiUrl,
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
            },
        });
        AxiosInterceptor.interceptRequest(axiosInstance, this.config);
        AxiosInterceptor.interceptResponse(axiosInstance);
        return axiosInstance;
    }

    static interceptRequest(axiosInstance, appConfig) {
        return axiosInstance.interceptors.request.use((config) => {
            const axiosConfig = { ...config };
            //EventBus.$emit('loading', { source: 'AXIOS_INTERCEPTOR', method: 'interceptRequest' });

            const promise = new Promise((resolve, reject) => {
                const accessToken = Token.get();
                if (!accessToken || !Token.expired()) return resolve(accessToken);

                // return Token.refresh(appConfig.apiUrl('account/token/refresh'))
                //     .then(data => resolve(data.access_token))
                //     .catch(err => reject(err));
            });

            return promise.then((accessToken) => {
                axiosConfig.headers.Accept = 'application/json';
                if (accessToken) axiosConfig.headers.Authorization = `Bearer ${accessToken}`;
                return axiosConfig;
            }).catch(() => {
                //EventBus.$emit('loaded', { source: 'AXIOS_INTERCEPTOR', method: 'interceptRequest' });
                //EventBus.$emit('logout', { source: 'AXIOS_INTERCEPTOR', method: 'interceptRequest' });
                return axiosConfig;
            });
        }, error => Promise.reject(error));
    }

    static interceptResponse(axiosInstance) {
        return axiosInstance.interceptors.response.use((response) => {
            //EventBus.$emit('loaded', { source: 'AXIOS_INTERCEPTOR', method: 'interceptResponse' });
            return response;
        }, (error) => {
            //EventBus.$emit('loaded', { source: 'AXIOS_INTERCEPTOR', method: 'interceptResponse' });
            const { response } = error;
            // if (response.status && response.status === 403) {
                //EventBus.$emit('limitedAccess', { source: 'AXIOS_INTERCEPTOR', method: 'interceptResponse', data: response.data });
            // }
            return response;
        });
    }
}