import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { Animated } from 'react-animated-css';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { callApi } from '../../store/reducers/commonFunction'
import common from '../../store/reducers/commons'
import errorMessages from '../../store/reducers/errorMessages'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
class CompleteProfile extends Component {

    /**
    |--------------------------------------------------
    | constructor
    |--------------------------------------------------
    */

    constructor(props) {
        super(props);
        this.state = {
            employeeList: [],
            roleadmin: false,
            rolecategoryadmin: false,
            first_name: '',
            last_name: '',
            email: '',
            designation: '',
            phone: '',
            photo_url: '',
            errors: {
                email: '', first_name: '', designation: '', phone: ''
            }
        }
    }

    /**
    |--------------------------------------------------
    | componentDidMount
    |--------------------------------------------------
    */

    async componentDidMount() {
        await this.getUserDetails();
    }

    /**
    |--------------------------------------------------
    | getUserDetails
    |--------------------------------------------------
    */

    getUserDetails = async () => {
        let user_id = this.props.userData.user_id ? this.props.userData.user_id : '';
        let body = { user_id }
        const response = await this.props.callApi('/getUserInfo', body, 'post', null, true);
        if (response) {
            this.setState({
                first_name: response.data.first_name, last_name: response.data.last_name,
                email: response.data.email, designation: response.data.designation, phone: response.data.phone,
                photo_url: response.data.image
            })
        }
    }

    /**
    |--------------------------------------------------
    | updateEmployeProfile
    |--------------------------------------------------
    */

    updateEmployeProfile = async (e) => {
        e.preventDefault();
        var { first_name, last_name, phone, file, email, designation } = this.state;
        let company_id = this.props.userData.company_id ? this.props.userData.company_id : '';
        let user_id = this.props.userData.user_id ? this.props.userData.user_id : '';

        if (this.validateForm()) {
            const formData = new FormData()
            formData.append('image', file)
            formData.append('first_name', first_name)
            formData.append('last_name', last_name)
            formData.append('email', email)
            formData.append('designation', designation)
            formData.append('company_id', company_id)
            formData.append('user_id', user_id)
            formData.append('phone', phone)
            formData.append('dail_code', "+91")

            const response = await this.props.callApi('/updateEmployee', formData, 'post', null, true);
            if (response.data.status === true) {
                this.setState({
                    userName: response.data,
                    photo_url: response.data.image
                })

                common.showSuccessMessage(response.data.message)
            }
        }
    }

    /**
    |--------------------------------------------------
    | validateForm
    |--------------------------------------------------
    */

    validateForm() {
        let { first_name, designation, errors, isFormValid } = this.state;
        if (!first_name.trim()) errors.first_name = errorMessages.PROVIDE_first_name;
        else errors.first_name = '';
        if (!designation.trim()) errors.designation = errorMessages.PROVIDE_designation;
        else errors.designation = '';
        if (errors.first_name !== '' || errors.designation !== '') isFormValid = false;
        else isFormValid = true;
        this.setState({ errors, isFormValid })
        return isFormValid
    }

    /**
    |--------------------------------------------------
    | fileUpload
    |--------------------------------------------------
    */

    fileUpload = async (event) => {
        let reader = new FileReader()
        let file = event.target.files[0]
        reader.onloadend = () => {
            this.setState({
                file: file,
                photo_url: reader.result,
                fileVisible: true
            })
        }
        reader.readAsDataURL(file)
    }

    /**
    |--------------------------------------------------
    | handleChange
    |--------------------------------------------------
    */

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value })
        if (event.target.value) {
            this.setState({
                errors: Object.assign(this.state.errors, { [event.target.name]: '' })
            })
        } else {
            this.setState({
                errors: Object.assign(this.state.errors, { [event.target.name]: errorMessages.REQUIRED })
            })
        }
    }

    /**
    |--------------------------------------------------
    | render
    |--------------------------------------------------
    */

    render() {
        let { first_name, phone, email, designation, errors, photo_url } = this.state;
        let imagePreview = null;
        if (photo_url) {
            imagePreview = (<img src={photo_url} alt="Icon" />);
        } else {
            imagePreview = (<img src="images/noimage.jpeg" alt="Icon" />);
        }

        return (
            <div className="login-register-wrapper complete-profile-page">
                <Animated animationIn="fadeInRight" animationOut="fadeOut" isVisible={true} animationInDelay={500}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 mx-auto">
                                <h2 className="page-heading mb-2">Complete Profile</h2>
                                <p>Complete your profile to connect with the people of your company.</p>

                                <form>
                                    <div className="row">

                                        <div className="col-12">
                                            <div className="company-logo-block">
                                                <span>
                                                    {imagePreview}
                                                </span>
                                                <input className="file-input company-logo-changer" type="file" name="profile_photo" accept="image/*" id="file" onChange={this.fileUpload.bind(this)} />
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="form-group mb-4">
                                                <input
                                                    type="text"
                                                    id="firstName"
                                                    name="first_name"
                                                    className="form-control"
                                                    placeholder="First Name"
                                                    value={first_name}
                                                    onChange={this.handleChange}
                                                />
                                                <span className='error-msg'>{errors.first_name}</span>

                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="form-group mb-4">
                                                <input
                                                    type="email"
                                                    id="lastEmail"
                                                    name="email"
                                                    className="form-control"
                                                    placeholder="Last Email"
                                                    value={email}
                                                    onChange={this.handleChange}
                                                />
                                                <span className='error-msg'>{errors.email}</span>

                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="form-group mb-4">
                                                <input
                                                    type="designation"
                                                    id="designation"
                                                    name="designation"
                                                    className="form-control"
                                                    placeholder="Designation"
                                                    value={designation}
                                                    onChange={this.handleChange}
                                                />
                                                <span className='error-msg'>{errors.designation}</span>

                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="input-group mobile-input mb-4">
                                                <div className="input-group-prepend">
                                                    {/* <div className="input-group-text" id="basic-addon1">
                                                        <span className="">+1</span>
                                                    </div> */}
                                                </div>
                                                {/* <input
                                                    className="form-control"
                                                    type="number"
                                                    placeholder="Mobile Number"
                                                    aria-label="mobileNumber"
                                                    aria-describedby="basic-addon1"
                                                    value={phone}
												    onChange={this.handleChange}
                                                /> */}
                                                <PhoneInput
                                                    className="form-control"
                                                    name="phone"
                                                    country={'us'}
                                                    value={phone}
                                                    // onChange={this.handleChange}
                                                    onChange={phone => this.setState({ phone })}
                                                />
                                                <span className='error-msg'>{errors.phone}</span>

                                            </div>
                                        </div>

                                        <div className="col-12 text-right">
                                            <Button variant="outline-primary" className="text-uppercase">Cancel</Button>
                                            <Link className="btn btn-primary text-uppercase ml-3" onClick={this.updateEmployeProfile} to="#">Continue</Link>
                                        </div>

                                    </div>

                                </form>

                            </div>
                        </div>
                    </div>
                </Animated>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        userData: state.getUserData
    }
}
export default withRouter(connect(mapStateToProps, { callApi })(CompleteProfile))