import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import { Button, Modal, Nav, Dropdown } from 'react-bootstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Animated } from 'react-animated-css';
import Select from 'react-select';
import MobileToggle from '../mobileToggles/mobileToggles';
// import Leftbar from './leftbar/Leftbar';
import SidebarAnnouncement from '../sidebarAnnouncement/SidebarAnnouncement';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { callApi, setKnowledgeBankToReadData } from '../../store/reducers/commonFunction'
import Pagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';
import errorMessages from '../../store/reducers/errorMessages';
import common from '../../store/reducers/commons';
import swal from 'sweetalert';
import { Spinner } from 'react-activity';
import 'react-activity/dist/react-activity.css';
import _ from 'lodash';
import _FilterComponent from './Component/_FilterComponent';
import _FloatingMenu from './Component/_FloatingMenu';
import helper from '../../services/helper';
import appConstant from '../../constant/appConstant';

class knowledgeBankToRead extends Component {

    /**
    |--------------------------------------------------
    | constructor
    |--------------------------------------------------
    */

    constructor(props) {
        super(props);
        this.state = {
            OpenOptions: false,
            CloseOptions: false,


            ismodelName: "",
            current_page: 1,
            per_page: 10,
            total: 1,
            last_page: 1,

            filterArr: [],
            loadedFiles: [],
            imgArr: [],
            message: '',
            link: '',
            code: '',
            url_image: '',
            descriptions: '',
            show: false,
            shows: false,
            errors: {
                message: ''
            },
            isLoading: false
        }
    }

    /**
    |--------------------------------------------------
    | componentDidMount
    |--------------------------------------------------
    */

    async componentDidMount() {
        this.props.onRef && this.props.onRef(this);
        // await this.getknowledgeaDetails(false);
        await this.getKnowledgeBankToReadData(false)

        await this.getTags()
    }

    /**
    |--------------------------------------------------
    | componentWillUnmount
    |--------------------------------------------------
    */

    componentWillUnmount() {
        this.props.onRef && this.props.onRef(undefined);
    }

    /**
    |--------------------------------------------------
    | handleClose
    |--------------------------------------------------
    */

    handleClose = (modelName) => {
        this.setState({ visible: false });
    }

    /**
    |--------------------------------------------------
    | handleCloseimg
    |--------------------------------------------------
    */

    handleCloseimg = (modelName) => {
        this.setState({ shows: false });

    }

    /**
    |--------------------------------------------------
    | handleShow
    |--------------------------------------------------
    */

    handleShow = (modelName) => {
        this.setState({ visible: true, ismodelName: modelName }, () => this.CloseOptions);
    }

    /**
    |--------------------------------------------------
    | OpenOptions
    |--------------------------------------------------
    */

    OpenOptions = () => {
        this.setState({ leftbar: !this.state.OpenOptions },
            function () {
                document.body.classList.toggle('OpenOptions');
            });
    }

    /**
    |--------------------------------------------------
    | CloseOptions
    |--------------------------------------------------
    */

    CloseOptions = () => {

        this.setState({ leftbar: !this.state.CloseOptions },
            function () {
                document.body.classList.remove('CloseOptions');
            });
    }

    /**
    |--------------------------------------------------
    | componentWillReceiveProps
    |--------------------------------------------------
    */

    async componentWillReceiveProps() {
        if (this.props.history.location.view_node ? this.props.history.location.view_node : '') {
            let knowledge_id = this.props.history.location.view_node
            await this.setParentStatus(knowledge_id);
        }
    }

    /**
    |--------------------------------------------------
    | setParentStatus
    |--------------------------------------------------
    */

    setParentStatus = async (id) => {
        let knowledge_id = id;
        let body = {
            knowledge_id
        }
        this.setState({ knowledge_id: knowledge_id })
        const response = await this.props.callApi('/getKnowledgeDetail', body, 'post', null, true);

        this.setState({
            Knowledge: response.data,
            // tagVal: tagVal
        })
    }

    /**
    |--------------------------------------------------
    | getKnowledgeBankToReadData
    |--------------------------------------------------
    */

    getKnowledgeBankToReadData = async (isAppend = true) => {
        let { filterArr, tagVal, current_page } = this.state;
        let tagsvalue = tagVal ? tagVal.map((i) => {
            return (
                i.value
            )
        }) : '';
        this.setState({
            isLoading: true
        })
        let filterData = _FilterComponent.getFilterValue();
        let body = { page: current_page, type: "text", tags: filterData.tagVal, filter_by: filterData.filterArr }
        const response = await this.props.getKnowledgeList(body);
        if (response.data && response.data.tags && response.data.tags.length > 0) {
            tagVal = response.data.tags && Array.isArray(response.data.tags) && response.data.tags.map((values) => {
                return (
                    { label: values, value: values }
                )
            })
        }
        if (response) {
            this.props.setKnowledgeBankToReadData(isAppend ? [...this.props.knowledgeBankToReadData, ...response.data] : response.data);
            this.setState({
                total: response.meta.total,
                show: false,
                isLoading: false,
                last_page: response.meta.last_page,
            })
            if (this.props.getChildEvent && this.props.activeTab == "toRead") {

                if (response && response.data && response.data.length > 0) {
                    this.props.getChildEvent(response.data[0].knowledge_id, appConstant.TYPE.TEXT);
                } else {
                    this.props.getChildEvent('');
                }
            }
        }
    }

    /**
    |--------------------------------------------------
    | getknowledgeaDetails
    |--------------------------------------------------
    */

    getknowledgeaDetails = async (isAppend = true) => {
        let { filterArr, tagVal, current_page } = this.state;
        // let tagsarray = [];
        let tagsvalue = tagVal ? tagVal.map((i) => {
            return (
                i.value
            )
        }) : '';
        // tagsarray.push(tagsvalue.toString())
        this.setState({
            isLoading: true
        })

        let body = { page: current_page, type: "text", tags: tagsvalue.toString(), filter_by: filterArr.toString() }
        const response = await this.props.callApi('/getKnowledgeList', body, 'post', 'ADMIN_LOGIN', true);
        if (response.data && response.data.tags && response.data.tags.length > 0) {
            tagVal = response.data.tags && Array.isArray(response.data.tags) && response.data.tags.map((values) => {
                return (
                    { label: values, value: values }
                )
            })
        }
        if (response) {
            console.log('getKnowledgeList response-->', JSON.stringify(response));
            this.props.setKnowledgeBankToReadData(isAppend ? [...this.props.knowledgeBankToReadData, ...response.data] : response.data)
            this.setState({
                // Knowledge: response.data,
                per_page: response.meta.per_page,
                total: response.meta.total,
                show: false,
                isLoading: false,
                last_page: response.meta.last_page,
            })
            if (this.props.getChildEvent && !isAppend) {
                if (response && response.data && response.data.length > 0) {
                    this.props.getChildEvent(response.data[0].knowledge_id, appConstant.TYPE.TEXT);
                } else {
                    this.props.getChildEvent('');
                }
            }
        }

    }

    /**
    |--------------------------------------------------
    | getTags
    |--------------------------------------------------
    */

    getTags = async () => {
        let company_id = this.props.userData.company_id ? this.props.userData.company_id : '';
        let body = { company_id }
        const response = await this.props.callApi('/getTags', body, 'post', 'ADMIN_LOGIN', true);
        if (response) {
            this.setState({ tags: response })
        }
    }

    /**
    |--------------------------------------------------
    | tagValue
    |--------------------------------------------------
    */

    tagValue = async (e) => {
        this.setState({
            tagVal: e
        })
    }

    /**
    |--------------------------------------------------
    | validateForm
    |--------------------------------------------------
    */

    validateForm() {
        let { message, errors, isFormValid } = this.state;

        if (!message.trim()) errors.message = errorMessages.PROVIDE_MESSAGE;
        else errors.message = '';

        if (errors.message !== '') isFormValid = false;
        else isFormValid = true;
        this.setState({ errors, isFormValid })
        return isFormValid
    }

    /**
    |--------------------------------------------------
    | addLoadedFile
    |--------------------------------------------------
    */

    addLoadedFile(file) {
        let tempPhotos = this.state.loadedFiles;
        tempPhotos.push(file);
        this.setState({
            loadedFiles: tempPhotos,
        });
    }

    /**
    |--------------------------------------------------
    | deleteKnowledge
    |--------------------------------------------------
    */

    deleteKnowledge = async (knowledge_id) => {

        swal({
            title: "Are you sure want to delete this Knowledge ?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async willDelete => {
                if (willDelete) {
                    let body = {
                        knowledge_id: knowledge_id
                    }
                    this.setState({
                        knowledge_id
                    })
                    const response = await this.props.callApi('/deleteKnowledge', body, 'post', null, true);
                    if (response.data.code === 200) {
                        common.showSuccessMessage(response.data.message);
                        this.getknowledgeaDetails()
                    }
                }
            });
    }

    /**
    |--------------------------------------------------
    | openModal
    |--------------------------------------------------
    */

    openModal(Knowledge) {
        // let tagVal=[];
        let tagsvalue = Knowledge.tags.map((i) => {
            return (
                i
            )
        })
        this.setState({
            descriptions: Knowledge.text,
            knowledge_id: Knowledge.knowledge_id,
            tagvalue: tagsvalue,
            shows: true,
        });
    }

    /**
    |--------------------------------------------------
    | tagValue
    |--------------------------------------------------
    */

    tagValue = async (e) => {
        this.setState({
            tagVal: e
        })
    }

    /**
    |--------------------------------------------------
    | handleScroll
    |--------------------------------------------------
    */

    handleScroll = (e) => {
        let element = e.target
        if (element.scrollTop + element.offsetHeight >= element.scrollHeight) {
            if (this.state.current_page != this.state.last_page) {
                this.setState({ current_page: this.state.current_page + 1 }, () => {
                    this.getKnowledgeBankToReadData();
                })
            }
        }
    }

    /**
    |--------------------------------------------------
    | onPressFilter
    |--------------------------------------------------
    */

    onPressFilter = (tags, filter) => {
        console.log('tags-->', JSON.stringify(tags))
        console.log('filter-->', JSON.stringify(filter))
        this.setState({
            tagVal: tags,
            filterArr: filter,
            current_page: 1
        }, () => this.getknowledgeaDetails(false));
    }

    /**
    |--------------------------------------------------
    | onItemClick
    |--------------------------------------------------
    */

    onItemClick = (knowledgeDetail) => {
        this.props.getChildEvent(knowledgeDetail.knowledge_id, appConstant.TYPE.TEXT)
    }

    /**
    |--------------------------------------------------
    | render
    |--------------------------------------------------
    */

    render() {
        let user_id = this.props.userData.user_id ? this.props.userData.user_id : '';
        const { knowledgeBankToReadData } = this.props;

        var { thumbimage, file, imgArr, Knowledge, tagvalue, descriptions, total, current_page, per_page, tags, message, errors, link, code } = this.state
        var strmessage = message;
        var strdescription = descriptions;
        let tagsList = tags && Array.isArray(tags.data) && tags.data.map((values) => {
            return (
                { label: values, value: values }
            )
        })
        let updatetags = tagvalue && Array.isArray(tagvalue) && tagvalue.map((values) => {
            return (
                { label: values, value: values }
            )
        })

        return (
            <PerfectScrollbar onScroll={this.handleScroll}>
                {
                    knowledgeBankToReadData && Array.isArray(knowledgeBankToReadData) && knowledgeBankToReadData.map((Knowledgedetail, key) => {
                        const listItemClassName = (Knowledgedetail.knowledge_id == _.get(this.props, 'knowledgeDetail.knowledge_id')) ? "leftbar-list-item active" : "leftbar-list-item"
                        return (
                            <li className={listItemClassName} key={key} onClick={() => { this.onItemClick(Knowledgedetail) }}>
                                <div className="leftbar-list-item-inner">
                                    <div className="card-kb">
                                        <div className="card-kb-header">
                                            <div className="list-item-leftbar py-0">
                                                <div className="user-img">
                                                    <div className="user-img-wrapper">
                                                        {
                                                            (Knowledgedetail.user_image === '') ? <img src="images/noimage.jpeg" alt="" /> :
                                                                <img src={Knowledgedetail.user_image} alt="" />
                                                        }
                                                    </div>
                                                </div>
                                                <div className="user-data w-100">
                                                    <div className="user-name">{Knowledgedetail.user_name}</div>
                                                    <div className="data-text text-capitalize">{helper.formatTimeAgo(Knowledgedetail.created_at)}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-kb-body">
                                            <p className="card-kb-text">{Knowledgedetail.text}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="idea-post-control-panel">
                                    <div className="selected-categories-block">
                                        <div className="permission-badge-block mt-0">
                                            {
                                                Knowledgedetail && Array.isArray(Knowledgedetail.tags) && Knowledgedetail.tags.map((tag, key) => {
                                                    return (
                                                        <span className="permission-badge" key={key}>{tag}</span>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>

                                    {this.props.likeCommentView(Knowledgedetail)}
                                </div>
                            </li>
                        )
                    })
                }
                {this.state.isLoading && <div className={'loader-block'}>
                    <Spinner />
                </div>}
                {(!this.state.isLoading && knowledgeBankToReadData && knowledgeBankToReadData.length === 0) && this.props.noRecordView()}

            </PerfectScrollbar>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        userData: state.getUserData,
        knowledgeBankToReadData: state.knowledgeBankToReadData,
    }
}
export default withRouter(connect(mapStateToProps, { callApi, setKnowledgeBankToReadData })(knowledgeBankToRead))