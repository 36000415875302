
import swal from "sweetalert";

//**********************************************************************
    // @PURPOSE : //We can use following function to store data in localstorage,
    //Validations and 
    //Messages
//*********************************************************************//
export default {
    /*************************************************************************************
                                    Store Data To local Storage
    **************************************************************************************/
    setItem(key, value) {
         localStorage.setItem(key, value);
    },
    /*************************************************************************************/

    /*************************************************************************************
                               Get Data From local Storage
   **************************************************************************************/
    getItem(key) {
        return localStorage.getItem(key);
    },
    /*************************************************************************************
                                 Remove Data in local Storage
   **************************************************************************************/
    removeItem(key) {
        localStorage.removeItem(key)
    },
    /*************************************************************************************/

    /*************************************************************************************
                               Email Validation
    **************************************************************************************/
    validateEmail(email) {
        var pattern = new RegExp(/^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        return pattern.test(email);
    },
    /*************************************************************************************/

    /*************************************************************************************
                               Password Validation
    **************************************************************************************/
    validatePassword(pass) {
        // var pattern = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/);
        //var pattern = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d_-]{4,}$/);
        // var pattern = new RegExp(/^^([a-zA-Z0-9_-]{8,16})$/);
        var pattern = new RegExp(/^[0-9\b]+$/);
        return pattern.test(pass);
    },
    /*************************************************************************************/
    
    /*************************************************************************************
                               Mobile Number Validation
    **************************************************************************************/
    validateMobileNumber(mobileNo) {
        var pattern = new RegExp(/^\(?([0-9]{12})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/);
        return pattern.test(mobileNo);
    },
    /*************************************************************************************/

    /*************************************************************************************
                                Character Validation
    **************************************************************************************/
    allowChar(e) {
        const pattern = new RegExp(/^[a-zA-Z\s]{0,255}$/);
        return pattern.test(e);
    },
    /*************************************************************************************/
    
    /*************************************************************************************
                                Number Validation
    **************************************************************************************/
    allowNumbers(e) {
        const pattern = new RegExp(/^[0-9\b]+$/);
        return pattern.test(e);
    },
    /*************************************************************************************/
    
    /*************************************************************************************
                                Set Success Message
    **************************************************************************************/
    showSuccessMessage(msg) {
        swal({
            position: 'center',
            icon: 'success',
            text: msg,
            buttons: false,
            timer: 2500,
            className: 'custom-toaster'
        });
    },
    /*************************************************************************************/

    /*************************************************************************************
                                Set Error Message
    **************************************************************************************/
    showErrorMessage(msg) {
        swal({
            position: 'center',
            icon: 'error',
            text: msg,
            buttons: false,
            timer: 3000,
            className: 'custom-toaster'
        });
    },
    /*************************************************************************************/

    /*************************************************************************************
                                Set Warning Message
    **************************************************************************************/
    showWarningMessage(msg) {
        swal({
            position: 'center',
            icon: 'warning',
            text: msg,
            buttons: false,
            timer: 3000,
            className: 'custom-toaster'
        });
    },
    /*******************************************************************************************/
   
    // /*************************************************************************************
    //                            PinCode Validate
    // **************************************************************************************/
    // // for pincode number validate
    // validatePincodeNumber(mobileNo) {
    //     var pattern = new RegExp(/^[0-9]{5}(?:-[0-9]{4})?$/);
    //     return pattern.test(mobileNo);
    // },
}