const env = {
  production: {
    apiUrl:'https://api.echoloudapp.com/api/v1',
    mediaServerUrl: '',
    authServerUrl: '',
    clientId: '1',
    clientSecret: process.env.REACT_APP_CLIENTSECRET,
  },
  staging: {
    apiUrl:'',
    mediaServerUrl: '',
    authServerUrl: '',
    clientId: '',
    clientSecret: process.env.REACT_APP_CLIENTSECRET,
  },
  development: {
    apiUrl:'https://echoloud.devpress.net/api/v1',
    mediaServerUrl: 'http://arosa.admin.com',
    authServerUrl: 'http://arosa.admin.com',
    clientId: '1',
    clientSecret: process.env.REACT_APP_CLIENTSECRET,
  },
  local: {
    apiUrl:'https://echoloud.devpress.net/api/v1',
    mediaServerUrl: 'http://arosa.admin.com',
    authServerUrl: 'http://arosa.admin.com',
    clientId: '1',
    clientSecret: process.env.REACT_APP_CLIENTSECRET,
  },
};

module.exports = env[process.env.REACT_APP_NODE_ENV];

