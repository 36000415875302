import { STORE_USER, LOGOUT_USER } from './actionTypes';

let initialState = {
    authenticated: undefined,
    // userData:{},
    // categoryId:'',
    getUserData:undefined,
    allCategoriesDetails:[],
    categoryList:[],
    adminDetailsList:[],
    getRegisterData:[],
    allIdeaData:[],
    employeeList:[],
    categoryColor:[],
    adminData:[],
    feedbackList:[],
    allRegisterData:[],
    allFeedbackData:[],
    sentFeedbackData:[],
    receivedFeedbackData:[],
    knowledgeBankToReadData:[],
    knowledgeBankLinkData:[],
    knowledgeBankPhotosData:[],
    knowledgeBankCodeData:[],
    announcementData: [],
    timelineData: [],
    profileInformation: undefined
};

export default (state = initialState, action) => {
    switch (action.type) {

        case STORE_USER:
            return {
                ...state,
                authenticated: action.payload
            };
            
        case 'GET_USER_DATA':
            return  Object.assign({},state,{getUserData:action.payload})
        case 'REGISTER_DATA':
        return  Object.assign({},state,{getRegisterData:action.payload})
        case 'GET_REGISTER_DATA':
            return  Object.assign({},state,{allRegisterData:action.payload})
        case 'ALL_FEEDBACK_DATA':
        return  Object.assign({},state,{allFeedbackData:action.payload})
        case 'SENT_FEEDBACK_DATA':
        return  Object.assign({},state,{sentFeedbackData:action.payload})
        case 'RECEIVED_FEEDBACK_DATA':
        return  Object.assign({},state,{receivedFeedbackData:action.payload})
        case 'ALL_IDEA_DATA':
        return  Object.assign({},state,{allIdeaData:action.payload})
        case 'CATEGORY_COLOR':
        return  Object.assign({},state,{categoryColor:action.payload})
        case 'ADMIN_DATA':
        return  Object.assign({},state,{adminData:action.payload})
        case 'ALL_CATEGORY_DETAILS':
        return  Object.assign({},state,{allCategoriesDetails:action.payload})
        case 'CATEGORY_LIST':
        return  Object.assign({},state,{categoryList:action.payload})
        case 'ADMIN_LIST':
        return  Object.assign({},state,{adminDetailsList:action.payload})
        case 'ALL_EMPLOYEE_DETAILS':
        return  Object.assign({},state,{employeeList:action.payload})
        case 'FEEDBACK_LIST':
        return  Object.assign({},state,{feedbackList:action.payload})
        case 'KNOWLEDGE_BANK_TO_READ_DATA':
        return  Object.assign({},state,{knowledgeBankToReadData:action.payload})
        case 'KNOWLEDGE_BANK_LINK_DATA':
        return  Object.assign({},state,{knowledgeBankLinkData:action.payload})
        case 'KNOWLEDGE_BANK_PHOTOS_DATA':
        return  Object.assign({},state,{knowledgeBankPhotosData:action.payload})
        case 'KNOWLEDGE_BANK_CODE_DATA':
        return  Object.assign({},state,{knowledgeBankCodeData:action.payload})
        case 'TIMELINE_DATA':
        return  Object.assign({},state,{timelineData:action.payload})
        case 'ANNOUNCEMENT_DATA':
        return  Object.assign({},state,{announcementData:action.payload})
        case 'PROFILE_INFO':
        return  Object.assign({},state,{profileInformation:action.payload})
        case LOGOUT_USER:
        return  Object.assign({},state,{
            authenticated: undefined,
            // userData:{},
            // categoryId:'',
            getUserData:undefined,
            allCategoriesDetails:[],
            categoryList:[],
            adminDetailsList:[],
            getRegisterData:[],
            allIdeaData:[],
            employeeList:[],
            categoryColor:[],
            adminData:[],
            feedbackList:[],
            allRegisterData:[],
            allFeedbackData:[],
            sentFeedbackData:[],
            receivedFeedbackData:[],
            knowledgeBankToReadData:[],
            knowledgeBankLinkData:[],
            knowledgeBankPhotosData:[],
            knowledgeBankCodeData:[],
            announcementData: [],
            timelineData: [],
            profileInformation: undefined
        })
        default:
            return state;
    }
}

// import {GET_USER_DATA} from '../store/reducers/commonFunction'
// const INITIAL_STATE ={    
      
//     userData:{},
//     categoryId:'',
//     loginUserData:[]
// }

// export default (state=INITIAL_STATE,action) => {
    
//     switch (action.type) {
//         case 'USER_DATA':
//         return  Object.assign({},state,{userData:action.payload})
//         case 'CATEGORY_DATA':
//         return  Object.assign({},state,{categoryId:action.payload})
//         case "LOGOUT":
//         return  INITIAL_STATE
//         case GET_USER_DATA:
//         return Object.assign({},state,{loginUserData:action.payload})
//         default : 
//         return INITIAL_STATE ;
//     }
// }