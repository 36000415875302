
import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux'
import { callApi, allCategoryDetails } from '../../store/reducers/commonFunction';
import common from '../../store/reducers/commons';
import errorMessages from '../../store/reducers/errorMessages';
import appConstant from '../../constant/appConstant';

function PermissionModal(props) {
    const { show, onHidePress, selectedUser, onPressUpdate } = props;
    const dispatch = useDispatch()
    const categoryListData = useSelector(state => state.categoryList)
    const userData = useSelector(state => state.getUserData)
    const [selectedRole, setSelectedRole] = useState('');
    const [multipleSelect, setMultipleSelect] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [validationError, setValidationError] = useState({});
    const [categoryList, setCategoryList] = useState(categoryListData);

    useEffect(() => {
        console.log('selectedUser-->', JSON.stringify(selectedUser));
        setSelectedRole(selectedUser.role);
        setMultipleSelect([...selectedUser.category]);
        getAllCategory();
    }, [selectedUser])

    const getAllCategory = async () => {

        let body = {}
        const response = await dispatch(callApi('/getCategory', body, 'post', 'ADMIN_LOGIN', true));
        if (response) {
            setCategoryList(response.data)
            dispatch(allCategoryDetails(response.data || []))
        }

    }

    const checkArray = (id) => {
        if (multipleSelect.findIndex(x => x.id == id) != -1) return true;
        else return false;
    }

    const onCheckbox = (category) => {

        var delarray = multipleSelect;
        let selectedIndex = delarray.findIndex(x => x.id == category.id)
        if (selectedIndex == -1) {
            delarray.push(category);
        } else {
            delarray.splice(selectedIndex, 1);
        }
        setMultipleSelect([...delarray])
        setValidationError({
            categoryMessage: ''
        });
    }

    /******************* Radio Options ***********************/
    const selectRole = (e) => {
        setValidationError({
            roleMessage: '',
        });
        setSelectedRole(e.target.value)
    }

    const onPressUpdateFunction = async () => {
        // onPressUpdate()
        try {
            if (validationForm()) {
                let company_id = userData.company_id ? userData.company_id : '';
                let selectedCategoryId = multipleSelect.map(x => x.id);
                let body = {
                    user_id: selectedUser.user_id,
                    category_id: selectedCategoryId.toString(),
                    role: selectedRole,
                    company_id
                }
                setIsLoading(true)
                const response = await dispatch(callApi('/addAdmin', body, 'post', null, true));
                if (response) {
                    common.showSuccessMessage(response.data.message);
                    onHidePress()
                    onPressUpdate()
                    setIsLoading(false)
                } else {
                    setIsLoading(false)
                }
            }
        } catch (error) {
            setIsLoading(false)
            throw (error)
        }
    }

    const validationForm = () => {

        if (selectedRole.trim() == "" || selectedRole == 'employee') {
            setValidationError({
                roleMessage: errorMessages.PROVIDE_ROLE,
                categoryMessage: ''
            });
            return false
        } else if (multipleSelect.length == 0) {
            setValidationError({
                categoryMessage: errorMessages.PROVIDE_CATEGORY,
                roleMessage: ''
            });
            return false
        } else {
            setValidationError({});
        }
        return true
    }

    let isDisabledClassName = userData.role == "admin" ? "text disabled" : "text"
    return (
        <Modal className="common-modal small" centered show={show} onHide={() => { onHidePress(); setValidationError({}) }}>
            <button type="button" className="close fixed-close-btn" onClick={() => { onHidePress(); setValidationError({}) }}>
                <span aria-hidden="true">×</span>
            </button>
            <Modal.Body className="">
                <div className="give-permission-block mt-4">
                    <p className="s1-subtitle mb-3">Edit Permission</p>
                    <div className="checkbox-radio w-100 my-2">
                        <label className="mb-0">
                            <input disabled={userData.role == appConstant.ROLE.ADMIN} type="radio" name="permission" checked={selectedRole === appConstant.ROLE.ADMIN || selectedRole === appConstant.ROLE.COMPANY_ADMIN} value='admin' onChange={(e) => selectRole(e)} />
                            <span>
                                <span className="radio-style"></span>
                                <span className={isDisabledClassName}>admin</span>
                            </span>
                        </label>
                    </div>
                    <div className="checkbox-radio w-100 my-2 category-admin">
                        <label className="mb-0">
                            <input type="radio" name="permission" checked={selectedRole === appConstant.ROLE.CATEGORY_ADMIN} value='categoryadmin' onClick={(e) => selectRole(e)} />
                            <span>
                                <span className="radio-style"></span>
                                <span className="text">category admin</span>
                            </span>
                            <div className="select-admin-category mt-4">
                                <p className="s1-subtitle mb-2">Select Category</p>
                                <span className='error-msg'>{validationError.categoryMessage}</span>
                                {
                                    categoryList && Array.isArray(categoryList) && categoryList.map((category, key) => {
                                        return (
                                            <div className="checkbox-radio w-100 my-2" key={key}>
                                                <label className="mb-0">
                                                    <input type="checkbox" value="" checked={(checkArray(category.id))} onChange={() => onCheckbox(category)} />

                                                    {/* <input type="checkbox" value="" /> */}
                                                    <span>
                                                        <span className="checkbox-style"></span>
                                                        <span className="text tnc-check" style={{ color: "#" + category.color_code }}>{category.name}
                                                        </span>
                                                    </span>
                                                </label>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </label>
                        <span className='error-msg'>{validationError.roleMessage}</span>
                        <div className="col-12 text-right pb-3">
                            {/* <Button variant="outline-primary" onClick={() => onHidePress()}>Cancel</Button> */}
                            <Button disabled={isLoading} className="mt-3" onClick={() => onPressUpdateFunction()}>{isLoading ? "Loading..." : "Update"}</Button>
                        </div>
                    </div>
                </div>

            </Modal.Body>
        </Modal >
    )
}

PermissionModal.propTypes = {
    show: PropTypes.bool,
    onHidePress: PropTypes.func,
    onPressUpdate: PropTypes.func,
    selectedUser: PropTypes.object,
};

export default PermissionModal;