import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, Button } from 'react-bootstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Select from 'react-select';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { callApi, setIdeaData } from '../../../store/reducers/commonFunction'
import Pagination from 'rc-pagination'
import 'rc-pagination/assets/index.css';
import { Spinner } from 'react-activity';
import 'react-activity/dist/react-activity.css';
import helper from '../../../services/helper';
import _ from 'lodash';

class Leftbar extends Component {

    /**
    |--------------------------------------------------
    | constructor
    |--------------------------------------------------
    */

    constructor(props) {
        super(props);
        this.state = {
            leftbar: false,
            ideas: [],
            filterArr: [],
            current_page: 1,
            per_page: 10,
            total: 1,
            last_page: 1,
            total_pages: null,
            show: false,
            visibles: false,
            isLoading: false,
            filterCategory: [
                {
                    id: 'most_liked', title: 'Most Liked'
                },
                {
                    id: 'this_month', title: 'This Month'
                },
                {
                    id: 'last_month', title: 'Last Month'
                },
                {
                    id: 'most_recent', title: 'Most Recent'
                },
                {
                    id: 'by_me', title: 'By Me'
                },
            ]
        }
    }

    /**
    |--------------------------------------------------
    | componentDidMount
    |--------------------------------------------------
    */

    async componentDidMount() {

        await this.getIdeaDetails(false);
        await this.getTags();
    }

    /**
    |--------------------------------------------------
    | getIdeaDetails
    |--------------------------------------------------
    */

    getIdeaDetails = async (isAppend = true) => {
        this.setState({
            isLoading: true
        })
        let { filterArr, tagVal, current_page } = this.state;
        // let tagsarray=[];
        let tagsvalue = tagVal ? tagVal.map((i) => {
            return (
                i.value
            )
        }) : '';
        // tagsarray.push(tagsvalue.toString())
        let body = { page: current_page, tags: tagsvalue.toString(), filter_by: filterArr.toString() }
        console.log('getIdeaList body-->', JSON.stringify(body));
        const response = await this.props.callApi('/getIdeaList', body, 'post', 'ADMIN_LOGIN', true);
        console.log('getIdeaList response-->', JSON.stringify(response));
        this.props.setIdeaData(isAppend ? [...this.props.allIdeaData, ...response.data] : response.data || []);
        if (response) {
            if (current_page == 1) {
                this.props.displayIdeaData && this.props.displayIdeaData()
            }
            this.setState({
                // ideas: response.data,
                per_page: response.meta.per_page,
                last_page: response.meta.last_page,
                total: response.meta.total,
                isLoading: false,
                show: false
            });
        } else {
            this.setState({
                isLoading: false,
            })
        }
        if (body.tags || body.filter_by) {
            this.setState({
                visibles: true,
            })
        } else {
            this.setState({
                visibles: false,
            })
        }
    }

    /**
    |--------------------------------------------------
    | LeftbarToggle
    |--------------------------------------------------
    */

    LeftbarToggle = () => {
        this.setState({ leftbar: !this.state.leftbar },
            function () {
                document.body.classList.remove('leftbar-open');
            });
    }

    /**
    |--------------------------------------------------
    | getIdea
    |--------------------------------------------------
    */

    getIdea = (idealist) => {
        if (this.props && this.props.getChildEvent) {
            this.props.getChildEvent(idealist);
        }
    }

    /**
    |--------------------------------------------------
    | getTags
    |--------------------------------------------------
    */

    getTags = async () => {

        let company_id = this.props.userData.company_id ? this.props.userData.company_id : '';
        let body = { company_id }
        const response = await this.props.callApi('/getTags', body, 'post', 'ADMIN_LOGIN', true);
        if (response) {
            this.setState({ tags: response })
        }
    }

    /**
    |--------------------------------------------------
    | tagValue
    |--------------------------------------------------
    */

    tagValue = async (e) => {
        this.setState({
            tagVal: e
        })
    }

    /**
    |--------------------------------------------------
    | filterCheckbox
    |--------------------------------------------------
    */

    filterCheckbox = async (e) => {
        let filterArr = this.state.filterArr;
        this.state.filterCategory.map((filterItem, index) => {

            if (e.target.value != filterItem.id) {
                document.getElementById(filterItem.id).checked = false
            } else {
                filterArr.push(e.target.value);
            }
        })
        if (document.getElementById(e.target.value).checked) {
            // filterArr.push(e.target.value);
            filterArr = [e.target.value];
        } else {
            filterArr = [];
        }
        this.setState({ filterArr })
    }

    /**
    |--------------------------------------------------
    | dropdownToggle
    |--------------------------------------------------
    */

    dropdownToggle = () => {
        this.setState({ show: !this.state.show });
    }

    /**
    |--------------------------------------------------
    | closeMenu
    |--------------------------------------------------
    */

    closeMenu = () => {

        var x = document.getElementsByClassName("checkbox");
        for (let i = 0; i <= x.length; i++) {
            if (x[i]) {
                x[i].checked = false;
            }
        }
        this.setState({
            show: false,
            visibles: false,
            filterArr: [],
            tagVal: [],
            current_page: 1
        }, () => this.getIdeaDetails(false))
    }

    /**
    |--------------------------------------------------
    | handleAllScroll
    |--------------------------------------------------
    */

    handleAllScroll = (e) => {
        let element = e.target
        if (element.scrollTop + element.offsetHeight >= element.scrollHeight) {
            if (this.state.current_page != this.state.last_page) {
                this.setState({ current_page: this.state.current_page + 1 }, () => {
                    this.getIdeaDetails()
                })
            }
        }
    }

    /**
    |--------------------------------------------------
    | render
    |--------------------------------------------------
    */

    render() {
        var { ideas, tags, total, current_page, per_page, filterCategory } = this.state;
        const { allIdeaData } = this.props;
        let tagsList = tags && Array.isArray(tags.data) && tags.data.map((values) => {
            return (
                { label: values, value: values }
            )
        })
        return (
            <div className="leftbar idea-sharing-leftbar">

                <span className="leftbar-toggler" onClick={this.LeftbarToggle}>
                    <span className="material-icons">arrow_back</span>
                </span>

                <span className="btn plus-btn" onClick={() => this.props.handleShowD()}>+</span>

                <Dropdown alignRight className="d-inline-block filter-dropdown" show={this.state.show} onToggle={this.dropdownToggle}>
                    <Dropdown.Toggle id="dropdown-basic" className="filter-btn" >
                        <span className="material-icons">filter_list</span>
                        {
                            this.state.visibles ?
                                <span className="red-dot bg-danger position-absolute rounded-circle"></span>
                                : null
                        }
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Select
                            isMulti
                            name="addTag"
                            options={tagsList}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            value={this.state.tagVal ? this.state.tagVal : ''}
                            onChange={(e) => this.tagValue(e)}
                            placeholder={"Type to add tag"}
                        />
                        <Dropdown.Header className="px-0">Category</Dropdown.Header>
                        {filterCategory.map((filterItem, index) => {
                            return (
                                <div className="d-block">
                                    <div className="checkbox-radio">
                                        <label>
                                            <input className="checkbox" type="checkbox" value={filterItem.id} id={filterItem.id} onChange={(e) => this.filterCheckbox(e)} />
                                            <span>
                                                <span className="checkbox-style"></span>
                                                <span className="text tnc-check">{filterItem.title}</span>
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            )
                        })}

                        {/* <Dropdown.Header className="px-0">Sort Order</Dropdown.Header>
                        <div className="d-block">
                            <div className="checkbox-radio">
                                <label>
                                    <input type="checkbox" value="" defaultChecked />
                                    <span>
                                        <span className="checkbox-style"></span>
                                        <span className="text tnc-check">Ascending
                                                        </span>
                                    </span>
                                </label>
                            </div>
                        </div>
                        <div className="d-block">
                            <div className="checkbox-radio">
                                <label>
                                    <input type="checkbox" value="" />
                                    <span>
                                        <span className="checkbox-style"></span>
                                        <span className="text tnc-check">Descending
                                                        </span>
                                    </span>
                                </label>
                            </div>
                        </div> */}

                        <div className="d-flex justify-content-between align-items-center py-2">
                            <Button onClick={() => this.setState({ current_page: 1 }, () => this.getIdeaDetails(false))} className="px-3">Filter</Button>
                            <Button variant="outline-primary" className="px-2" onClick={this.closeMenu}>CLEAR ALL</Button>
                        </div>
                    </Dropdown.Menu>
                </Dropdown>

                <div className="contacts-leftbar-header">
                    <h6>Idea Sharing</h6>
                    <span className="heading-detail">Every Idea helps! Where do you want idea to go today?</span>
                </div>

                <ul className="leftbar-list feedback-list">
                    <PerfectScrollbar onScroll={this.handleAllScroll}>
                        {
                            allIdeaData && Array.isArray(allIdeaData) && allIdeaData.map((idea, key) => {
                                let listItemClassName = (idea.idea_id == _.get(this.props, 'selectedIdeaDetail.idea_id')) ? "leftbar-list-item active" : "leftbar-list-item"
                                return (
                                    <li className={listItemClassName} key={key} onClick={() => { this.getIdea(idea.idea_id) }}>
                                        <div className="leftbar-list-item-inner">
                                            <div className="user-img">
                                                <div className="user-img-wrapper">
                                                    {
                                                        (idea.user_image === '') ? <img src="images/noimage.jpeg" alt="" /> :
                                                            <img src={idea.user_image} alt="" />
                                                    }
                                                </div>
                                            </div>
                                            <div className="user-data added-icons">
                                                <div className="user-name">
                                                    <span>{idea.user_name}</span>

                                                </div>
                                                <div className="user-data-inner">
                                                    <div className="data-message">
                                                        <div className="data-text">{idea.text}</div>
                                                    </div>
                                                    <div className="date-wrapper">
                                                        <div className="user-date-inner">
                                                            <span className="date">{helper.getFormateDate(idea.last_updated_date)}</span>
                                                            <span className="material-icons" onClick={() => { this.getIdea(idea.idea_id) }}>keyboard_arrow_right</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                )
                            })
                        }
                        {
                            (!this.state.isLoading && allIdeaData && allIdeaData.length === 0) ?
                                <div className="empty-no-data">
                                    <img src="images/img-share-idea.svg" alt="no data found" />
                                    <h6>Try Another Filter</h6>
                                    <span>We did not find any records matching with you filter.Try something different!</span>
                                </div> : null
                        }
                        {this.state.isLoading && <div className={'loader-block'}>
                            <Spinner />
                        </div>}
                    </PerfectScrollbar>
                </ul>

            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        userData: state.getUserData,
        allIdeaData: state.allIdeaData,
    }
}
export default withRouter(connect(mapStateToProps, { callApi, setIdeaData })(Leftbar))