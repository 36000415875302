import React, { Component } from 'react';
import { Button, Modal, Nav, Dropdown } from 'react-bootstrap';
import { connect } from 'react-redux';
import { callApi, setKnowledgeBankToReadData } from '../../../store/reducers/commonFunction';
import Select from 'react-select';

let _this = null;
class _FilterComponent extends Component {

    /**
    |--------------------------------------------------
    | constructor
    |--------------------------------------------------
    */

    constructor(props) {
        super(props)
        _this = this;
        this.state = {
            filterArr: [],
            tagVal: [],
            show: false,
            visibles: false,
            filterCategory: [
                {
                    id: 'most_liked', title: 'Most Liked'
                },
                {
                    id: 'this_month', title: 'This Month'
                },
                {
                    id: 'last_month', title: 'Last Month'
                },
                {
                    id: 'most_recent', title: 'Most Recent'
                },
                {
                    id: 'by_me', title: 'By Me'
                },
            ]
        }
    }

    /**
    |--------------------------------------------------
    | componentDidMount
    |--------------------------------------------------
    */

    componentDidMount() {
    }

    /**
    |--------------------------------------------------
    | closeMenu
    |--------------------------------------------------
    */

    closeMenu = () => {
        var x = document.getElementsByClassName("checkbox");
        for (let i = 0; i <= x.length; i++) {
            if (x[i]) {
                x[i].checked = false;
            }
        }
        this.setState({
            filterArr: [],
            tagVal: []
        }, () => {
            this.onPressFilter()
        })

    }

    /**
    |--------------------------------------------------
    | tagValue
    |--------------------------------------------------
    */

    tagValue = async (e) => {
        this.setState({
            tagVal: e
        })
    }

    /**
    |--------------------------------------------------
    | filterCheckbox
    |--------------------------------------------------
    */

    filterCheckbox = async (e) => {
        // let filterArr = this.state.filterArr;
        // if (document.getElementById(e.target.value).checked) {
        //     filterArr.push(e.target.value);
        // } else {
        //     filterArr.splice(filterArr.indexOf(e.target.value), 1);
        // }
        // this.setState({ filterArr })
        let filterArr = this.state.filterArr;
        this.state.filterCategory.map((filterItem, index) => {

            if (e.target.value != filterItem.id) {
                document.getElementById(filterItem.id).checked = false
            } else {
                filterArr.push(e.target.value);
            }
        })
        if (document.getElementById(e.target.value).checked) {
            // filterArr.push(e.target.value);
            filterArr = [e.target.value];
        } else {
            filterArr = [];
        }
        this.setState({ filterArr })
    }

    /**
    |--------------------------------------------------
    | dropdownToggle
    |--------------------------------------------------
    */

    dropdownToggle = () => {
        this.setState({ show: !this.state.show });
    }

    /**
    |--------------------------------------------------
    | onPressFilter
    |--------------------------------------------------
    */

    onPressFilter = () => {

        const { tagVal, filterArr } = this.state;
        this.setState({ show: !this.state.show, visibles: ((tagVal && tagVal.length > 0) || (filterArr && filterArr.length > 0)) ? true : false });
        this.props.onPressFilter()
    }

    /**
    |--------------------------------------------------
    | getFilterValue
    |--------------------------------------------------
    */

    static getFilterValue = () => {
        console.log('filterArr-->', JSON.stringify(_this.state.filterArr));
        console.log('tagVal-->', JSON.stringify(_this.state.tagVal));
        return {
            filterArr: _this.state.filterArr.toString(),
            tagVal: _this.state.tagVal.map(x => x.value).toString()
        }
    }

    /**
    |--------------------------------------------------
    | render
    |--------------------------------------------------
    */

    render() {
        const { filterCategory, tagVal, filterArr } = this.state;
        return (
            <Dropdown alignRight className="d-inline-block filter-dropdown" show={this.state.show} onToggle={this.dropdownToggle}>
                <Dropdown.Toggle id="dropdown-basic" className="filter-btn">
                    <span className="material-icons">filter_list</span>
                    {
                        this.state.visibles ?
                            <span className="red-dot bg-danger position-absolute rounded-circle"></span>
                            : null
                    }
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Select
                        isMulti
                        name="addTag"
                        options={this.props.tagsList}
                        className="common-select"
                        classNamePrefix="form-control"
                        placeholder={"Type to add tag"}
                        value={this.state.tagVal ? this.state.tagVal : ''}
                        onChange={(e) => this.tagValue(e)}
                    />
                    <Dropdown.Header className="px-0">Category</Dropdown.Header>
                    {filterCategory.map((filterItem, index) => {
                        return (
                            <div className="d-block" key={index}>
                                <div className="checkbox-radio">
                                    <label>
                                        <input className="checkbox" type="checkbox" value={filterItem.id} id={filterItem.id} onChange={(e) => this.filterCheckbox(e)} />
                                        <span>
                                            <span className="checkbox-style"></span>
                                            <span className="text tnc-check">{filterItem.title}</span>
                                        </span>
                                    </label>
                                </div>
                            </div>
                        )
                    })}
                    {/* <Dropdown.Header className="px-0">Sort Order</Dropdown.Header> */}
                    {/* <div className="d-block">
                        <div className="checkbox-radio">
                            <label>
                                <input type="checkbox" value="" defaultChecked />
                                <span>
                                    <span className="checkbox-style"></span>
                                    <span className="text tnc-check">Ascending
                                        </span>
                                </span>
                            </label>
                        </div>
                    </div>
                    <div className="d-block">
                        <div className="checkbox-radio">
                            <label>
                                <input type="checkbox" value="" />
                                <span>
                                    <span className="checkbox-style"></span>
                                    <span className="text tnc-check">Descending
                                        </span>
                                </span>
                            </label>
                        </div>
                    </div> */}
                    <div className="d-flex justify-content-between align-items-center py-2">
                        <Button disabled={(tagVal && tagVal.length > 0) || (filterArr && filterArr.length > 0) ? false : true} className="p-2" onClick={this.onPressFilter}>Filter</Button>
                        <Button className="p-2" variant="outline-primary" onClick={this.closeMenu}>clear all</Button>
                    </div>
                </Dropdown.Menu>
            </Dropdown>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        userData: state.getUserData,
    }
}
export default connect(mapStateToProps, { callApi, setKnowledgeBankToReadData })(_FilterComponent)
