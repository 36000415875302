export default class UserService {

    constructor(config, axios) {
        this.config = config;
        this.axios = axios;
        this.apiUrl = config.apiUrl;
        this.authServerUrl = config.authServerUrl;
        

    }

    async attempt(data) {
        const response = await this.axios.post(`${this.authServerUrl}/oauth/token`, data)
        if (response.status !== 200) throw response;
        return response.data;
    }

    async register(data) {
        const response = await this.axios.post(`${this.apiUrl}/register`, data)
        if (response.status !== 201) throw response;
        return response.data.success;
    }

    async profile() {
        const response = await this.axios.get(`${this.apiUrl}/login`);
        if (response.status !== 200) throw response;
        return response.data.success.data;
    }
}