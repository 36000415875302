import React, { Component } from "react";
import { Provider } from "react-redux";
import { store, persistor } from "./store";
import Login from "./views/Login";
import LoginEmail from "./views/forms/login/LoginEmail";
import Register from "./views/Register";
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import dI from "./services/DI/boot";
import Master from "./views/layouts/Master";
import "./App.css";
import "../src/assets/scss/style.scss";
import "react-perfect-scrollbar/dist/css/styles.css";

import CompanyRegistration from "./views/ComapnyRegistration/companyRegistration";
import EmailVerification from "./views/ComapnyRegistration/emailVerification";
import EmailVerified from "./views/ComapnyRegistration/emailVerified";
import SetPasscode from "./views/ComapnyRegistration/setPasscode";
// import confirmPassword from './views/ComapnyRegistration/confirmPassword';
import ConfirmPassword from "./views/ComapnyRegistration/confirmPassword";

import CompleteProfile from "./views/ComapnyRegistration/completeProfile";

import Feedback from "./views/feedback/feedback";
import NewFeedback from "./views/feedback/newFeedback";

import IdeaSharing from "./views/ideaSharing/ideaSharing";
import NewIdea from "./views/ideaSharing/newIdea";

import Contacts from "./views/Contacts/contacts";
import AddEmployee from "./views/Contacts/addEmployee";

import Announcement from "./views/Announcement/announcement";

import KnowledgeBank from "./views/KnowledgeBank/knowledgeBank";
import KnowledgeBankLinks from "./views/KnowledgeBank/knowledgeBankLinks";
import KnowledgeBankPhotos from "./views/KnowledgeBank/knowledgeBankPhotos";
import KnowledgeBankCode from "./views/KnowledgeBank/knowledgeBankCode";

import Company from "./views/Company/company";

import FeedbackCategory from "./views/FeedbackCategory/feedbackCategory";

import Admin from "./views/Admin/admin";
import AddAdmin from "./views/Admin/addAdmin";

import Profile from "./views/Profile/profile";
import PrivacyPolicy from "./views/private/PrivacyPolicy";
import TermsCondition from "./views/private/TermsCondition";

require("dotenv").config();

class App extends Component {
  constructor(props) {
    super(props);
    this.dI = dI;
    if (process.env.REACT_APP_NODE_ENV === 'production') {
      console.log = function () { };
    }
  }
  // constructor(props) {
  //   super (props);
  //   this.state = {

  //   }
  // }
  Authorization = () => {
    return localStorage.getItem("accessToken") ? true : false;
  };

  render() {
    const PrivateRoute = ({ component: Component, ...rest }) => (
      <Route
        {...rest}
        render={(props) =>
          this.Authorization() ? <Component {...props} /> : <Redirect to="/" />
        }
      />
    );
    // const ResetPasswordCheckRoute = ({ component: Component, ...rest }) => (
    //   <Route {...rest} render={(props) => (
    //     this.Authorization()
    //       ? <Redirect to='/feedback' />
    //       : <Component {...props} />
    //   )} />
    // );

    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <div className="App">
              <Switch>
                <Route exact path="/">
                  <Master {...this.props} dI={this.dI}>
                    <Register {...this.props} dI={this.dI} />
                    {/* <Login {...this.props} dI={this.dI} /> */}
                    {/* <Home {...this.props} dI={this.dI} /> */}
                  </Master>
                </Route>

                <Route exact path="/login">
                  <Master {...this.props} dI={this.dI}>
                    <Login {...this.props} dI={this.dI} />
                  </Master>
                </Route>

                <Route exact path="/forgot-password">
                  <Master {...this.props} dI={this.dI}>
                    <LoginEmail {...this.props} dI={this.dI} />
                  </Master>
                </Route>

                <Route exact path="/register">
                  <Master {...this.props} dI={this.dI}>
                    <Register {...this.props} dI={this.dI} />
                  </Master>
                </Route>

                <Route exact path="/privacy_policy">
                  <Master {...this.props} dI={this.dI}>
                    <PrivacyPolicy {...this.props} dI={this.dI} />
                  </Master>
                </Route>

                <Route exact path="/terms_conditions">
                  <Master {...this.props} dI={this.dI}>
                    <TermsCondition {...this.props} dI={this.dI} />
                  </Master>
                </Route>
                <Route exact path="/companyRegistration">
                  <Master {...this.props} dI={this.dI}>
                    <CompanyRegistration {...this.props} dI={this.dI} />
                  </Master>
                </Route>

                <Route exact path="/emailVerification">
                  <Master {...this.props} dI={this.dI}>
                    <EmailVerification
                      {...this.props}
                      dI={this.dI}
                      getPropsData={this.props}
                    />
                  </Master>
                </Route>

                <Route exact path="/emailVerified">
                  <Master {...this.props} dI={this.dI}>
                    <EmailVerified {...this.props} dI={this.dI} />
                  </Master>
                </Route>

                <Route exact path="/setPasscode">
                  <Master {...this.props} dI={this.dI}>
                    <SetPasscode {...this.props} dI={this.dI} />
                  </Master>
                </Route>

                <Route exact path="/confirmPassword">
                  <Master {...this.props} dI={this.dI}>
                    <ConfirmPassword {...this.props} dI={this.dI} />
                  </Master>
                </Route>

                <PrivateRoute exact path="/completeProfile">
                  <Master {...this.props} dI={this.dI}>
                    <CompleteProfile {...this.props} dI={this.dI} />
                  </Master>
                </PrivateRoute>

                <PrivateRoute exact path="/feedback">
                  <Master {...this.props} dI={this.dI}>
                    <Feedback {...this.props} dI={this.dI} />
                  </Master>
                </PrivateRoute>

                <PrivateRoute exact path="/newFeedback">
                  <Master {...this.props} dI={this.dI}>
                    <NewFeedback {...this.props} dI={this.dI} />
                  </Master>
                </PrivateRoute>

                <PrivateRoute exact path="/ideaSharing">
                  <Master {...this.props} dI={this.dI}>
                    <IdeaSharing {...this.props} dI={this.dI} />
                  </Master>
                </PrivateRoute>

                <PrivateRoute exact path="/newIdea">
                  <Master {...this.props} dI={this.dI}>
                    <NewIdea {...this.props} dI={this.dI} />
                  </Master>
                </PrivateRoute>

                <PrivateRoute exact path="/contacts">
                  <Master {...this.props} dI={this.dI}>
                    <Contacts {...this.props} dI={this.dI} />
                  </Master>
                </PrivateRoute>

                <PrivateRoute exact path="/addEmployee">
                  <Master {...this.props} dI={this.dI}>
                    <AddEmployee {...this.props} dI={this.dI} />
                  </Master>
                </PrivateRoute>

                <PrivateRoute exact path="/announcement">
                  <Master {...this.props} dI={this.dI}>
                    <Announcement {...this.props} dI={this.dI} />
                  </Master>
                </PrivateRoute>

                <PrivateRoute exact path="/knowledgeBank">
                  <Master {...this.props} dI={this.dI}>
                    <KnowledgeBank {...this.props} dI={this.dI} />
                  </Master>
                </PrivateRoute>

                {/* <PrivateRoute exact path="/knowledgeBankLinks">
                  <Master {...this.props} dI={this.dI}>
                    <KnowledgeBankLinks {...this.props} dI={this.dI} />
                  </Master>
                </PrivateRoute> */}

                <PrivateRoute exact path="/knowledgeBankPhotos">
                  <Master {...this.props} dI={this.dI}>
                    <KnowledgeBankPhotos {...this.props} dI={this.dI} />
                  </Master>
                </PrivateRoute>

                <PrivateRoute exact path="/knowledgeBankCode">
                  <Master {...this.props} dI={this.dI}>
                    <KnowledgeBankCode {...this.props} dI={this.dI} />
                  </Master>
                </PrivateRoute>

                <PrivateRoute exact path="/company">
                  <Master {...this.props} dI={this.dI}>
                    <Company {...this.props} dI={this.dI} />
                  </Master>
                </PrivateRoute>

                <PrivateRoute exact path="/feedbackCategory">
                  <Master {...this.props} dI={this.dI}>
                    <FeedbackCategory {...this.props} dI={this.dI} />
                  </Master>
                </PrivateRoute>

                <PrivateRoute exact path="/admin">
                  <Master {...this.props} dI={this.dI}>
                    <Admin {...this.props} dI={this.dI} />
                  </Master>
                </PrivateRoute>

                <PrivateRoute exact path="/addAdmin">
                  <Master {...this.props} dI={this.dI}>
                    <AddAdmin {...this.props} dI={this.dI} />
                  </Master>
                </PrivateRoute>

                <PrivateRoute exact path="/profile">
                  <Master {...this.props} dI={this.dI}>
                    <Profile {...this.props} dI={this.dI} />
                  </Master>
                </PrivateRoute>
              </Switch>
            </div>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    );
  }
}

export default App;
