import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import {  } from 'react-bootstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { connect } from 'react-redux';
import { callApi, setCategoryColor } from '../../../store/reducers/commonFunction'
class Leftbar extends Component {

    /**
    |--------------------------------------------------
    | constructor
    |--------------------------------------------------
    */

    constructor(props) {
        super(props);
        this.state = {
            leftbar: false,
        }
    }

    /**
    |--------------------------------------------------
    | LeftbarToggle
    |--------------------------------------------------
    */

    LeftbarToggle = () => {
        this.setState({ leftbar: !this.state.leftbar },
            function () {
                document.body.classList.remove('leftbar-open');
            });
    }

    /**
    |--------------------------------------------------
    | componentDidMount
    |--------------------------------------------------
    */

    async componentDidMount() {
        this.props.onRef && this.props.onRef(this)
        await this.getAllCategories();
    }

    /**
    |--------------------------------------------------
    | componentWillUnmount
    |--------------------------------------------------
    */

    componentWillUnmount() {
        this.props.onRef && this.props.onRef(undefined)
    }

    /**
    |--------------------------------------------------
    | getAllCategories
    |--------------------------------------------------
    */

    getAllCategories = async (displayCategoryId = undefined) => {

        let body = {}
        const response = await this.props.callApi('/getCategory', body, 'post', 'ADMIN_LOGIN', true);

        console.log('getCategory response-->', JSON.stringify(response));
        if (response) {

            this.props.setCategoryColor(response.data || []);
            // this.props.setCategoryList(response);
            if (displayCategoryId) {
                let selectedCategoryIndex = response.data && response.data.findIndex(x => x.id == displayCategoryId);
                this.props.getChildEvent(response.data[selectedCategoryIndex]);
            } else {
                this.props.getChildEvent(response.data[0]);
            }
        }
    }

    /**
    |--------------------------------------------------
    | categoryDetails
    |--------------------------------------------------
    */

    categoryDetails = (categories) => {
        this.props.getChildEvent(categories);
    }

    /**
    |--------------------------------------------------
    | render
    |--------------------------------------------------
    */

    render() {
        var { categoryColor } = this.props;
        return (
            <div className="leftbar feedback-category">

                <span className="leftbar-toggler" onClick={this.LeftbarToggle}>
                    <span className="material-icons">arrow_back</span>
                </span>

                <Link onClick={() => this.props.onPressAddEditCategoryModal()} className="btn plus-btn">+</Link>

                <div className="contacts-leftbar-header">
                    <h6>Suggestion Categories</h6>
                    <span className="heading-detail">Manage your company employees, categories, designations & admins</span>
                </div>

                <ul className="leftbar-list">
                    <PerfectScrollbar>
                        {
                            categoryColor && Array.isArray(categoryColor) && categoryColor.map((category, key) => {
                                let listItemClassName = this.props.selectedCategory.id === category.id ? "leftbar-list-item active" : "leftbar-list-item"
                                return (
                                    <li className={listItemClassName} key={key} onClick={() => this.categoryDetails(category)}>
                                        <div className="leftbar-list-item-inner">
                                            <div className="user-img">
                                                {/* <div className="select-color"><span className="color-dot yellow"></span><span className="color-name">yellow</span></div>  */}
                                                {/* <div className="color-block"  style = {{color:"#" + category.color_code }}>{category.color_code}</div> */}
                                            </div>
                                            <div className="user-data">
                                                <div className="user-name">{category.name}</div>
                                            </div>
                                            <div className="date-wrapper">
                                                <div className="user-date-inner">
                                                    <span className="material-icons" onClick={() => this.categoryDetails(category)}>keyboard_arrow_right</span>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                )
                            })
                        }

                    </PerfectScrollbar>
                </ul>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        categoryColor: state.categoryColor,
    }
}
export default connect(mapStateToProps, { callApi, setCategoryColor })(Leftbar)
