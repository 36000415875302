import axios from 'axios';
import * as moment from 'moment';

export default class Token {
    static get() {
        return window.localStorage.getItem('access_token');
    }

    static refresh(url) {
        return new Promise((resolve, reject) => {
            axios.post(url).then((response) => {
                Token.store(response.data);
                return resolve(response.data);
            }).catch(err => reject(err));
        });
    }

    static store(data) {
        console.log('store data-->', JSON.stringify(data));
        const expiresAt = moment().add(data.expires_in, 'seconds');
        const expiresAtFormat = 'YYYY-MM-DD HH:mm:ss';

        window.localStorage.setItem('access_token', data.token);
        // window.localStorage.setItem('expires_in', data.expires_in);
        window.localStorage.setItem('expires_in', expiresAt.format(expiresAtFormat));
        window.localStorage.setItem('expires_at', expiresAt.format(expiresAtFormat));
        window.localStorage.setItem('expires_at_format', expiresAtFormat);
    }

    static expired() {
        const expired = false;
        const accessToken = window.localStorage.getItem('access_token');
        if (!accessToken) return expired;

        const expiresAt = window.localStorage.getItem('expires_at');
        const expiresAtFormat = window.localStorage.getItem('expires_at_format');
        const expiresAtDate = moment(expiresAt, expiresAtFormat);
        return (!expiresAtDate.isValid() || !expiresAtDate.isAfter(moment()));
    }

    static destroy() {
        window.localStorage.removeItem('access_token');
        window.localStorage.removeItem('expires_in');
        window.localStorage.removeItem('expires_at');
        window.localStorage.removeItem('expires_at_format');
    }
}