// import { createStore } from 'redux';
import commonReducer from "./reducers/common";
// export default createStore(commonReducer);
import { compose,applyMiddleware,createStore} from 'redux';
import thunk from 'redux-thunk';
// to store data in redux //
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' 
// import reducers from '../reducers';
// import common from '../reducers'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;


const persistConfig = {
  key: 'root',
  blacklist: ['allFeedbackData','sentFeedbackData','receivedFeedbackData',
  'allIdeaData','knowledgeBankToReadData','knowledgeBankLinkData',
  'knowledgeBankPhotosData','knowledgeBankCodeData','timelineData','announcementData'],
  storage,
}

// const store =createStore (
//   reducers,
//   composeEnhancers(
//     applyMiddleware(thunk),
//   )
// )
// export default store;

const persistedReducer = persistReducer(persistConfig, commonReducer)

export const store = createStore(
   persistedReducer,
   composeEnhancers(
     applyMiddleware(thunk)
   )
);

export const persistor = persistStore(store)

// import { combineReducers }  from 'redux';
// import reducer from './reducer'
// export default combineReducers({
//   user:reducer
// })