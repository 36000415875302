import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { callApi, setAdminData } from '../../../store/reducers/commonFunction'
import { connect } from 'react-redux';
import _ from 'lodash';
import { Spinner } from 'react-activity';
import 'react-activity/dist/react-activity.css';
import appConstant from '../../../constant/appConstant';

class Leftbar extends Component {

    /**
    |--------------------------------------------------
    | constructor
    |--------------------------------------------------
    */

    constructor(props) {
        super(props);
        this.state = {
            leftbar: false,
            adminList: [],
            isLoading: false
        }
    }

    /**
    |--------------------------------------------------
    | componentDidMount
    |--------------------------------------------------
    */

    async componentDidMount() {
        this.props.onRef && this.props.onRef(this)
        await this.getAllAdminDetails()
    }

    /**
    |--------------------------------------------------
    | componentWillUnmount
    |--------------------------------------------------
    */

    componentWillUnmount() {
        this.props.onRef && this.props.onRef(undefined)
    }

    /**
    |--------------------------------------------------
    | getAllAdminDetails
    |--------------------------------------------------
    */

    getAllAdminDetails = async (user_id, isLoading = true) => {

        let body = {}
        this.setState({
            isLoading: isLoading
        })
        const response = await this.props.callApi('/getEmployee', body, 'post', null, true);
        if (response) {
            console.log('getEmployee response-->', JSON.stringify(response.data));
            let filterAdminData = response.data && response.data.filter(x => x.role == appConstant.ROLE.COMPANY_ADMIN || x.role == appConstant.ROLE.ADMIN || x.role == appConstant.ROLE.CATEGORY_ADMIN);

            let finalAdminData = [];
            filterAdminData.map(item => {
                if (item.role == appConstant.ROLE.COMPANY_ADMIN) {
                    finalAdminData.unshift(item)
                } else {
                    finalAdminData.push(item)
                }
            })

            this.props.setAdminData(finalAdminData)

            if (user_id) {
                let selectedUser = finalAdminData && finalAdminData.find(x => x.user_id == user_id);
                this.props.getChildEvent(selectedUser);
            } else {
                this.props.getChildEvent(finalAdminData[0]);
            }
            this.setState({
                adminList: finalAdminData,
                isLoading: false
            })
        } else {
            this.setState({
                isLoading: false
            })
        }

    }

    /**
    |--------------------------------------------------
    | getAdminDetails
    |--------------------------------------------------
    */

    getAdminDetails = (admin) => {
        if (window.location.pathname !== '/admin') {
            this.props.history.push({ pathname: '/admin', adminId: admin })
        }
        if (this.props && this.props.getChildEvent) {
            this.props.getChildEvent(admin);
        }
        // this.props.getChildEvent(admin);
    }

    /**
    |--------------------------------------------------
    | LeftbarToggle
    |--------------------------------------------------
    */

    LeftbarToggle = () => {
        this.setState({ leftbar: !this.state.leftbar },
            function () {
                document.body.classList.remove('leftbar-open');
            });
    }

    /**
    |--------------------------------------------------
    | render
    |--------------------------------------------------
    */

    render() {
        var { adminList } = this.state;
        const { userData } = this.props;
        let role = _.get(userData, 'role') || '';
        return (
            <div className="leftbar">

                <span className="leftbar-toggler" onClick={this.LeftbarToggle}>
                    <span className="material-icons">arrow_back</span>
                </span>
                { (role === appConstant.ROLE.COMPANY_ADMIN || role === appConstant.ROLE.ADMIN) &&
                    <Link onClick={() => this.props.openAddAdminModal()} className="btn plus-btn">+</Link>
                }
                <div className="contacts-leftbar-header">
                    <h6>Admins</h6>
                    <span className="heading-detail">Manage your company employees, categories, designations & admins</span>
                </div>
                <ul className="leftbar-list">
                    <PerfectScrollbar>
                        {
                            adminList && Array.isArray(adminList) && adminList.map((admin, key) => {
                                let itemListClassName = _.get(this.props, 'selectedUser.user_id') == admin.user_id ? 'leftbar-list-item active' : 'leftbar-list-item'
                                let userNameClassName = _.get(userData, 'user_id') === admin.user_id ? "user-name active" : "user-name"
                                return (
                                    <li className={itemListClassName} key={key} onClick={() => this.getAdminDetails(admin)}>
                                        <div className="leftbar-list-item-inner">
                                            <div className="user-img">
                                                <div className="user-img-wrapper">
                                                    {
                                                        (admin.image === '') ? <img src="images/noimage.jpeg" alt="" /> :
                                                            <img src={admin.image} alt="" width="40" height="40" />
                                                    }
                                                </div>
                                            </div>
                                            <div className="user-data">
                                                <div className={userNameClassName}>{admin.first_name} {admin.last_name}</div>
                                                <div className="data-text text-capitalize">{admin.designation}</div>
                                                <div className="data-text">{admin.email}</div>
                                            </div>
                                            <div className="date-wrapper">
                                                <div className="user-date-inner">
                                                    <span className="material-icons" onClick={() => this.getAdminDetails(admin)}>keyboard_arrow_right</span>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                )
                            })
                        }
                        {this.state.isLoading && <div className={'loader-block'}>
                            <Spinner />
                        </div>}

                    </PerfectScrollbar>
                </ul>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        userData: state.getUserData,
    }
}

export default connect(mapStateToProps, { callApi, setAdminData })(Leftbar)