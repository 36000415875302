
// import { I18nManager } from 'react-native';
// import { browserName } from 'react-device-detect';
module.exports = {
  ROLE: {
    COMPANY_ADMIN: 'companyadmin',
    ADMIN: 'admin',
    CATEGORY_ADMIN: 'categoryadmin',
    EMPLOYEE: 'employee'
  },
  PLATFORM: 'web',
  APP_VERSION: '1.0.0',
  TYPE: {
    TEXT: 'text',
    LINK: 'link',
    CODE: 'code',
    FILE: 'file'
  }
}
