import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Animated } from "react-animated-css";
import { callApi } from '../../store/reducers/commonFunction'
import { withRouter } from 'react-router';
import common from '../../store/reducers/commons';

class EmailVerification extends Component {

    /**
    |--------------------------------------------------
    | constructor
    |--------------------------------------------------
    */

    constructor(props) {
        super(props)
        this.state = {
            registerDetail: [],
            errors: {
                name: '',
            },
            isFormValid: true
        }
    }

    /**
    |--------------------------------------------------
    | componentDidMount
    |--------------------------------------------------
    */

    async componentDidMount() {
    }

    /**
    |--------------------------------------------------
    | verifyEmail
    |--------------------------------------------------
    */

    verifyEmail = async () => {
        try {
            let contact_email = this.props.registerData.data.contact_email ? this.props.registerData.data.contact_email : '';
            let body = {
                email: contact_email
            };
            const response = await this.props.callApi('/loginEmailPassword', body, 'post', 'PROVIDE_EMAIL');
            //  if (response) {
            //   console.log("123",response.data.message)
            //  common.showSuccessMessage(response.data.message)
            //  }
            if (response) {
                common.showSuccessMessage(response.data.message)
                common.setItem("passwordToken", response.data.token)
            }

        } catch (error) {
            throw (error)
        }
    }

    /**
    |--------------------------------------------------
    | render
    |--------------------------------------------------
    */

    render() {
        console.log("@@@", this.props.registerData)
        let contact_email = this.props.registerData.data.contact_email ? this.props.registerData.data.contact_email : '';
        return (
            <div className="login-register-wrapper email-varification-page">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-4 col-lg-6 ml-auto d-lg-block d-none">
                            <div className="verify-email-img-block">
                                <img src="images/verify-email-bgi-img.svg" alt="" />
                                <div className="verify-email-letter-blue-img">
                                    <Animated animationIn="fadeInDown" animationOut="fadeOut" isVisible={true} animationInDelay={800}>
                                        <img src="images/verify-email-letter-blue-img.svg" alt="" />
                                    </Animated>
                                </div>
                                <div className="verify-email-letter-white-img">
                                    <Animated animationIn="fadeInDown" animationOut="fadeOut" isVisible={true} animationInDelay={1500}>
                                        <img src="images/verify-email-letter-white-img.svg" alt="" />
                                    </Animated>
                                </div>
                                <div className="verify-email-letterbox-front-img">
                                    <img src="images/verify-email-letterbox-front-img.svg" alt="" />
                                </div>
                                <div className="verify-email-plant-img">
                                    <img src="images/verify-email-plant-img.svg" alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 mr-auto">
                            <h4 className="mb-2">Verify Email Address</h4>
                            <p className="para">To use this app, you need to verify that {contact_email}.io is your e-mail address.</p>
                            <p className="para">We’ve sent one verification mail on your given address, check your company mailbox for verification mail and follow instructions.</p>

                            <p className="no-mail-received">Didn’t receieve verification mail?</p>
                            {/* <Button >send again</Button> */}
                            <button className='btn btn-primary text-uppercase ml-3' type='submit' onClick={this.verifyEmail}> send again </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        registerData: state.allRegisterData
    }
}
export default withRouter(connect(mapStateToProps, { callApi })(EmailVerification))