import common from "../../store/reducers/commons";
import axios from "axios";
import { apiUrl } from "../../config";
import { browserName, osVersion } from 'react-device-detect';
import appConstant from "../../constant/appConstant";
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error && error.response && 401 === error.response.status) {
      common.showErrorMessage("Token Expired");

      localStorage.removeItem("accessToken", "");
      window.location.href = "/";
      // } else if(error && error.response.data.error.code === 422){
      //   common.showErrorMessage(error.response.data.error.message);
    } else {
      // console.log("checking",response)
      return Promise.reject(error);
    }
  }
);

/*******************************************************************************************
     @PURPOSE      	: 	Call api.
     @Parameters 	: 	{
       url : <url of api>
       data : <data object (JSON)>
       method : String (get, post)
       isForm (Optional) : Boolean - to call api with form data
       isPublic (Optional) : Boolean - to call api without auth header
     }
/*****************************************************************************************/
export const callApi = (
  url,
  data,
  method,
  Type = null,
  isAuthorized = false,
  isForm = false
) => (dispatch) => {
  
  url = apiUrl + url;
  let headers = { "Content-Type": "application/json" };
  var token = common.getItem("accessToken");
  if (isAuthorized) headers.Authorization = "Bearer " + token;
  if (isForm) headers["Content-Type"] = "multipart/form-data";
  // Api calling

  let newData = data;
  
  if(data instanceof FormData){
    newData.append('device', browserName)
    newData.append('platform', appConstant.PLATFORM)
    newData.append('appVersion', appConstant.APP_VERSION)
    newData.append('osVersion', osVersion)
    newData.append('timestamp', Math.round(new Date().getTime() / 1000))
  }else{
    newData = Object.assign(data, {
      timestamp: Math.round(new Date().getTime() / 1000),
      platform: appConstant.PLATFORM,
      appVersion: appConstant.APP_VERSION,
      device: browserName,
      osVersion: osVersion,
    });
  }
  // console.log('osVersion-->',osVersion);
  // let newData = Object.assign(data, {
  //   timestamp: new Date().getTime().toString(),
  //   platform: "web",
  //   appVersion: "1.0.0",
  //   device: "chrome",
  //   osVersion: "10.14.6",
  // });
  // console.log("body-->", JSON.stringify(newDta));
  // console.log("body-->", typeof newDta, newDta, typeof data, data);

  return new Promise((resolve, reject) => {
    axios({ method, url, headers, data : newData })
      .then((response) => {
        let { data } = response;
        if (
          data.data.status === true ||
          data.data.code === 200 ||
          data.data !== undefined
        ) {
          if (Type !== null) {
            var payload = { data: data.data };
            dispatch({ type: Type, payload });
          }
          // typeof (data.data.message ? data.data.message : 'success') === "string" ? common.showSuccessMessage(data.data.message ? data.data.message : 'success') : common.showWarningMessage("Something went wrong! ")
        } else {
          typeof (data.data.message ? data.data.message : "error") === "string"
            ? common.showErrorMessage(
                data.data.message ? data.data.message : "error"
              )
            : common.showWarningMessage("Something went wrong! ");
        }
        resolve(data);
      })
      .catch((error) => {
        // common.showErrorMessage(
        //   error.response.data && error.response.data.error.message
        // );
        error && error.response && error.response.data && error.response.data.error && error.response.data.error.message && common.showWarningMessage(error.response.data.error.message);
        console.log("error in catch ", error);
        reject(error);
      });
  });
};

//**************************** USER DATA ***********************************/

export const registerDetails = (id) => async (dispatch) => {
  dispatch({ type: "REGISTER_DATA", payload: id });
};

export const categoryDetails = (id) => async (dispatch) => {
  dispatch({ type: "CATEGORY_DATA", payload: id });
};

//**************************** Common Timefunction ****************************/
export const timeConvert = (time) => {
  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ];

  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(""); // return adjusted time or original string
};
export const getEndTime = (time, min) => {
  var d = new Date();
  var a = time.split(":");
  d.setHours(parseInt(a[0]), parseInt(a[1]) + 120, 0);

  var newTime = d.toLocaleTimeString();
  newTime = newTime
    .toString()
    .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [newTime];

  if (newTime.length > 1) {
    // If time format correct
    newTime = newTime.slice(1); // Remove full string match value
    newTime[5] = +newTime[0] < 12 ? "AM" : "PM"; // Set AM/PM
    newTime[0] = +newTime[0] % 12 || 12; // Adjust hours
  }
  return newTime.join(""); // return adjusted time or original string
};

export const setUserData = (data) => async (dispatch) => {
  dispatch({ type: "GET_USER_DATA", payload: data });
};

export const setRegisterData = (data) => async (dispatch) => {
  console.log("###", data);
  dispatch({ type: "GET_REGISTER_DATA", payload: data });
};
export const setCategoryColor = (data) => async (dispatch) => {
  dispatch({ type: "CATEGORY_COLOR", payload: data });
};

export const setFeedback = (data) => async (dispatch) => {
  dispatch({ type: "FEEDBACK_LIST", payload: data });
};

export const setAdminData = (data) => async (dispatch) => {
  dispatch({ type: "ADMIN_DATA", payload: data });
};
export const setCategoryList = (data) => async (dispatch) => {
  dispatch({ type: "CATEGORY_LIST", payload: data });
};
export const getAdminDetails = (data) => async (dispatch) => {
  dispatch({ type: "ADMIN_LIST", payload: data });
};

export const setAllFeedBackData = (id) => async (dispatch) => {
  dispatch({ type: "ALL_FEEDBACK_DATA", payload: id });
};

export const setSentFeedBackData = (id) => async (dispatch) => {
  dispatch({ type: "SENT_FEEDBACK_DATA", payload: id });
};

export const setReceivedFeedBackData = (id) => async (dispatch) => {
  dispatch({ type: "RECEIVED_FEEDBACK_DATA", payload: id });
};

export const setIdeaData = (id) => async (dispatch) => {
  dispatch({ type: "ALL_IDEA_DATA", payload: id });
};

export const allCategoryDetails = (data) => async (dispatch) => {
  dispatch({ type: "ALL_CATEGORY_DETAILS", payload: data });
};

export const setEmployeeDetails = (data) => async (dispatch) => {
  dispatch({ type: "ALL_EMPLOYEE_DETAILS", payload: data });
};

export const setKnowledgeBankToReadData = (data) => async (dispatch) => {
  dispatch({ type: "KNOWLEDGE_BANK_TO_READ_DATA", payload: data });
};

export const setKnowledgeBankLinkData = (data) => async (dispatch) => {
  dispatch({ type: "KNOWLEDGE_BANK_LINK_DATA", payload: data });
};

export const setKnowledgeBankPhotosData = (data) => async (dispatch) => {
  dispatch({ type: "KNOWLEDGE_BANK_PHOTOS_DATA", payload: data });
};

export const setKnowledgeBankCodeData = (data) => async (dispatch) => {
  dispatch({ type: "KNOWLEDGE_BANK_CODE_DATA", payload: data });
};

export const setTimelineData = (data) => async (dispatch) => {
  dispatch({ type: "TIMELINE_DATA", payload: data });
};

export const setAnnouncementData = (data) => async (dispatch) => {
  dispatch({ type: "ANNOUNCEMENT_DATA", payload: data });
};

export const setProfileInformation = (data) => async (dispatch) => {
  dispatch({ type: "PROFILE_INFO", payload: data });
};
