import NavBar from './partials/Navbar';
import { Link } from 'react-router-dom';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { STORE_USER, LOGOUT_USER } from '../../store/reducers/actionTypes';
import { callApi } from '../../store/reducers/commonFunction'
import { Button, Accordion } from "react-bootstrap";
import appConstant from '../../constant/appConstant';
import Token from '../../services/Token';
import _ from 'lodash';
class Master extends Component {

    constructor(props) {
        super(props);
        this.state = {
            profileFetched: false,
            WebNavigationToggle: false,
        }
        if (!_.get(props, 'userData') && this.props.history.location.pathname !== '/setPasscode' && this.props.history.location.pathname !== '/forgot-password') {
            this.props.history.push('/register')
        }
    }

    WebNavigationToggle = () => {
        this.setState({ WebNavigationToggle: !this.state.WebNavigationToggle },
            function () {
                setTimeout(function () { document.body.classList.remove('modalview'); }, 500);
                setTimeout(function () { document.body.classList.remove('animate'); }, 100);
            });
    }

    componentDidMount() {
        console.log(this.props.match);
        this.profile();
    }

    logout = async () => {
        let body = {
            device_uuid: "dsaad432424",
        }
        const response = await this.props.callApi('/logout', body, 'post', "LOGOUT", true);
        if (response) {
            this.WebNavigationToggle()
            this.props.history.push('/register')
            Token.destroy();
            this.props.logout();
            localStorage.setItem('accessToken', '')

            // window.location.reload();
        }
    }

    profile() {
        this.userService = this.props.dI.make('USER_SERVICE');
        this.userService.profile().then(profile => {
            this.props.setAuthenticated(profile);
            this.setState({ profileFetched: true });
        }).catch(error => {
            this.setState({ profileFetched: true });
            console.error(error)
        });
    }

    render() {
        let role = _.get(this.props, 'userData.role') || '';

        return (
            <React.Fragment>
                <div className="perspective effect-rotateleft" id="perspective">
                    <div className="body-container">
                        <div className="wrapper">
                            <NavBar {...this.props} />
                            {this.state.profileFetched ? this.props.children : ''}
                        </div>
                    </div>

                    <nav className="outer-nav right vertical">
                        <Link to="#" className="close-menu" onClick={this.WebNavigationToggle}>
                            <i className="material-icons">close</i>
                        </Link>
                        <Link onClick={this.WebNavigationToggle} to="/profile" className="nav-childe-item">Profile</Link>
                        <Link onClick={this.WebNavigationToggle} to="/feedback" className="nav-childe-item">suggestion</Link>
                        <Link onClick={this.WebNavigationToggle} to="/announcement" className="nav-childe-item">broadcast</Link>
                        <Link onClick={this.WebNavigationToggle} to="/ideaSharing" className="nav-childe-item">idea</Link>
                        <Link onClick={this.WebNavigationToggle} to="/knowledgeBank" className="nav-childe-item">knowledge bank</Link>
                        <Link onClick={this.WebNavigationToggle} to="/contacts" className="nav-childe-item">contacts</Link>
                        {/* this.props.history.push('/ideaSharing')} */}
                        <div className="navigation-dropdown-menu nav-childe-item">
                            <Accordion>
                                {/* {(role == 'employee') ? <div></div> : */}
                                <span>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                        settings
                                </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="0">
                                        <ul>
                                            <Link to="/company" onClick={this.WebNavigationToggle} className="accordion-link">Company Information</Link>
                                            {/* {(role == 'categoryadmin') ? <div></div> : */}
                                            <span>
                                                {(role === appConstant.ROLE.ADMIN || role === appConstant.ROLE.COMPANY_ADMIN) && <Link to="/feedbackCategory" onClick={this.WebNavigationToggle} className="accordion-link">Categories</Link>}
                                                {role !== appConstant.ROLE.EMPLOYEE && <Link to="/admin" onClick={this.WebNavigationToggle} className="accordion-link">Administrator</Link>}
                                            </span>
                                            {/* } */}
                                        </ul>
                                    </Accordion.Collapse>
                                </span>
                                {/* } */}
                            </Accordion>
                        </div>
                        <Link onClick={this.logout} className="nav-childe-item" to="#">logout</Link>
                    </nav>
                </div>
            </React.Fragment>
        )
    }
}

const mapStatsToProps = (state) => {
    return {
        setAuthenticated: (profile) => state({ type: STORE_USER, payload: profile }),
        userData: state.getUserData
    };
};

const mapDispatchToProps = dispatch => {
    return {
        callApi: (props) => callApi(props),
        logout: () => dispatch({ type: LOGOUT_USER })
    };
};


export default withRouter(connect(mapStatsToProps, mapDispatchToProps)(Master));