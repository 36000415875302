/* eslint-disable react/jsx-pascal-case */
import React, { Component } from 'react';
import { Button, Modal, Nav, Dropdown } from 'react-bootstrap';
import { connect } from 'react-redux';
import { callApi, setKnowledgeBankToReadData, setKnowledgeBankLinkData, setKnowledgeBankCodeData, setKnowledgeBankPhotosData } from '../../../store/reducers/commonFunction';
import CreatableSelect from 'react-select/creatable';
import errorMessages from '../../../store/reducers/errorMessages';
import common from '../../../store/reducers/commons';
import swal from 'sweetalert';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { monokai } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import _ShareLinkModal from './_ShareLinkModal';
import { debounce } from 'lodash';
import helper from '../../../services/helper';
import { Spinner } from 'react-activity';
import 'react-activity/dist/react-activity.css';
import appConstant from '../../../constant/appConstant';

const urlMetadata = require('url-metadata')
let _this = null;
class _FloatingMenu extends Component {

    /**
    |--------------------------------------------------
    | constructor
    |--------------------------------------------------
    */

    constructor(props) {
        super(props)
        _this = this;
        this.state = {
            visible: false,
            tagVal: [],
            errors: {
                message: '',
                type: ''
            },
            code: '',
            url_image: '',
            descriptions: '',
            loadedFiles: [],
            filesArr: [],
            isMetaDataFetching: false,
            isUpdateFetching: false,
            url_meta_title: '',
            url_meta_description: '',
            url_meta_image: '',
            message: '',
            link: '',
            isLoading: false
        }
        this.getMetaDataAPI = debounce(this.metaDataAPICall.bind(this), 300);
    }

    /**
    |--------------------------------------------------
    | componentDidMount
    |--------------------------------------------------
    */

    componentDidMount() {
    }

    /**
    |--------------------------------------------------
    | tagValue
    |--------------------------------------------------
    */

    tagValue = async (e) => {
        this.setState({
            tagVal: e
        }, () => {
            const { errors } = this.state;
            if (this.state.tagVal) {
                errors.tag = ''
            } else {
                errors.tag = errorMessages.REQUIRED
            }
            this.setState({ errors })
        })
    }

    /**
    |--------------------------------------------------
    | handleClose
    |--------------------------------------------------
    */

    handleClose = (modelName) => {
        if (modelName === "link") {
            this.setState({
                isMetaDataFetching: false,
                isUpdateFetching: false,
                url_meta_title: '',
                url_meta_description: '',
                url_meta_image: '',
                message: '',
                link: '',
                tagVal: [],
                knowledgeData: undefined
            })
        }
        this.setState({ visible: false });

    }

    /**
    |--------------------------------------------------
    | handleShow
    |--------------------------------------------------
    */

    static handleShow = (modelName, knowledgeData = undefined) => {

        if (knowledgeData) {
            let tagsvalue = knowledgeData.tags.map((i) => {
                return ({
                    label: i,
                    value: i
                })
            })
            if (knowledgeData.type == appConstant.TYPE.TEXT) {
                _this.setState({
                    visible: true,
                    ismodelName: modelName,
                    message: knowledgeData.text,
                    tagVal: tagsvalue,
                    knowledgeData
                }, () => _this.CloseOptions);
            } else if (knowledgeData.type == appConstant.TYPE.LINK) {
                _this.setState({
                    visible: true,
                    ismodelName: modelName,
                    link: knowledgeData.text,
                    message: knowledgeData.description,
                    url_meta_image: knowledgeData.url_meta_image,
                    url_meta_title: knowledgeData.url_meta_title,
                    url_meta_description: knowledgeData.url_meta_description,
                    tagVal: tagsvalue,
                    knowledgeData
                }, () => _this.CloseOptions);
            } else if (knowledgeData.type == appConstant.TYPE.CODE) {
                _this.setState({
                    visible: true,
                    ismodelName: modelName,
                    code: knowledgeData.text,
                    message: knowledgeData.description,
                    tagVal: tagsvalue,
                    knowledgeData
                }, () => _this.CloseOptions);
            } else if (knowledgeData.type == appConstant.TYPE.FILE) {
                let fileUrls = knowledgeData.uploads.map(x => {
                    if (x.subtype == "video") {
                        return {
                            url: x.video,
                            thumbimage: x.image,
                            type: 'video',
                            knowledge_upload_id: x.knowledge_upload_id
                        }
                    } else {
                        return {
                            url: x.image,
                            type: 'image',
                            knowledge_upload_id: x.knowledge_upload_id
                        }
                    }
                })
                _this.setState({
                    visible: true,
                    ismodelName: modelName,
                    code: knowledgeData.text,
                    message: knowledgeData.description,
                    tagVal: tagsvalue,
                    filesArr: fileUrls,
                    knowledgeData
                }, () => _this.CloseOptions);
            }
        } else {
            _this.setState({
                visible: true,
                ismodelName: modelName,
                link: '',
                message: '',
                url_meta_image: '',
                url_meta_title: '',
                url_meta_description: '',
                tagVal: [],
                filesArr: [],
                knowledgeData: undefined
            }, () => _this.CloseOptions);
        }
    }

    /**
    |--------------------------------------------------
    | OpenOptions
    |--------------------------------------------------
    */

    OpenOptions = () => {
        this.setState({ leftbar: !this.state.OpenOptions },
            function () {
                document.body.classList.toggle('OpenOptions');
            });
    }

    /**
    |--------------------------------------------------
    | CloseOptions
    |--------------------------------------------------
    */

    CloseOptions = () => {

        this.setState({ leftbar: !this.state.CloseOptions },
            function () {
                document.body.classList.remove('CloseOptions');
            });
    }

    /**
    |--------------------------------------------------
    | metaDataAPICall
    |--------------------------------------------------
    */

    metaDataAPICall(url) {
        let tempURL = url
        let _this = this;

        if (!(url.includes("http://")) && !(url.includes("https://"))) {
            tempURL = "https://" + tempURL
        }
        this.setState({
            isMetaDataFetching: true
        }, () => {
            urlMetadata(tempURL).then(
                function (metadata) { // success handler
                    _this.setState({
                        url_meta_title: (metadata && metadata.title) ? metadata.title : '',
                        url_meta_description: (metadata && metadata.description) ? metadata.description : '',
                        message: (metadata && metadata.description) ? metadata.description : '',
                        url_meta_image: (metadata && metadata.image) ? metadata.image : '',
                        isMetaDataFetching: false
                    })
                },
                function (error) {
                    // failure handler
                    _this.setState({
                        isMetaDataFetching: false
                    })
                })
        })
    }

    /**
    |--------------------------------------------------
    | handleChange
    |--------------------------------------------------
    */

    handleChange = async (event) => {
        this.setState({ [event.target.name]: event.target.value })
        if (event.target.value) {
            this.setState({
                errors: Object.assign(this.state.errors, { [event.target.name]: "" })
            });
            if (event.target.name === 'link') {
                this.setState({
                    errors: Object.assign(this.state.errors, { [event.target.name]: !helper.isValidUrl(event.target.value) ? errorMessages.PROVIDE_VALID_URL : '' })
                })
                if (helper.isValidUrl(event.target.value)) {
                    this.getMetaDataAPI(event.target.value)
                }
            }
        } else {
            this.setState({
                errors: Object.assign(this.state.errors, { [event.target.name]: errorMessages.REQUIRED })
            })
        }
    }

    /**
    |--------------------------------------------------
    | validateForm
    |--------------------------------------------------
    */

    validateForm(type) {
        let { message, code, errors, isFormValid, tagVal, link, filesArr } = this.state;

        if (type == appConstant.TYPE.TEXT) {
            if (!message.trim()) {
                errors.message = errorMessages.REQUIRED;
                errors.type = 'message'
            } else if (!tagVal || tagVal.length == 0) {
                errors.message = errorMessages.REQUIRED
                errors.type = 'tag'
            } else {
                errors.message = ''
                errors.type = ''
            }

            if (errors.message !== '') isFormValid = false;
            else isFormValid = true;
        } else if (type == appConstant.TYPE.LINK) {
            if (!link.trim()) {
                errors.message = errorMessages.REQUIRED;
                errors.type = 'link';
            } else if (!helper.isValidUrl(link.trim())) {
                errors.link = errorMessages.PROVIDE_VALID_URL;
                errors.type = 'link';
            } else if (!message.trim()) {
                errors.message = errorMessages.REQUIRED;
                errors.type = 'message';
            } else if (!tagVal || tagVal.length == 0) {
                errors.message = errorMessages.REQUIRED
                errors.type = 'tag'
            } else {
                errors.type = ''
                errors.message = ''
            }

            if (errors.message !== '') isFormValid = false;
            else isFormValid = true;
        } else if (type == appConstant.TYPE.CODE) {
            if (!code.trim()) {
                errors.message = errorMessages.REQUIRED;
                errors.type = 'code';
            } else if (!message.trim()) {
                errors.message = errorMessages.REQUIRED;
                errors.type = 'message';
            } else if (!tagVal || tagVal.length == 0) {
                errors.message = errorMessages.REQUIRED
                errors.type = 'tag'
            } else {
                errors.type = ''
                errors.message = ''
            }

            if (errors.message !== '') isFormValid = false;
            else isFormValid = true;
        } else if (type == appConstant.TYPE.FILE) {
            if (filesArr.length == 0) {
                errors.message = errorMessages.REQUIRED
                errors.type = 'file'

            } else if (filesArr.length > 0) {
                filesArr.map((file) => {
                    if (file.type == "video") {
                        if (file.size > 10000000) {
                            errors.message = errorMessages.VIDEO_LENGTH_ERROR
                            errors.type = 'file'
                        }
                    }
                })
            } else if (!message.trim()) {
                errors.message = errorMessages.REQUIRED;
                errors.type = 'message';
            } else if (!tagVal || tagVal.length == 0) {
                errors.message = errorMessages.REQUIRED
                errors.type = 'tag'
            } else {
                errors.type = ''
                errors.message = ''
            }

            if (errors.message !== '') isFormValid = false;
            else isFormValid = true;
        }

        this.setState({ errors, isFormValid })
        return isFormValid
    }

    /**
    |--------------------------------------------------
    | fileChangedHandlerImg
    |--------------------------------------------------
    */

    fileChangedHandlerImg(event) {
        let filesArr = this.state.filesArr

        for (const [key, file] of Object.entries(event.target.files)) {
            console.log('Files Object-->', file);
            if (file.type.match('image')) {
                console.log('file-->', file)
                var image = new Image();
                image.src = window.URL.createObjectURL(file);
                let image_width = 0;
                let image_height = 0;

                image.onload = () => {
                    image_width = image.naturalWidth;
                    image_height = image.naturalHeight;
                }

                let reader = new FileReader()

                console.log('filesArr-->', JSON.stringify(filesArr))

                reader.onloadend = () => {
                    this.addLoadedFile(file);
                }
                if (filesArr.length <= 4) {
                    filesArr.push({
                        url: image.src,
                        file: file,
                        image_width: image_width,
                        image_height: image_height,
                        type: 'image'
                    });

                    this.setState({ filesArr: [...filesArr] });
                }

                reader.readAsDataURL(file)
                // return true;
            } else {
                let fileReader = new FileReader()
                var _that = this;
                var duration = 0;
                var url = '';
                var blob = undefined;
                var imageBlob = undefined;
                let image_width = 0;
                let image_height = 0;
                fileReader.onloadend = function () {
                    blob = new Blob([fileReader.result], { type: file.type });
                    url = URL.createObjectURL(blob);
                    console.log('video url-->', url)
                    var video = document.createElement('video');
                    let timeStamp = new Date().getTime();
                    var timeupdate = function () {
                        video.removeEventListener('timeupdate', timeupdate);
                        if (snapImage(timeStamp)) {
                            video.removeEventListener('timeupdate', timeupdate);
                            video.pause();
                        }
                    };
                    // video.addEventListener('loadeddata', function () {
                    //     if (snapImage()) {
                    //         video.removeEventListener('timeupdate', timeupdate);
                    //     }
                    // });


                    var snapImage = (timeStamp) => {
                        console.log('snapImage-->', key);
                        var canvas = document.createElement('canvas');
                        canvas.width = video.videoWidth;
                        canvas.height = video.videoHeight;

                        image_width = video.videoWidth;
                        image_height = video.videoHeight;

                        canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
                        var image = canvas.toDataURL();
                        let block = image && image.split(";");
                        let contentType = block[0] && block[0].split(":")[1];
                        let realData = block[1] && block[1].split(",")[1];// In this case "iVBORw0KGg...."
                        if (realData !== undefined) {
                            let sliceSize = 512;
                            let byteCharacters = atob(realData, contentType);
                            let byteArrays = [];
                            for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                                let slice = byteCharacters.slice(offset, offset + sliceSize);

                                let byteNumbers = new Array(slice.length);
                                for (let i = 0; i < slice.length; i++) {
                                    byteNumbers[i] = slice.charCodeAt(i);
                                }
                                let byteArray = new Uint8Array(byteNumbers);
                                byteArrays.push(byteArray);
                            }

                            imageBlob = new Blob(byteArrays, { contentType })
                            /**************video duriatio *************/
                            duration = video.duration;
                            let index = filesArr.findIndex(x => x.timeStamp == timeStamp);

                            if (index != -1) {
                                filesArr[index].thumbimage = imageBlob;
                                filesArr[index].duration = duration;
                                filesArr[index].image_width = image_width;
                                filesArr[index].image_height = image_height;
                                filesArr[index].file = file;
                                _this.setState({ filesArr: [...filesArr] });
                            }

                            var success = image.length > 100000;
                            if (success) {
                                // var img = document.createElement('img');
                                // img.src = image;
                                // document.getElementsByTagName('div')[0].appendChild(img);
                                URL.revokeObjectURL(url);
                            }
                            // return success;
                        }
                    };

                    if (filesArr.length <= 4) {
                        console.log("Video URL-->", url);
                        filesArr.push({
                            // file: file,
                            // thumbimage: imageBlob,
                            // duration: video.duration,
                            // image_width: video.videoWidth,
                            // image_height: video.videoHeight,
                            url: url,
                            type: 'video',
                            size: file.size,
                            timeStamp: timeStamp
                        })
                        console.log("Inside filesArr-->", filesArr);
                        console.log('outside loop filesArr-->', JSON.stringify(filesArr))
                        _this.setState({ filesArr: [...filesArr] });
                    }

                    video.addEventListener('timeupdate', timeupdate);
                    video.preload = 'metadata';
                    video.src = url;
                    video.ondurationchange = function () {
                        if (duration > 45) {
                            swal("", "Currently, you can only upload files smaller than 10 MB (a 45 sec video for instance).", "warning")
                        }
                    }
                    video.muted = true;
                    video.playsInline = true;
                    video.play();
                    _this.addLoadedFile(file);
                };
                fileReader.readAsArrayBuffer(file)
            }
        }
    }

    /**
    |--------------------------------------------------
    | removeImage
    |--------------------------------------------------
    */

    removeImage(index, mediaId) {
        const { knowledgeData } = this.state;
        let filesArr = this.state.filesArr;
        let loadedFiles = this.state.loadedFiles;
        if (knowledgeData && mediaId) {
            filesArr.splice(index, 1, {
                knowledge_id: knowledgeData.knowledge_id,
                knowledge_upload_id: mediaId,
                isRemove: true
            });
        } else {
            filesArr.splice(index, 1);
            loadedFiles.splice(index, 1);
        }

        this.setState({
            filesArr,
            loadedFiles
        })
    }

    /**
    |--------------------------------------------------
    | addLoadedFile
    |--------------------------------------------------
    */

    addLoadedFile(file) {
        let tempPhotos = this.state.loadedFiles;
        tempPhotos.push(file);
        this.setState({
            loadedFiles: tempPhotos,
        });
    }

    /**
    |--------------------------------------------------
    | addKnowledge
    |--------------------------------------------------
    */

    addKnowledge = async (type) => {
        try {
            var { message, tagVal, url_meta_image, url_title, url_meta_description, code, link } = this.state;
            let tagsarray = [];
            let tagsvalue = tagVal.map((i) => {
                return (
                    i.value
                )
            })
            tagsarray.push(tagsvalue.toString())

            if (this.validateForm(type)) {
                this.setState({
                    isLoading: true
                })
                let body = {};
                if (type == appConstant.TYPE.TEXT) {
                    body["text"] = message;
                } else if (type == appConstant.TYPE.LINK) {
                    let tempURL = link;
                    if (!(link.includes("http://")) && !(link.includes("https://"))) {
                        tempURL = "https://" + tempURL
                    }
                    body['text'] = tempURL;
                    body['description'] = message;
                    body['url_meta_image'] = url_meta_image;
                    body['url_meta_title'] = url_title;
                    body['url_meta_description'] = url_meta_description;
                } else if (type == appConstant.TYPE.CODE) {
                    body['text'] = code;
                    body['description'] = message;
                }

                body['tags'] = tagsvalue.toString();
                body['type'] = type;
                console.log('body-->', JSON.stringify(body));
                const response = await this.props.callApi('/newKnowledge', body, 'post', null, true);
                if (response) {
                    common.showSuccessMessage("Knowledge text details added successfully");
                    console.log('newKnowledge-->', JSON.stringify(response));
                    if (type == appConstant.TYPE.TEXT) {
                        this.props.setKnowledgeBankToReadData([response.data, ...this.props.knowledgeBankToReadData])
                        this.props.getChildEvent(response.data.knowledge_id, appConstant.TYPE.TEXT);
                        if (this.props.leftBarKbRef && this.props.leftBarKbRef.state.activeTab != 'toRead') {
                            this.props.leftBarKbRef.setState({
                                activeTab: 'toRead'
                            }, () => {
                                this.props.leftBarKbRef.knowledgeBankLinkRef.setState({
                                    current_page: 1
                                }, () => {
                                    this.props.leftBarKbRef.props.setKnowledgeBankLinkData([]);
                                    this.props.leftBarKbRef.knowledgeBankLinkRef.getKnowledgeBankLinkData(false)
                                })
                            })
                        }
                    } else if (type == appConstant.TYPE.LINK) {
                        this.props.setKnowledgeBankLinkData([response.data, ...this.props.knowledgeBankLinkData])
                        this.props.getChildEvent(response.data.knowledge_id, appConstant.TYPE.LINK);
                        if (this.props.leftBarKbRef && this.props.leftBarKbRef.state.activeTab != 'links') {
                            this.props.leftBarKbRef.setState({
                                activeTab: 'links'
                            }, () => {
                                this.props.leftBarKbRef.knowledgeBankLinkRef.setState({
                                    current_page: 1
                                }, () => {
                                    this.props.leftBarKbRef.props.setKnowledgeBankLinkData([]);
                                    this.props.leftBarKbRef.knowledgeBankLinkRef.getKnowledgeBankLinkData(false)
                                })
                            })
                        }
                    } else if (type == appConstant.TYPE.CODE) {

                        this.props.setKnowledgeBankCodeData([response.data, ...this.props.knowledgeBankCodeData])
                        this.props.getChildEvent(response.data.knowledge_id, appConstant.TYPE.CODE);
                        if (this.props.leftBarKbRef && this.props.leftBarKbRef.state.activeTab != 'code') {
                            this.props.leftBarKbRef.setState({
                                activeTab: 'code'
                            }, () => {
                                this.props.leftBarKbRef.knowledgeBankLinkRef.setState({
                                    current_page: 1
                                }, () => {
                                    this.props.leftBarKbRef.props.setKnowledgeBankCodeData([]);
                                    this.props.leftBarKbRef.knowledgeBankCodeRef.getKnowledgeCodeData(false)
                                })
                            })
                        }
                    }
                }
                this.props.sidebarAnnouncementRef.getAnnouncement(false, false);
                this.setState({
                    visible: false,
                    isLoading: true,
                    tagVal: [],
                    description: '',
                    message: ''
                });

            }
        } catch (error) {
            throw (error)
        }
    }

    /**
    |--------------------------------------------------
    | updateKnowledge
    |--------------------------------------------------
    */

    updateKnowledge = async () => {
        var { link, message, url_meta_image, url_title,
            url_meta_description, knowledgeData, tagVal, code } = this.state;

        let tagsvalue = tagVal && tagVal.map((i) => {
            return (
                i.value
            )
        })
        //  tagsarray.push(tagsvalue.toString())
        if (this.validateForm(knowledgeData.type)) {
            this.setState({
                isLoading: true
            })
            const formData = new FormData()
            if (knowledgeData.type == appConstant.TYPE.TEXT) {
                formData.append('text', message)
            } else if (knowledgeData.type == appConstant.TYPE.LINK) {
                let tempURL = link;
                if (!(link.includes("http://")) && !(link.includes("https://"))) {
                    tempURL = "https://" + tempURL
                }
                formData.append('text', tempURL)
                formData.append('description', message)
                formData.append('url_meta_image', url_meta_image)
                formData.append('url_meta_title', url_title)
                formData.append('url_meta_description', url_meta_description)
            } else if (knowledgeData.type == appConstant.TYPE.CODE) {
                formData.append('text', code)
                formData.append('description', message)
            }

            formData.append('tags', tagsvalue)
            formData.append('type', knowledgeData.type)
            formData.append('knowledge_id', knowledgeData.knowledge_id)

            const response = await this.props.callApi('/updateKnowledge', formData, 'post', null, true, true);
            if (response) {
                common.showSuccessMessage("Knowledge details updated succesfully");
                if (knowledgeData.type == appConstant.TYPE.TEXT) {
                    let tempKnowledgeDataToRead = this.props.knowledgeBankToReadData;
                    let selectedIndex = tempKnowledgeDataToRead.findIndex(x => x.knowledge_id == knowledgeData.knowledge_id);
                    tempKnowledgeDataToRead.splice(selectedIndex, 1, response.data);
                    this.props.setKnowledgeBankToReadData([...tempKnowledgeDataToRead])
                    this.props.getChildEvent(knowledgeData.knowledge_id, appConstant.TYPE.TEXT);
                } else if (knowledgeData.type == appConstant.TYPE.LINK) {
                    let tempKnowledgeDataLink = this.props.knowledgeBankLinkData;
                    let selectedIndex = tempKnowledgeDataLink.findIndex(x => x.knowledge_id == knowledgeData.knowledge_id);
                    tempKnowledgeDataLink.splice(selectedIndex, 1, response.data);
                    this.props.setKnowledgeBankToReadData([...tempKnowledgeDataLink])
                    this.props.getChildEvent(knowledgeData.knowledge_id, appConstant.TYPE.LINK);
                } else if (knowledgeData.type == appConstant.TYPE.CODE) {
                    let tempKnowledgeDataCode = this.props.knowledgeBankCodeData;
                    let selectedIndex = tempKnowledgeDataCode.findIndex(x => x.knowledge_id == knowledgeData.knowledge_id);
                    tempKnowledgeDataCode.splice(selectedIndex, 1, response.data);
                    this.props.setKnowledgeBankCodeData([...tempKnowledgeDataCode])
                    this.props.getChildEvent(knowledgeData.knowledge_id, appConstant.TYPE.CODE);
                }
            }
            this.props.sidebarAnnouncementRef.getAnnouncement(false, false);
            this.setState({
                visible: false,
                knowledgeData: undefined,
                message: '',
                tagVal: [],
                isLoading: false
            });
        }
    }

    /**
    |--------------------------------------------------
    | AddKnowledgefile
    |--------------------------------------------------
    */

    AddKnowledgefile = async () => {
        try {
            var { message, tagVal, file, filesArr, thumbimage, image_width, image_height,
                duration, loadedFiles, width, height } = this.state;

            console.log('filesArr-->', filesArr);

            let tagsarray = [];
            let tagsvalue = tagVal.map((i) => {
                return (
                    i.value
                )
            })
            tagsarray.push(tagsvalue.toString())

            if (this.validateForm(appConstant.TYPE.FILE)) {
                this.setState({
                    isLoading: true
                })
                let fData = new FormData();
                fData.append('type', 'file')
                fData.append('tags', tagsarray.toString())
                fData.append('description', message)
                if (filesArr[0].type === 'video') {
                    fData.append('video', filesArr[0].file)
                    fData.append('image', filesArr[0].thumbimage)
                    fData.append('image_width', filesArr[0].image_width)
                    fData.append('image_height', filesArr[0].image_height)
                    fData.append('video_duration', filesArr[0].duration)
                } else {
                    fData.append('image', filesArr[0].file)
                    fData.append('image_width', filesArr[0].image_width)
                    fData.append('image_height', filesArr[0].image_height)
                }

                const response1 = await this.props.callApi('/uploadNewKnowledgeFile', fData, 'post', null, true);
                if (response1) {
                    console.log('uploadNewKnowledgeFile response-->', JSON.stringify(response1));
                    this.props.setKnowledgeBankPhotosData([response1.data, ...this.props.knowledgeBankPhotosData])

                    this.setState({
                        visible: false,
                        isLoading: false,
                        tagVal: [],
                        filesArr: [],
                        description: '',
                        message: '',
                    });

                    if (this.props.leftBarKbRef && this.props.leftBarKbRef.state.activeTab != 'photo') {
                        this.props.getChildEvent(response1.data.knowledge_id, appConstant.TYPE.FILE);
                        this.props.leftBarKbRef.setState({
                            activeTab: 'photo'
                        }, () => {
                            this.props.leftBarKbRef.knowledgeBankPhotosRef.setState({
                                current_page: 1
                            }, () => {
                                // this.props.leftBarKbRef.props.setKnowledgeBankPhotosData([]);
                                this.props.leftBarKbRef.knowledgeBankPhotosRef.getKnowledgePhotos(false)
                            })
                        })
                    }
                    this.props.sidebarAnnouncementRef.getAnnouncement(false, false);
                    common.showSuccessMessage("Knowledge File added successfully");
                    if (filesArr.length > 1) {
                        if (response1 && response1.data && response1.data.uploads && response1.data.uploads[0]) {
                            const formData = new FormData()
                            // formData.append('tags', tagsarray.toString())
                            formData.append('type', "file")
                            formData.append('knowledge_id', response1.data.uploads[0].knowledge_id)
                            // formData.append('description', message)

                            for (let i = 1; i < filesArr.length; i++) {
                                if (filesArr[i].type == "video") {
                                    formData.append('video', filesArr[i].file)
                                    formData.append('image', filesArr[i].thumbimage)
                                    formData.append('image_width', filesArr[i].image_width)
                                    formData.append('image_height', filesArr[i].image_height)
                                    formData.append('video_duration', filesArr[i].duration)
                                } else {
                                    formData.append('image', filesArr[i].file)
                                    formData.append('image_width', filesArr[i].image_width)
                                    formData.append('image_height', filesArr[i].image_height)
                                }

                                const response = await this.props.callApi('/uploadKnowledgeFile', formData, 'post', null, true);
                                console.log('uploadKnowledgeFile response-->', JSON.stringify(response));
                                if (response) {
                                    this.setState({
                                        visible: false,
                                        isLoading: false,
                                        tagVal: [],
                                        filesArr: [],
                                        description: '',
                                        message: '',
                                    });
                                    let knowledgeBankPhotosData = this.props.knowledgeBankPhotosData
                                    knowledgeBankPhotosData[0] = response.data
                                    this.props.setKnowledgeBankPhotosData([...knowledgeBankPhotosData])
                                    this.props.getChildEvent(response1.data.knowledge_id, appConstant.TYPE.FILE);
                                    this.props.sidebarAnnouncementRef.getAnnouncement(false, false);
                                    // if (this.props.leftBarKbRef) {
                                    //     this.props.leftBarKbRef.setState({
                                    //         activeTab: 'photo'
                                    //     }, () => {
                                    //         this.props.leftBarKbRef.knowledgeBankPhotosRef.setState({
                                    //             current_page: 1
                                    //         }, () => {
                                    //             this.props.leftBarKbRef.props.setKnowledgeBankPhotosData([]);
                                    //             this.props.leftBarKbRef.knowledgeBankPhotosRef.getKnowledgePhotos(false)
                                    //         })
                                    //     })
                                    // }
                                }
                            }
                        }
                    } else {
                        this.setState({
                            visible: false,
                            isLoading: false,
                            tagVal: [],
                            filesArr: [],
                            description: '',
                            message: ''
                        });

                        this.props.setKnowledgeBankPhotosData([response1.data, ...this.props.knowledgeBankPhotosData])
                        if (this.props.leftBarKbRef && this.props.leftBarKbRef.state.activeTab != 'photo') {
                            this.props.getChildEvent(response1.data.knowledge_id, appConstant.TYPE.FILE);
                            this.props.leftBarKbRef.setState({
                                activeTab: 'photo'
                            }, () => {
                                this.props.leftBarKbRef.knowledgeBankPhotosRef.setState({
                                    current_page: 1
                                }, () => {
                                    this.props.leftBarKbRef.props.setKnowledgeBankPhotosData([]);
                                    this.props.leftBarKbRef.knowledgeBankPhotosRef.getKnowledgePhotos(false)
                                })
                            })
                        }
                        this.props.sidebarAnnouncementRef.getAnnouncement(false, false);
                        common.showSuccessMessage("Knowledge File added successfully");
                    }
                }
            }
        } catch (error) {
            throw (error)
        }
    }

    /**
    |--------------------------------------------------
    | updateKnowledgeFile
    |--------------------------------------------------
    */

    updateKnowledgeFile = async () => {
        try {
            var { message, tagVal, file, filesArr, thumbimage, image_width, image_height,
                duration, loadedFiles, width, height, knowledgeData } = this.state;

            console.log('filesArr-->', filesArr);

            let removeFileArr = filesArr.filter((x) => {
                return x.isRemove
            })

            let newFileArr = filesArr.filter((x) => {
                return x.file
            })

            let tagsarray = [];
            let tagsvalue = tagVal.map((i) => {
                return (
                    i.value
                )
            })
            tagsarray.push(tagsvalue.toString())

            if (this.validateForm(appConstant.TYPE.FILE)) {
                this.setState({
                    isLoading: true
                })

                if (removeFileArr.length > 0) {
                    removeFileArr.map(async (fileData) => {
                        let fData = new FormData();
                        fData.append('type', 'file')
                        fData.append('tags', tagsarray.toString())
                        fData.append('description', message)
                        fData.append('knowledge_id', knowledgeData.knowledge_id)

                        fData.append('knowledge_upload_id', fileData.knowledge_upload_id)
                        const response1 = await this.props.callApi('/replaceDeleteKnowledgeFile', fData, 'post', null, true);

                        let tempKnowledgeBankPhotosData = this.props.knowledgeBankPhotosData
                        let selectedIndex = tempKnowledgeBankPhotosData.findIndex(x => x.knowledge_id == knowledgeData.knowledge_id);
                        tempKnowledgeBankPhotosData.splice(selectedIndex, 1, response1.data);
                        this.props.setKnowledgeBankPhotosData([...tempKnowledgeBankPhotosData]);
                        this.props.getChildEvent(knowledgeData.knowledge_id, appConstant.TYPE.FILE);

                    });
                } else {
                    let fData = new FormData();
                    fData.append('type', 'file')
                    fData.append('tags', tagsarray.toString())
                    fData.append('description', message)
                    fData.append('knowledge_id', knowledgeData.knowledge_id)

                    const response1 = await this.props.callApi('/replaceDeleteKnowledgeFile', fData, 'post', null, true);

                    let tempKnowledgeBankPhotosData = this.props.knowledgeBankPhotosData;
                    let selectedIndex = tempKnowledgeBankPhotosData.findIndex(x => x.knowledge_id == knowledgeData.knowledge_id);
                    tempKnowledgeBankPhotosData.splice(selectedIndex, 1, response1.data);
                    this.props.setKnowledgeBankPhotosData([...tempKnowledgeBankPhotosData]);
                    this.props.getChildEvent(knowledgeData.knowledge_id, appConstant.TYPE.FILE);

                }

                this.setState({
                    visible: false,
                    isLoading: false,
                    tagVal: [],
                    filesArr: [],
                    description: '',
                    message: '',
                });
                this.props.sidebarAnnouncementRef.getAnnouncement(false, false);
                common.showSuccessMessage("Knowledge File update successfully");


                if (newFileArr.length > 0) {
                    const formData = new FormData()
                    formData.append('tags', tagsarray.toString())
                    formData.append('type', "file")
                    formData.append('knowledge_id', knowledgeData.knowledge_id)
                    formData.append('description', message)

                    // newFileArr.map(async (fileData) => {
                    for (let i = 0; i < newFileArr.length; i++) {
                        if (newFileArr[i].type == "video") {
                            if (newFileArr[i].file) {
                                formData.append('video', newFileArr[i].file)
                                formData.append('image', newFileArr[i].thumbimage)
                                formData.append('image_width', newFileArr[i].image_width)
                                formData.append('image_height', newFileArr[i].image_height)
                                formData.append('video_duration', newFileArr[i].duration)
                            } else {
                                formData.append('knowledge_upload_id', newFileArr[i].knowledge_upload_id)
                            }
                        } else {
                            if (newFileArr[i].file) {
                                formData.append('image', newFileArr[i].file)
                                formData.append('image_width', newFileArr[i].image_width)
                                formData.append('image_height', newFileArr[i].image_height)
                            } else {
                                formData.append('knowledge_upload_id', newFileArr[i].knowledge_upload_id)
                            }
                        }

                        const response = await this.props.callApi('/uploadKnowledgeFile', formData, 'post', null, true);
                        console.log('uploadKnowledgeFile response-->', JSON.stringify(response));
                        let tempKnowledgeBankPhotosData = this.props.knowledgeBankPhotosData
                        let selectedIndex = tempKnowledgeBankPhotosData.findIndex(x => x.knowledge_id == knowledgeData.knowledge_id);
                        tempKnowledgeBankPhotosData.splice(selectedIndex, 1, response.data);
                        this.props.setKnowledgeBankPhotosData([...tempKnowledgeBankPhotosData]);
                        this.props.getChildEvent(response.data.knowledge_id, appConstant.TYPE.FILE);
                    }
                    // })

                    this.setState({
                        visible: false,
                        isLoading: false,
                        tagVal: [],
                        filesArr: [],
                        description: '',
                        message: '',
                    });

                    this.props.sidebarAnnouncementRef.getAnnouncement(false, false);
                    common.showSuccessMessage("Knowledge File update successfully");



                    // let knowledgeBankPhotosData = this.props.knowledgeBankPhotosData
                    // knowledgeBankPhotosData[0] = response.data
                    // this.props.setKnowledgeBankPhotosData([...knowledgeBankPhotosData])
                    // this.props.getChildEvent(response1.data.knowledge_id, appConstant.TYPE.FILE);
                }
            }
        } catch (error) {
            throw (error)
        }
    }

    /**
    |--------------------------------------------------
    | render
    |--------------------------------------------------
    */

    render() {
        var { thumbimage, file, filesArr, knowledgeData, tagvalue, descriptions, total, current_page, per_page, tags,
            message, errors, link, code, tagVal, isMetaDataFetching, isUpdateFetching, isLoading } = this.state
        var strmessage = message;
        var strdescription = descriptions;

        let updatetags = tagvalue && Array.isArray(tagvalue) && tagvalue.map((values) => {
            return (
                { label: values, value: values }
            )
        })
        return (
            <div>
                <nav className="floating-menu" id="nav" onClick={this.OpenOptions}>
                    <ul className="floating-navigation">
                        <li className="floating-navigation-item" onClick={() => _FloatingMenu.handleShow("text")}>
                            <span className="material-icons">create</span>
                        </li>
                        <li className="floating-navigation-item" onClick={() => _FloatingMenu.handleShow("link")}>
                            <span className="material-icons">link</span>
                        </li>
                        <li className="floating-navigation-item" onClick={() => _FloatingMenu.handleShow(appConstant.TYPE.FILE)}>
                            <span className="material-icons">camera_alt</span>
                        </li>
                        <li className="floating-navigation-item" onClick={() => _FloatingMenu.handleShow(appConstant.TYPE.CODE)}>
                            <span className="material-icons">code</span>
                        </li>
                    </ul>
                    <div className="floating-menu-btn" id="menu">
                        <span className="material-icons">add</span>
                    </div>
                </nav>

                {/* Modal for Sharing Text */}
                <Modal className="common-modal small" centered show={this.state.visible && this.state.ismodelName === "text"} onHide={() => this.handleClose("text")}>

                    {/* <Modal.Header closeButton>
                        <Modal.Title>Modal heading</Modal.Title>
                    </Modal.Header> */}
                    <button type="button" className="close fixed-close-btn" onClick={() => this.handleClose("text")}>
                        <span aria-hidden="true">×</span>
                    </button>
                    <Modal.Body className="">
                        <h4>Share Text</h4>
                        {/* <span className="common-text">This announcement will be displayed to all your colleague on the home screen until you do not remove it.</span> */}
                        <div className="mt-3 mb-3">
                            <div className="suggestion-block">
                                <textarea
                                    name="message"
                                    placeholder="Start typing here..."
                                    className="form-control"
                                    value={message}
                                    onChange={this.handleChange}
                                    maxLength="3000"
                                ></textarea>
                                {errors.type === "message" && <span className="error-msg"> {errors.message} </span>}
                                <div className="d-flex justify-content-end">
                                    <span className="remaining-words"><span>{message.length}</span>/3000</span>
                                </div>
                            </div>
                        </div>
                        <div className="react-select-tag">
                            <CreatableSelect
                                isMulti
                                name="addTag"
                                options={this.props.tagsList}
                                className="common-select"
                                classNamePrefix="form-control"
                                placeholder={"Type to add tag"}
                                value={this.state.tagVal ? this.state.tagVal : ''}
                                onChange={(e) => this.tagValue(e)}
                            />
                            {errors.type === "tag" && <span className="error-msg"> {errors.message} </span>}
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                            <Button disabled={isLoading} className="w-100 text-capitalize mt-3" onClick={() => knowledgeData ? this.updateKnowledge() : this.addKnowledge('text')}>{isLoading ? "Loading..." : knowledgeData ? "Update" : "Share this"}</Button>
                        </div>
                    </Modal.Body>
                </Modal>

                {/* Modal for Sharing Link */}
                <Modal
                    className="common-modal small"
                    centered
                    show={this.state.visible && this.state.ismodelName === "link"}
                    onHide={() => this.handleClose("link")}
                >
                    <button type="button" className="close fixed-close-btn" onClick={() => this.handleClose("link")}>
                        <span aria-hidden="true">×</span>
                    </button>

                    <Modal.Body className="">
                        <h4>Share Link</h4>
                        <div className="mb-3">
                            <div className="suggestion-block">
                                <div className="spinner-parent">
                                    <input
                                        type="email"
                                        className="form-control"
                                        placeholder="www.example.com"
                                        name={'link'}
                                        value={link}
                                        onChange={this.handleChange}
                                    />
                                    {isMetaDataFetching && <Spinner size="sm" animation="border" />}
                                </div>
                                {errors.type == "link" && <span className="error-msg">{errors.message} </span>}
                            </div>
                        </div>

                        <div className="mb-3">
                            <div className="suggestion-block">
                                <textarea
                                    placeholder="Describe your link here"
                                    className="form-control"
                                    name={'message'}
                                    value={message}
                                    onChange={this.handleChange}
                                    maxLength="3000"
                                />
                                {errors.type == "message" && <span className="error-msg"> {errors.message} </span>}
                                <div className="d-flex justify-content-end">
                                    <span className="remaining-words"><span>{message.length}</span>/3000</span>
                                </div>
                            </div>
                        </div>
                        <div className="react-select-tag">
                            <CreatableSelect
                                isMulti
                                name="addTag"
                                options={this.props.tagsList}
                                className="common-select"
                                classNamePrefix="form-control"
                                placeholder={"Type to add tag"}
                                value={tagVal ? tagVal : ''}
                                onChange={(e) => this.tagValue(e)}
                            />
                            {errors.type == "tag" && <span className="error-msg"> {errors.message} </span>}
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                            <Button
                                className="w-100 text-capitalize mt-3"
                                disabled={isLoading}
                                onClick={() => knowledgeData ? this.updateKnowledge() : this.addKnowledge(appConstant.TYPE.LINK)}>
                                {isLoading ? "Loading..." : knowledgeData ? "Update" : "Share this"}
                            </Button>
                        </div>
                    </Modal.Body>
                </Modal >

                {/* Modal for Sharing Photos/Videos */}
                <Modal className="common-modal small" centered show={this.state.visible && this.state.ismodelName === appConstant.TYPE.FILE} onHide={() => this.handleClose(appConstant.TYPE.FILE)}>
                    <button type="button" className="close fixed-close-btn" onClick={() => this.handleClose(appConstant.TYPE.FILE)}>
                        <span aria-hidden="true">×</span>
                    </button>
                    <Modal.Body className="">
                        <h4>Share Photos/Videos</h4>
                        {/* <div className="company-logo-block add-picture new-idea my-3 flex-wrap">
                            <span className="company-logo"><img src="images/camera.svg" alt="" /></span>

                            <input
                                type="file"
                                multiple
                                accept="image/*,video/*"
                                className="company-logo-changer  new-idea"
                                id="file-browser-input"
                                name="file-browser-input"
                                onChange={this.fileChangedHandlerImg.bind(this)} />
                        </div> */}
                        <div className="add-img add-video mt-3">
                            <span className="camera-icon">
                                <img src="images/camera.svg" alt="" />
                            </span>
                            <input
                                multiple
                                accept="image/*,video/*"
                                id="file-browser-input"
                                name="file-browser-input"
                                type="file"
                                className="input-file"
                                onChange={this.fileChangedHandlerImg.bind(this)} />
                        </div>
                        <ul className="modal-uploaded-img-block">
                            {
                                Array.isArray(filesArr) && filesArr.map((values, key) => {
                                    console.log('values-->', values);
                                    if (values.type === 'video') {
                                        return (
                                            values.url && values.url != '' && <span className="li-video-wrap" key={key}>
                                                <span className="remove-image" onClick={() => this.removeImage(key, values.knowledge_upload_id)}>
                                                    X
                                                    </span>
                                                <video width="320" height="240" controls>
                                                    <source src={values.url} type="video/mp4" />
                                                </video>
                                            </span>
                                        )
                                    } else {
                                        return (
                                            values.url && values.url != '' && <li className="li-image-wrap" key={key}>
                                                <span className="remove-image" onClick={() => this.removeImage(key, values.knowledge_upload_id)}>
                                                    X
                                                    </span>
                                                <img src={values.url} alt="upload image" class={'li-image'} />
                                            </li>
                                        )
                                    }
                                })
                            }
                        </ul>

                        {errors.type == "file" && <span className="error-msg"> {errors.message} </span>}
                        <div className="mt-3 mb-3">
                            <div className="suggestion-block">
                                <textarea
                                    name="message"
                                    placeholder="Describe your media here"
                                    className="form-control"
                                    value={message}
                                    onChange={this.handleChange}
                                    maxLength="3000"
                                ></textarea>
                                {errors.type == "message" && <span className="error-msg"> {errors.message} </span>}
                                <div className="d-flex justify-content-end">
                                    <span className="remaining-words"><span>{strmessage.length}</span>/3000</span>
                                </div>
                            </div>
                        </div>
                        <div className="react-select-tag">
                            <CreatableSelect
                                isMulti
                                name="addTag"
                                options={this.props.tagsList}
                                className="common-select"
                                classNamePrefix="form-control"
                                placeholder={"Type to add tag"}
                                value={this.state.tagVal ? this.state.tagVal : ''}
                                onChange={(e) => this.tagValue(e)}
                            />
                            {errors.type == "tag" && <span className="error-msg"> {errors.message} </span>}
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                            <Button disabled={isLoading} className="w-100 text-capitalize mt-3" onClick={knowledgeData ? this.updateKnowledgeFile : this.AddKnowledgefile}>{isLoading ? "Loading..." : knowledgeData ? "Update" : "Share this"}</Button>
                        </div>
                    </Modal.Body>
                </Modal>


                {/* Modal for Sharing Code */}
                <Modal className="common-modal small" centered show={this.state.visible && this.state.ismodelName === appConstant.TYPE.CODE} onHide={() => this.handleClose(appConstant.TYPE.CODE)}>
                    <button type="button" className="close fixed-close-btn" onClick={() => this.handleClose(appConstant.TYPE.CODE)}>
                        <span aria-hidden="true">×</span>
                    </button>
                    <Modal.Body className="">
                        <h4>Share Code</h4>
                        <div className="code-preview-box mb-3">
                            {/* <pre>
                                <span className="tag">&#10216;p&#10217;</span>i am paraghraph<span className="tag">&#10216;/p&#10217;</span>
                            </pre> */}
                            {/* <SyntaxHighlighter 
                                className="form-control form-control-code"
                                language="html javascript" 
                                style={monokai}
                                name="code"
                                value={code}
                                onChange={this.handleChange}
                                maxLength="3000" > */}
                            <textarea
                                name="code"
                                placeholder="Add your code here"
                                className="form-control form-control-code"
                                value={code}
                                onChange={this.handleChange}
                                maxLength="3000"
                            ></textarea>
                            {/* {code}
                            </SyntaxHighlighter> */}
                            {errors.type == "code" && <span className="error-msg"> {errors.message} </span>}
                        </div>
                        <div className="mt-3 mb-3">
                            <div className="suggestion-block">
                                <textarea
                                    name="message"
                                    placeholder="Describe your code here"
                                    className="form-control"
                                    value={message}
                                    onChange={this.handleChange}
                                    maxLength="3000"
                                ></textarea>
                                {errors.type == "message" && <span className="error-msg"> {errors.message} </span>}
                                <div className="d-flex justify-content-end">
                                    <span className="remaining-words">0/3000</span>
                                </div>
                            </div>
                        </div>
                        <div className="react-select-tag">
                            <CreatableSelect
                                isMulti
                                name="addTag"
                                options={this.props.tagsList}
                                className="common-select"
                                classNamePrefix="form-control"
                                placeholder={'Type to add tag'}
                                value={this.state.tagVal ? this.state.tagVal : ''}
                                onChange={(e) => this.tagValue(e)}
                            />
                            {errors.type == "tag" && <span className="error-msg"> {errors.message} </span>}
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                            <Button disabled={isLoading} className="w-100 text-capitalize mt-3" onClick={() => knowledgeData ? this.updateKnowledge() : this.addKnowledge(appConstant.TYPE.CODE)}>{isLoading ? "Loading..." : knowledgeData ? "Update" : "Share this"}</Button>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal className="common-modal small" centered show={this.state.shows} onHide={() => this.handleCloseimg("userUploadedImgs")}>
                    <button type="button" className="close fixed-close-btn" onClick={() => this.handleCloseimg("userUploadedImgs")}>
                        <span aria-hidden="true">×</span>
                    </button>
                    <Modal.Body className="">

                        <h4>Update Knowledge</h4>
                        <span className="common-text">This knowledge will be displayed to all your colleague on the home screen until you do not remove it.</span>
                        <div className="mt-3 mb-3">
                            <div className="new-topic-select">
                                <CreatableSelect
                                    isMulti
                                    name="addTag"
                                    options={this.props.tagsList}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    defaultValue={updatetags}
                                    onChange={(e) => this.tagValue(e)}
                                />
                            </div>
                            <div className="suggestion-block">
                                <textarea
                                    className='form-control'
                                    type='text'
                                    name='descriptions'
                                    placeholder='Enter announcement'
                                    value={this.state.descriptions}
                                    onChange={this.handleChange}
                                />
                                <div className="d-flex justify-content-end">
                                    <span className="remaining-words"><span>{strdescription.length}</span>/3000</span>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">

                            <Button onClick={this.updateKnowledge}>update</Button>
                        </div>
                    </Modal.Body>
                </Modal>
            </div >
        )
    }
}

const mapStateToProps = (state) => {
    return {
        userData: state.getUserData,
        knowledgeBankToReadData: state.knowledgeBankToReadData,
        knowledgeBankLinkData: state.knowledgeBankLinkData,
        knowledgeBankCodeData: state.knowledgeBankCodeData,
        knowledgeBankPhotosData: state.knowledgeBankPhotosData,
    }
}
export default connect(mapStateToProps, { callApi, setKnowledgeBankToReadData, setKnowledgeBankLinkData, setKnowledgeBankCodeData, setKnowledgeBankPhotosData })(_FloatingMenu)
