import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Button, Dropdown } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Token from '../../../../services/Token';
import { LOGOUT_USER } from '../../../../store/reducers/actionTypes';
// import PerfectScrollbar from 'react-perfect-scrollbar';
import 'rc-pagination/assets/index.css';
import 'antd/dist/antd.css';
import { Select } from 'antd';
import _ from 'lodash';
const { Option } = Select;


class NavigationBar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            navbarMenu: false,
            leftbar: false,
            announcementSidebar: false,
            mobileSearch: false,
            WebNavigationToggle: false,
            searchText: '',
            searchValue: '',
            searchValuei: '',
            searchValuek: '',
            first_name: '',
            employeedata: [],
            current_page: 1,
            per_page: 10,
            total: 1,
            last_page: false,
            total_pages: null,
        }
    }

    componentDidMount() {
        console.log("indexfile", this.props.match.path);
        console.log('componentDidMount this.props.userData-->', JSON.stringify(this.props.userData));
    }

    componentWillReceiveProps(newProps) {
        let listuser = this.props.employeeData.data;
        console.log('componentWillReceiveProps this.props.userData-->', JSON.stringify(newProps.userData));
        this.setState({
            employeedata: listuser
        })
    }

    WebNavigationToggle = () => {
        this.setState({ WebNavigationToggle: !this.state.WebNavigationToggle },
            function () {
                document.body.classList.add('modalview');
                document.body.classList.add('animate');
            });
    }

    NavbarToggle = () => {
        this.setState({ navbarMenu: !this.state.navbarMenu },
            function () {
                if (this.state.navbarMenu === true) {
                    document.body.classList.add('navbar-open');
                } else {
                    document.body.classList.remove('navbar-open');
                }
            });
    }

    LeftbarToggle = () => {
        this.setState({ leftbar: !this.state.leftbar },
            function () {
                document.body.classList.add('leftbar-open');
            });
    }

    AnnouncementToggle = () => {
        this.setState({ announcementSidebar: !this.state.announcementSidebar },
            function () {
                document.body.classList.add('announcement-open');
            });
    }

    logoutClicked() {
        Token.destroy();
        this.props.logout();
    }

    MobileSearchToggler = () => {
        this.setState({ mobileSearch: !this.state.mobileSearch },
            function () {
                if (this.state.mobileSearch === true) {
                    document.body.classList.add('mobile-search-open');
                } else {
                    document.body.classList.remove('mobile-search-open');
                }
            });
    }

    searchHandleOnChange = async (value) => {

        let user_id = _.get(this.props, 'userData.user_id') || '';
        let company_id = _.get(this.props, 'userData.company_id') || '';
        let { current_page } = this.state;
        this.setState({ searchText: value })
        if (this.props.history.location.pathname === '/feedback') {
            let body = {
                page: current_page,
                search: value,
                user_id, company_id
            }
            const response = await this.props.callApi('/searchSuggestions', body, 'post', null, true);

            //    let searchValue = this.state.searchValue
            if (response) {
                this.setState({
                    searchValue: [...this.state.searchValue, ...response.data],
                    per_page: response.meta.per_page,
                    total: response.meta.total
                });
                // this.handleScroll(value)
            }
        } else if (this.props.history.location.pathname === '/ideaSharing') {

            let body = {
                page: current_page,
                search: value,
            }
            const response = await this.props.callApi('/searchIdeas', body, 'post', null, true);
            if (response) {
                this.setState({
                    searchValuei: response.data,
                });
            }
            // || knowledgeBankLinks || knowledgeBankPhotos || knowledgeBankCode
        } else if (this.props.history.location.pathname === '/knowledgeBank') {
            let body = {
                page: current_page,
                search: value,
            }
            const response = await this.props.callApi('/searchKnowledges', body, 'post', null, true);
            if (response) {
                this.setState({
                    searchValuek: response.data,
                });
            }
        }
        else if (this.props.history.location.pathname === '/contacts') {

            this.setState((prevState) => ({
                employees: prevState.employeedata.filter(function (employee) {

                    if (employee.first_name !== undefined && employee.first_name !== "") {
                        return employee.first_name.toLowerCase().includes(value.toLowerCase());
                    }
                    return true;
                },
                    this.setState({
                        employe: this.state.employees
                    })
                )
            }))
        }

    };

    optionSelected = (data) => {
        if (this.props.location.pathname === '/feedback') {
            this.props.history.push({ pathname: '/feedback', view_node: data });
        } else if (this.props.location.pathname === '/ideaSharing') {
            this.props.history.push({ pathname: '/ideaSharing', view_node: data });
        } else if (this.props.location.pathname === '/knowledgeBank') {
            this.props.history.push({ pathname: '/knowledgeBank', view_node: data });
        } else if (this.props.history.location.pathname === '/contacts') {
            this.props.history.push({ pathname: '/contacts', view_node: data });
        }
        this.setState({ isOpensearch: false, searchText: '' })
    }


    onInputKeyDown = (event) => {
        if (event.keyCode === 13) {
            if (this.state.searchValue[0] === '') {
                // document.body.classList.remove("detail_search_body");
                this.props.history.push('/no_result')
                this.closeSearch();
                // document.body.className.replace("detail_search_body","");
            } else if (this.state.searchValue.length > 0) {
                // document.body.classList.remove("detail_search_body");
                this.props.history.push({ pathname: '/search_details', searchData: this.state.searchValue, name: this.state.searchText });
                this.closeSearch();
                // document.body.className.replace("detail_search_body","");
                this.setState({
                    searchText: ''
                })
            }
        }
    }

    //********************** PAGINATION *****************************************//
    // paginationChange(current_page) {
    //     let per_page =this.state.per_page;
    //     this.setState({
    //         current_page, per_page
    //     },()=>{this.searchHandleOnChange()});
    // } 

    /********************************* */
    handleScroll = (e) => {
        console.log("tes908", e)
        let element = e.target
        if (element.scrollTop + element.offsetHeight >= element.scrollHeight) {
            this.setState({ page: this.state.page + 1 }, () => {
                this.searchHandleOnChange()
            })
        }
    }

    getScreenTitle() {
        if (this.props.match.path === '/feedback') {
            return "Suggestion";
        } else if (this.props.match.path === '/ideaSharing') {
            return "Idea";
        } else if (this.props.match.path === '/knowledgeBank') {
            return "Knowledge Bank";
        } else if (this.props.match.path === '/contacts') {
            return "Contacts";
        } else if (this.props.match.path === '/announcement') {
            return "Broadcast";
        } else if (this.props.match.path === '/company') {
            return "Company Information";
        } else if (this.props.match.path === '/feedbackCategory') {
            return "Category";
        } else if (this.props.match.path === '/admin') {
            return "Admins";
        } else if (this.props.match.path === '/profile') {
            return "Profile";
        }
    }

    render() {
        let { searchText, searchValue, searchValuei, searchValuek, employe } = this.state;
        const { userData } = this.props;
        let isNameDisplay = this.props.history.location.pathname !== '/register' && this.props.history.location.pathname !== '/setPasscode' && this.props.history.location.pathname !== '/forgot-password' && this.props.history.location.pathname !== '/login'
        //   const options = this.state.data.map(d => <Option key={d.value}>{d.text}</Option>);
        const accessToken = window.localStorage.getItem('accessToken');
        return (
            <React.Fragment>
                <header className="">
                    <div className="navbar-inner navbar-web">
                        {(accessToken === null) ?
                            <Link className="logo" >
                                <img src="images/logo-new.svg" alt="LOGO" />
                                <span>EchoLoud</span>
                            </Link> :
                            <Link className="logo" to="/feedback">
                                <img src="images/logo-new.svg" alt="LOGO" />
                                <span>EchoLoud</span>
                            </Link>
                        }
                        <div className="plus-settings d-lg-inline d-none">
                            <span className="page-title-text">{this.getScreenTitle()}</span>
                        </div>
                        {
                            (false && (this.props.match.path === '/feedback' || this.props.match.path === '/ideaSharing' || this.props.match.path === '/knowledgeBank' || this.props.match.path === '/contacts')) ?
                                <div className="plus-settings d-lg-inline d-none">
                                    <div className="input-group search-bar" >
                                        {/* <PerfectScrollbar onScroll={this.handleScroll}> */}
                                        <div className="input-group-prepend">
                                            <div className="input-group-text" id="basic-addon1">
                                                <img src="images/icon-search-light.svg" alt="" />
                                            </div>
                                        </div>
                                        <React.Fragment>
                                            <Select
                                                showSearch
                                                value={searchText}
                                                style={this.props.style}
                                                defaultActiveFirstOption={false}
                                                showArrow={false}
                                                filterOption={false}
                                                onSearch={this.searchHandleOnChange}
                                                notFoundContent={null}
                                                onSelect={this.optionSelected}
                                                onInputKeyDown={this.onInputKeyDown}

                                            >
                                                {searchValuei && Array.isArray(searchValuei) && searchValuei.map((data, key) => {

                                                    return (
                                                        <Option key={data.idea_id}>
                                                            {/* text */}
                                                            {data.text}
                                                        </Option>
                                                    )
                                                })

                                                }
                                                {/* <div onScroll={this.handleScroll}> */}
                                                {searchValue && Array.isArray(searchValue) && searchValue.map((data, key) => {
                                                    return (
                                                        <Option key={data.feedback_id} >
                                                            {data.user_first_name} {data.user_last_name}
                                                            {/* {data.first_message} {data.last_message} */}
                                                        </Option>
                                                    )
                                                })
                                                }
                                                {/* </div> */}
                                                {
                                                    searchValuek && Array.isArray(searchValuek) && searchValuek.map((data, key) => {
                                                        return (
                                                            <Option key={data.knowledge_id} >
                                                                {/* text */}
                                                                {data.user_name}
                                                            </Option>
                                                        )
                                                    })
                                                }
                                                {
                                                    employe && Array.isArray(employe) && employe.map((data, key) => {
                                                        return (
                                                            <Option key={data.user_id}>
                                                                {data.first_name} {data.last_name}
                                                            </Option>
                                                        )
                                                    })
                                                }

                                            </Select>
                                        </React.Fragment>
                                        {/* </PerfectScrollbar> */}
                                    </div>

                                </div> : null
                        }


                        <div className="d-flex align-items-center">
                            {/* <div className="d-lg-none d-inline">
                                <span className="material-icons search-btn-mobile" onClick={this.MobileSearchToggler}>search</span>
                                <div className="input-group search-bar-mobile">
                                    <input className="form-control" type="text" placeholder="Search name or message" />
                                </div>
                            </div> */}

                            {isNameDisplay && userData && <div className="profile-image-text-header" onClick={() => this.props.history.push({ pathname: '/profile' })}>
                                <span>{userData.name}</span>
                                <div className="user-img">
                                    <div className="user-img-wrapper">
                                        {
                                            // <img src="images/annonymous.jpg" alt="" />

                                            <img src={userData.image} alt="images/may-tucker.jpg" />
                                        }
                                    </div>
                                </div>
                            </div>}

                            <Dropdown className="settings-menu mr-md-2 mx-2" alignRight>
                                <Dropdown.Toggle id="dropdown-basic">
                                    <span className="material-icons">settings</span>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {/* <Dropdown.Item href="/profile" className="profile-link">
                                        <span>
                                            <img src="https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" alt="" />
                                        </span>
                                    my profile
                                    </Dropdown.Item> */}
                                    {/* <Dropdown.Item href="/company">company</Dropdown.Item>
                                    <Dropdown.Item href="/admin">admin</Dropdown.Item>
                                    <Dropdown.Item href="/setPasscode">Change Passcode</Dropdown.Item>
                                    <Dropdown.Item href="/feedbackCategory">EchoLoud Category</Dropdown.Item>
                                    <Dropdown.Item href="#">Share your EchoLoud</Dropdown.Item>
                                    <Dropdown.Item href="/login">Logout</Dropdown.Item> */}
                                    <Dropdown.Item href="#">Push Notification</Dropdown.Item>
                                    {/* <Dropdown.Item href="/setPasscode">Change Passcode</Dropdown.Item> */}
                                    <Dropdown.Item href="#">Check for Update</Dropdown.Item>
                                    <Dropdown.Item href="https://hello.raiseaticket.com/support/#/newticket" target="_new">Report Issue</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            {(this.props.history.location.pathname === '/forgot-password' || this.props.history.location.pathname === '/register' || this.props.match.path === '/' || this.props.match.path === '/login' || this.props.match.path === '/companyRegistration' || this.props.match.path === '/emailVerification' || this.props.match.path === '/emailVerified') ? <span></span> :
                                <span className="material-icons menu-toggler" onClick={this.WebNavigationToggle}>menu</span>
                            }
                        </div>
                    </div>

                    <div className="navbar-inner navbar-mobile">
                        <div className="hamburger-menu hamburger-menu-two mr-2">
                            <input type="checkbox" className="input-check2" id="input-check2" hidden onClick={this.NavbarToggle} />
                            <label className="bars-container bars-container2" htmlFor="input-check2">
                                <span className="bar bar1"></span>
                                <span className="bar bar2"></span>
                                <span className="bar bar3"></span>
                            </label>
                        </div>
                        <Link className="navbar-brand" to="/feedback">
                            <img src="images/logo.svg" alt="LOGO" width="30" height="30" />
                        </Link>

                        <Button className="search-btn" onClick={this.MobileSearchToggler}>
                            <span className="material-icons">search</span>
                        </Button>


                        <div className="input-group search-bar">
                            <div className="input-group-prepend">
                                <div className="input-group-text" id="basic-addon1">
                                    <img src="images/icon-search-light.svg" alt="" />
                                </div>
                            </div>
                            <input
                                className="form-control"
                                type="text"
                                placeholder="Search name or message"
                                aria-label="Search"
                                aria-describedby="basic-addon1"
                            />
                        </div>
                        <Dropdown className="settings-menu" alignRight>
                            <Dropdown.Toggle id="dropdown-basic">
                                <span className="material-icons">settings</span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item href="/profile" className="profile-link">
                                    <span>
                                        <img src="https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" alt="" />
                                    </span>
                                    my profile
                                    </Dropdown.Item>
                                <Dropdown.Item href="/company">company</Dropdown.Item>
                                <Dropdown.Item href="/admin">admin</Dropdown.Item>
                                <Dropdown.Item href="/setPasscode">Change Passcode</Dropdown.Item>
                                <Dropdown.Item href="/feedbackCategory">EchoLoud Category</Dropdown.Item>
                                <Dropdown.Item href="#">Share your EchoLoud</Dropdown.Item>
                                <Dropdown.Item href="/login">Logout</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>

                        <ul className="navigation-menu">
                            <li>
                                <NavLink onClick={this.NavbarToggle} to="/feedback">
                                    <svg width={30} height={24} viewBox="0 0 19 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                        <image xlinkHref="images/icon-feedback.svg" width={19} height={24} />
                                        <image xlinkHref="images/icon-feedback-active.svg" width={19} height={24} />
                                    </svg>
                                    <span>EchoLoud</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink onClick={this.NavbarToggle} to="/contacts">
                                    <svg width={30} height={24} viewBox="0 0 30 21" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                        <image xlinkHref="images/icon-contact.svg" width={30} height={21} />
                                        <image xlinkHref="images/icon-contact-active.svg" width={30} height={21} />
                                    </svg>
                                    <span>contacts</span>
                                </NavLink>
                            </li>

                            <li>
                                <NavLink onClick={this.NavbarToggle} to="/announcement">
                                    <svg width={30} height={24} viewBox="0 0 19 23" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                        <image xlinkHref="images/icon-announcement.svg" width={19} height={23} />
                                        <image xlinkHref="images/icon-announcement-active.svg" width={19} height={23} />
                                    </svg>
                                    <span>announcement</span>
                                </NavLink>
                            </li>
                        </ul>

                    </div>
                </header>


                {/* <div className="togglers-block">
                    <span className="leftbar-toggler" onClick={this.LeftbarToggle}>
                        <span className="material-icons open-leftbar">more_vert</span>
                        <span className="material-icons close-leftbar">arrow_back</span>
                    </span>
                    <div className="expand-btn" onClick={this.AnnouncementToggle}>
                        <span className="material-icons">notifications_active</span>
                    </div>
                </div> */}


                <footer>
                    <div className="copyrights footer-text">
                        ©EchoLoud - 2020 All Rights Reserved.
                    </div>
                    <div className="policy-terms">
                        <Link to="/privacy_policy" className="footer-text">Privacy Policy</Link>
                        &nbsp;|&nbsp;
                        <Link to="/terms_conditions" className="footer-text">Terms of use</Link>
                    </div>
                </footer>
            </React.Fragment>
        );
    }
}

const mapStatsToProps = state => {
    return {
        authenticated: state.authenticated,
        userData: state.getUserData,
        employeeData: state.employeeList,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        logout: () => dispatch({ type: LOGOUT_USER })
    };
};

export default withRouter(connect(mapStatsToProps, mapDispatchToProps)(NavigationBar));
