/* eslint-disable react/jsx-pascal-case */
import React, { Component } from 'react';
import { Tab, Nav } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
    callApi, setAllFeedBackData, setSentFeedBackData, setReceivedFeedBackData,
    setKnowledgeBankLinkData, setKnowledgeBankToReadData, setKnowledgeBankCodeData,
    setKnowledgeBankPhotosData
} from '../../../store/reducers/commonFunction'
import 'react-activity/dist/react-activity.css';
import 'rc-pagination/assets/index.css';
import _ from 'lodash';
import _FilterComponent from '../Component/_FilterComponent';
import _FloatingMenu from '../Component/_FloatingMenu';
import KnowledgeBankLinks from '../knowledgeBankLinks';
import KnowledgeBankToRead from '../knowledgeBankToRead';
import KnowledgeBankCode from '../knowledgeBankCode';
import KnowledgeBankPhotos from '../knowledgeBankPhotos';

class LeftbarKB extends Component {

    /**
    |--------------------------------------------------
    | constructor
    |--------------------------------------------------
    */

    constructor(props) {
        super(props);
        this.state = {
            multipleSelect: [],
            per_page: 10,
            total: 1,
            total_pages: null,
            isFormValid: true,
            leftbar: false,
            userDetails: '',
            status: '',
            categories: '',
            visible: false,
            is_public: false,
            is_open: false,
            is_close: false,
            show: false,
            visibles: false,

            current_page: 1,
            last_page: 1,
            isLoading: false,

            activeTab: 'toRead',
            tagVal: [],
            filterArr: [],
        }
    }

    /**
    |--------------------------------------------------
    | componentDidMount
    |--------------------------------------------------
    */

    async componentDidMount() {
        this.props.onRef && this.props.onRef(this);
    }

    /**
    |--------------------------------------------------
    | componentWillUnmount
    |--------------------------------------------------
    */

    componentWillUnmount() {
        this.props.onRef && this.props.onRef(undefined)
    }

    /**
    |--------------------------------------------------
    | getAllCategory
    |--------------------------------------------------
    */

    getAllCategory = async () => {

        let body = {}
        const response = await this.props.callApi('/getCategory', body, 'post', 'ADMIN_LOGIN', true);
        //  if(response.data !==undefined){
        this.props.setCategoryList(response);
        this.setState({ categories: response.data })
        //  }

    }

    /**
    |--------------------------------------------------
    | getCategoryId
    |--------------------------------------------------
    */

    getCategoryId = (data, type) => {

        if (this.props && this.props.getChildEvent) {
            this.props.getChildEvent(data, type);
        }
        // this.props.getChildEvent(catId);
    }

    /**
    |--------------------------------------------------
    | closeModal
    |--------------------------------------------------
    */

    closeModal = () => {
        this.setState({
            visible: !this.state.visible,
        })
    }

    /**
    |--------------------------------------------------
    | LeftbarToggle
    |--------------------------------------------------
    */

    LeftbarToggle = () => {
        this.setState({ leftbar: !this.state.leftbar },
            function () {
                document.body.classList.remove('leftbar-open');
            });
    }

    /**
    |--------------------------------------------------
    | getUserName
    |--------------------------------------------------
    */

    getUserName = (data) => {
        return (_.get(this.props, 'userData.user_id') == data.user_id) ? "You" : _.get(data, 'user_first_name') || ''
    }

    /**
    |--------------------------------------------------
    | renderNoRecordFound
    |--------------------------------------------------
    */

    renderNoRecordFound = () => {
        let title = (this.state.visibles) ? "Try Another Filter" : "Share anything you feel!"
        let subTitle = (this.state.visibles) ? "We did not find any records matching with you filter.Try something different!" : "Select topic and send suggestions regarding\nanything with your name or anonymously"
        return (
            <div className="empty-no-data">
                <img src="images/img-empty-suggestions.png" alt="no data found" />
                <h6>{title}</h6>
                <span>{subTitle}</span>
            </div>
        )
    }

    /**
    |--------------------------------------------------
    | onPressFilter
    |--------------------------------------------------
    */

    onPressFilter = (tags, filter) => {
        // this.setState({
        //     tagVal: tags,
        //     filterArr: filter,
        // });
        this.knowledgeBankToReadRef.setState({
            current_page: 1
        }, () => {
            this.props.setKnowledgeBankToReadData([]);
            this.knowledgeBankToReadRef.getKnowledgeBankToReadData(false)
        })

        this.knowledgeBankLinkRef.setState({
            current_page: 1
        }, () => {
            this.props.setKnowledgeBankLinkData([]);
            this.knowledgeBankLinkRef.getKnowledgeBankLinkData(false)
        })


        this.knowledgeBankCodeRef.setState({
            current_page: 1
        }, () => {
            this.props.setKnowledgeBankCodeData([]);
            this.knowledgeBankCodeRef.getKnowledgeCodeData(false)
        })
    }

    /**
    |--------------------------------------------------
    | getKnowledgeList
    |--------------------------------------------------
    */

    getKnowledgeList = async (body = {}) => {
        const response = await this.props.callApi('/getKnowledgeList', body, 'post', 'ADMIN_LOGIN', true);
        return response;
    }

    /**
    |--------------------------------------------------
    | likeCommentView
    |--------------------------------------------------
    */

    likeCommentView = (KnowledgeDetail) => {
        return (
            <div className="control-panel-block idea-like-control-panel">
                <div className="likes-comments-block sidebar-wrap-likes-comments-block">
                    <div className="control-panel-options likes-block">
                        <div className="icon-outer likes-icon">
                            <div className="likes-checkbox">
                                <label htmlFor="like">
                                    <input type="checkbox" className="like-input" defaultChecked={KnowledgeDetail.is_liked ? true : false} onClick={() => this.props.handleLike(KnowledgeDetail)} />
                                    {
                                        (KnowledgeDetail.is_liked === 1) ?
                                            <img src="/images/like-icon-pink.svg" alt="liked post" className="purple-icon" /> :
                                            <img src="/images/like-icon-gray.svg" alt="like post" className="gray-icon" />
                                    }
                                </label>
                            </div>
                        </div>
                        <div className="counter-label-block">
                            {(KnowledgeDetail.total_likes === 0) ? <span className="likes-label">No Likes</span> :
                                (KnowledgeDetail.is_liked === 0 && KnowledgeDetail.total_likes === 1) ? <span className="likes-label">{KnowledgeDetail.total_likes || 0} Like</span> :
                                    (KnowledgeDetail.is_liked === 1 && KnowledgeDetail.total_likes === 1) ? <span className="active-likes-label">{KnowledgeDetail.total_likes || 0} Like</span> :
                                        (KnowledgeDetail.is_liked === 1 && KnowledgeDetail.total_likes >= 1) ? <span className="active-likes-label">{KnowledgeDetail.total_likes || 0} Likes</span> :
                                            <span className="likes-label">{KnowledgeDetail.total_likes} Likes</span>}
                        </div>
                    </div>
                    <div className="control-panel-options comments-block">
                        <div className="icon-outer">
                            <img src="images/comments-icon.svg" alt="" width="15" />
                        </div>
                        <div className="counter-label-block">
                            {
                                (KnowledgeDetail.total_comments === 0) ? <span className="label">no comments</span> :
                                    (KnowledgeDetail.total_comments === 1) ? <span className="label">{KnowledgeDetail.total_comments} comment</span> :
                                        <span className="label">{KnowledgeDetail.total_comments} comments</span>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    /**
    |--------------------------------------------------
    | render
    |--------------------------------------------------
    */

    render() {
        // let feedback_id = this.props.feedbackslist.data.feedback_id?this.props.feedbackslist.data.feedback_id:'';
        const { tagsList, knowledgeDetail } = this.props;
        var { filterArr, tagVal, activeTab } = this.state;

        return (
            <div className="leftbar">

                <span className="leftbar-toggler" onClick={this.LeftbarToggle}>
                    <span className="material-icons">arrow_back</span>
                </span>

                <div className="fbtn plus-filter" >
                    <_FloatingMenu
                        tagsList={tagsList}
                        leftBarKbRef={this}
                        getChildEvent={this.props.getChildEvent}
                        sidebarAnnouncementRef={this.props.sidebarAnnouncementRef}
                    />
                </div>

                <_FilterComponent
                    tagsList={tagsList}
                    onPressFilter={this.onPressFilter}
                />

                <Tab.Container id="left-tabs-example" activeKey={activeTab} defaultActiveKey="toRead" onSelect={(activeTab) => this.setState({ activeTab })}>
                    <Nav variant="tabs" className="tab-kb-nav">
                        <Nav.Item>
                            <Nav.Link eventKey="toRead" onClick={() => {
                                this.props.knowledgeBankRef.setState({
                                    knowledge_id: undefined,
                                    knowledgeDetail: undefined,
                                    current_page: 1,
                                    isLoading: false,
                                    comments: []
                                })
                                this.knowledgeBankToReadRef.setState({
                                    current_page: 1
                                }, () => {
                                    // this.props.setKnowledgeBankToReadData([]);
                                    this.knowledgeBankToReadRef.getKnowledgeBankToReadData(false)
                                })
                            }}>To Read</Nav.Link>
                        </Nav.Item>

                        <Nav.Item>
                            <Nav.Link eventKey="links" onClick={() => {
                                this.props.knowledgeBankRef.setState({
                                    knowledge_id: undefined,
                                    knowledgeDetail: undefined,
                                    current_page: 1,
                                    isLoading: false,
                                    comments: []
                                })
                                this.knowledgeBankLinkRef.setState({
                                    current_page: 1
                                }, () => {
                                    // this.props.setKnowledgeBankLinkData([]);
                                    this.knowledgeBankLinkRef.getKnowledgeBankLinkData(false)
                                })
                            }}>Links</Nav.Link>
                        </Nav.Item>

                        <Nav.Item>
                            <Nav.Link eventKey="photo" onClick={() => {
                                this.props.knowledgeBankRef.setState({
                                    knowledge_id: undefined,
                                    knowledgeDetail: undefined,
                                    current_page: 1,
                                    isLoading: false,
                                    comments: []
                                })
                                this.knowledgeBankPhotosRef.setState({
                                    current_page: 1
                                }, () => {
                                    // this.props.setKnowledgeBankPhotosData([]);
                                    this.knowledgeBankPhotosRef.getKnowledgePhotos(false)
                                })
                            }}>Photos</Nav.Link>
                        </Nav.Item>

                        <Nav.Item>
                            <Nav.Link eventKey="code" onClick={() => {
                                this.props.knowledgeBankRef.setState({
                                    knowledge_id: undefined,
                                    knowledgeDetail: undefined,
                                    current_page: 1,
                                    isLoading: false,
                                    comments: []
                                })
                                this.knowledgeBankCodeRef.setState({
                                    current_page: 1
                                }, () => {
                                    // this.props.setKnowledgeBankCodeData([]);
                                    this.knowledgeBankCodeRef.getKnowledgeCodeData(false)
                                })
                            }}>Code</Nav.Link>
                        </Nav.Item>
                    </Nav>

                    <Tab.Content>
                        <Tab.Pane eventKey="toRead">
                            <ul className="leftbar-list feedback-list">
                                <KnowledgeBankToRead
                                    onRef={ref => this.knowledgeBankToReadRef = ref}
                                    getChildEvent={this.props.getChildEvent}
                                    activeTab={activeTab}
                                    // onItemClick={(data, type) => { this.props.getChildEvent(data, type); this.LeftbarToggle() }}
                                    filterArr={filterArr}
                                    tagVal={tagVal}
                                    LeftbarToggle={this.LeftbarToggle}
                                    knowledgeDetail={knowledgeDetail}
                                    getKnowledgeList={this.getKnowledgeList}
                                    noRecordView={this.renderNoRecordFound}
                                    likeCommentView={this.likeCommentView}
                                />
                            </ul>
                        </Tab.Pane>

                        <Tab.Pane eventKey="links">
                            <ul className="leftbar-list feedback-list">
                                <KnowledgeBankLinks
                                    onRef={ref => this.knowledgeBankLinkRef = ref}
                                    getChildEvent={this.props.getChildEvent}
                                    activeTab={activeTab}
                                    // onItemClick={(data, type) => { this.props.getChildEvent(data, type); this.LeftbarToggle() }}
                                    filterArr={filterArr}
                                    tagVal={tagVal}
                                    LeftbarToggle={this.LeftbarToggle}
                                    knowledgeDetail={knowledgeDetail}
                                    getKnowledgeList={this.getKnowledgeList}
                                    noRecordView={this.renderNoRecordFound}
                                    likeCommentView={this.likeCommentView}
                                />
                            </ul>
                        </Tab.Pane>

                        <Tab.Pane eventKey="photo">
                            <ul className="leftbar-list feedback-list">
                                <KnowledgeBankPhotos
                                    onRef={ref => this.knowledgeBankPhotosRef = ref}
                                    getChildEvent={this.props.getChildEvent}
                                    activeTab={activeTab}
                                    // onItemClick={(data, type) => { this.props.getChildEvent(data, type); this.LeftbarToggle() }}
                                    filterArr={filterArr}
                                    tagVal={tagVal}
                                    LeftbarToggle={this.LeftbarToggle}
                                    knowledgeDetail={knowledgeDetail}
                                    getKnowledgeList={this.getKnowledgeList}
                                    noRecordView={this.renderNoRecordFound}
                                    likeCommentView={this.likeCommentView}
                                />
                            </ul>
                        </Tab.Pane>

                        <Tab.Pane eventKey="code">
                            <ul className="leftbar-list feedback-list">
                                <KnowledgeBankCode
                                    onRef={ref => this.knowledgeBankCodeRef = ref}
                                    getChildEvent={this.props.getChildEvent}
                                    activeTab={activeTab}
                                    // onItemClick={(data, type) => { this.props.getChildEvent(data, type); this.LeftbarToggle() }}
                                    filterArr={filterArr}
                                    tagVal={tagVal}
                                    LeftbarToggle={this.LeftbarToggle}
                                    knowledgeDetail={knowledgeDetail}
                                    getKnowledgeList={this.getKnowledgeList}
                                    noRecordView={this.renderNoRecordFound}
                                    likeCommentView={this.likeCommentView}
                                />
                            </ul>
                        </Tab.Pane>

                    </Tab.Content>
                </Tab.Container>

            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        userData: state.getUserData,
        knowledgeBankLinkData: state.knowledgeBankLinkData,
        knowledgeBankToReadData: state.knowledgeBankToReadData,
    }
}
export default withRouter(connect(mapStateToProps, { callApi, setKnowledgeBankLinkData, setKnowledgeBankToReadData, setAllFeedBackData, setSentFeedBackData, setReceivedFeedBackData, setKnowledgeBankCodeData, setKnowledgeBankPhotosData })(LeftbarKB))