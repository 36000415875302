import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
// import NumberFormat from 'react-number-format';
import Select from 'react-select';
import { Animated } from 'react-animated-css';
import MobileToggle from '../mobileToggles/mobileToggles';
import Leftbar from './leftbar/Leftbar';
import SidebarAnnouncement from '../sidebarAnnouncement/SidebarAnnouncement';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { callApi } from '../../store/reducers/commonFunction'
import common from '../../store/reducers/commons'
import errorMessages from '../../store/reducers/errorMessages'
class AddAdmin extends Component {

    /**
    |--------------------------------------------------
    | constructor
    |--------------------------------------------------
    */

    constructor(props) {
        super(props);
        this.state = {
            employeeList: [],
            multipleSelect: [],
            roleadmin: false,
            rolecategoryadmin: false
        }
    }

    /**
    |--------------------------------------------------
    | componentDidMount
    |--------------------------------------------------
    */

    async componentDidMount() {
        await this.getEmployeeDetails();
        // if(this.props.adminList)
        // this.setState({
        //     admindetails:this.props.adminList,
        //     role:this.props.adminList.role
        // })
        // if(this.props.adminList.role == 'admin'){
        //   this.setState({
        //     roleadmin:true,
        //   })  
        // }else{
        //     this.setState({
        //         rolecategoryadmin:true,
        //       })

        // }

    }

    /**
    |--------------------------------------------------
    | getEmployeeDetails
    |--------------------------------------------------
    */

    getEmployeeDetails = async () => {

        let company_id = this.props.userData.company_id ? this.props.userData.company_id : '';
        let body = {
            company_id
        }
        const response = await this.props.callApi('/getEmployee', body, 'post', null, true);
        if (response) {
            this.setState({
                employeeList: response.data
            })
        }
    }

    /**
    |--------------------------------------------------
    | changeCategory
    |--------------------------------------------------
    */

    changeCategory = (e) => {
        this.setState({ employeVal: e, user_id: e.value })
    }

    /**
    |--------------------------------------------------
    | AddAdmin
    |--------------------------------------------------
    */

    AddAdmin = async () => {

        try {
            var { role, user_id, multipleSelect } = this.state;
            let company_id = this.props.userData.company_id ? this.props.userData.company_id : '';
            let body = {
                user_id, category_id: multipleSelect.toString(), role, company_id
            }
            const response = await this.props.callApi('/addAdmin', body, 'post', null, true);
            if (response) {
                common.showSuccessMessage(response.data.message);
                this.props.history.push("/admin")
            }
        } catch (error) {
            throw (error)
        }
    }

    /**
    |--------------------------------------------------
    | validateForm
    |--------------------------------------------------
    */

    validateForm() {
        let { message, errors, isFormValid } = this.state;
        if (!message.trim()) errors.message = errorMessages.PROVIDE_NAME;
        else errors.message = '';

        if (errors.message !== '') isFormValid = false;
        else isFormValid = true;
        this.setState({ errors, isFormValid })
        return isFormValid
    }

    /**
    |--------------------------------------------------
    | checkArray
    |--------------------------------------------------
    */

    checkArray = (id) => {

        let { multipleSelect } = this.state;
        if (multipleSelect.includes(id)) return true;
        else return false;
    }

    /**
    |--------------------------------------------------
    | onCheckbox
    |--------------------------------------------------
    */

    onCheckbox(id) {

        var delarray = this.state.multipleSelect;
        if (!delarray.includes(id)) {
            delarray.push(id);
        }
        else {
            delarray.splice(delarray.indexOf(id), 1);
        }
        if (delarray.length !== this.state.employeeList.length) {
            this.setState({ checked: false });
        }
        if (this)
            this.setState({ multipleSelect: delarray })
    }

    /**
    |--------------------------------------------------
    | selectRole
    |--------------------------------------------------
    */

    selectRole = (e) => {
        // this.setState({rolestatus:!this.state.role})
        // if(this.state.rolestatus === 'true'){
        this.setState({ role: e.target.value })
        // }
    }

    /**
    |--------------------------------------------------
    | selectRoleadmin
    |--------------------------------------------------
    */

    selectRoleadmin = (e) => {
        // this.setState({rolestatus:!this.state.role})
        // if(this.state.rolestatus === 'true'){
        this.setState({ role: e.target.value })
        // }
    }

    /**
    |--------------------------------------------------
    | setParentStatus
    |--------------------------------------------------
    */

    setParentStatus = (admin) => {
        this.setState({
            adminData: admin
        })
    }

    /**
    |--------------------------------------------------
    | render
    |--------------------------------------------------
    */

    render() {
        let { employeeList } = this.state;
        // { Option } = Select;
        let getEmployeList = employeeList && Array.isArray(employeeList) && employeeList.map((employee, key) => {
            return (
                {
                    value: employee.user_id, label: <div className="select-color"><span className="color-dot"><img src={employee.image} alt="" /></span><span className="color-name">{employee.first_name} {employee.last_name}</span></div>
                }
            )
        })
        var categoriesList = this.props.categoryData.data ? this.props.categoryData.data : ''
        return (
            <div className="main-page-wrapper contacts-page">
                <MobileToggle {...this.props} />
                <Leftbar {...this.props} getChildEvent={this.setParentStatus} selectedUser={{ category: [] }} />
                <SidebarAnnouncement {...this.props} />
                <div className="content-area-wrapper">
                    <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={true} animationInDelay={500}>
                        <div className="content-area-header">
                            <h6 className="">Add New Admin</h6>
                        </div>

                        <div className="content-area-inner">

                            <div className="add-new-admin-block">
                                <Select
                                    value={this.state.employeVal ? this.state.employeVal : ''}
                                    options={getEmployeList}
                                    className="common-select"
                                    classNamePrefix="form-control"
                                    isSearchable={false}
                                    placeholder="Select User"
                                    onChange={(e) => this.changeCategory(e)}
                                // menuIsOpen={true}
                                />

                                <div className="give-permission-block mt-4">
                                    <p className="s1-subtitle mb-3">Permission</p>
                                    <div className="checkbox-radio w-100 my-2">
                                        <label className="mb-0">
                                            <input type="radio" name="permission" value='admin' onChange={(e) => this.selectRole(e)} />
                                            <span>
                                                <span className="radio-style"></span>
                                                <span className="text">admin</span>
                                            </span>
                                        </label>
                                    </div>
                                    <div className="checkbox-radio w-100 my-2 category-admin">
                                        <label className="mb-0">
                                            <input type="radio" name="permission" value='categoryadmin' onClick={(e) => this.selectRoleadmin(e)} />
                                            <span>
                                                <span className="radio-style"></span>
                                                <span className="text">category admin</span>
                                            </span>
                                            <div className="select-admin-category mt-4">
                                                <p className="s1-subtitle mb-2">Select Category</p>
                                                {
                                                    categoriesList && Array.isArray(categoriesList) && categoriesList.map((category, key) => {
                                                        return (
                                                            <div className="checkbox-radio w-100 my-2" key={key}>
                                                                <label className="mb-0">
                                                                    <input type="checkbox" value="" checked={(this.checkArray(category.id)) ? true : false} onChange={() => this.onCheckbox(category.id)} />

                                                                    {/* <input type="checkbox" value="" /> */}
                                                                    <span>
                                                                        <span className="checkbox-style"></span>
                                                                        <span className="text tnc-check" style={{ color: "#" + category.color_code }}>{category.name}
                                                                        </span>
                                                                    </span>
                                                                </label>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </label>
                                    </div>
                                </div>

                                <Button className="mt-4" onClick={this.AddAdmin}>make admin</Button>
                            </div>

                        </div>
                    </Animated>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        categoryData: state.categoryList,
        userData: state.getUserData,
        adminList: state.adminDetailsList

    }
}
export default withRouter(connect(mapStateToProps, { callApi })(AddAdmin))
