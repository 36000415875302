import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import common from '../../../store/reducers/commons'
import { callApi } from '../../../store/reducers/commonFunction'
// import { Button } from 'react- ';
import { Animated } from "react-animated-css";
import errorMessages from '../../../store/reducers/errorMessages'
import { Spinner } from 'react-activity';
import 'react-activity/dist/react-activity.css';

class LoginEmail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            errors: {
                email: '',
            },
            isLoading: false
        }
    }

    handleChange = (event) => {
        let { errors, isFormValid } = this.state;
        if (!isFormValid) {
            if (event.target.name === 'email') {
                if (!common.validateEmail(event.target.value)) errors.email = errorMessages.PROVIDE_VALID_EMAIL;
                else errors.email = true;
            }
        }
        this.setState({ [event.target.name]: event.target.value, errors });
    }

    //******************** Forgot password **************************/ 

    forgotPassword = async (e) => {
        e.preventDefault();
        var body = { email: this.state.email };
        if (this.validateForm()) {
            this.setState({ isLoading: true })
            const response = await this.props.callApi('/requestResetPassword', body, 'post');
            this.setState({ isLoading: false })
            if (response.data.status === true) {
                this.setState({ email: '' })
                common.setItem("passwordToken", response.data.token)
                common.showSuccessMessage(response.data.message);
            } else {
                common.showErrorMessage(response.data.message);
            }
        }
    }
    /********************  VALIDATION   *********************************/
    validateForm() {
        let { email, errors, isFormValid } = this.state;
        // for email
        if (!email.trim()) errors.email = errorMessages.PROVIDE_EMAIL;
        else if (!common.validateEmail(email)) errors.email = errorMessages.PROVIDE_VALID_EMAIL;
        else errors.email = true;

        if (errors.email !== true) isFormValid = false;
        else isFormValid = true;
        this.setState({ errors, isFormValid });
        return isFormValid;
    }

    render() {

        let { email, errors, isLoading } = this.state;
        return (
            <div className="login-register-wrapper register-page">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 mx-auto">
                            <h2 className="page-heading mb-2">Forgot Password</h2>
                            {/* <p className="mb-4">Register via your company email to connect with the people of your company.</p> */}

                            <form>
                                <div className="form-group mb-4">

                                    <input type='text'
                                        className={errors.email ? 'form-control input-error' : 'form-control'}
                                        name='email'
                                        value={email}
                                        id='email'
                                        placeholder='Enter Email ID'
                                        onChange={this.handleChange} />
                                    <i className='fa fa-user' aria-hidden='true' />
                                    <span className='error-msg'>{errors.email}</span>
                                </div>
                                {/* <Link type="submit" className="btn btn-primary btn-block" to="/companyRegistration">Next</Link> */}
                                <button className='btn btn-block btn-primary btn-lg font-weight-medium' type='submit' onClick={this.forgotPassword}>
                                    {isLoading ? <Spinner color={'#fff'} /> : <span>Submit </span>}
                                </button>

                            </form>
                        </div>

                        <div className="col-lg-7 text-center d-lg-block d-none">
                            <div className="register-img-block">
                                <img src="/images/register-page-bgi-img.svg" alt="" />
                                <div className="register-social-icons-img">
                                    <Animated animationIn="bounceIn" animationOut="fadeOut" isVisible={true} animationInDelay={1800}>
                                        <img src="/images/register-page-social-icons-img.svg" alt="" className="" />
                                    </Animated>
                                </div>
                                <div className="register-bird-img">
                                    <Animated animationIn="fadeInLeft" animationOut="fadeOut" isVisible={true} animationInDelay={3000}>
                                        <img src="/images/register-page-bird-img.svg" alt="" className="" />
                                    </Animated>
                                </div>
                                <div className="register-character-img">
                                    <Animated animationIn="fadeInRight" animationOut="fadeOut" isVisible={true} animationInDelay={500}>
                                        <img src="/images/register-page-character-img.svg" alt="" className="" />
                                    </Animated>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default withRouter(connect(null, { callApi })(LoginEmail))
