
import React from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';
import CreatableSelect from 'react-select/creatable';

function _ShareLinkModal(props) {
    const { isUpdateFetching, isMetaDataFetching, show, onHidePress, handleClose, linkTextFieldName, handleChange, linkTextFieldValue, descriptionTextFieldName, descriptionTextFieldValue, errors, tagsList, tagVal, onChangeTagValue, AddKnowledgelinkOnPress } = props;
    return (
        <Modal
            className="common-modal small"
            centered
            show={show}
            onHide={onHidePress}
        >
            <button type="button" className="close fixed-close-btn" onClick={handleClose}>
                <span aria-hidden="true">×</span>
            </button>

            <Modal.Body className="">
                <h4>Share Link</h4>
                <div className="mb-3">
                    <div className="suggestion-block">
                        <div className="spinner-parent">
                            <input
                                type="email"
                                className="form-control"
                                placeholder="www.example.com"
                                name={linkTextFieldName}
                                value={linkTextFieldValue}
                                onChange={handleChange}
                            />
                            {isMetaDataFetching && <Spinner size="sm" animation="border" />}
                        </div>
                        <span className="error-msg">{errors.link} </span>
                    </div>
                </div>

                <div className="mb-3">
                    <div className="suggestion-block">
                        <textarea
                            placeholder="Describe your link here"
                            className="form-control"
                            name={descriptionTextFieldName}
                            value={descriptionTextFieldValue}
                            onChange={handleChange}
                            maxLength="3000"
                        />
                        <span className="error-msg"> {errors.message} </span>
                        <div className="d-flex justify-content-end">
                            <span className="remaining-words"><span>{descriptionTextFieldValue.length}</span>/3000</span>
                        </div>
                    </div>
                </div>
                <div className="react-select-tag">
                    <CreatableSelect
                        isMulti
                        name="addTag"
                        options={tagsList}
                        className="common-select"
                        classNamePrefix="form-control"
                        placeholder={"Type to add tag"}
                        value={tagVal ? tagVal : ''}
                        onChange={(e) => onChangeTagValue(e)}
                    />
                    <span className="error-msg"> {errors.tag} </span>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                    <Button
                        className="w-100 text-capitalize mt-3"
                        disabled={isUpdateFetching}
                        onClick={AddKnowledgelinkOnPress}>
                        {(isUpdateFetching) ? 'Loading...' : 'Share this'}
                    </Button>
                </div>
            </Modal.Body>
        </Modal >
    )
}

_ShareLinkModal.propTypes = {
    show: PropTypes.bool,
    onHidePress: PropTypes.func,
    handleClose: PropTypes.func,
    linkTextFieldName: PropTypes.string,
    handleChange: PropTypes.any,
    linkTextFieldValue: PropTypes.any,
    descriptionTextFieldName: PropTypes.string,
    descriptionTextFieldValue: PropTypes.string,
    errors: PropTypes.any,
    tagsList: PropTypes.any,
    tagVal: PropTypes.any,
    onChangeTagValue: PropTypes.any,
    AddKnowledgelinkOnPress: PropTypes.any,
    isMetaDataFetching: PropTypes.bool,
};

export default _ShareLinkModal;