import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import Select from 'react-select';
import NumberFormat from 'react-number-format';
import { Animated } from 'react-animated-css';
import MobileToggle from '../mobileToggles/mobileToggles';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import Leftbar from './leftbar/Leftbar';
import { callApi, getAdminDetails, setAdminData } from '../../store/reducers/commonFunction';
import SidebarAnnouncement from '../sidebarAnnouncement/SidebarAnnouncement';
import errorMessages from '../../store/reducers/errorMessages'
import swal from 'sweetalert'
import common from '../../store/reducers/commons'
import PermissionModal from '../../component/PermissonModal/PermissionModal';
import { Link } from 'react-router-dom';
import _ from 'lodash'
import helper from '../../services/helper';
import appConstant from '../../constant/appConstant';

class Admin extends Component {

    /**
    |--------------------------------------------------
    | constructor
    |--------------------------------------------------
    */

    constructor(props) {
        super(props);
        this.state = {
            viewEmployee: true,
            editEmployee: false,
            selectedUserData: undefined,
            employeDelete: '',
            isPermissionModalVisible: false,
            addAdminModalVisible: false,
            employeeList: [],
            selectedCategory: [],
            selectedRole: 'admin',
            selectedEmployee: undefined,
            errors: {
                message: ''
            },
            isLoading: false,
        }
        if (!_.get(this.props, 'userData')) {
            this.props.history.push('/register')
        }
    }

    /**
    |--------------------------------------------------
    | componentDidMount
    |--------------------------------------------------
    */

    async componentDidMount() {
        this.getEmployeeDetails();
    }

    /**
    |--------------------------------------------------
    | getEmployeeDetails
    |--------------------------------------------------
    */

    getEmployeeDetails = async () => {

        let company_id = this.props.userData.company_id ? this.props.userData.company_id : '';
        let body = {
            company_id
        }
        const response = await this.props.callApi('/getEmployee', body, 'post', null, true);
        if (response) {
            let employeeList = response.data && Array.isArray(response.data) && response.data.map((employee, key) => {
                return (
                    {
                        value: employee.user_id, label: <div className="select-color"><span className="color-dot"><img src={employee.image} alt="" /></span><span className="color-name">{employee.first_name} {employee.last_name}</span></div>
                    }
                )
            })
            this.setState({
                employeeList: employeeList
            })
        }
    }

    /**
    |--------------------------------------------------
    | viewEmployeeBtn
    |--------------------------------------------------
    */

    viewEmployeeBtn = () => {
        this.setState({ viewEmployee: true })
        this.setState({ editEmployee: false })
    }

    /**
    |--------------------------------------------------
    | editEmployeeBtn
    |--------------------------------------------------
    */

    editEmployeeBtn = () => {
        this.setState({ viewEmployee: false })
        this.setState({ editEmployee: true })
    }

    /**
    |--------------------------------------------------
    | setParentStatus
    |--------------------------------------------------
    */

    setParentStatus = (admin) => {
        this.setState({
            selectedUserData: admin
        })
    }

    /**
    |--------------------------------------------------
    | deleteUser
    |--------------------------------------------------
    */

    async deleteUser(uid, cid) {
        // var delArr = this.state.employeDelete;

        swal({
            title: "Are you sure you want to remove this admin ?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async willDelete => {
                if (willDelete) {
                    //   if (delArr.length > 0) {
                    //     var body = { user_id: delArr,company_id:delArr }
                    //   }
                    //   else {
                    var body = { user_id: uid, company_id: cid }
                    //   }
                    const response = await this.props.callApi('/removeAdmin', body, 'post', null, true);
                    if (response.data.code === 200) {
                        common.showSuccessMessage(response.data.message);

                        let adminData = this.props.adminData;
                        let selectedIndex = adminData.findIndex(x => x.user_id == uid);
                        adminData.splice(selectedIndex, 1);

                        this.setParentStatus(adminData[selectedIndex] || adminData[0]);
                        this.props.setAdminData([...adminData]);

                        // this.getAllUsers()
                    }
                }
            });
    }

    /**
    |--------------------------------------------------
    | openAddAdminModal
    |--------------------------------------------------
    */

    openAddAdminModal = () => {
        const { userData } = this.props;
        let role = _.get(userData, 'role') || '';
        this.setState({
            addAdminModalVisible: true,
            errors: {
                message: ''
            },
            selectedEmployee: undefined,
            selectedEmployeeUse_id: undefined,
            selectedCategory: [],
            selectedRole: role == "admin" ? "categoryadmin" : 'admin'
        })
    }

    /**
    |--------------------------------------------------
    | changeEmployee
    |--------------------------------------------------
    */

    changeEmployee = (e) => {
        this.setState({ selectedEmployee: e, selectedEmployeeUse_id: e.value })
    }

    /**
    |--------------------------------------------------
    | checkArray
    |--------------------------------------------------
    */

    checkArray = (id) => {

        let { selectedCategory } = this.state;
        if (selectedCategory.includes(id)) return true;
        else return false;
    }

    /**
    |--------------------------------------------------
    | onCheckbox
    |--------------------------------------------------
    */

    onCheckbox(id) {

        var delarray = this.state.selectedCategory;
        if (!delarray.includes(id)) {
            delarray.push(id);
        }
        else {
            delarray.splice(delarray.indexOf(id), 1);
        }

        this.setState({
            selectedCategory: delarray,
            errors: {
                ...this.state.errors,
                categoryMessage: ''
            }
        })
    }

    /**
    |--------------------------------------------------
    | selectRole
    |--------------------------------------------------
    */

    selectRole = (e) => {
        this.setState({ selectedRole: e.target.value })
    }

    /**
    |--------------------------------------------------
    | AddAdmin
    |--------------------------------------------------
    */

    AddAdmin = async () => {

        try {
            if (this.validateForm()) {
                this.setState({
                    isLoading: true
                })
                var { selectedRole, selectedEmployeeUse_id, selectedCategory } = this.state;
                let company_id = this.props.userData.company_id ? this.props.userData.company_id : '';
                let body = {
                    user_id: selectedEmployeeUse_id,
                    category_id: selectedCategory.length > 0 ? selectedCategory.toString() : undefined,
                    role: selectedRole,
                    company_id
                }
                const response = await this.props.callApi('/addAdmin', body, 'post', null, true);
                if (response) {
                    common.showSuccessMessage(response.data.message);
                    this.leftBarRef.getAllAdminDetails(undefined, false)
                }
                this.setState({
                    isLoading: false,
                    addAdminModalVisible: false
                })
            }
        } catch (error) {
            this.setState({
                isLoading: false,
            });
            throw (error)
        }
    }

    /**
    |--------------------------------------------------
    | validateForm
    |--------------------------------------------------
    */

    validateForm() {
        let { selectedEmployee, selectedCategory, errors, isFormValid } = this.state;
        if (!selectedEmployee) {
            errors.employeeMessage = errorMessages.PROVIDE_EMPLOYEE;
            errors.categoryMessage = '';
        } else if (selectedCategory.length == 0) {
            errors.categoryMessage = errorMessages.PROVIDE_CATEGORY;
            errors.employeeMessage = ''
        } else {
            errors.employeeMessage = '';
            errors.categoryMessage = '';
        }

        if (errors.employeeMessage !== '' || errors.categoryMessage !== '') isFormValid = false;
        else isFormValid = true;
        this.setState({ errors, isFormValid })
        return isFormValid
    }

    /**
    |--------------------------------------------------
    | render
    |--------------------------------------------------
    */

    render() {

        const { viewEmployee, editEmployee, selectedUserData, employeeList, selectedRole, errors, isLoading } = this.state;
        const { categoryList, userData } = this.props;
        let role = _.get(userData, 'role') || '';
        let textClassName = _.get(userData, 'role') == "admin" ? "text disabled" : "text"

        return (
            <div className="main-page-wrapper contacts-page">
                <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={true} animationInDelay={500}>
                    <MobileToggle {...this.props} />
                    <Leftbar onRef={ref => this.leftBarRef = ref} {...this.props} getChildEvent={this.setParentStatus} selectedUser={selectedUserData} openAddAdminModal={this.openAddAdminModal} />
                    <SidebarAnnouncement {...this.props} />
                    <div className="content-area-wrapper">
                        <div className="content-area-inner">

                            {
                                selectedUserData &&
                                <div className="employee-details">
                                    <div className="company-logo-block mt-0">
                                        <span className="company-logo">
                                            {
                                                (selectedUserData.image === '') ? <img src="images/noimage.jpeg" alt="" /> :
                                                    <img src={selectedUserData.image} alt="" width="40" height="40" />
                                            }
                                        </span>
                                        <div className="employee-name-designation">
                                            <h6>{selectedUserData.first_name} {selectedUserData.last_name}</h6>
                                            <span className="text-light text-capitalize">{selectedUserData.designation}</span>
                                        </div>
                                    </div>

                                    <div className="employee-mail-number">
                                        <p className="text-light mb-1">{selectedUserData.email}</p>
                                        <p className="text-light">{selectedUserData.dial_code}{selectedUserData.phone}</p>
                                    </div>

                                    <div className="given-permissions mt-5">
                                        <p className="given-permissions-label">Permission: <span>{helper.getRoleName(selectedUserData.role)}</span></p>
                                        {/* {
                                                selectedUserData.category !=null? */}

                                        <div className="permission-badge-block">
                                            {
                                                selectedUserData && Array.isArray(selectedUserData.category) && selectedUserData.category.map((category) => {
                                                    return (
                                                        <span className="permission-badge" style={{ color: "#" + category.color_code }}>{category.name}</span>

                                                    )
                                                })
                                            }
                                        </div>
                                        {/* :null} */}
                                    </div>
                                    {(role !== selectedUserData.role && (role === appConstant.ROLE.COMPANY_ADMIN || role === appConstant.ROLE.ADMIN)
                                        && (!(role === appConstant.ROLE.ADMIN && selectedUserData.role === appConstant.ROLE.ADMIN) && !(selectedUserData.role === appConstant.ROLE.COMPANY_ADMIN && role === appConstant.ROLE.ADMIN)))
                                        && <div className="employee-options">
                                            <ul>
                                                <li>
                                                    <Link onClick={() => this.setState({ isPermissionModalVisible: true })}>
                                                        <img src="images/edit-user.svg" alt="" />
                                                            Edit Permission
                                                        </Link>
                                                </li>
                                            </ul>
                                        </div>}
                                    {(role !== selectedUserData.role && (role === appConstant.ROLE.COMPANY_ADMIN || role === appConstant.ROLE.ADMIN)
                                        && (!(role === appConstant.ROLE.ADMIN && selectedUserData.role === appConstant.ROLE.ADMIN) && !(selectedUserData.role === appConstant.ROLE.COMPANY_ADMIN && role === appConstant.ROLE.ADMIN))) ?
                                        <div className="pt-5 pb-3">
                                            {/* <Button onClick={()=>{ this.props.getAdminDetails(selectedUserData)}}>Edit Permission</Button> */}
                                            <Button variant="outline-primary" onClick={() => this.deleteUser(selectedUserData.user_id, selectedUserData.company_id)}>Remove Admin</Button>
                                        </div> : null}
                                </div>
                            }

                            {
                                editEmployee === true ?
                                    <div className="row mx-0">
                                        <div className="col-12">
                                            <h6 className="">Edite Profile</h6>
                                        </div>

                                        <div className="col-12">
                                            <div className="company-logo-block">
                                                <span className="company-logo">
                                                    <img src="images/may-tucker.jpg" alt="" />
                                                </span>
                                                <input type="file" className="company-logo-changer" />
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="form-group mb-4">
                                                <input
                                                    type="text"
                                                    id="name"
                                                    name="name"
                                                    className="form-control"
                                                    placeholder="Name"
                                                    defaultValue="May"
                                                />
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="form-group mb-4">
                                                <input
                                                    type="email"
                                                    id="lastName"
                                                    name="lastName"
                                                    className="form-control"
                                                    placeholder="Last Name"
                                                    defaultValue="Tucker"
                                                />
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="form-group mb-4">
                                                <input
                                                    type="text"
                                                    id="designation"
                                                    name="designation"
                                                    className="form-control"
                                                    placeholder="Designation"
                                                    defaultValue="sales"
                                                />
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="input-group mobile-input mb-4">
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text" id="basic-addon1">
                                                        <span className="">+91</span>
                                                    </div>
                                                </div>
                                                <NumberFormat
                                                    className="form-control"
                                                    placeholder="Mobile Number"
                                                    aria-label="mobileNumber"
                                                    aria-describedby="basic-addon1"
                                                    format="### ### ####"
                                                    defaultValue="2123456481"
                                                />
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <hr className="mt-1 mb-4" />
                                        </div>

                                        <div className="col-12 text-right pb-3">
                                            <Button variant="outline-primary" onClick={this.viewEmployeeBtn}>cancel</Button>
                                            <Button className="ml-3" onClick={this.viewEmployeeBtn}>update</Button>
                                        </div>
                                    </div> : null
                            }

                        </div>
                    </div>
                    {selectedUserData && <PermissionModal
                        show={this.state.isPermissionModalVisible}
                        onHidePress={() => this.setState({ isPermissionModalVisible: false })}
                        selectedUser={selectedUserData}
                        onPressUpdate={() => this.leftBarRef.getAllAdminDetails(selectedUserData.user_id, false)}
                    />}

                    <Modal className="common-modal small" centered show={this.state.addAdminModalVisible} onHide={() => this.setState({ addAdminModalVisible: false })}>
                        <button type="button" className="close fixed-close-btn" onClick={() => this.setState({ addAdminModalVisible: false })}>
                            <span aria-hidden="true">×</span>
                        </button>
                        <Modal.Body className="">
                            <div className="content-area-header">
                                <h6 className="">Add New Admin</h6>
                            </div>

                            <div className="content-area-inner">

                                <div className="add-new-admin-block">
                                    <Select
                                        value={this.state.selectedEmployee ? this.state.selectedEmployee : ''}
                                        options={employeeList}
                                        className="common-select"
                                        classNamePrefix="form-control"
                                        isSearchable={false}
                                        placeholder="Select Member"
                                        onChange={(e) => this.changeEmployee(e)}
                                    // menuIsOpen={true}
                                    />
                                    <span className='error-msg'>{errors.employeeMessage}</span>

                                    <div className="give-permission-block mt-4">
                                        <p className="s1-subtitle mb-3">Permission</p>
                                        <span className='error-msg'>{errors.categoryMessage}</span>
                                        <div className="checkbox-radio w-100 my-2">
                                            <label className="mb-0">
                                                <input type="radio" disabled={role === 'admin'} checked={selectedRole === "admin"} name="permission" value='admin' onChange={(e) => this.selectRole(e)} />
                                                <span>
                                                    <span className="radio-style"></span>
                                                    <span className={textClassName}>Admin</span>
                                                </span>
                                            </label>
                                        </div>
                                        <div className="checkbox-radio w-100 my-2 category-admin">
                                            <label className="mb-0">
                                                <input type="radio" name="permission" checked={selectedRole === "categoryadmin"} value='categoryadmin' onClick={(e) => this.selectRole(e)} />
                                                <span>
                                                    <span className="radio-style"></span>
                                                    <span className="text">Category admin</span>
                                                </span>
                                                <div className="select-admin-category mt-4">
                                                    <p className="s1-subtitle mb-2">Select Category</p>
                                                    {
                                                        categoryList && Array.isArray(categoryList) && categoryList.map((category, key) => {
                                                            return (
                                                                <div className="checkbox-radio w-100 my-2" key={key}>
                                                                    <label className="mb-0">
                                                                        <input type="checkbox" value="" checked={(this.checkArray(category.id)) ? true : false} onChange={() => this.onCheckbox(category.id)} />

                                                                        {/* <input type="checkbox" value="" /> */}
                                                                        <span>
                                                                            <span className="checkbox-style"></span>
                                                                            <span className="text tnc-check" style={{ color: "#" + category.color_code }}>{category.name}
                                                                            </span>
                                                                        </span>
                                                                    </label>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </label>
                                        </div>
                                    </div>

                                    <Button className="mt-4" onClick={this.AddAdmin}>{isLoading ? "Loading..." : "Make admin"}</Button>
                                </div>

                            </div>
                        </Modal.Body>
                    </Modal>
                </Animated>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        adminData: state.adminData,
        userData: state.getUserData,
        categoryList: state.categoryList,
    }
}
export default withRouter(connect(mapStateToProps, { callApi, getAdminDetails, setAdminData })(Admin))