import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
// import { Tab, Nav, Dropdown, Button } from 'react-bootstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { connect } from 'react-redux';
import {
    callApi, setTimelineData, setAnnouncementData,
    setIdeaData, setKnowledgeBankToReadData, setKnowledgeBankLinkData, setKnowledgeBankPhotosData,
    setKnowledgeBankCodeData
} from '../../store/reducers/commonFunction'
// import Pagination from 'rc-pagination'
import 'rc-pagination/assets/index.css';
import 'react-activity/dist/react-activity.css';
// import {  Modal} from 'react-bootstrap';
import { withRouter } from 'react-router';
import { Spinner } from 'react-activity';
import helper from '../../services/helper';
// import moment  from 'moment';

import SyntaxHighlighter from 'react-syntax-highlighter';
import { monokai } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import common from '../../store/reducers/commons';
import _ from 'lodash';
import Linkify from 'react-linkify';
import IdeaSharing from '../ideaSharing/ideaSharing';
import knowledgeBankLinks from '../KnowledgeBank/knowledgeBankLinks';

class SidebarAnnouncement extends Component {

    /**
    |--------------------------------------------------
    | constructor
    |--------------------------------------------------
    */

    constructor(props) {
        super(props);
        this.state = {
            annoucmentList: [],
            announcementSidebar: false,
            announcementSidebarMobile: false,
            current_page: 1,
            per_page: 10,
            total: 1,
            last_page: false,
            total_pages: null,
            total_likes: '',
            total_like_count: [],
            todaytimeline: '',
            yesterdaytimeline: '',
            earliertimeline: '',
            yytimeline: '',
            isLoading: false
        }
    }

    /**
    |--------------------------------------------------
    | AnnouncementToggle 
    |--------------------------------------------------
    */

    AnnouncementToggle = () => {
        this.setState({ announcementSidebar: !this.state.announcementSidebar },
            function () {
                if (this.state.announcementSidebar === true) {
                    document.body.classList.add('announcement-open');
                } else {
                    document.body.classList.remove('announcement-open');
                }
            });
    }

    /**
    |--------------------------------------------------
    | AnnouncementToggleMobile
    |--------------------------------------------------
    */

    AnnouncementToggleMobile = () => {
        this.setState({ announcementSidebarMobile: !this.state.announcementSidebarMobile },
            function () {
                document.body.classList.remove('announcement-open');
            });
    }

    /**
    |--------------------------------------------------
    | componentDidMount
    |--------------------------------------------------
    */

    componentDidMount() {
        this.props.onRef && this.props.onRef(this);
        this.getAnnouncement();
    }

    /**
    |--------------------------------------------------
    | componentWillUnmount
    |--------------------------------------------------
    */

    componentWillUnmount() {
        this.props.onRef && this.props.onRef(undefined);
    }

    /**
    |--------------------------------------------------
    | getAnnouncement
    |--------------------------------------------------
    */

    getAnnouncement = async (isLoading = true, isAppend = true) => {
        let { current_page } = this.state;
        const { userData } = this.props;
        this.setState({
            isLoading: isLoading,
            current_page: !isAppend ? 1 : this.state.current_page
        })


        //  let company_id = this.props.userData.company_id?this.props.userData.company_id:'';
        let body = {
            anonymous_token: helper.generateAnonymousKey(userData.user_id, userData.email),
            page: !isAppend ? 1 : current_page
        }
        const response = await this.props.callApi('/getTimeline ', body, 'post', null, true);
        console.log('getTimeline-->', JSON.stringify(response))
        if (response) {
            this.props.setTimelineData(isAppend ? [...this.props.timelineData, ...response.data] : response.data || []);
            this.setState({
                per_page: response.meta.per_page,
                total: response.meta.total,
                isLoading: false
            })
        }
    }

    /**
    |--------------------------------------------------
    | handleScroll
    |--------------------------------------------------
    */

    handleScroll = (e) => {
        let element = e.target
        if (element.scrollTop + element.offsetHeight >= element.scrollHeight) {
            this.setState({ current_page: this.state.current_page + 1 }, () => {
                this.getAnnouncement()
            })
        }
    }

    /**
    |--------------------------------------------------
    | openModal
    |--------------------------------------------------
    */

    openModal(image) {

        this.setState({
            image: image,
            show: true
        });
    }

    /**
    |--------------------------------------------------
    | handleClose
    |--------------------------------------------------
    */

    handleClose = (modelName) => {
        this.setState({ show: false });
    }

    /**
    |--------------------------------------------------
    | handleLikeAndUnlike
    |--------------------------------------------------
    */

    handleLikeAndUnlike = async (timeline, announcement_id, index) => {
        let endPoint = ''
        let body = {}
        if (timeline.type === 'Idea') {
            endPoint = '/likeIdea'
            body = { idea_id: announcement_id }
        } else if (timeline.type === 'KB') {
            endPoint = '/likeKnowledge'
            body = { knowledge_id: announcement_id }
        } else {
            endPoint = '/likeAnnouncement'
            body = { announcement_id: announcement_id }
        }
        try {
            let total_like_count = [];
            // let { id } = this.state;
            this.setState({ announcement_id })
            this.updateLikeAndUnlikeStatus(timeline.id);
            const response = await this.props.callApi(endPoint, body, 'post', 'ADMIN_LOGIN', true);
            if (response) {
                total_like_count[index] = response.data.like_count
                this.setState({
                    likes: response,
                    total_like_count: response.data.like_count,
                }, () => {
                    // this.getAnnouncement()
                })
            }
            // common.showSuccessMessage(response.data.message);
        } catch (error) {
            throw (error)
        }
    }

    /**
    |--------------------------------------------------
    | updateLikeAndUnlikeStatus
    |--------------------------------------------------
    */

    updateLikeAndUnlikeStatus = (id) => {
        let timelineData = this.props.timelineData
        let selectedIndex = timelineData.findIndex(x => x.id == id);
        _.set(timelineData, `[${selectedIndex}].data.total_likes`,
            _.get(timelineData, `[${selectedIndex}].data.is_liked`) == 0
                ? _.get(timelineData, `[${selectedIndex}].data.total_likes`) + 1
                : _.get(timelineData, `[${selectedIndex}].data.total_likes`) - 1);
        _.set(timelineData, `[${selectedIndex}].data.is_liked`, _.get(timelineData, `[${selectedIndex}].data.is_liked`) == 1 ? 0 : 1);

        this.props.setTimelineData([...timelineData]);

        if (this.props.match.path === '/announcement') {

            let annoucmentListData = this.props.announcementData
            let selectedAnnouncementIndex = annoucmentListData.findIndex(x => x.announcement_id == timelineData[selectedIndex].data.announcement_id);
            _.set(annoucmentListData, `[${selectedAnnouncementIndex}].total_likes`,
                _.get(annoucmentListData, `[${selectedAnnouncementIndex}].is_liked`) == 0
                    ? _.get(annoucmentListData, `[${selectedAnnouncementIndex}].total_likes`) + 1
                    : _.get(annoucmentListData, `[${selectedAnnouncementIndex}].total_likes`) - 1);
            _.set(annoucmentListData, `[${selectedAnnouncementIndex}].is_liked`, _.get(annoucmentListData, `[${selectedIndex}].is_liked`) == 1 ? 0 : 1);

            this.props.setAnnouncementData([...annoucmentListData])
        } else if (this.props.match.path === '/ideaSharing') {
            IdeaSharing.updateIdeaData(timelineData[selectedIndex].data.idea_id);
            let ideaData = this.props.ideaData
            let selectedIdeaIndex = ideaData.findIndex(x => x.idea_id == ideaData[selectedIndex].data.idea_id);

            _.set(ideaData, `[${selectedIdeaIndex}].total_likes`,
                _.get(ideaData, `[${selectedIdeaIndex}].is_liked`) == 0
                    ? _.get(ideaData, `[${selectedIdeaIndex}].total_likes`) + 1
                    : _.get(ideaData, `[${selectedIdeaIndex}].total_likes`) - 1);
            _.set(ideaData, `[${selectedIdeaIndex}].is_liked`, _.get(ideaData, `[${selectedIndex}].is_liked`) == 1 ? 0 : 1);

            this.props.setIdeaData([...ideaData])
        } else if (this.props.match.path === '/knowledgeBank') {
            let knowledgeBankToReadData = this.props.knowledgeBankToReadData
            let knowledgeBankLinkData = this.props.knowledgeBankLinkData
            let knowledgeBankPhotosData = this.props.knowledgeBankPhotosData
            let knowledgeBankCodeData = this.props.knowledgeBankCodeData
            let selectedIdeaIndex = knowledgeBankToReadData.findIndex(x => x.knowledge_id == timelineData[selectedIndex].data.knowledge_id);

            if (selectedIdeaIndex != -1) {
                _.set(knowledgeBankToReadData, `[${selectedIdeaIndex}].total_likes`,
                    _.get(knowledgeBankToReadData, `[${selectedIdeaIndex}].is_liked`) == 0
                        ? _.get(knowledgeBankToReadData, `[${selectedIdeaIndex}].total_likes`) + 1
                        : _.get(knowledgeBankToReadData, `[${selectedIdeaIndex}].total_likes`) - 1);
                _.set(knowledgeBankToReadData, `[${selectedIdeaIndex}].is_liked`, _.get(knowledgeBankToReadData, `[${selectedIdeaIndex}].is_liked`) == 1 ? 0 : 1);
                this.props.setKnowledgeBankToReadData([...knowledgeBankToReadData]);
            }

            selectedIdeaIndex = knowledgeBankLinkData.findIndex(x => x.knowledge_id == timelineData[selectedIndex].data.knowledge_id);

            if (selectedIdeaIndex != -1) {
                _.set(knowledgeBankLinkData, `[${selectedIdeaIndex}].total_likes`,
                    _.get(knowledgeBankLinkData, `[${selectedIdeaIndex}].is_liked`) == 0
                        ? _.get(knowledgeBankLinkData, `[${selectedIdeaIndex}].total_likes`) + 1
                        : _.get(knowledgeBankLinkData, `[${selectedIdeaIndex}].total_likes`) - 1);
                _.set(knowledgeBankLinkData, `[${selectedIdeaIndex}].is_liked`, _.get(knowledgeBankLinkData, `[${selectedIdeaIndex}].is_liked`) == 1 ? 0 : 1);

                this.props.setKnowledgeBankLinkData([...knowledgeBankLinkData]);
            }

            selectedIdeaIndex = knowledgeBankPhotosData.findIndex(x => x.knowledge_id == timelineData[selectedIndex].data.knowledge_id);

            if (selectedIdeaIndex != -1) {
                _.set(knowledgeBankPhotosData, `[${selectedIdeaIndex}].total_likes`,
                    _.get(knowledgeBankPhotosData, `[${selectedIdeaIndex}].is_liked`) == 0
                        ? _.get(knowledgeBankPhotosData, `[${selectedIdeaIndex}].total_likes`) + 1
                        : _.get(knowledgeBankPhotosData, `[${selectedIdeaIndex}].total_likes`) - 1);
                _.set(knowledgeBankPhotosData, `[${selectedIdeaIndex}].is_liked`, _.get(knowledgeBankPhotosData, `[${selectedIdeaIndex}].is_liked`) == 1 ? 0 : 1);

                this.props.setKnowledgeBankPhotosData([...knowledgeBankPhotosData]);
            }

            selectedIdeaIndex = knowledgeBankCodeData.findIndex(x => x.knowledge_id == timelineData[selectedIndex].data.knowledge_id);

            if (selectedIdeaIndex != -1) {
                _.set(knowledgeBankCodeData, `[${selectedIdeaIndex}].total_likes`,
                    _.get(knowledgeBankCodeData, `[${selectedIdeaIndex}].is_liked`) == 0
                        ? _.get(knowledgeBankCodeData, `[${selectedIdeaIndex}].total_likes`) + 1
                        : _.get(knowledgeBankCodeData, `[${selectedIdeaIndex}].total_likes`) - 1);
                _.set(knowledgeBankCodeData, `[${selectedIdeaIndex}].is_liked`, _.get(knowledgeBankCodeData, `[${selectedIdeaIndex}].is_liked`) == 1 ? 0 : 1);

                this.props.setKnowledgeBankCodeData([...knowledgeBankCodeData]);
            }
        }
    }

    /**
    |--------------------------------------------------
    | renderLikeUnlike
    |--------------------------------------------------
    */

    renderLikeUnlike = (timeline, key, isCommentDisplay) => {
        return (
            <div className="timeline-likes-comments-block">
                <div className="control-panel-block">
                    <div className="control-panel-options likes-block">
                        <div className="icon-outer likes-icon">
                            <div className="likes-checkbox">
                                <label htmlFor="like">
                                    <input type="checkbox" className="like-input" checked={timeline.data.is_liked ? true : false} onClick={() => this.handleLikeAndUnlike(timeline, timeline.event_id, key)} />
                                    {/* <span className="like-checkbox-style">
                                                                                    <span className="material-icons">favorite</span>
                                                                                </span> */}
                                    {
                                        (timeline.data.is_liked === 1) ?
                                            <img src={timeline.type === 'Announcement' ? "/images/upvote_purple.svg" : "/images/like-icon-pink.svg"} alt="liked post" className="purple-icon" /> :
                                            <img src={timeline.type === 'Announcement' ? "/images/upvote.svg" : "/images/like-icon-gray.svg"} alt="like post" className="gray-icon" />
                                    }
                                </label>
                            </div>
                        </div>
                        <div className="counter-label-block">

                            {
                                (timeline.type == "Announcement") ?
                                    (timeline.data.total_likes === 0) ? <span className="likes-label">Be the first upvote</span> :
                                        (timeline.data.is_liked == 1 && timeline.data.total_likes === 1) ? <span className="active-upvote-label">You're the first to upvote</span> :
                                            (timeline.data.is_liked == 1 && timeline.data.total_likes === 2) ? <span className="active-upvote-label">You & one other upvoted</span>
                                                :
                                                (timeline.data.is_liked == 1 && timeline.data.total_likes > 1) ? <span className="active-upvote-label">You & {Number(timeline.data.total_likes) - 1} other upvoted</span>
                                                    :
                                                    <span className="likes-label">{timeline.data.total_likes} upvoted</span>
                                    :
                                    (timeline.data.total_likes === 0) ? <span className="likes-label">No Likes</span> :
                                        (timeline.data.is_liked === 1) ? <span className="active-likes-label">{timeline.data.total_likes || 0} Like</span> :
                                            <span className="likes-label">{timeline.data.total_likes} Likes</span>
                            }

                        </div>
                    </div>
                    {isCommentDisplay && <div className="control-panel-options likes-block">
                        <div className="icon-outer likes-icon">
                            <div className="likes-checkbox">
                                <label htmlFor="like">
                                    {/* <input type="checkbox" className="like-input" checked={timeline.data.is_liked ? true : false} onClick={() => this.handleideaLike(timeline.id, timeline.event_id, key)} /> */}
                                    <img src="/images/img-comment.svg" alt="comment post" className="gray-icon" />
                                </label>
                            </div>
                        </div>
                        <div className="counter-label-block">
                            <span className="likes-label">{`${timeline.data.total_comments || 0} Comment`}</span>
                        </div>
                    </div>}
                </div>
            </div>
        )
    }

    /**
    |--------------------------------------------------
    | renderTimeLine
    |--------------------------------------------------
    */

    renderTimeLine = (timeline, key) => {
        var { annoucmentList, total_like_count } = this.state;

        const { userData } = this.props;
        let userName = '';
        if (_.get(timeline, 'data.is_anonymous') == 1) {
            userName = (helper.generateAnonymousKey(userData.user_id, userData.email) == timeline.owner_id || _.get(this.props, 'userData.user_id') == timeline.user_id) ? "You" : "Anonymous"
        } else {
            userName = (_.get(this.props, 'userData.user_id') == timeline.user_id) ? "You" : _.get(timeline, 'user_data.first_name') || ''
        }

        let titleLine = ''
        let type = ''
        let textMessage = ''
        let isLikeAndCommentVisible = true;

        if (timeline.type === 'Suggestion') {
            if (timeline.event === 'New_post' || timeline.event === 'Status_changed') {
                titleLine = 'shared a new'
            } else {
                isLikeAndCommentVisible = false
                titleLine = 'replied to a'
            }
            textMessage = _.get(timeline, 'data.first_message') || _.get(timeline, 'data.message') || _.get(timeline, 'data.comment') || _.get(timeline, 'data.text') || ''
            type = timeline.type
        } else if (timeline.type === 'Idea') {
            if (timeline.event == 'New_post') {
                titleLine = 'suggested a new'
            } else {
                isLikeAndCommentVisible = false
                titleLine = 'commented on an'
            }
            textMessage = _.get(timeline, 'data.first_message') || _.get(timeline, 'data.message') || _.get(timeline, 'data.comment') || _.get(timeline, 'data.text') || ''
            type = timeline.type
        } else if (timeline.type === 'KB') {
            if (timeline.data.type === "text") {
                type = `Article`
            } else if (timeline.data.type === "link") {
                type = `Link`
            } else if (timeline.data.type === "code") {
                type = `Code Block`
            } else {
                type = `Media`
            }
            if (timeline.event === 'New_post') {
                titleLine = 'shared a new'
                type += " in Knowledge Base"
            } else {
                isLikeAndCommentVisible = false
                if (timeline.data.type === "text") {
                    titleLine = 'commented on an '
                } else {
                    titleLine = 'commented on a '
                }
            }
            textMessage = _.get(timeline, 'data.first_message') || _.get(timeline, 'data.message') || _.get(timeline, 'data.comment') || _.get(timeline, 'data.text') || ''

        } else {
            textMessage = timeline.data.announcement || _.get(timeline, 'data.first_message') || _.get(timeline, 'data.message') || _.get(timeline, 'data.comment') || _.get(timeline, 'data.text') || ''
            titleLine = 'shared new'
            type = timeline.type
        }

        if (textMessage === '') {
            textMessage = "Shared a Media"
        }

        return (
            <li className="timeline-history-item" key={key}>
                {/* Timeline Items Wrapper */}

                <ul className="timeline-item-wrapper">
                    {/* Timeline Item - (list item) */}
                    {
                        timeline.type === 'Suggestion' ?

                            <li className="timeline-item-block suggestion">
                                {/* Timeline Block */}
                                <div className="timeline-item">
                                    {/* Timeline Item User DP/Image block */}
                                    <span className="timeline-user-dp-block">
                                        <span className="timeline-user-dp">
                                            <span className="timeline-user-dp-wrapper">
                                                {
                                                    (_.get(timeline, 'data.is_anonymous') === 1) ? <img src="images/annonymous.jpg" alt="" /> :
                                                        (timeline.user_data.profile_image === '') ? <img src="images/noimage.jpeg" alt="" /> :
                                                            <img src={timeline.user_data.profile_image} alt="images/may-tucker.jpg" />
                                                }
                                                {/* <img src={_.get(timeline,'data.user_image')} alt="" /> */}
                                                {/* <img src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" alt="" /> */}
                                            </span>
                                            <span className="timeline-item-type-icon">
                                                <img src="/images/timeline-suggestion-icon.svg" alt="" />

                                            </span>
                                        </span>
                                    </span>
                                    {/* Timeline Item Content Block */}
                                    <div className="timeline-item-content-block">
                                        {/* Timeline Item Header Block */}
                                        <div className="timeline-item-header-block">
                                            {/* Timeline Item Header */}
                                            <p className="timeline-item-header">
                                                {
                                                    <span className="timeline-user-name">{userName}</span>
                                                }
                                                <span>{titleLine}</span>
                                                <span className="timeline-item-type">{type}</span>
                                            </p>
                                            {/* Timeline Item Time */}
                                            <p className="timeline-item-time">{helper.formatTimeAgo(timeline.created_at)} </p>
                                        </div>
                                        {/* Timeline Item Category Block */}
                                        <div className="timeline-item-category-block">
                                            {/* Timeline Category Type */}
                                            <span className="timeline-category-type" style={{ color: `#${timeline.data.category_color_code}` }}>{timeline.data.category_name}</span>
                                            {/* Timeline Category Options Block */}
                                            <span className="timeline-category-options">
                                                {/* Timeline Category Options Item */}
                                                {timeline.data.suggestion_status === 'close' && <span className="timeline-category-options-item">
                                                    <span className="material-icons">lock</span>
                                                    {/* {
                                                        timeline.data.suggestion_status === 'close' ? <span className="material-icons">lock</span> :
                                                            null
                                                        // <span className="material-icons">lock_open</span>
                                                    } */}
                                                </span>}
                                                {timeline.data.type === 'public' && <span className="timeline-category-options-item">
                                                    <span className="material-icons">people</span>
                                                    {/* {
                                                        timeline.data.type === 'public' ? <span className="material-icons">people</span>
                                                            : null //<span className="material-icons">person</span>
                                                    } */}

                                                </span>}
                                            </span>
                                        </div>
                                        {/* Timeline Item Details Text Preview Block */}
                                        <div className="timeline-item-details-preview">
                                            <span className="timeline-preview-text">{textMessage}</span>
                                        </div>
                                        {/* <div className="timeline-item-media-preview">

                                            <span>
                                                {timeline.data.video !== '' ?
                                                    <video width="320" height="180" controls>
                                                        <source src={timeline.data.video} type="video/mp4" />
                                                    </video> :
                                                    <img src={timeline.data.image} alt="" />
                                                }
                                            </span>

                                        </div> */}
                                        {/* Likes & Comments Block */}

                                    </div>
                                </div>
                            </li>
                            : timeline.type === 'Idea' ?
                                <li className="timeline-item-block idea">
                                    {/* Timeline Block */}
                                    <div className="timeline-item">
                                        {/* Timeline Item User DP/Image block */}
                                        <span className="timeline-user-dp-block">
                                            <span className="timeline-user-dp">
                                                <span className="timeline-user-dp-wrapper">
                                                    {
                                                        (_.get(timeline, 'data.is_anonymous') === 1) ? <img src="images/annonymous.jpg" alt="" /> :
                                                            (_.get(timeline, 'data.user_image') === '') ? <img src="images/noimage.jpeg" alt="" /> :
                                                                <img src={_.get(timeline, 'data.user_image')} alt="images/may-tucker.jpg" />
                                                    }
                                                    {/* <img src={_.get(timeline,'data.user_image')} alt="" /> */}
                                                </span>
                                                <span className="timeline-item-type-icon">
                                                    <img src='/images/timeline-idea-icon.svg' alt='' />
                                                </span>
                                            </span>
                                        </span>
                                        {/* Timeline Item Content Block */}
                                        <div className="timeline-item-content-block">
                                            {/* Timeline Item Header Block */}
                                            <div className="timeline-item-header-block">
                                                {/* Timeline Item Header */}
                                                <p className="timeline-item-header">
                                                    <span className="timeline-user-name">{userName}</span>
                                                    <span>{titleLine}</span>
                                                    <span className="timeline-item-type">{type}</span>
                                                </p>
                                                {/* Timeline Item Time */}
                                                <p className="timeline-item-time">{helper.formatTimeAgo(timeline.created_at)}</p>
                                            </div>
                                            {/* Timeline Item Details Text Preview Block */}
                                            <div className="timeline-item-details-preview">
                                                <span className="timeline-preview-text">{textMessage}</span>
                                                <div className="timeline-item-media-preview">
                                                    {_.get(timeline, 'data.type') == 'video' ?
                                                        _.get(timeline, 'data.video') && <video width="320" height="180" controls>
                                                            <source src={timeline.data.video} type="video/mp4" />
                                                        </video> :
                                                        _.get(timeline, 'data.image') && <img src={timeline.data.image} alt="" />}
                                                    {/* {timeline.data.image && <span>
                                                        <img src={timeline.data.image} alt="timeline-idea-media" />
                                                    </span>} */}
                                                </div>
                                            </div>
                                        </div>
                                        {isLikeAndCommentVisible && this.renderLikeUnlike(timeline, key, true)}
                                    </div>
                                </li>
                                : timeline.type === 'KB' ?

                                    <li className="timeline-item-block knowledge-base">
                                        {/* Timeline Block */}
                                        <div className="timeline-item">
                                            {/* Timeline Item User DP/Image block */}
                                            <span className="timeline-user-dp-block">
                                                <span className="timeline-user-dp">
                                                    <span className="timeline-user-dp-wrapper">
                                                        {
                                                            (_.get(timeline, 'data.is_anonymous') === 1) ? <img src="images/annonymous.jpg" alt="" /> :
                                                                (_.get(timeline, 'data.user_image') === '') ? <img src="images/noimage.jpeg" alt="" /> :
                                                                    <img src={_.get(timeline, 'data.user_image')} alt="images/may-tucker.jpg" />
                                                        }
                                                    </span>
                                                    <span className="timeline-item-type-icon">
                                                        <img src="/images/timeline-knowledge-icon.svg" alt="" />
                                                    </span>
                                                </span>
                                            </span>
                                            {/* Timeline Item Content Block */}
                                            <div className="timeline-item-content-block">
                                                {/* Timeline Item Header Block */}
                                                <div className="timeline-item-header-block">
                                                    {/* Timeline Item Header */}
                                                    <p className="timeline-item-header">
                                                        <span className="timeline-user-name">{userName}</span>
                                                        <span>{titleLine}</span>
                                                        <span className="timeline-item-type">{type}</span>
                                                    </p>
                                                    {/* Timeline Item Time */}
                                                    <p className="timeline-item-time">{helper.formatTimeAgo(timeline.created_at)}</p>
                                                </div>
                                                {/* Timeline Item Details Text Preview Block */}
                                                <div className="timeline-item-details-preview">
                                                    {
                                                        timeline.event === "New_post" ?
                                                            timeline.data.type === 'text' || timeline.data.type === 'link' ?
                                                                <Linkify><span className="timeline-preview-text">{textMessage}</span> </Linkify>
                                                                : timeline.data.type === 'code' ?
                                                                    <SyntaxHighlighter language="html javascript" style={monokai}>{textMessage}</SyntaxHighlighter>
                                                                    : timeline.data.type === 'file' ?
                                                                        <div className="timeline-item-media-preview">
                                                                            {
                                                                                timeline.data.uploads && timeline.data.uploads.map((photo, key) => {
                                                                                    return (
                                                                                        <span key={key}>
                                                                                            {photo.subtype === 'video' ?
                                                                                                <video width="320" height="180" controls>
                                                                                                    <source src={photo.video} type="video/mp4" />
                                                                                                </video> :
                                                                                                <img src={photo.image} alt="" />
                                                                                            }
                                                                                        </span>
                                                                                    )
                                                                                })

                                                                            }
                                                                        </div> : null
                                                            :
                                                            <Linkify><span className="timeline-preview-text">{textMessage}</span> </Linkify>
                                                    }
                                                </div>
                                                {isLikeAndCommentVisible && this.renderLikeUnlike(timeline, key, true)}
                                            </div>
                                        </div>
                                    </li>
                                    : timeline.type === 'Announcement' ?

                                        <li className="timeline-item-block announcement">
                                            {/* Timeline Block */}
                                            <div className="timeline-item">
                                                {/* Timeline Item User DP/Image block */}
                                                <span className="timeline-user-dp-block">
                                                    <span className="timeline-user-dp">
                                                        <span className="timeline-user-dp-wrapper">
                                                            <img src={_.get(timeline, 'data.user_image')} alt="" />
                                                        </span>
                                                        <span className="timeline-item-type-icon">
                                                            <img src="/images/timeline-announccement-icon.svg" alt="" />
                                                        </span>
                                                    </span>
                                                </span>
                                                {/* Timeline Item Content Block */}
                                                <div className="timeline-item-content-block">
                                                    {/* Timeline Item Header Block */}
                                                    <div className="timeline-item-header-block">
                                                        {/* Timeline Item Header */}
                                                        <p className="timeline-item-header">
                                                            <span className="timeline-user-name">{userName}</span>
                                                            <span>{titleLine}</span>
                                                            <span className="timeline-item-type">{type}</span>
                                                        </p>
                                                        {/* Timeline Item Time */}
                                                        <p className="timeline-item-time">{helper.formatTimeAgo(timeline.created_at)}</p>
                                                    </div>
                                                    {/* Timeline Item Details Text Preview Block */}
                                                    <div className="timeline-item-details-preview">
                                                        <span className="timeline-preview-text">{textMessage}</span>

                                                        <div className="timeline-item-media-preview">
                                                            {timeline.data.type == 'video' ?
                                                                timeline.data.video && <video width="320" height="180" controls>
                                                                    <source src={timeline.data.video} type="video/mp4" />
                                                                </video> :
                                                                timeline.data.image && <img src={timeline.data.image} alt="" />}
                                                            {/* {timeline.data.image && <span>
                                                        <img src={timeline.data.image} alt="timeline-idea-media" />
                                                    </span>} */}
                                                        </div>
                                                    </div>
                                                </div>
                                                {isLikeAndCommentVisible && this.renderLikeUnlike(timeline, key, false, true)}

                                            </div>
                                        </li> : null
                    }
                </ul>
            </li>
        )
    }

    /**
    |--------------------------------------------------
    | render
    |--------------------------------------------------
    */

    render() {

        let date = new Date();
        let todaydate = new Date(date).toLocaleDateString();
        var yesterday = new Date(Date.now() - 864e5).toLocaleDateString();
        var { annoucmentList, total_like_count } = this.state;
        const { timelineData } = this.props

        var todaytimeline = [];
        var yesterdaytimeline = [];
        var earliertimeline = [];

        timelineData && Array.isArray(timelineData) && timelineData.map((timeline, key) => {
            if (new Date(timeline.created_at).toLocaleDateString() === todaydate) {
                todaytimeline.push(timeline);
            } else if (new Date(timeline.created_at).toLocaleDateString() === yesterday) {
                yesterdaytimeline.push(timeline);
            } else {
                earliertimeline.push(timeline);
            }
        })

        return (
            <div className="announcement-sidebar">
                <div className="expand-btn" onClick={this.AnnouncementToggle}>
                    <span className="material-icons">notifications_active</span>
                    <span>Expand</span>
                    {/* <span>NEW<br/>ANNOUNCEMENTS</span> */}
                </div>
                <div className="announcement-sidebar-inner">
                    <div className="announcement-heading">
                        {/* <span className="collapse-menu mb-0" onClick={this.AnnouncementToggle}>Collapse <span className="material-icons">keyboard_arrow_right</span></span> */}
                        <span className="announcement-heading-label">Here’s your activity feed…</span>
                        <span className="collapse-menu mb-0" onClick={this.AnnouncementToggle}>
                            <img src="/images/timeline-close-icons.jpg" alt="" />
                        </span>

                        {/* <span className="collapse-menu-mobile material-icons" onClick={this.AnnouncementToggleMobile}>arrow_forward</span> */}
                    </div>

                    <ul className="announcemnt-list">
                        <PerfectScrollbar onScroll={this.handleScroll}>
                            {/* Timeline Main Block */}
                            <div className="activity-timeline-block">

                                {/* Timeline Block Wrapper */}
                                <ul className="activity-timeline-wrapper">
                                    {/* Timeline History Block - Today, Yesterday & Earlier */}
                                    <li className="timeline-history-item">
                                        {
                                            todaytimeline.length > 0 ? <p className="timeline-history-item-label">Today</p>
                                                : null
                                        }

                                    </li>
                                    {
                                        todaytimeline && Array.isArray(todaytimeline) && todaytimeline.map((timeline, key) => {
                                            return this.renderTimeLine(timeline, key)
                                        })
                                    }
                                    <li className="timeline-history-item">
                                        {
                                            yesterdaytimeline.length > 0 ? <p className="timeline-history-item-label">Yesterday</p>
                                                : null
                                        }
                                    </li>
                                    {
                                        yesterdaytimeline && Array.isArray(yesterdaytimeline) && yesterdaytimeline.map((timeline, key) => {
                                            return this.renderTimeLine(timeline, key)
                                        })
                                    }
                                    <li className="timeline-history-item">
                                        {
                                            earliertimeline.length > 0 ? <p className="timeline-history-item-label">Earlier</p>
                                                : null
                                        }

                                    </li>
                                    {
                                        earliertimeline && Array.isArray(earliertimeline) && earliertimeline.map((timeline, key) => {
                                            return this.renderTimeLine(timeline, key)
                                        })
                                    }
                                    {/* {
                            (annoucmentList && annoucmentList.length ===  0) ? <div>  </div> :
                            <div className="pagination-wrapper py-3" >
                                <Pagination className="d-flex justify-content-center" pageSize={per_page} current={current_page} total={total} onChange={this.paginationChange.bind(this)}  />
                            </div>
                            } */}
                                </ul>
                            </div>
                            {this.state.isLoading && <div className={'loader-block'}>
                                <Spinner />
                            </div>}
                        </PerfectScrollbar>

                    </ul>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        userData: state.getUserData,
        timelineData: state.timelineData,
        announcementData: state.announcementData,
        ideaData: state.allIdeaData,
        knowledgeBankToReadData: state.knowledgeBankToReadData,
        knowledgeBankLinkData: state.knowledgeBankLinkData,
        knowledgeBankPhotosData: state.knowledgeBankPhotosData,
        knowledgeBankCodeData: state.knowledgeBankCodeData
    }
}
export default withRouter(connect(mapStateToProps, {
    callApi,
    setTimelineData,
    setAnnouncementData,
    setIdeaData,
    setKnowledgeBankToReadData,
    setKnowledgeBankLinkData,
    setKnowledgeBankPhotosData,
    setKnowledgeBankCodeData
})(SidebarAnnouncement))