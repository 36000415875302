import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import common from '../../../store/reducers/commons'
import { callApi, registerDetails } from '../../../store/reducers/commonFunction'
// import { Button } from 'react- ';
import { Animated } from "react-animated-css";
import errorMessages from '../../../store/reducers/errorMessages'
import { Spinner } from 'react-activity';
import 'react-activity/dist/react-activity.css';

class Register extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: "",
            isFormValid: true,
            isLoading: false,
            errors: {
                email: '',
            },
        };
        this.handleChange = this.handleChange.bind(this)
    }

    /*******************  Login  ******************/
    adminLogin = async (event) => {

        event.preventDefault();
        try {
            let { email } = this.state;
            let body = {
                email,
            };
            if (this.validateForm()) {
                this.setState({ isLoading: true })

                const data = await this.props.callApi('/loginEmailPassword', body, 'post', 'PROVIDE_VALID_EMAIL');
                console.log("checkingdata", data)

                this.setState({ isLoading: false })

                if (data.data.is_verify === 1) {
                    this.props.registerDetails(this.state.email)
                    common.setItem("accessToken", data.data.token)
                    this.props.history.push('/login')
                } else {
                    common.showSuccessMessage(data.data.message)
                    common.setItem("passwordToken", data.data.token)
                }
            }
        } catch (error) {
            this.setState({ isLoading: false })
            throw (error)
        }
    }

    /***************** Handle Change ******************************/
    handleChange(event) {
        let { errors, isFormValid } = this.state;
        if (!isFormValid) {
            if (event.target.name === 'email') {
                if (!common.validateEmail(event.target.value)) errors.email = errorMessages.PROVIDE_VALID_EMAIL;
                else errors.email = '';
            }
        }
        this.setState({ [event.target.name]: event.target.value, errors });
    }


    /*******************  Form validation  ******************/
    validateForm() {
        let { email, errors, isFormValid } = this.state;
        if (!email.trim()) errors.email = errorMessages.PROVIDE_EMAIL;
        else if (!common.validateEmail(email)) errors.email = errorMessages.PROVIDE_VALID_EMAIL;
        else errors.email = '';
        if (errors.email !== '') isFormValid = false;
        if (errors.email === "") isFormValid = true;
        this.setState({ errors, isFormValid });
        return isFormValid;
    }


    render() {

        let { email, errors, isLoading } = this.state;
        return (
            <div className="login-register-wrapper register-page">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 mx-auto">
                            <h2 className="page-heading mb-2">Get Started</h2>
                            <p className="mb-4">Register via your company email to connect with the people of your company.</p>

                            <form onSubmit={this.adminLogin}>
                                <div className="form-group mb-4">
                                    {/* <input
                                        type="email"
                                        id="email"
                                        name="email"
                                        value={email}
                                        className="form-control"
                                        placeholder="Your Company Email"
                                    /> */}
                                    {/* <Error errors={this.state.errors} field="email" /> */}
                                    <input type='text'
                                        className={errors.email ? 'form-control input-error' : 'form-control'}
                                        name='email'
                                        value={email}
                                        id='email'
                                        placeholder='Enter Email ID'
                                        onChange={this.handleChange} />
                                    <i className='fa fa-user' aria-hidden='true' />
                                    <span className='error-msg'>{errors.email}</span>
                                </div>
                                <Link type="submit" className="btn btn-primary btn-block" onClick={this.adminLogin}>
                                    {isLoading ? <Spinner color={'#fff'} /> : "Next"}
                                </Link>
                                {/* <Spinner /> */}
                                {/* <span className='btn btn-block btn-primary btn-lg font-weight-medium' onClick={this.adminLogin}>
                                    {isLoading ? <Spinner color={'#fff'} /> : "Next"}
                                </span> */}
                                {/* <button className='btn btn-block btn-primary btn-lg font-weight-medium' type='submit' onClick={this.adminLogin}> Next </button> */}

                            </form>
                            {/* <div className="text-center mb-2">
                                <span className="s1-subtitle text-light text-center d-inline">Already have an account?</span>
                            </div>

                            <Link className="btn btn-outline-primary d-block" to={{ pathname: '/login' }}>LOGIN</Link> */}
                            {/* <div className="text-center mb-2">
                                    <span className="s1-subtitle text-light text-center d-inline">Not a member?</span>
                                </div>

                                <Link className="btn btn-outline-primary d-block" to={{ pathname: '/companyRegistration' }}>JOIN NOW</Link> */}

                        </div>

                        <div className="col-lg-7 text-center d-lg-block d-none">
                            <div className="register-img-block">
                                <img src="/images/register-page-bgi-img.svg" alt="" />
                                <div className="register-social-icons-img">
                                    <Animated animationIn="bounceIn" animationOut="fadeOut" isVisible={true} animationInDelay={1800}>
                                        <img src="/images/register-page-social-icons-img.svg" alt="" className="" />
                                    </Animated>
                                </div>
                                <div className="register-bird-img">
                                    <Animated animationIn="fadeInLeft" animationOut="fadeOut" isVisible={true} animationInDelay={3000}>
                                        <img src="/images/register-page-bird-img.svg" alt="" className="" />
                                    </Animated>
                                </div>
                                <div className="register-character-img">
                                    <Animated animationIn="fadeInRight" animationOut="fadeOut" isVisible={true} animationInDelay={500}>
                                        <img src="/images/register-page-character-img.svg" alt="" className="" />
                                    </Animated>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default withRouter(connect(null, { callApi, registerDetails })(Register))