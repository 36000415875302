import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import { Dropdown, Modal, Button } from 'react-bootstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Animated } from "react-animated-css";
import { callApi, setFeedback, setAllFeedBackData, setSentFeedBackData, setReceivedFeedBackData, allCategoryDetails } from '../../store/reducers/commonFunction';
import common from '../../store/reducers/commons';
import Leftbar from './leftbar/Leftbar';
import MobileToggle from '../mobileToggles/mobileToggles';
import helper from '../../services/helper';
import { connect } from 'react-redux';
import SidebarAnnouncement from '../sidebarAnnouncement/SidebarAnnouncement';
import errorMessages from '../../store/reducers/errorMessages';
import { Spinner, Sentry } from 'react-activity';
import 'react-activity/dist/react-activity.css';
import { withRouter } from 'react-router';
import swal from 'sweetalert'
import _ from 'lodash';
// import { Notifications } from 'react-push-notification';
import addNotification from 'react-push-notification';
import InnerImageZoom from 'react-inner-image-zoom';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';
import appConstant from '../../constant/appConstant';

import Linkify from 'react-linkify';
let _this;
class Feedback extends Component {

    /**
    |--------------------------------------------------
    | constructor
    |--------------------------------------------------
    */

    constructor(props) {
        super(props);
        _this = this;
        this.state = {
            contentArea: false,
            show: false,
            suggestionList: [],
            feedbackDetails: undefined,
            comment: '',
            filename: '',
            errors: {
                name: ''
            },
            status_feedback: undefined,
            isCategoryDisplay: false,
            isDropdownDisplay: false,
            isLoading: true,
            isZoomed: false,
            addFeedbackVisible: false,

            categoryVal: undefined,
            category_id: 0,
            message: '',
            file: '',
            duration: 0,
            isAddFeedbackLoading: false,

            commentFile: undefined,
            commentThumbnail: undefined,
            commentFilename: '',
            showMessage: false,
            isCommentApiCalling: false,

            isCommentFile: false
        }
    }

    /**
    |--------------------------------------------------
    | componentDidMount
    |--------------------------------------------------
    */

    async componentDidMount() {
        this.getAllCategory();
    }

    /**
    |--------------------------------------------------
    | getAllCategory
    |--------------------------------------------------
    */

    getAllCategory = async () => {

        let body = {}
        const response = await this.props.callApi('/getCategory', body, 'post', 'ADMIN_LOGIN', true);
        if (response) {
            this.props.allCategoryDetails(response.data || [])
        }
    }

    /**
    |--------------------------------------------------
    | displayFeedBackData
    |--------------------------------------------------
    */

    async displayFeedBackData(index = 0, feedback_id = undefined) {

        // let props = this.props?this.props:'';
        if (this.props && this.props.location && this.props.location.feedbackId) {
            let feeduer = this.props.location.feedbackId ? this.props.location.feedbackId : '';
            await this.setParentStatus(feeduer);
        } else {
            if (feedback_id) {
                _this.setParentStatus(feedback_id);
            } else {
                let feedbackData = _this.props.allFeedbackData && _this.props.allFeedbackData[index];
                if (_this.leftBarRef.state.activeTab == 'all') {
                    feedbackData = _this.props.allFeedbackData && _this.props.allFeedbackData[index];
                } else if (_this.leftBarRef.state.activeTab == 'sent') {
                    feedbackData = _this.props.sentFeedbackData && _this.props.sentFeedbackData[index];
                } else {
                    feedbackData = _this.props.receivedFeedbackData && _this.props.receivedFeedbackData[index];
                }

                console.log('feedbackData', JSON.stringify(feedbackData));
                feedbackData && await _this.setParentStatus(feedbackData.feedback_id);
            }
        }
    }

    /**
    |--------------------------------------------------
    | handleChange
    |--------------------------------------------------
    */

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value })
        if (event.target.value) {
            this.setState({
                errors: Object.assign(this.state.errors, { [event.target.name]: "" })
            });
        } else {
            this.setState({
                errors: Object.assign(this.state.errors, { [event.target.name]: errorMessages.REQUIRED })
            })
        }
    }

    /**
    |--------------------------------------------------
    | openAddFeedbackModal
    |--------------------------------------------------
    */

    openAddFeedbackModal = () => {
        this.setState({
            addFeedbackVisible: true,
            categoryVal: undefined,
            category_id: 0,
            files: undefined,
            filename: '',
            fileVisible: false,
            message: ''
        })
    }

    /**
    |--------------------------------------------------
    | AddMessage
    |--------------------------------------------------
    */

    AddMessage = async (event) => {
        event && event.preventDefault();
        var { comment, feedback_id, commentFile, commentThumbnail, status_feedback } = this.state;
        const { userData } = this.props
        let user_id = this.props.userData.user_id ? this.props.userData.user_id : '';

        if (status_feedback.is_anonymous == 1) {
            if (helper.generateAnonymousKey(userData.user_id, userData.email) == status_feedback.feedback_owner_id) {
                user_id = status_feedback.feedback_owner_id
            }
        }
        // if (this.validateForm()) {
        this.setState({
            isCommentApiCalling: true
        })
        const formData = new FormData()
        if (commentFile) {
            if (commentFile.type === 'video/mp4') {
                formData.append('video', commentFile)
                formData.append('image', commentThumbnail)
            } else {
                formData.append('image', commentFile)
            }
        }
        if (comment != '') {
            formData.append('message', comment)
        }
        formData.append('user_id', user_id)
        formData.append('feedback_id', feedback_id)
        const response = await this.props.callApi('/addMessage', formData, 'post', null, true, true);

        console.log("AddMessage response-->", JSON.stringify(response))
        if (response) {
            this.setState({
                // suggestionList: response, 
                comment: '',
                commentFile: undefined,
                commentThumbnail: undefined,
                isCommentFile: false,
                isCommentApiCalling: false
            }, () => {
                this.setState({
                    suggestionList: [...this.state.suggestionList, response.data]
                })
            })
            this.sidebarAnnouncementRef.getAnnouncement(false, false);
            this.scrollToEnd()
        } else {
            this.setState({
                isCommentApiCalling: false
            })
        }
        addNotification({
            title: response.data.first_name,
            subtitle: 'This is a subtitle',
            message: response.data.message,
            theme: 'darkblue',
            native: true // when using native, your OS will handle theming.
        });
    }

    /**
    |--------------------------------------------------
    | scrollToEnd
    |--------------------------------------------------
    */

    scrollToEnd() {
        setTimeout(() => {
            const container = document.querySelector(".content-list .scrollbar-container");
            if (container && container.scrollHeight) {
                container.scrollTop = container.scrollHeight;
            }
        }, 100);
    }

    /**
    |--------------------------------------------------
    | setParentStatus
    |--------------------------------------------------
    */

    setParentStatus = async (id) => {
        this.setState({
            isLoading: true
        })
        let feedback_id = id;
        // let user_id= id.user_id;

        let body = {
            feedback_id
        }
        this.setState({ feedback_id: feedback_id })
        const response = await this.props.callApi('/getSuggestionDetails', body, 'post', null, true);
        console.log('getSuggestionDetails-->', JSON.stringify(response));
        this.props.setFeedback(response)

        if (response) {
            this.setState({
                suggestionList: response.data,
                status_feedback: response.data[Object.keys(response.data)[0]] || undefined,
                isLoading: false
            })
        } else {
            this.setState({
                suggestionList: [],
                status_feedback: undefined,
                isLoading: false
            })
        }
    }

    /**
    |--------------------------------------------------
    | openModal
    |--------------------------------------------------
    */

    openModal(mediaId, image) {
        this.setState({
            feedback_id: mediaId,
            image: image,
            show: true
        });
    }

    /**
    |--------------------------------------------------
    | handleClose
    |--------------------------------------------------
    */

    handleClose = (modelName) => {
        this.setState({ show: false });
    }

    /**
    |--------------------------------------------------
    | handleShow
    |--------------------------------------------------
    */

    handleShow = (modelName) => {
        this.setState({ show: true });
    }

    /**
    |--------------------------------------------------
    | deleteFeedback
    |--------------------------------------------------
    */

    deleteFeedback = async (id) => {
        swal({
            title: "Are you sure want to delete Feedback ?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async willDelete => {
                if (willDelete) {
                    let feedback_id = id;
                    let body = {
                        feedback_id
                    }
                    this.setState({
                        feedback_id: feedback_id,
                        isDropdownDisplay: false
                    })

                    const response = await this.props.callApi('/deleteFeedback', body, 'post', null, true);
                    if (response.data.code === 200) {
                        common.showSuccessMessage(response.data.message);
                        if (this.leftBarRef.state.activeTab == 'all') {
                            let feedbackData = this.props.allFeedbackData || [];
                            let feedbackIndex = feedbackData.findIndex(x => x.feedback_id == feedback_id);
                            if (feedbackIndex != -1) {
                                feedbackData.splice(feedbackIndex, 1)
                                this.props.setAllFeedBackData([...feedbackData]);
                                let nextFeedbackId = this.props.allFeedbackData[feedbackIndex] && this.props.allFeedbackData[feedbackIndex].feedback_id || undefined
                                this.displayFeedBackData(feedbackIndex, nextFeedbackId)
                            }
                        } else if (this.leftBarRef.state.activeTab == 'sent') {
                            let feedbackData = this.props.sentFeedbackData || [];
                            let feedbackIndex = feedbackData.findIndex(x => x.feedback_id == feedback_id);
                            if (feedbackIndex != -1) {
                                feedbackData.splice(feedbackIndex, 1)
                                let nextFeedbackId = this.props.sentFeedbackData[feedbackIndex] && this.props.sentFeedbackData[feedbackIndex].feedback_id || undefined
                                this.props.setSentFeedBackData([...feedbackData]);
                                this.displayFeedBackData(feedbackIndex, nextFeedbackId)
                            }
                        } else {
                            let feedbackData = this.props.receivedFeedbackData || [];
                            let feedbackIndex = feedbackData.findIndex(x => x.feedback_id == feedback_id);
                            if (feedbackIndex != -1) {
                                feedbackData.splice(feedbackIndex, 1)
                                let nextFeedbackId = this.props.receivedFeedbackData[feedbackIndex] && this.props.receivedFeedbackData[feedbackIndex].feedback_id || undefined
                                this.props.setReceivedFeedBackData(JSON.parse(JSON.stringify(feedbackData)));
                                this.displayFeedBackData(feedbackIndex, nextFeedbackId)
                            }
                        }
                        this.sidebarAnnouncementRef.getAnnouncement(false, false);
                        // window.location.reload();
                    }
                }
            });
    }

    /**
    |--------------------------------------------------
    | deleteMessage
    |--------------------------------------------------
    */

    deleteMessage = async (feedback_id, user_id) => {
        swal({
            title: "Are you sure want to delete this message ?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async willDelete => {
                if (willDelete) {
                    // let feedback_id = id;
                    let body = {
                        feedback_message_id: feedback_id, user_id: user_id
                    }
                    this.setState({
                        feedback_message_id: feedback_id
                    })
                    const response = await this.props.callApi('/deleteMessage', body, 'post', null, true);
                    if (response.data.code === 200) {
                        common.showSuccessMessage(response.data.message);
                        let messagelist = this.state.suggestionList.filter(list => list.feedback_message_id !== this.state.feedback_message_id)
                        this.setState({
                            suggestionList: messagelist,
                            isDropdownDisplay: false
                        })
                    }
                }
            });
    }

    /**
    |--------------------------------------------------
    | suggestionStatus
    |--------------------------------------------------
    */

    suggestionStatus = async (status, isType) => {
        let user_id = this.props.userData.user_id ? this.props.userData.user_id : '';
        let { feedback_id } = this.state;
        const formData = new FormData()

        formData.append('status', status)
        formData.append('feedback_id', feedback_id)
        formData.append('user_id', user_id)
        console.log('updateSuggestionStatus status-->', status);
        console.log('updateSuggestionStatus feedback_id-->', feedback_id);
        console.log('updateSuggestionStatus user_id-->', user_id);
        console.log('updateSuggestionStatus body-->', formData);
        const response = await this.props.callApi('/updateSuggestionStatus', formData, 'post', null, true);
        console.log('updateSuggestionStatus response-->', JSON.stringify(response));
        if (response.data.code === 200) {
            this.leftBarRef.updateStatus(feedback_id, status, isType)
            if (isType) {
                this.setState({
                    status_feedback: { ...this.state.status_feedback, type: status },
                    isDropdownDisplay: false
                })
            } else {
                this.setState({
                    status_feedback: { ...this.state.status_feedback, suggestion_status: status },
                    isDropdownDisplay: false
                })
            }
            this.sidebarAnnouncementRef.getAnnouncement(false, false);
            common.showSuccessMessage(response.data.message)
        }
    }

    /**
    |--------------------------------------------------
    | updateSuggestionCategory
    |--------------------------------------------------
    */

    updateSuggestionCategory = async (category) => {
        this.setState({ isDropdownDisplay: false, isCategoryDisplay: false })
        let user_id = this.props.userData.user_id ? this.props.userData.user_id : '';
        let { feedback_id } = this.state;
        const formData = new FormData()

        formData.append('category_id', category.id)
        formData.append('feedback_id', feedback_id)
        const response = await this.props.callApi('/updateSuggestionCategory', formData, 'post', null, true);
        console.log('updateSuggestionCategory response-->', JSON.stringify(response));
        if (response) {
            this.leftBarRef.updateCategory(feedback_id, category)

            this.setState({
                status_feedback: {
                    ...this.state.status_feedback,
                    category_id: category.id,
                    category_name: category.name,
                    category_color_code: category.color_code,
                },
                isDropdownDisplay: false
            })
            // if(isType){
            //     this.setState({
            //         status_feedback : {...this.state.status_feedback,type : status} 
            //      }) 
            // }else{
            //     this.setState({
            //         status_feedback : {...this.state.status_feedback,suggestion_status : status} 
            //      }) 
            // }
            this.sidebarAnnouncementRef.getAnnouncement(false, false);
            common.showSuccessMessage("Category update successfully")
        }
    }

    /**
    |--------------------------------------------------
    | changeCategory
    |--------------------------------------------------
    */

    changeCategory = (e) => {
        this.setState({ categoryVal: e.name, category_id: e.id }, () => this.forceUpdate())
    }

    /**
    |--------------------------------------------------
    | AddFeedback
    |--------------------------------------------------
    */

    AddFeedback = async () => {
        try {
            var { message, category_id, file, thumbimage, image_width, image_height, duration } = this.state;
            let user_id = this.props.userData.user_id ? this.props.userData.user_id : '';
            let company_id = this.props.userData.company_id ? this.props.userData.company_id : '';

            if (this.validateForm()) {
                this.setState({
                    isAddFeedbackLoading: true
                })
                const formData = new FormData()
                if (file) {
                    if (file.type === 'video/mp4') {
                        formData.append('video', file)
                        formData.append('image', thumbimage)
                        formData.append('image_width', image_width)
                        formData.append('image_height', image_height)
                        formData.append('video_duration', duration)
                    } else {
                        formData.append('image', file)
                    }
                }
                formData.append('company_id', company_id)
                formData.append('message', message)
                formData.append('category_id', category_id)
                formData.append('user_id', user_id)

                const response = await this.props.callApi('/newSuggestion', formData, 'post', null, true);
                if (response) {
                    common.showSuccessMessage("Thank you so much for your suggestion/feedback. We will get back to you very soon");
                    console.log('newSuggestion response-->', JSON.stringify(response));
                    this.setState({
                        isAddFeedbackLoading: false,
                        addFeedbackVisible: false,
                        showMessage: false,
                        isDropdownDisplay: false
                    }, () => {
                        if (this.leftBarRef) {
                            if (this.leftBarRef.state.activeTab === "all") {
                                this.props.setAllFeedBackData([response.data, ...this.props.allFeedbackData]);
                            } else if (this.leftBarRef.state.activeTab === "sent") {
                                this.props.setSentFeedBackData([response.data, ...this.props.sentFeedbackData]);
                            } else {
                                this.props.setReceivedFeedBackData([response.data, ...this.props.receivedFeedbackData]);
                            }
                        }
                        this.sidebarAnnouncementRef.getAnnouncement(false, false);
                        this.setParentStatus(_.get(response, 'data.feedback_id'));
                    })
                }
            }
        } catch (error) {
            this.setState({
                isAddFeedbackLoading: false,
                addFeedbackVisible: false,
                showMessage: false
            })
            throw (error)
        }
    }

    /**
    |--------------------------------------------------
    | AddAnonymousFeedback
    |--------------------------------------------------
    */

    AddAnonymousFeedback = async (e) => {
        e.preventDefault();
        try {
            var { message, category_id, file } = this.state;
            let email = this.props.userData.email ? this.props.userData.email : '';
            let user_id = this.props.userData.user_id ? this.props.userData.user_id : '';
            let company_id = this.props.userData.company_id ? this.props.userData.company_id : '';

            if (this.validateForm()) {
                const formData = new FormData()
                if (file) {
                    if (file.type === 'video/mp4') {
                        formData.append('video', file)
                    } else {
                        formData.append('image', file)
                    }
                }
                formData.append('company_id', company_id)
                formData.append('message', message)
                formData.append('category_id', category_id)
                formData.append('anonymous_token', helper.generateAnonymousKey(user_id, email))

                const response = await this.props.callApi('/newAnnonymousSuggestion', formData, 'post', null, true);
                if (response) {
                    common.showSuccessMessage("Thank you so much for your suggestion/feedback. We will get back to you very soon");
                    this.setState({
                        isAddFeedbackLoading: false,
                        addFeedbackVisible: false,
                        showMessage: false,
                        isDropdownDisplay: false
                    }, () => {
                        if (this.leftBarRef) {
                            if (this.leftBarRef.state.activeTab === "all") {
                                this.props.setAllFeedBackData([response.data, ...this.props.allFeedbackData]);
                            } else if (this.leftBarRef.state.activeTab === "sent") {
                                this.props.setSentFeedBackData([response.data, ...this.props.sentFeedbackData]);
                            } else {
                                this.props.setReceivedFeedBackData([response.data, ...this.props.receivedFeedbackData]);
                            }
                            this.sidebarAnnouncementRef.getAnnouncement(false, false);
                        }
                        this.setParentStatus(_.get(response, 'data.feedback_id'));
                    })
                }
            }
        } catch (error) {
            throw (error)
        }
    }

    /**
    |--------------------------------------------------
    | fileChangedHandlerVideoImg
    |--------------------------------------------------
    */

    async fileChangedHandlerVideoImg(event) {
        let targetName = event.target.name
        if (event && event.target && event.target.files) {
            let file = _.get(event, 'target.files[0]');
            let filename = _.get(event, 'target.files[0].name') || new Date().getTime().toString();
            if (_.get(event, 'target.files[0].type').match('image')) {
                let reader = new FileReader()
                // let file = event.target.files[0]
                // let filename = event.target.files[0].name

                reader.onloadend = () => {
                    if (targetName == "commentFile") {
                        this.setState({
                            commentFile: file,
                            commentFilename: filename,
                            // imagePreviewUrl: reader.result,
                            // fileVisible: true
                        }, () => {
                            this.AddMessage()
                        })
                    } else {
                        this.setState({
                            file: file,
                            filename: filename,
                            imagePreviewUrl: reader.result,
                            fileVisible: true
                        })
                    }
                }
                reader.readAsDataURL(file)
            } else {
                let fileReader = new FileReader()
                var _that = this;
                fileReader.onloadend = function () {
                    var blob = new Blob([fileReader.result], { type: file.type });
                    var url = URL.createObjectURL(blob);
                    var video = document.createElement('video');

                    var timeupdate = function () {
                        video.removeEventListener('timeupdate', timeupdate);
                        if (snapImage()) {
                            video.removeEventListener('timeupdate', timeupdate);
                            video.pause();
                        }
                    };
                    video.addEventListener('loadeddata', function () {
                        if (snapImage()) {
                            video.removeEventListener('timeupdate', timeupdate);
                        }
                    });
                    var snapImage = () => {

                        var canvas = document.createElement('canvas');
                        canvas.width = video.videoWidth;
                        canvas.height = video.videoHeight;

                        canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
                        var image = canvas.toDataURL();
                        let block = image && image.split(";");
                        let contentType = block[0] && block[0].split(":")[1];
                        let realData = block[1] && block[1].split(",")[1];// In this case "iVBORw0KGg...."
                        if (realData !== undefined) {
                            let sliceSize = 512;
                            let byteCharacters = atob(realData, contentType);
                            let byteArrays = [];
                            for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                                let slice = byteCharacters.slice(offset, offset + sliceSize);
                                let byteNumbers = new Array(slice.length);
                                for (let i = 0; i < slice.length; i++) {
                                    byteNumbers[i] = slice.charCodeAt(i);
                                }
                                let byteArray = new Uint8Array(byteNumbers);
                                byteArrays.push(byteArray);
                            }

                            let blob = new Blob(byteArrays, { contentType })
                            /**************video duriatio *************/
                            var duration = video.duration;

                            /*********video end*********/
                            if (targetName == "commentFile") {
                                _that.setState({
                                    commentFile: file,
                                    commentThumbnail: blob,
                                    commentFilename: filename,
                                    // imagePreviewUrl: reader.result,
                                }, () => {
                                    _that.AddMessage()
                                })
                            } else {
                                _that.setState({
                                    file: file,
                                    thumbimage: blob,
                                    duration: duration,
                                    image_width: canvas.width,
                                    image_height: canvas.height,
                                    url: url,
                                    fileVisible: true
                                })
                            }

                            // video.ondurationchange = function() {
                            //     alert(this.duration);
                            // };
                            var success = image.length > 100000;
                            if (success) {
                                // var img = document.createElement('img');
                                // img.src = image;
                                // document.getElementsByTagName('div')[0].appendChild(img);
                                URL.revokeObjectURL(url);
                            }
                            return success;
                        }
                    };
                    video.addEventListener('timeupdate', timeupdate);
                    video.preload = 'metadata';
                    video.src = url;
                    video.ondurationchange = function () {
                        if (this.duration > 45) {
                            swal("", "Currently, you can only upload files smaller than 10 MB (a 45 sec video for instance).", "warning")

                        }
                        //   alert(this.duration);
                    };
                    // Load video in Safari / IE11
                    video.muted = true;
                    video.playsInline = true;
                    video.play();
                };
                fileReader.readAsArrayBuffer(file);
            }
        }
    }

    /**
    |--------------------------------------------------
    | validateForm
    |--------------------------------------------------
    */

    validateForm() {
        let { message, category_id, errors, isFormValid } = this.state;

        if (category_id == 0) {
            errors.message = errorMessages.PROVIDE_CATEGORY;
            errors.type = 'CATEGORY'
        } else if (!message.trim()) {
            errors.message = errorMessages.PROVIDE_MESSAGE;
            errors.type = 'MESSAGE'
        } else {
            errors.message = '';
            errors.type = ''
        }

        if (errors.message !== '') isFormValid = false;
        else isFormValid = true;
        this.setState({ errors, isFormValid })
        return isFormValid
    }

    /**
    |--------------------------------------------------
    | changeStatus
    |--------------------------------------------------
    */

    changeStatus = () => {
        this.setState((prevState) => ({ showMessage: !prevState.showMessage }))
    }

    /**
    |--------------------------------------------------
    | render
    |--------------------------------------------------
    */

    render() {
        const { isCommentApiCalling } = this.state;
        const { allCategoriesDetails } = this.props;
        let role = _.get(this.props, 'userData.role') || '';
        let userData = _.get(this.props, 'userData');
        console.log('profileInformation-->', JSON.stringify(this.props.profileInformation));
        // let user_status = suggestionList[Object.keys(suggestionList)[0]];

        var { suggestionList, comment, status_feedback, isCategoryDisplay, isDropdownDisplay, categoryVal,
            errors, message, imagePreviewUrl, fileVisible, file, commentFile, isLoading, isAddFeedbackLoading } = this.state;
        var str = message;
        let loginUserAnonymousKey = helper.generateAnonymousKey(userData.user_id, userData.email)
        return (
            <div className="main-page-wrapper feedback-page">
                <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={true} animationInDelay={500}>
                    <MobileToggle {...this.props} />
                    <Leftbar onRef={ref => this.leftBarRef = ref} {...this.props} getChildEvent={this.setParentStatus} selected_feedback={this.state.status_feedback} displayFeedBackData={this.displayFeedBackData} openAddFeedbackModal={this.openAddFeedbackModal} />
                    <SidebarAnnouncement onRef={ref => this.sidebarAnnouncementRef = ref} {...this.props} />
                    <div className={this.state.contentArea === false ? "content-area-wrapper" : "content-area-wrapper close-sidebar"}>

                        {!isLoading && status_feedback && <div className="content-area-header">
                            <span className="content-area-heading">
                                <span className="user-category" style={{ color: "#" + status_feedback.category_color_code }}>{status_feedback.category_name}</span>
                                <span className="category-icons-block">
                                    {
                                        (status_feedback.suggestion_status === "close") &&
                                        <span className="icons-block">
                                            <span className="material-icons">lock</span>
                                        </span>
                                    }
                                    {
                                        ((role === appConstant.ROLE.COMPANY_ADMIN || role === appConstant.ROLE.ADMIN || role === appConstant.ROLE.CATEGORY_ADMIN) && status_feedback.type === "public") &&
                                        <span className="icons-block">
                                            <span className="material-icons">people</span>
                                        </span>
                                    }
                                </span>
                            </span>


                            {
                                (role === appConstant.ROLE.COMPANY_ADMIN || role === appConstant.ROLE.ADMIN || (userData.user_id == status_feedback.user_id && status_feedback.suggestion_status == 'open')) ?
                                    <Dropdown alignRight className="d-inline-block dropdown-block" show={isDropdownDisplay} >
                                        <Dropdown.Toggle variant="" id="dropdown-basic" className="text-btn" onClick={() => this.setState({ isDropdownDisplay: !this.state.isDropdownDisplay, isCategoryDisplay: false })}>
                                            Feedback menu
                                </Dropdown.Toggle>

                                        {isCategoryDisplay ? <Dropdown.Menu className="">
                                            <span className="close-dropdown" onClick={() => this.setState({ isDropdownDisplay: !this.state.isDropdownDisplay, isCategoryDisplay: false })}>
                                                X
                                    </span>
                                            {/* <Dropdown.Header className="px-0">Category</Dropdown.Header> */}
                                            <div className="d-block">
                                                {
                                                    allCategoriesDetails && Array.isArray(allCategoriesDetails) && allCategoriesDetails.map((category, key) => {
                                                        return (
                                                            <div className="checkbox-radio w-100" key={key} onClick={() => this.updateSuggestionCategory(category)}>
                                                                <label>
                                                                    <span>
                                                                        <span className="checkbox-style"></span>
                                                                        <span className="text tnc-check" style={{ color: "#" + category.color_code }}>{category.name}
                                                                        </span>
                                                                    </span>
                                                                </label>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </Dropdown.Menu>
                                            :
                                            <Dropdown.Menu>
                                                <span className="close-dropdown" onClick={() => this.setState({ isDropdownDisplay: !this.state.isDropdownDisplay, isCategoryDisplay: false })}>
                                                    X
                                    </span>
                                                <Dropdown.Item onClick={() => this.setState({ isDropdownDisplay: true, isCategoryDisplay: true })}>Change Category</Dropdown.Item>
                                                {
                                                    (role === appConstant.ROLE.COMPANY_ADMIN || role === appConstant.ROLE.ADMIN) ? (status_feedback.suggestion_status === "open") ? <Dropdown.Item onClick={() => this.suggestionStatus('close', false)}>Close</Dropdown.Item> :
                                                        <Dropdown.Item onClick={() => this.suggestionStatus('open', false)}>Open</Dropdown.Item>
                                                        : null
                                                }
                                                {
                                                    (role === appConstant.ROLE.COMPANY_ADMIN || role === appConstant.ROLE.ADMIN) ? (status_feedback.type === 'public') ?
                                                        <Dropdown.Item onClick={() => this.suggestionStatus('private', true)}>Private</Dropdown.Item>
                                                        :
                                                        <Dropdown.Item onClick={() => this.suggestionStatus('public', true)}>Public</Dropdown.Item>

                                                        : null
                                                }
                                                {(role === appConstant.ROLE.COMPANY_ADMIN || role === appConstant.ROLE.ADMIN) && <Dropdown.Item onClick={() => this.deleteFeedback(status_feedback.feedback_id)}>delete feedback</Dropdown.Item>}

                                            </Dropdown.Menu>}
                                    </Dropdown> : null
                            }
                        </div>}

                        {!isLoading ? <div>
                            <div className="content-area-inner" >
                                <span className="chat-area" >
                                    <ul className="content-list" id="scroll-bottom">
                                        <PerfectScrollbar >
                                            {
                                                suggestionList && Array.isArray(suggestionList) && suggestionList.map((category, key) => {
                                                    let userName = '';
                                                    if (category.is_anonymous === 1 && category.user_id.length > 60) {
                                                        userName = helper.generateAnonymousKey(userData.user_id, userData.email) == category.user_id ? 'You' : 'Anonymous';
                                                    } else {
                                                        userName = (helper.generateAnonymousKey(userData.user_id, userData.email) == category.user_id
                                                            || _.get(this.props, 'userData.user_id') == category.user_id)
                                                            ? "You" : _.get(category, 'first_name')
                                                    }
                                                    return (
                                                        <li className="content-list-item" key={key}>
                                                            <div className="leftbar-list-item-inner">
                                                                <div className="user-img small">
                                                                    <div className="user-img-wrapper">
                                                                        {
                                                                            ((category.is_anonymous === 1 && key == 0) || category.user_image == "") ? <img src="images/annonymous.jpg" alt="" /> :
                                                                                <img src={category.user_image} alt="images/may-tucker.jpg" />
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className="user-data">
                                                                    <span>{userName}</span>
                                                                    <span className="date-time">{helper.formatDetailsDate(category.created_at)}</span>
                                                                </div>
                                                                {
                                                                    ((role === appConstant.ROLE.COMPANY_ADMIN || role === appConstant.ROLE.ADMIN || category.user_id == userData.user_id) && (((new Date().getTime() - new Date(category.created_at).getTime()) / 1000) / 60) <= 3) ?
                                                                        <Dropdown className="dots-dropdown ml-auto">
                                                                            <Dropdown.Toggle variant="" id="dropdown-basic" className="material-icons">
                                                                                more_vert
                                                                </Dropdown.Toggle>

                                                                            <Dropdown.Menu alignRight>
                                                                                <Dropdown.Item onClick={() => this.deleteMessage(category.feedback_message_id, category.user_id)}>delete message</Dropdown.Item>
                                                                            </Dropdown.Menu>
                                                                        </Dropdown> : null}
                                                            </div>
                                                            {category.message !== '' ?
                                                                <div className="card">
                                                                    <div className="card-body">
                                                                        <Linkify><p className="card-text">{category.message}</p></Linkify>
                                                                    </div>
                                                                </div> : null}

                                                            {
                                                                (category.video !== undefined && category.video !== '') ?
                                                                    <video width="320" height="180" controls>
                                                                        <source src={category.video} type="video/mp4" />
                                                                    </video>
                                                                    :
                                                                    category.image && category.image != "" ? <span className="user-uploded-img" onClick={() => this.openModal(category.id, category.image)}>
                                                                        <img src={category.image} alt="" />
                                                                    </span>
                                                                        : null
                                                            }

                                                        </li>
                                                    )
                                                })
                                            }
                                            {/* <div className py-5 bg-danger"></div> */}
                                        </PerfectScrollbar>
                                    </ul>
                                </span>
                            </div>

                            {
                                (status_feedback && status_feedback.suggestion_status !== 'close') &&
                                ((status_feedback.feedback_owner_id == helper.generateAnonymousKey(userData.user_id, userData.email) ||
                                    status_feedback.user_id == userData.user_id) || userData.role == appConstant.ROLE.COMPANY_ADMIN || userData.role == appConstant.ROLE.CATEGORY_ADMIN || userData.role == appConstant.ROLE.ADMIN) &&

                                <div className="chat-input-block">
                                    <form className="comment-input-text" onSubmit={this.AddMessage}>
                                        <div className="chat-input-inner ">
                                            <div className="attachment-btn">
                                                {/* <input type="file" /> */}
                                                <input
                                                    type="file"
                                                    className="company-logo-changer"
                                                    accept="image/*,video/*"
                                                    name="commentFile"
                                                    onChange={this.fileChangedHandlerVideoImg.bind(this)} />
                                            </div>

                                            <input
                                                type="text"
                                                className="form-control "
                                                placeholder="Write something"
                                                name='comment'
                                                maxLength="2000"
                                                value={comment}
                                                onChange={this.handleChange}
                                            />

                                            <Button className="send-btn" onClick={this.AddMessage} disabled={isCommentApiCalling || comment == "" && commentFile == undefined}>
                                                {isCommentApiCalling ? <Sentry color='#fff' /> : <img src="images/send-icon.svg" alt="" />}
                                            </Button>
                                            {/* <div style={{ float:"left", clear: "both" }}
                                                    ref={(el) => { this.messagesEnd = el; }}>
                                                </div> */}
                                        </div>
                                    </form>
                                </div>
                            }

                        </div>
                            :
                            <div className={'loader-block'}>
                                <Spinner />
                            </div>}
                    </div>
                </Animated >
                <Modal className="common-modal small transparent-modal" centered show={this.state.show} onHide={() => this.handleClose("userUploadedImgs")} >
                    <Modal.Body className="p-5">
                        {/* <InnerImageZoom src={'https://www.fillmurray.com/640/360'} zoomSrc="https://www.fillmurray.com/1280/720" /> */}
                        <div className="modal-image">
                            <img
                                className="d-block w-100"
                                src={this.state.image}
                                alt=""
                            />
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal className="common-modal small" centered show={this.state.addFeedbackVisible} onHide={() => this.setState({ addFeedbackVisible: false })}>
                    <button type="button" className="close fixed-close-btn" onClick={() => this.setState({ addFeedbackVisible: false })}>
                        <span aria-hidden="true">×</span>
                    </button>
                    <Modal.Body className="">

                        <div className="content-area-header">
                            <h6 className="">New Suggestion</h6>
                        </div>

                        <div className="content-area-inner">
                            <div className="new-topic-select">

                                <Dropdown alignRight className="d-inline-block filter-dropdown">
                                    <Dropdown.Toggle variant="" id="dropdown-basic" className="text-btn">
                                        {categoryVal ? categoryVal.toLocaleUpperCase() : 'Select Category'}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {allCategoriesDetails && Array.isArray(allCategoriesDetails) && allCategoriesDetails.map((category, key) => {
                                            let isDisabled = role == appConstant.ROLE.CATEGORY_ADMIN && _.get(this.props, 'profileInformation.category') && _.get(this.props, 'profileInformation.category').find(x => x.name == category.name) != undefined;
                                            if (isDisabled)
                                                return null
                                            return (
                                                <Dropdown.Item onClick={() => this.changeCategory(category)}>
                                                    <div className="checkbox-radio w-100" key={key}>
                                                        <label>
                                                            <span>
                                                                <span className="checkbox-style"></span>
                                                                <span className="text tnc-check" style={{ color: "#" + category.color_code }}>{category.name}
                                                                </span>
                                                            </span>
                                                        </label>
                                                    </div>
                                                </Dropdown.Item>
                                            )
                                        })}
                                    </Dropdown.Menu>
                                </Dropdown>
                                {errors.type == 'CATEGORY' && <span className="error-block"> {errors.message} </span>}
                            </div>

                            <div className="suggestion-block">
                                {/* <textarea name="suggestion" id="" placeholder="Describe your suggestion here" className="form-control"></textarea> */}
                                <textarea
                                    className='form-control'
                                    type='text'
                                    name="message"
                                    placeholder='Write your suggestion here...'
                                    value={message}
                                    onChange={this.handleChange}
                                    maxLength="1000"
                                ></textarea>
                                {errors.type == 'MESSAGE' && <span className="error-block"> {errors.message} </span>}
                                <div className="d-flex justify-content-end">
                                    <span className="remaining-words">{str.length}/1000</span>
                                </div>
                            </div>
                            {!fileVisible ? <div className="add-img my-3">
                                <span className="camera-icon">
                                    <img src="images/camera.svg" alt="" />
                                </span>
                                <input type="file"
                                    accept="image/*"
                                    className="input-file"
                                    onChange={this.fileChangedHandlerVideoImg.bind(this)} />
                            </div> :
                                (this.state.duration > 45) ? <span></span> :
                                    (file.type === 'video/mp4') ?
                                        <li class="li-image-wrap">
                                            <span class="remove-image" onClick={() => this.setState({ fileVisible: !this.state.fileVisible, file: '' })}>
                                                X
                                    </span>
                                            <video width="320" height="240" controls >
                                                <source src={this.state.url} type="video/mp4" />
                                            </video>
                                        </li>
                                        :
                                        fileVisible ?
                                            <ul className="modal-uploaded-img-block">
                                                <li class="li-image-wrap">
                                                    <span class="remove-image" onClick={() => this.setState({ fileVisible: !this.state.fileVisible, file: '' })}>
                                                        X
                                            </span>
                                                    <img src={imagePreviewUrl} width="100" alt="" />
                                                </li>
                                            </ul> : null
                            }
                        </div>

                        <div className="send-anonymous">
                            <div className="checkbox-radio">
                                <label className="mb-0">
                                    <input type="checkbox" value="" onClick={this.changeStatus} />
                                    <span>
                                        <span className="checkbox-style"></span>
                                        <span className="text">Send as anonymous</span>
                                    </span>
                                    <p className="common-text">Sure, No one will be able to track your name, not even developers.</p>
                                </label>
                                <svg className="what-is-anonymous" style={{ cursor: "pointer" }} width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" onClick={() => this.setState({ anonymousShow: true })}>
                                    <title>What is anonymous ?</title>
                                    <desc>Created with Sketch.</desc>
                                    <defs>
                                        <path d="M10,14 C10.553,14 11,14.447 11,15 C11,15.553 10.553,16 10,16 C9.447,16 9,15.553 9,15 C9,14.447 9.447,14 10,14 Z M10,4 C11.93,4 13.5,5.57 13.5,7.5 C13.5,9.08 12.44,10.403 11,10.837 L11,10.837 L11,12 C11,12.553 10.553,13 10,13 C9.447,13 9,12.553 9,12 L9,12 L9,10 C9,9.447 9.447,9 10,9 C10.827,9 11.5,8.327 11.5,7.5 C11.5,6.673 10.827,6 10,6 C9.173,6 8.5,6.673 8.5,7.5 C8.5,8.053 8.053,8.5 7.5,8.5 C6.947,8.5 6.5,8.053 6.5,7.5 C6.5,5.57 8.07,4 10,4 Z M10,18 C5.589,18 2,14.411 2,10 C2,5.589 5.589,2 10,2 C14.411,2 18,5.589 18,10 C18,14.411 14.411,18 10,18 M10,0 C4.486,0 0,4.486 0,10 C0,15.514 4.486,20 10,20 C15.514,20 20,15.514 20,10 C20,4.486 15.514,0 10,0" id="path-1"></path>
                                    </defs>
                                    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                        <g id="New-Feedback" transform="translate(-612.000000, -483.000000)">
                                            <g id="27)-Icon/question-mark-circle" transform="translate(612.000000, 483.000000)">
                                                <mask id="mask-2" fill="white">
                                                    <use xlinkHref="#path-1"></use>
                                                </mask>
                                                <use id="🎨-Icon-Сolor" fill="#3366FF" xlinkHref="#path-1"></use>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                        </div>

                        <div className="text-right mb-4">
                            {/* <Link to="/feedback" className="btn btn-outline-primary">cancel</Link> */}
                            <Button onClick={() => this.setState({ addFeedbackVisible: false })} className="btn btn-outline-primary">Cancel</Button>
                            {/* <Button className="ml-3">send</Button> */}
                            {this.state.showMessage ?
                                <Button className="ml-3"
                                    disabled={isAddFeedbackLoading}
                                    onClick={this.AddAnonymousFeedback}>{isAddFeedbackLoading ? "Loading…" : "Send as anonymous"}</Button> :
                                <Button className='ml-3'
                                    disabled={isAddFeedbackLoading}
                                    onClick={!isAddFeedbackLoading ? this.AddFeedback : null}> {isAddFeedbackLoading ? 'Loading…' : 'Send as ' + userData.first_name}</Button>
                            }
                        </div>


                    </Modal.Body>
                </Modal>

                <Modal className="common-modal small" centered show={this.state.anonymousShow} onHide={() => this.setState({ anonymousShow: false })}>
                    <button type="button" className="close fixed-close-btn" onClick={() => this.setState({ anonymousShow: false })}>
                        <span aria-hidden="true">×</span>
                    </button>
                    <Modal.Body className="p-md-5 p-3 pt-5">
                        <h4 className="mb-3">What is Anonymous?</h4>
                        <h5>Be Assured, Your Identity Remians <span className="text-danger">Unknown Forever!</span></h5>
                        <p className="common-text">When you post any EchoLoud selecting as Anonymous, it will be posted with a “Ghost” identity which is created when you logged in.</p>
                        <p className="common-text">We do not store or link this “Ghost” identity anywhere in our system with your actual/real identity. This will remain stored encrypted only on your iPhone/iPad as long as you’re logged in and will be removed permanently upon logging out and can never be trace backed as long as the Earth exists.</p>
                        <p className="common-text mb-0">The system is designed and developed in such a way that not even the creator of this system can trace who sent those anonymous EchoLoud/Suggestions/Messages from anywhere.</p>
                    </Modal.Body>
                </Modal>
            </div >
        );
    }
}
const mapStateToProps = (state) => {
    return {
        allFeedbackData: state.allFeedbackData,
        sentFeedbackData: state.sentFeedbackData,
        receivedFeedbackData: state.receivedFeedbackData,
        allCategoriesDetails: state.allCategoriesDetails,
        userData: state.getUserData,
        profileInformation: state.profileInformation,
    }
}
export default withRouter(connect(mapStateToProps, { callApi, setFeedback, setAllFeedBackData, setSentFeedBackData, setReceivedFeedBackData, allCategoryDetails })(Feedback))
