import React, { Component } from 'react';
import { Animated } from "react-animated-css";
import { connect } from 'react-redux';
// import MobileToggle from '../mobileToggles/mobileToggles';
import 'react-phone-input-2/lib/style.css'
import 'react-image-crop/dist/ReactCrop.css';
// import PerfectScrollbar from 'react-perfect-scrollbar';

class PrivacyPolicy extends Component {
    constructor(props) {
        super(props);
        this.state = {
                           
        }
    }


    async componentDidMount() {
    }
   
render() {

        return (
            <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={true} animationInDelay={500}>
            <div className="main-page-wrapper privacy-terms-page">
                {/* <MobileToggle {...this.props} /> */}
                    <div>
                        <h1><b>Privacy Policy</b></h1>
                        <p>This Privacy Policy statement is made by the IndiaNIC Group consisting of all the entities listed here (collectively, “IndiaNIC”, “we”, “us” or “our”) and is effective as of 25th May 2018. It applies to all our divisions: IndiaNIC Infotech Limited and Netlancers Inc DBA IndiaNIC.
                        </p>
                        <h3 className="mt-4"><b>IndiaNIC’s Privacy Commitment</b></h3>
                        <p>IndiaNIC has worried about customer and user privacy for almost 20 years, long before it become politically correct, fashionable, or legally binding to take such position. We ask / collect only the least amount of information necessary gathering only when we believe is essential for doing business, or for the specific transaction at hand. We let customers know the information we have on them and allow them to opt out for specific engagements. But, our biggest commitment is that we do not make a single dollar from advertising revenu-never have, never will, even if you just have submit a enquiry for project and does not opt to our services. We avoid the fundamental conflict of interest between gathering customer information and fueling advertising revenue, and the inevitable compromises in customer privacy that it brings.</p>

                        <p>The goal of this policy is to make explicit the information we gather on our customers and website users, how we will use it, and how we will not. This policy is unfortunately longer that we would like, but we must unambiguously address all the relevant cases. We will try and keep the language simple and direct as much as possible.</p>
                        
                        <h1 className="mt-4"><b>Scope of this Privacy Policy</b></h1>

                        <p>This Privacy Policy applies to all IndiaNIC websites that link to it. It also applies to the products and services provided by IndiaNIC through these websites, our mobile applications. This Privacy Policy does not apply to any of our websites, products or services that have a separate privacy policy.</p>

                        <p>This Privacy Policy is divided into three parts:</p>

                        <b>Part I – Information IndiaNIC collects and controls</b>

                        <p>This part deals with how IndiaNIC collects and uses information about website visitors, potential customers, users of IndiaNIC’s services, and others who contact IndiaNIC through forms or email addresses published on or linked to our websites.</p>

                        <b>Part II – Information that IndiaNIC processes on your behalf</b>

                        <p>This part deals with how IndiaNIC handles data that you entrust to IndiaNIC when you use our products and services, or when you share any personal or confidential information with us while requesting customer support.</p>

                        <b>Part III – General</b>

                        <p>This part deals with topics that are relevant to both Parts I and II, and other general topics such as IndiaNIC’s security commitments and how we will inform you when we change this Privacy Policy.</p>

                        <h1 className="mt-4"><b>Part I – Information IndiaNIC collects and controls</b><br /><b>What information IndiaNIC collects</b></h1>

                        <p>We collect information about you only if we need the information for some legitimate purpose. IndiaNIC will have information about you only if (a) you have provided the information yourself, (b) IndiaNIC has automatically collected the information, or (c) IndiaNIC has obtained the information from a third party. Below we describe the various scenarios that fall under each of those three categories and the information collected in each one.</p>

                        <h3 className="mt-4"><b><u>Information that you provide us</u></b></h3>

                        <ul>
                            <ol>
                                <li className="mb-2">i. Enquiry Form / Email / Third Party Partners to generate new enquiries : When you contact us using online web form, email to obtain or enquire regarding one or more of our services, we ask for information like your name, contact number, email address, company name and country to complete the enquiry process. You may also provide us with more information such as your photo, time zone and language, but we don’t require that information for initial contact. Once you submit the enquiry in any above mentioned form, our legitimate employees will have direct access to your information to further process the enquiry and contact you in regard with obtaining pre-sales information regarding your business and project related information.</li>

                                <li className="mb-2">ii. Surveys, Feedback and other form submissions: We record information that you submit when you (i) register for any event, including webinars or seminars, (ii) subscribe to our newsletter or any other mailing list, (iii) submit a form in order to download portfolio, company profile, whitepaper, or other materials, (iv) participate in contests or respond to surveys, or (v) submit a form to request customer support or to contact IndiaNIC for any other purpose.</li>

                                <li className="mb-2">iii. Project Creation : When you acquire or sign a contract to buy any of our services, we ask you to provide your name, contact information, personal details, project related information, social media profile links and timezone. We store all of this information to our secured project management software (ERP).</li>

                                <li className="mb-2">iv. Payment processing : When you acquire or buy any of services from us, we ask you to provide your name, contact information, and credit card information or paypal. We NEVER STORE any of your Payment related information including credit card, bank details, paypal details or any other means through which your choose to make a payment. All payments are getting processed with secured servers of our Payment Gateway Service Providers.</li>

                                <li className="mb-2">v. Testimonials : When you authorize us to post testimonials about our products and services on websites, we may include your name and other personal information in the testimonial. You will be given an opportunity to review and approve the testimonial before we post it. If you wish to update or delete your testimonial, you can contact us through this form.</li>

                                <li className="mb-2">vi. Interactions with IndiaNIC : We may record, analyze and use your interactions with us, including email, telephone, and chat conversations with our sales, project management, account management and customer support professionals, for improving our interactions with you and other customers.</li>
                            </ol>
                        </ul>

                        <h3 className="mt-4"><b><u>Information that we collect automatically</u></b></h3>
                        <ul>
                            <ol>
                                <li className="mb-2">i. Information from browsers, devices and servers : When you visit our websites, we collect information that web browsers, mobile devices and servers make available, such as the internet protocol address, browser type, language preference, time zone, referring URL, date and time of access, operating system, mobile device manufacturer, mobile network information and your geographic information. We include these in our log files to understand more about visitors to our websites.</li>

                                <li className="mb-2">ii. Information from first party cookies and tracking technologies : We use temporary and permanent cookies to identify users of our services and to enhance user experience. We also use cookies, tags, scripts, and other similar technologies to identify visitors, track website navigation, gather demographic information about visitors and users, understand email campaign effectiveness and for targeted visitor and user engagement by tracking your activities on our websites. We use third-party tracking services like google analytics and google tag manager to understand the behaviour of our website visitors and serve them better.</li>

                                <li className="mb-2">iii. Information from application logs and mobile analytics : We collect information about your use of our website and mobile applications from application logs and third party analytics tools, and use it to understand how your business use and needs can improve our services. This information includes clicks, scrolls, features accessed, access time and frequency, errors generated, performance data, and configurations, and devices used to access and their locations.</li>
                            </ol>
                        </ul>

                        <h3 className="mt-4"><b><u>Information that we collect from third parties</u></b></h3>
                        <ul>
                            <ol>
                                <li className="mb-2">i. Referrals : If someone has referred any of our products or services to you through any of our referral programs, that person may have provided us your name, email address and other personal information. You may contact us through this form to request that we remove your information from our database. If you provide us information about another person, or if another person gives us your information, we will only use that information for the specific reason for which it was provided to us.</li>
                                <br />
                                <li className="mb-2">ii. Information from social media sites and other publicly available sources : When you interact or engage with us on social media sites such as Facebook, Twitter, Google+ and Instagram through posts, comments, questions and other interactions, we may collect such publicly available information, including profile information, to allow us to connect with you, improve our services, or better understand user reactions and issues. We must tell you that once collected, this information may remain with us even if you delete it from the social media sites.</li>
                            </ol>
                        </ul>

                        <h3 className="mt-4"><b>Refund Policy</b></h3>

                        <p>IndiaNIC believes in helping its customers as much as possible, and has therefore a considerate cancellation policy. We, as a merchant, provide a service that comes in the form of services. As such, our offering is not a tangible product that can be returned for a refund. It can be used immediately upon receipt; there is no product to return.</p>

                        <ul>
                            <li className="mb-2">IndiaNIC undertakes all projects under the purview of Milestones that dictate the direction / road-map followed by the project.</li>

                            <li className="mb-2">All Web Design/ Development/ Applications are carried out and accepted upon a complete analysis and creation of a complete scope document, in order to make sure that teams have complete understanding of the work needed to be done, elimination possibilities of a project cancellation / reversal / dispute.</li>

                            <li className="mb-2">Since IndiaNIC and the Clientele realize that there is work involved in achieving every milestone of the project and completion of every module, a refund would not be possible for the work already completed.</li>

                            <li className="mb-2">IndiaNIC makes sure that in case of a project termination on a mutual basis, the client has control of all the work done till that date and the money, if paid for further development, is returned.</li>
                        </ul>

                        <h3 className="mt-4"><b><u>Delivery Policy</u></b></h3>
                        <ul>
                            <ol>
                                <li className="mb-2">We are in service business and doesn’t have tangible item to deliver. So there is no chance of missing or misplacement of any item. Our deliverables are in form of designs file, software code & compiled application. These deliverable are delivered to you in form of electronic files using our collaboration tool or email. Thereafter you have to save a copy of delivered electronic files on your server or on any storage media. Up-to 30 days from delivery date IndiaNIC can re-deliver the electronic files to you, thereafter IndiaNIC doesn’t owe the responsibility.</li>
                            </ol>
                        </ul>
                    </div>
                </div>
            </Animated>
        );
    }
}

export default (connect('',{})(PrivacyPolicy))