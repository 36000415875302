import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router'
import LoginForm from './forms/login/Login';
import Token from '../services/Token';
import { STORE_USER } from '../store/reducers/actionTypes';

class Login extends Component {

    constructor(props) {
        super(props);
        this.userService = this.props.dI.make('USER_SERVICE');
    }

    userAuthenticated(data) {
        console.log('userAuthenticated-->', JSON.stringify(data));
        this.props.setAuthenticated(data.data.token);
        return;
        Token.destroy();
        Token.store(data);
        this.userService.profile().then(profile => {
            this.props.setAuthenticated(profile);
        }).catch(error => console.error(error));
    }

    render() {
        return (
            <React.Fragment>
                {this.props.authenticated
                    ? <Redirect to={{ pathname: '/feedback' }} />
                    : (
                        <div className="">
                            <LoginForm {...this.props} authenticated={(data) => { this.userAuthenticated(data) }} />
                        </div>
                    )
                }
            </React.Fragment>
        );
    }
}

const mapStatsToProps = state => {
    return {
        authenticated: state.authenticated
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setAuthenticated: (profile) => dispatch({ type: STORE_USER, payload: profile })
    };
};

export default connect(mapStatsToProps, mapDispatchToProps)(Login);
