import React, { Component } from 'react';
import { Dropdown, Modal, Button } from 'react-bootstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Animated } from 'react-animated-css';
import common from '../../store/reducers/commons'
import { callApi, setTimelineData, setAnnouncementData } from '../../store/reducers/commonFunction'
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import errorMessages from '../../store/reducers/errorMessages'
import MobileToggle from '../mobileToggles/mobileToggles';
import SidebarAnnouncement from '../sidebarAnnouncement/SidebarAnnouncement';
import Pagination from 'rc-pagination'
import 'rc-pagination/assets/index.css';
import swal from 'sweetalert'
import helper from '../../services/helper';
import { Spinner } from 'react-activity';
import 'react-activity/dist/react-activity.css';
import Linkify from 'react-linkify';
import _ from 'lodash';


class Announcement extends Component {

    /**
    |--------------------------------------------------
    | constructor
    |--------------------------------------------------
    */

    constructor(props) {
        super(props);
        this.state = {
            contentArea: false,
            newAnnouncement: false,
            width: '',
            height: '',
            filename: '',
            file: undefined,
            imagePreviewUrl: '',
            errors: {
                name: '',
                announcement: '',
            },
            isFormValid: true,
            announcement: '',
            current_page: 1,
            per_page: 10,
            total: 1,
            last_page: 1,
            total_pages: null,
            visible: false,
            total_like_count: [],
            is_liked: 0,
            vsibles: false,
            isLoading: false,
            isAddAnnouncementLoading: false,
            isUpdate: false,
            annoucmentList: [],
        }
    }

    /**
    |--------------------------------------------------
    | componentDidMount
    |--------------------------------------------------
    */

    async componentDidMount() {
        await this.getAnnoucment();
    }

    /**
    |--------------------------------------------------
    | getAnnoucment
    |--------------------------------------------------
    */

    getAnnoucment = async (isAppend = true) => {

        let { current_page } = this.state;
        this.setState({
            isLoading: true
        })
        let company_id = this.props.userData.company_id ? this.props.userData.company_id : '';
        let body = {
            company_id, page: current_page
        }
        const response = await this.props.callApi('/getAnnouncement', body, 'post', null, true);
        console.log('getAnnouncement response-->', JSON.stringify(response));
        if (response) {
            this.props.setAnnouncementData(isAppend ? [...this.props.announcementData, ...response.data] : response.data);
            this.setState({
                per_page: response.meta.per_page,
                total: response.meta.total,
                isLoading: false,
                last_page: response.meta.last_page,
            })
        } else {
            this.setState({
                isLoading: false
            })
        }
    }

    /**
    |--------------------------------------------------
    | AddAnnoucement
    |--------------------------------------------------
    */

    AddAnnoucement = async () => {
        var { file, announcement, width, height } = this.state;
        let company_id = this.props.userData.company_id ? this.props.userData.company_id : '';

        if (this.validateForm()) {
            this.setState({
                isAddAnnouncementLoading: true
            })
            const formData = new FormData()
            if (file) {
                formData.append('image', file)
                formData.append('image_width', width)
                formData.append('image_height', height)
            }
            formData.append('company_id', company_id)
            formData.append('announcement', announcement)

            const response = await this.props.callApi('/addAnnouncement', formData, 'post', null, true, true);
            if (response) {
                common.showSuccessMessage("Announcement Details Added Successfully");
                this.props.setAnnouncementData([response.data, ...this.props.announcementData]);
                this.setState({
                    show: false,
                    isAddAnnouncementLoading: false,
                    isUpdate: false
                });
                this.sidebarAnnouncementRef.getAnnouncement(false, false);
            } else {
                this.setState({
                    isAddAnnouncementLoading: false
                })
            }
        }
    }

    /**
    |--------------------------------------------------
    | handleChange
    |--------------------------------------------------
    */

    handleChange = (event) => {

        this.setState({ [event.target.name]: event.target.value })
        if (event.target.value) {
            this.setState({
                errors: Object.assign(this.state.errors, { [event.target.name]: "" })
            });
        } else {
            this.setState({
                errors: Object.assign(this.state.errors, { [event.target.name]: errorMessages.REQUIRED })
            })
        }
    }

    /**
    |--------------------------------------------------
    | validateForm
    |--------------------------------------------------
    */

    validateForm() {
        let { announcement, errors, isFormValid } = this.state;

        if (!announcement.trim()) {
            errors.message = errorMessages.PROVIDE_ANNOUNCEMENT;
            errors.type = "MESSAGE"
        } else {
            errors.message = '';
            errors.type = ""
        }
        if (errors.message !== '') isFormValid = false;
        else isFormValid = true;
        this.setState({ errors, isFormValid })
        return isFormValid
    }

    /**
    |--------------------------------------------------
    | fileChangedHandlerImg
    |--------------------------------------------------
    */

    fileChangedHandlerImg(event) {
        var img = new Image();
        img.src = window.URL.createObjectURL(event.target.files[0]);
        img.onload = () => {
            let width = img.naturalWidth,
                height = img.naturalHeight;
            this.setState({
                width: width,
                height: height
            })
        }
        let reader = new FileReader()
        let file = event.target.files[0]
        let filename = event.target.files[0].name
        reader.onloadend = () => {
            this.setState({
                file: file,
                filename: filename,
                imagePreviewUrl: reader.result,
                fileVisible: true
            })
            // }
        }
        reader.readAsDataURL(file)
    }

    /**
    |--------------------------------------------------
    | handleClose
    |--------------------------------------------------
    */

    handleClose = (modelName) => {
        this.setState({ show: false });
    }

    /**
    |--------------------------------------------------
    | handleShow
    |--------------------------------------------------
    */

    handleShow = (modelName) => {
        this.setState({
            show: true, announcement: '', file: undefined, filename: '', fileVisible: false, errors: {
                message: '', type: '', imagePreviewUrl: ''
            }
        });
    }

    /**
    |--------------------------------------------------
    | deleteAnnoucment
    |--------------------------------------------------
    */

    deleteAnnoucment = async (announcement_id) => {
        let company_id = this.props.userData.company_id ? this.props.userData.company_id : '';

        swal({
            title: "Are you sure,That you want to delete this Annoucement ?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async willDelete => {
                if (willDelete) {
                    let body = {
                        announcement_id: announcement_id, company_id: company_id
                    }
                    this.setState({
                        announcement_id
                    })
                    const response = await this.props.callApi('/removeAnnouncement', body, 'post', null, true);
                    if (response.data.code === 200) {
                        common.showSuccessMessage(response.data.message);

                        let annoucmentListData = this.props.announcementData
                        let selectedIndex = annoucmentListData.findIndex(x => x.announcement_id == announcement_id);
                        annoucmentListData.splice(selectedIndex, 1);
                        this.setState({ visible: false, annoucmentList: annoucmentListData });
                    }
                }
            });
    }

    /**
    |--------------------------------------------------
    | openModal
    |--------------------------------------------------
    */

    openModal(image, announcement, announcement_id) {

        this.setState({
            imagePreviewUrl: image,
            announcement: announcement,
            announcement_id: announcement_id,
            isUpdate: true,
            show: true,
        });
    }

    /**
    |--------------------------------------------------
    | handleCloses
    |--------------------------------------------------
    */

    handleCloses = (modelName) => {
        this.setState({ visible: false });
    }

    /**
    |--------------------------------------------------
    | handlevisibles
    |--------------------------------------------------
    */

    /**
    |--------------------------------------------------
    | handlevisibles
    |--------------------------------------------------
    */

    handlevisibles = (modelName) => {
        this.setState({ visible: true });
    }

    /**
    |--------------------------------------------------
    | updateAnnoucement
    |--------------------------------------------------
    */

    updateAnnoucement = async () => {
        var { file, imagePreviewUrl, announcement, width, height, announcement_id } = this.state;
        let company_id = this.props.userData.company_id ? this.props.userData.company_id : '';
        if (this.validateForm()) {
            const formData = new FormData()
            console.log("File-->", file)
            this.setState({
                isAddAnnouncementLoading: true
            })
            if (file) {
                formData.append('image', file)
                formData.append('image_width', width)
                formData.append('image_height', height)
            } else {
                imagePreviewUrl != "" && formData.append('image', imagePreviewUrl)
            }
            formData.append('announcement_id', announcement_id)
            formData.append('company_id', company_id)
            formData.append('announcement', announcement)

            const response = await this.props.callApi('/updateAnnouncement', formData, 'post', null, true, true);
            if (response) {
                // this.props.history.push('/announcement')
                common.showSuccessMessage("Announcement details update succesfully");
                let annoucmentListData = this.props.announcementData
                let selectedIndex = annoucmentListData.findIndex(x => x.announcement_id == announcement_id);
                annoucmentListData.splice(selectedIndex, 1, response.data);
                this.setState({
                    show: false,
                    annoucmentList: annoucmentListData,
                    isAddAnnouncementLoading: false,
                    imagePreviewUrl: undefined,
                    announcement: '',
                    announcement_id: undefined,
                    isUpdate: true,
                });
                this.sidebarAnnouncementRef.getAnnouncement(false, false);
            } else {
                this.setState({
                    isAddAnnouncementLoading: false
                })
            }
        }
    }

    /**
    |--------------------------------------------------
    | handleLike
    |--------------------------------------------------
    */

    handleLike = async (announcement_id, index) => {
        let total_like_count = [];
        let body = {
            announcement_id: announcement_id
        }

        const response = await this.props.callApi('/likeAnnouncement', body, 'post', 'ADMIN_LOGIN', true);
        if (response.data.code === 200) {

            let annoucmentListData = this.props.announcementData
            let selectedIndex = annoucmentListData.findIndex(x => x.announcement_id == announcement_id);
            _.set(annoucmentListData, `[${selectedIndex}].total_likes`,
                _.get(annoucmentListData, `[${selectedIndex}].is_liked`) == 0
                    ? _.get(annoucmentListData, `[${selectedIndex}].total_likes`) + 1
                    : _.get(annoucmentListData, `[${selectedIndex}].total_likes`) - 1);
            _.set(annoucmentListData, `[${selectedIndex}].is_liked`, _.get(annoucmentListData, `[${selectedIndex}].is_liked`) == 1 ? 0 : 1);

            this.props.setAnnouncementData(annoucmentListData);

            //Update Sidebar announcement data
            let timelineData = this.props.timelineData
            let timelineDataSelectedIndex = timelineData.findIndex(x => x.data.announcement_id == announcement_id);
            _.set(timelineData, `[${timelineDataSelectedIndex}].data.total_likes`,
                _.get(timelineData, `[${timelineDataSelectedIndex}].data.is_liked`) == 0
                    ? _.get(timelineData, `[${timelineDataSelectedIndex}].data.total_likes`) + 1
                    : _.get(timelineData, `[${timelineDataSelectedIndex}].data.total_likes`) - 1);
            _.set(timelineData, `[${timelineDataSelectedIndex}].data.is_liked`, _.get(timelineData, `[${timelineDataSelectedIndex}].data.is_liked`) == 1 ? 0 : 1);

            this.props.setTimelineData([...timelineData]);
        }
    }

    /**
    |--------------------------------------------------
    | openModal1
    |--------------------------------------------------
    */

    openModal1(image) {

        this.setState({
            image: image,
            vsibles: true
        });
    }

    /**
    |--------------------------------------------------
    | handleClosest
    |--------------------------------------------------
    */

    handleClosest = (modelName) => {
        this.setState({ vsibles: false });
    }

    /**
    |--------------------------------------------------
    | handleScroll
    |--------------------------------------------------
    */

    handleScroll = (e) => {
        let element = e.target
        if (element.scrollTop + element.offsetHeight >= element.scrollHeight) {
            if (this.state.current_page != this.state.last_page) {
                this.setState({ current_page: this.state.current_page + 1 }, () => {
                    this.getAnnoucment()
                })
            }
        }
    }

    /**
    |--------------------------------------------------
    | render
    |--------------------------------------------------
    */

    render() {
        let role = this.props.userData.role ? this.props.userData.role : '';
        const { announcementData } = this.props;
        var { total_like_count, imagePreviewUrl,
            fileVisible, errors, announcement, total, current_page, per_page,
            isAddAnnouncementLoading, isUpdate } = this.state;
        var str = announcement;
        return (
            <div className="main-page-wrapper announcements-page">
                <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={true} animationInDelay={500}>
                    <MobileToggle {...this.props} />
                    {/* <Leftbar {...this.props} /> */}
                    <SidebarAnnouncement {...this.props} onRef={ref => this.sidebarAnnouncementRef = ref} />
                    <div className={this.state.contentArea === false ? "content-area-wrapper" : "content-area-wrapper close-sidebar"}>
                        <div className="content-area-header">
                            <h6 className="">Broadcast</h6>
                            {/* {(role === 'employee') ? <span></span> :
                                <Button onClick={() => this.handleShow("newAnnouncement")}>make new</Button>
                            } */}
                        </div>

                        <div className="content-area-inner">
                            {(role !== 'employee') && <span className="btn plus-btn" onClick={() => this.handleShow("newAnnouncement")}>+</span>}
                            <ul className="content-list">
                                <PerfectScrollbar onScroll={this.handleScroll}>
                                    {
                                        announcementData && Array.isArray(announcementData) && announcementData.map((annoucment, key) => {

                                            return (
                                                <li className="mb-4" key={key}>
                                                    <div className="card">
                                                        <div className="card-header">
                                                            <div className="leftbar-list-item-inner py-0">
                                                                <div className="user-img small">
                                                                    <div className="user-img-wrapper m-0">

                                                                        {
                                                                            (annoucment.user_image === '') ? <img src="images/noimage.jpg" alt="" /> :
                                                                                <img src={annoucment.user_image} alt="images/may-tucker.jpg" />
                                                                        }
                                                                    </div>

                                                                </div>

                                                                <div className="user-data w-100">
                                                                    <div className="user-name">{annoucment.user_first_name} {annoucment.user_last_name}</div>
                                                                    <div className="data-text text-capitalize">{helper.formatDetailsDate(annoucment.created_at)}</div>
                                                                </div>
                                                                {annoucment.user_id === _.get(this.props, 'userData.user_id') && <Dropdown className="dots-dropdown ml-auto">
                                                                    <Dropdown.Toggle variant="" id="dropdown-basic" className="material-icons">
                                                                        more_vert
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu alignRight>
                                                                        <Dropdown.Item onClick={() => this.openModal(annoucment.image, annoucment.announcement, annoucment.announcement_id)}>Edit</Dropdown.Item>
                                                                        <Dropdown.Item onClick={() => this.deleteAnnoucment(annoucment.announcement_id)}>Delete</Dropdown.Item>

                                                                    </Dropdown.Menu>
                                                                </Dropdown>}
                                                            </div>
                                                        </div>

                                                        <div className="card-body">
                                                            <Linkify><p className="card-text">{annoucment.announcement} </p></Linkify>
                                                            {annoucment.image != "" && <span className="user-uploded-img">
                                                                <img className="m-0" src={annoucment.image} alt="" onClick={() => this.openModal1(annoucment.image)} />
                                                            </span>}
                                                        </div>
                                                        <div className="control-panel-options">
                                                            <div className="likes-counter-block">
                                                                <span className="likes-icon">
                                                                    <input type="checkbox" className="like-input" checked={annoucment.is_liked ? true : false} onClick={() => this.handleLike(annoucment.announcement_id, key)} />
                                                                    {
                                                                        (annoucment.is_liked === 1) ?
                                                                            <img src="/images/upvote_purple.svg" alt="liked post" className="purple-icon" /> :
                                                                            <img src="/images/upvote.svg" alt="like post" className="gray-icon" />
                                                                    }

                                                                    {/* <img src="/images/like-icon-purple.svg" alt="liked post" className="purple-icon" />
                                                        <img src="/images/like-icon-gray.svg" alt="like post" className="gray-icon" /> */}

                                                                </span>
                                                                {/* <span className="likes-label">1 like</span> */}

                                                                <div className="counter-label-block">

                                                                    {
                                                                        (annoucment.total_likes === 0) ? <span className="likes-label">Be the first upvote</span> :
                                                                            (annoucment.is_liked == 1 && annoucment.total_likes === 1) ? <span className="active-upvote-label">You're the first to upvote</span> :
                                                                                (annoucment.is_liked == 1 && annoucment.total_likes === 2) ? <span className="active-upvote-label">You & one other upvoted</span>
                                                                                    :
                                                                                    (annoucment.is_liked == 1 && annoucment.total_likes > 1) ? <span className="active-upvote-label">You & {Number(annoucment.total_likes) - 1} other upvoted</span>
                                                                                        :
                                                                                        <span className="likes-label">{annoucment.total_likes} upvoted</span>

                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            )
                                        })
                                    }
                                    {this.state.isLoading && <div className={'loader-block'}>
                                        <Spinner />
                                    </div>}
                                </PerfectScrollbar>
                            </ul>
                        </div>
                    </div>
                </Animated>

                <Modal className="common-modal small" centered show={this.state.show} onHide={() => this.handleClose("newAnnouncement")}>
                    {/* <Modal.Header closeButton>
                        <Modal.Title>Modal heading</Modal.Title>
                    </Modal.Header> */}
                    <button type="button" className="close fixed-close-btn" onClick={() => this.handleClose("newAnnouncement")}>
                        <span aria-hidden="true">×</span>
                    </button>
                    <Modal.Body className="">
                        <h4>{isUpdate ? "Update Broadcast" : "New Broadcast"}</h4>
                        <span className="common-text">This annoucment will be displayed to all your colleague on the home screen until you do not remove it.</span>
                        <div className="mt-3 mb-3">
                            <div className="suggestion-block">
                                {/* <textarea name="" id="" placeholder="Type here" className="form-control"></textarea> */}
                                <textarea
                                    className='form-control'
                                    type='text'
                                    name="announcement"
                                    placeholder='Write here...'
                                    value={announcement}
                                    onChange={this.handleChange}
                                    maxLength="3000"
                                ></textarea>
                                {errors.type == "MESSAGE" && <span className="error-block"> {errors.message} </span>}
                                <div className="d-flex justify-content-end">
                                    <span className="remaining-words"><span>{str.length}</span>/3000</span>
                                </div>
                            </div>
                        </div>
                        {imagePreviewUrl && imagePreviewUrl != "" &&
                            <ul className="modal-uploaded-img-block">
                                <li className="li-image-wrap">
                                    <span className="remove-image" onClick={() => this.setState({ fileVisible: false, imagePreviewUrl: '', file: undefined, filename: undefined, })}>
                                        X
                                    </span>
                                    <div className="">
                                        <img src={imagePreviewUrl} alt="" />
                                    </div>
                                </li>
                            </ul>}
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="add-img my-1">
                                {imagePreviewUrl == "" && <div>
                                    <span className="camera-icon">
                                        <img src="images/camera.svg" alt="" />
                                    </span>
                                    <input type="file"
                                        accept="image/*"
                                        className="input-file"
                                        onChange={this.fileChangedHandlerImg.bind(this)} />
                                </div>}
                            </div>

                            <Button disabled={isAddAnnouncementLoading} onClick={isUpdate ? this.updateAnnoucement : this.AddAnnoucement}>{isAddAnnouncementLoading ? "Loading..." : isUpdate ? "Update" : "Post"}</Button>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal className="common-modal small transparent-modal" centered show={this.state.vsibles} onHide={() => this.handleClosest("userUploadedImgs")}>
                    <Modal.Body className="p-5">
                        <div className="modal-image">
                            <img
                                className="d-block w-100"
                                src={this.state.image}
                                alt=""
                            />
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        userData: state.getUserData,
        timelineData: state.timelineData,
        announcementData: state.announcementData,
    }
}
export default withRouter(connect(mapStateToProps, { callApi, setTimelineData, setAnnouncementData })(Announcement))
