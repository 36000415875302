import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { Animated } from 'react-animated-css';
import MobileToggle from '../mobileToggles/mobileToggles';
import { connect } from 'react-redux';
import common from '../../store/reducers/commons'
import errorMessages from '../../store/reducers/errorMessages'
import { withRouter } from 'react-router';
import { callApi } from '../../store/reducers/commonFunction'
import Leftbar from './leftbar/Leftbar';
import SidebarAnnouncement from '../sidebarAnnouncement/SidebarAnnouncement';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';


class AddEmployee extends Component {

    /**
    |--------------------------------------------------
    | constructor
    |--------------------------------------------------
    */

    constructor(props) {
        super(props);
        this.state = {
            filename: '',
            file: '',
            imagePreviewUrl: '',
            errors: {
                first_name: '',
                last_name: '',
                designation: '',
            },
            isFormValid: true,
            first_name: '',
            last_name: '',
            email: '',
            designation: '',
            dail_code: '',
            phone: '',
            status: false,
            rawPhone: '',
            dial_code: '',
            crop: {
                unit: '%',
                width: 30,
                aspect: 16 / 9,
            },
        }
    }

    /**
    |--------------------------------------------------
    | handleChange
    |--------------------------------------------------
    */

    handleChange = (event) => {

        this.setState({ [event.target.name]: event.target.value })
        if (event.target.value) {
            this.setState({
                errors: Object.assign(this.state.errors, { [event.target.name]: "" })
            });
        } else {
            this.setState({
                errors: Object.assign(this.state.errors, { [event.target.name]: errorMessages.REQUIRED })
            })
        }
    }

    /**
    |--------------------------------------------------
    | fileChangedHandlerImg
    |--------------------------------------------------
    */

    fileChangedHandlerImg(event) {
        let reader = new FileReader()
        let file = event.target.files[0]
        let filename = event.target.files[0].name
        reader.onloadend = () => {
            this.setState({
                file: file,
                src: reader.result,
                filename: filename,
                imagePreviewUrl: reader.result,
                fileVisible: true
            })
        }
        reader.readAsDataURL(file)
    }

    /**
    |--------------------------------------------------
    | Redirect
    |--------------------------------------------------
    */

    Redirect = async () => {
        this.props.history.push('/contacts')

    }

    /**
    |--------------------------------------------------
    | AddNewEmploye
    |--------------------------------------------------
    */

    AddNewEmploye = async () => {
        var { Imgblob, first_name, last_name, email, rawPhone, dial_code, file, designation } = this.state;
        let company_id = this.props.userData.company_id ? this.props.userData.company_id : '';

        if (this.validateForm()) {
            const formData = new FormData()
            if (file) {
                formData.append('image', Imgblob)
            }
            formData.append('first_name', first_name)
            formData.append('last_name', last_name)
            formData.append('email', email)
            formData.append('designation', designation)
            formData.append('company_id', company_id)
            formData.append('phone', rawPhone)
            formData.append('dialcode', dial_code)

            const response = await this.props.callApi('/addSingleEmployee', formData, 'post', null, true);
            if (response.data.code === 200) {
                common.showSuccessMessage(response.data.message);
                this.props.history.push('/contacts')
            }
        }
    }

    /**
    |--------------------------------------------------
    | validateForm
    |--------------------------------------------------
    */

    validateForm() {
        let { first_name, email, errors, isFormValid } = this.state;
        if (!first_name.trim()) errors.first_name = errorMessages.PROVIDE_FIRST_NAME;
        else errors.first_name = '';
        if (!email.trim()) errors.email = errorMessages.PROVIDE_EMAIL;
        else errors.email = '';
        if (errors.first_name !== '' || errors.email !== '') isFormValid = false;
        else isFormValid = true;
        this.setState({ errors, isFormValid })
        return isFormValid
    }

    /**
    |--------------------------------------------------
    | handleOnChange
    |--------------------------------------------------
    */

    handleOnChange = (value, data, event, formattedValu) => {
        let dialnum = data.dialCode;
        let mobilenum = value.slice(data.dialCode.length);
        this.setState({
            dial_code: dialnum,
            rawPhone: mobilenum
        })
    }

    /**
    |--------------------------------------------------
    | onImageLoaded
    |--------------------------------------------------
    */

    onImageLoaded = image => {
        this.imageRef = image;
    };

    /**
    |--------------------------------------------------
    | onCropComplete
    |--------------------------------------------------
    */

    onCropComplete = (crop) => {
        this.makeClientCrop(crop);
    };

    /**
    |--------------------------------------------------
    | makeClientCrop
    |--------------------------------------------------
    */

    async makeClientCrop(crop) {
        if (this.imageRef && crop && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                this.state.profilePicName
            );
            this.setState({ croppedImageUrl });
        }
    }

    /**
    |--------------------------------------------------
    | onCropChange
    |--------------------------------------------------
    */

    onCropChange = (crop, percentCrop) => {
        this.setState({ crop });
    };

    /**
    |--------------------------------------------------
    | getCroppedImg
    |--------------------------------------------------
    */

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );
        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    common.showErrorMessage('Canvas is empty');
                    return;
                }
                this.setState({ Imgblob: blob })
                blob.name = fileName;
                window.URL.revokeObjectURL(this.fileUrl);
                this.fileUrl = window.URL.createObjectURL(blob);
                resolve(this.fileUrl);
            }, 'image/jpeg');
        });
    }

    /**
    |--------------------------------------------------
    | render
    |--------------------------------------------------
    */

    render() {
        var { first_name, last_name, email, designation, phone, errors } = this.state;
        return (
            <div className="main-page-wrapper contacts-page">
                <MobileToggle {...this.props} />
                <Leftbar {...this.props} />
                <SidebarAnnouncement {...this.props} />
                <div className="content-area-wrapper">
                    <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={true} animationInDelay={500}>
                        <div className="content-area-inner">

                            <h6>Add New Employee</h6>

                            {/* <div className="add-employee-block csv mt-5"> */}
                            {/* <div className="add-employee-heading mb-3">
                                    <div className="checkbox-radio">
                                        <label className="mb-0">
                                            <input type="radio" name="addEmployee" value="" />
                                            <span>
                                                <span className="radio-style"></span>
                                                <span className="text">Using CSV</span>
                                            </span>
                                        </label>
                                    </div>
                                    <Link to="#" className="theme-color">
                                        <svg className="mr-2" width="16px" height="17px" viewBox="0 0 16 17" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                            <desc>Created with Sketch.</desc>
                                            <defs>
                                                <path d="M15,13 C15.55,13 16,13.45 16,14 L16,16 L16,16 C16,16.5107143 15.6119898,16.9352041 15.116199,16.9932398 L15,17 L15,17 L1,17 C0.45,17 0,16.55 0,16 L0,14 C0,13.45 0.45,13 1,13 C1.55,13 2,13.45 2,14 L2,15 L14,15 L14,14 C14,13.45 14.45,13 15,13 Z M8,0 C8.553,0 9,0.448 9,1 L9,9 L11.4,7.2 C11.842,6.867 12.469,6.958 12.8,7.4 C13.132,7.842 13.042,8.469 12.6,8.8 L8.6,11.8 C8.423,11.933 8.211,12 8,12 C7.799,12 7.598,11.939 7.425,11.818 L3.425,9.004 C2.973,8.686 2.864,8.062 3.182,7.611 C3.5,7.159 4.123,7.05 4.575,7.368 L7.00297831,9.07777918 C7.00100489,9.05211103 7,9.02617241 7,9 L7,1 C7,0.448 7.447,0 8,0 Z" id="path-1"></path>
                                            </defs>
                                            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                <g id="Add-New-Employee" transform="translate(-924.000000, -167.000000)">
                                                    <g id="06)-Checkbox/2)-Primary/4)-Checkbox-+-Subtitle/1)-Default-Copy-2" transform="translate(920.000000, 162.000000)">
                                                        <g id="Checkbox" transform="translate(4.000000, 5.000000)">
                                                            <mask id="mask-2" fill="white">
                                                                <use xlinkHref="#path-1"></use>
                                                            </mask>
                                                            <use id="🎨-Icon-Сolor" fill="#3366FF" xlinkHref="#path-1"></use>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                        Download sample CSV</Link>
                                </div>
                                <p className="common-text mb-0">Select and upload CSV file to add your employee data at one shot (Max 1000)</p>
                                <p className="common-text">The format of CSV should be the same as per the sample below.</p>

                                <div className="requirements">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row mx-0">
                                                <div className="col-lg-6 px-0">
                                                    <div className="row mx-0">
                                                        <div className="col-12 px-0">
                                                            <div className="requirements-details">
                                                                <span className="requirements-label">first_name</span>
                                                                <span className="requirements-value text-danger">Required</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 px-0">
                                                            <div className="requirements-details">
                                                                <span className="requirements-label">last_name</span>
                                                                <span className="requirements-value">Optional</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 px-0">
                                                    <div className="row mx-0">
                                                        <div className="col-12 px-0">
                                                            <div className="requirements-details">
                                                                <span className="requirements-label">email</span>
                                                                <span className="requirements-value text-danger">Required</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 px-0">
                                                            <div className="requirements-details">
                                                                <span className="requirements-label">phone</span>
                                                                <span className="requirements-value">Optional</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 px-0">
                                                    <div className="requirements-details">
                                                        <span className="requirements-label">dialcode</span>
                                                        <span className="requirements-value">Optional</span>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 px-0">
                                                    <div className="requirements-details">
                                                        <span className="requirements-label">designation</span>
                                                        <span className="requirements-value">Optional</span>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 px-0">
                                                    <div className="requirements-details">
                                                        <span className="requirements-label">status</span>
                                                        <span className="requirements-value">(active/inactive)</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}


                            <div className="add-employee-block api mt-4">
                                {/* <div className="add-employee-heading mb-3">
                                    <div className="checkbox-radio">
                                        <label className="mb-0">
                                            <input type="radio" name="addEmployee" value="" />
                                            <span>
                                                <span className="radio-style"></span>
                                                <span className="text">Using API</span>
                                            </span>
                                        </label>
                                    </div>
                                </div> */}
                                {/* <p className="common-text">API Endpoint</p>
                                <p className="common-text theme-color">https://app.lorem.com/api/v1</p>
                                <p className="common-text">API Endpoint</p> */}
                                {/* <div className="api-docs-block">
                                    <input type="text" className="form-control" value="https://app.lorem.com/admin/docs/api/v1" disabled/>
                                    <span className="mx-3">
                                        <svg width="22px" height="22px" viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                            <desc>Created with Sketch.</desc>
                                            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                                                <g id="Add-New-Employee" transform="translate(-1074.000000, -547.000000)" stroke="#4D59EE" strokeWidth="2">
                                                    <g id="link" transform="translate(1075.000000, 548.000000)">
                                                        <path d="M8,11 C8.86895549,12.1616929 10.1996273,12.8887004 11.6466499,12.9923333 C13.0936724,13.0959663 14.5143658,12.5660061 15.54,11.54 L18.54,8.54 C20.434861,6.57810557 20.4077619,3.45957611 18.4790929,1.53090712 C16.5504239,-0.397761869 13.4318944,-0.424861048 11.47,1.47 L9.75,3.18" id="Path"></path>
                                                        <path d="M12,9 C11.1310445,7.83830708 9.8003727,7.11129957 8.35335013,7.00766666 C6.90632757,6.90403374 5.4856342,7.43399387 4.46,8.46 L1.46,11.46 C-0.434861048,13.4218944 -0.407761869,16.5404239 1.52090712,18.4690929 C3.44957611,20.3977619 6.56810557,20.424861 8.53,18.53 L10.24,16.82" id="Path"></path>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                    </span>
                                </div> */}
                                {/* <p className="common-text mt-4 mb-2">Your API token</p>
                                <p className="common-text theme-color">JRjjfjsaamdkjdf45adfs515df351xca51dcs15a5dsaf1f1adssdakjkds</p> */}
                            </div>


                            <div className="add-employee-block api mt-4">
                                {/* <div className="add-employee-heading mb-3">
                                    <div className="checkbox-radio">
                                        <label className="mb-0">
                                            <input type="radio" name="addEmployee" value="" />
                                            <span>
                                                <span className="radio-style"></span>
                                                <span className="text">manually</span>
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                <p className="common-text">Add employee detail manually one at a time.</p> */}
                                <div className="row">
                                    <div className="col-12">
                                        <div className="company-logo-block">
                                            {/* <span className="company-logo">
                                                <img src="images/noimage.jpeg" alt="" />
                                            </span> */}
                                            {/* {fileVisible ?
                                             <span>
                                             <img src={imagePreviewUrl} width="100px"/><span> <i className='fa fa-times-circle-o text-danger fa-2x' onClick={() => this.setState({ fileVisible: !this.state.fileVisible, file: '' })} /></span>
                                            </span> : null}  */}
                                            <input type="file" className="company-logo-changer" onChange={this.fileChangedHandlerImg.bind(this)} />
                                            <ReactCrop
                                                src={this.state.src}
                                                crop={this.state.crop}
                                                ruleOfThirds
                                                onImageLoaded={this.onImageLoaded}
                                                onComplete={this.onCropComplete}
                                                onChange={this.onCropChange}
                                                className="mx-3"
                                            />
                                            <span className="">
                                                {(this.state.croppedImageUrl) ?
                                                    <span className="company-logo mr-0">
                                                        <img alt="Crop" src={this.state.croppedImageUrl} />
                                                    </span> :
                                                    <span className="company-logo">
                                                        <img src="images/noimage.jpeg" alt="" />
                                                    </span>
                                                }
                                            </span>
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className="form-group mb-4">

                                            <input
                                                className='form-control'
                                                type='text'
                                                name='first_name'
                                                placeholder='Enter First Name'
                                                value={first_name}
                                                onChange={this.handleChange}

                                            />
                                            <span className="error-msg"> {errors.first_name} </span>

                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className="form-group mb-4">
                                            <input
                                                className='form-control'
                                                type='text'
                                                name='last_name'
                                                placeholder='Enter Last Name'
                                                value={last_name}
                                                onChange={this.handleChange}

                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className="form-group mb-4">
                                            <input
                                                type="text"
                                                id="designation"
                                                name="designation"
                                                className="form-control"
                                                placeholder="Designation"
                                                value={designation}
                                                onChange={this.handleChange}

                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className="input-group mobile-input mb-4">
                                            <div className="input-group-prepend">

                                            </div>
                                            <PhoneInput
                                                className="form-control"
                                                name="phone"
                                                country={'us'}
                                                value={phone}
                                                onChange={this.handleOnChange}
                                            />
                                            <span className='error-msg'>{errors.phone}</span>

                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className="form-group mb-4">
                                            <input
                                                type="email"
                                                id="email"
                                                name="email"
                                                className="form-control"
                                                placeholder="Email"
                                                value={email}
                                                onChange={this.handleChange}

                                            />
                                            <span className="error-msg"> {errors.email} </span>
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <hr className="mt-1 mb-4" />
                                    </div>

                                    <div className="col-12 text-right pb-3">
                                        <Button variant="outline-primary" onClick={this.Redirect}>cancel</Button>
                                        <Button className="ml-3" onClick={this.AddNewEmploye}>save</Button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </Animated>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        userData: state.getUserData
    }
}
export default withRouter(connect(mapStateToProps, { callApi })(AddEmployee))