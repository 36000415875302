import React, { Component } from 'react';
// import Error from '../error/Error';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';
import { Animated } from "react-animated-css";
import { callApi, setUserData, setProfileInformation } from '../../../store/reducers/commonFunction';
import common from '../../../store/reducers/commons';
import errorMessages from '../../../store/reducers/errorMessages'
import { Spinner } from 'react-activity';
import 'react-activity/dist/react-activity.css';

class Login extends Component {

    // constructor(props) {
    //     super(props);
    //     this.state = {
    //         forgotPasscodeModal: false,
    //         show: false,
    //     }
    // }
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            rememberme: false,
            isFormValid: true,
            isLoading: false,
            errors: {
                email: '',
                password: '',
            }
        }
        // this.handleChange = this.handleChange.bind(this)
        // this.rememberMe = this.rememberMe.bind(this)
    }


    /***************** Handle Change ******************************/
    handleChange = (event) => {
        let { errors, isFormValid } = this.state;
        if (!isFormValid) {
            if (event.target.name === 'email') {

                if (!common.validateEmail(event.target.value)) errors.email = errorMessages.PROVIDE_VALID_EMAIL;
                else errors.email = '';
            }
            else {
                if (!common.validatePassword(event.target.value)) errors.password = errorMessages.PROVIDE_VALID_PASSWORD;
                else errors.password = '';

            }
        }
        this.setState({ [event.target.name]: event.target.value, errors });
    }

    /*******************  Login  ******************/
    adminLogin = async (event) => {
        event.preventDefault();
        try {

            let { password } = this.state;
            let email = this.props.registermail ? this.props.registermail : '';

            //   if(email && password){
            let encodeCode = btoa(btoa(password));
            let body = { email, password: encodeCode };
            if (this.validateForm()) {
                this.setState({
                    isLoading: true
                })
                const data = await this.props.callApi('/verifyPassword', body, 'post', 'ADMIN_LOGIN');
                this.props.setUserData(data.data);
                this.setState({
                    isLoading: false
                })
                setTimeout(async () => {
                    let user_id = data.data.user_id ? data.data.user_id : '';

                    let profileBody = { user_id }
                    const response = await this.props.callApi('/getUserInfo', profileBody, 'post', null, true);
                    if (response) {
                        this.props.setProfileInformation(response.data);
                    }
                }, 2000);

                if (data.data.status === true) {
                    common.setItem("accessToken", data.data.token)
                }
                this.props.authenticated(data);
                this.props.history.push('/feedback')
            }
        } catch (error) {
            this.setState({
                isLoading: false
            })
            throw (error)
        }
    }

    /*******************  Form validation  ******************/
    validateForm() {
        let { password, errors, isFormValid } = this.state;
        // for email
        // if (!email.trim()) errors.email = errorMessages.PROVIDE_EMAIL;
        // else if (!common.validateEmail(email)) errors.email = errorMessages.PROVIDE_VALID_EMAIL;
        // else errors.email = '';
        // for password
        if (!password.trim()) errors.password = errorMessages.PROVIDE_PASSWORD;
        else errors.password = '';

        if (errors.password !== "") isFormValid = false;
        if (errors.password === "") isFormValid = true;
        this.setState({ errors, isFormValid });
        return isFormValid;
    }

    handleClose = (modelName) => {
        this.setState({ show: false });
    }
    handleShow = (modelName) => {
        this.setState({ show: true });
    }

    onClickCancel = (e) => {
        e.preventDefault()
        this.props.history.goBack();
    }

    //******************** Forgot password **************************/ 

    forgotPassword = async (e) => {
        e.preventDefault();
        let email = this.props.registermail
        var body = { email: email };
        // if (this.validateForm()) {
        // this.setState({ isLoading: true })
        const response = await this.props.callApi('/requestResetPassword', body, 'post');
        // this.setState({ isLoading: false })
        if (response.data.status === true) {
            this.handleClose("forgotPasscodeModal")
            common.setItem("passwordToken", response.data.token)
            common.showSuccessMessage(response.data.message);
        } else {
            this.handleClose("forgotPasscodeModal")
            common.showErrorMessage(response.data.message);
        }
        // }
    }

    render() {
        let email = this.props.registermail ? this.props.registermail : '';

        let { password, errors, isLoading } = this.state;

        return (
            <React.Fragment>
                <div className="login-register-wrapper login-page">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 mx-auto">
                                <h2 className="page-heading">Login</h2>
                                <form onSubmit={this.adminLogin}>
                                    <div className="form-group">
                                        <input type='text'
                                            className='form-control'
                                            name='email'
                                            value={email}
                                            id='email'
                                            placeholder='Enter Email ID'
                                            readOnly
                                        />
                                        {/* <span className='error-msg' >{errors.email}</span> */}
                                    </div>
                                    <div className="form-group">
                                        <input
                                            className={errors.password ? 'form-control input-error' : 'form-control'}
                                            type='password'
                                            name='password'
                                            value={password}
                                            id='password'
                                            placeholder=' Enter Password'
                                            onChange={this.handleChange} />
                                        <i className='fa fa-lock' aria-hidden='true' />
                                        <span className='error-msg' >{errors.password}</span>
                                    </div>
                                    <div className="text-right mb-4">
                                        <Link className="s1-subtitle theme-color d-inline" onClick={() => this.handleShow("forgotPasscodeModal")} to="#">Forgot Passcode?</Link>
                                    </div>
                                    {/* <Link to="#" type="submit" className="btn btn-primary btn-block" onClick={this.adminLogin}>Login</Link> */}
                                    <button type="submit" className='btn btn-primary btn-block' onClick={this.adminLogin}>
                                        {isLoading ? <Spinner color={'#fff'} /> : "Login"}
                                    </button>
                                    <button className='btn btn-outline-primary d-block btn-block' onClick={this.onClickCancel}>
                                        Back
                                    </button>
                                </form>

                                {/* <div className="text-center mb-2">
                                    <span className="s1-subtitle text-light text-center d-inline">Not a member?</span>
                                </div>

                                <Link className="btn btn-outline-primary d-block" to={{ pathname: '/register' }}>JOIN NOW</Link> */}
                            </div>

                            <div className="col-lg-7 text-center d-lg-block d-none">
                                <div className="login-img-block">
                                    <img src="/images/login-page-bgi-img.svg" alt="" />
                                    <div className="login-social-icons-img">
                                        <Animated animationIn="lightSpeedIn" animationOut="fadeOut" isVisible={true} animationInDelay={2000}>
                                            <img src="/images/login-page-social-icons-img.svg" alt="" className="" />
                                        </Animated>
                                    </div>
                                    <div className="login-character-img">
                                        <Animated animationIn="fadeInLeft" animationOut="fadeOut" isVisible={true} animationInDelay={900}>
                                            <img src="/images/login-page-character-img.svg" alt="" className="" />
                                        </Animated>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <Modal className="common-modal small" centered show={this.state.show} onHide={() => this.handleClose("forgotPasscodeModal")}>

                    <button type="button" className="close fixed-close-btn" onClick={() => this.handleClose("forgotPasscodeModal")}>
                        <span aria-hidden="true">×</span>
                    </button>
                    <Modal.Body className="p-5">
                        <div className="row">
                            <div className="col-md-6">
                                <img src="images/forgot-passcode-img.svg" alt="" />
                            </div>
                            <div className="col-md-6 d-flex flex-column justify-content-center">
                                <div className="modal-body-inner text-md-left text-center mt-md-0 mt-3">
                                    <h4>Forgot Passcode?</h4>
                                    <p className="common-text">We will semd you a link for verification and later you can reset your passcode.</p>
                                    {/* <Button onClick={() => this.handleClose("forgotPasscodeModal")}>Continue</Button> */}
                                    {/* <Button onClick={() => this.props.history.push('/forgot-password')}>send link</Button> */}
                                    <Button onClick={this.forgotPassword}>send link</Button>
                                    <Button variant="outline-primary" className="ml-3" onClick={() => this.handleClose("forgotPasscodeModal")}>not now</Button>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>

                </Modal>

            </React.Fragment >
        );
    }
}
const mapStateToProps = (state) => {
    return {
        registermail: state.getRegisterData,
    }
}

export default withRouter(connect(mapStateToProps, { callApi, setUserData, setProfileInformation })(Login))