import React, { Component } from 'react';
import { Animated } from "react-animated-css";
import { connect } from 'react-redux';
import { callApi } from '../../store/reducers/commonFunction'
import errorMessages from '../../store/reducers/errorMessages'
import common from '../../store/reducers/commons'
import { Spinner } from 'react-activity';
import 'react-activity/dist/react-activity.css';

import { withRouter } from 'react-router';
const queryString = require('query-string')
class SetPasscode extends Component {

    /**
    |--------------------------------------------------
    | constructor
    |--------------------------------------------------
    */

    constructor(props) {
        super(props)
        this.state = {
            newPassword: '',
            confirmPassword: '',
            isFormValid: true,
            token: '',
            errors: {
                newPassword: '',
                confirmPassword: ''
            },
            isLoading: false
        }
    }

    /**
    |--------------------------------------------------
    | componentDidMount
    |--------------------------------------------------
    */

    componentDidMount() {
        let url = this.props.location.search;
        let params = queryString.parse(url);
        this.setState({ token: params.token })
    }

    /**
    |--------------------------------------------------
    | handleChange
    |--------------------------------------------------
    */

    handleChange = event => {
        let { errors, isFormValid } = this.state;
        this.setState({ [event.target.name]: event.target.value })
        if (!isFormValid) {
            if (event.target.name === 'newPassword') {
                if (!common.validatePassword(event.target.value)) errors.newPassword = errorMessages.PROVIDE_VALID_PASSWORD;
                else errors.newPassword = '';
            }
            else {
                if (this.state.newPassword !== event.target.value) errors.confirmPassword = errorMessages.PASSWORD_NOT_MATCHED;
                else errors.confirmPassword = '';
            }
        }
    }

    /**
    |--------------------------------------------------
    | validateForm
    |--------------------------------------------------
    */

    validateForm() {
        let { newPassword, confirmPassword, errors, isFormValid } = this.state;
        //for newpassword
        if (!newPassword.trim()) errors.newPassword = errorMessages.PROVIDE_PASSWORD;
        else if (!common.validatePassword(newPassword)) errors.newPassword = errorMessages.PROVIDE_VALID_PASSWORD;
        else errors.newPassword = '';
        //for confirm password
        if (!confirmPassword.trim()) errors.confirmPassword = errorMessages.PROVIDE_CONFIRM_PASSWORD;
        else if (newPassword !== confirmPassword) errors.confirmPassword = errorMessages.PASSWORD_NOT_MATCHED;
        else errors.confirmPassword = '';
        if (errors.newPassword !== '' || errors.confirmPassword !== '') isFormValid = false;
        else isFormValid = true;
        this.setState({ errors, isFormValid })
        return isFormValid
    }

    /**
    |--------------------------------------------------
    | resetPassword
    |--------------------------------------------------
    */

    resetPassword = async (event) => {
        event.preventDefault();
        try {
            const { newPassword } = this.state;
            var token = common.getItem('passwordToken');
            let encodeCode = btoa(btoa(newPassword));
            let encodeCode1 = btoa(btoa(btoa(btoa(token))));
            var body = { password: encodeCode, token: encodeCode1 }
            if (this.validateForm()) {
                this.setState({ isLoading: true })
                const response = await this.props.callApi('/setPassword', body, 'post', "RESET_PASSWORD", true);
                this.setState({ isLoading: false })
                if (response.data.status === true) {
                    common.setItem("accessToken", response.data.token)
                    this.props.history.push('/feedback')
                }
            }
        } catch (error) {
            this.setState({ isLoading: false })
            throw (error)
        }
    }

    /**
    |--------------------------------------------------
    | handleShow
    |--------------------------------------------------
    */

    handleShow = (modelName) => {
        this.setState({ show: true });
    }

    /**
    |--------------------------------------------------
    | handleClose
    |--------------------------------------------------
    */

    handleClose = (modelName) => {
        this.setState({ show: false });
    }

    /**
    |--------------------------------------------------
    | render
    |--------------------------------------------------
    */

    render() {
        let { newPassword, confirmPassword, errors, isLoading } = this.state
        return (
            <div className="login-register-wrapper set-passcode-page">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-4 col-lg-5 mx-auto d-flex flex-column justify-content-center">
                            <h2 className="page-heading">Set Passcode</h2>

                            <form>
                                <div className="form-group mb-4">
                                    <input
                                        className='form-control'
                                        type='password'
                                        name='newPassword'
                                        value={newPassword}
                                        placeholder='Enter New Password'
                                        id='newpassword'
                                        required
                                        onChange={this.handleChange}
                                    />
                                    {/* <Error errors={this.state.errors} field="newPasscode" /> */}
                                    <span className='error-block'> {errors.newPassword}</span>
                                </div>
                                <div className="form-group mb-4">
                                    <input
                                        className='form-control'
                                        type="password"
                                        placeholder='Enter Confirm Password'
                                        id='confirmpassword'
                                        name='confirmPassword'
                                        value={confirmPassword}
                                        required
                                        onChange={this.handleChange}
                                    />
                                    <span className='error-block'>{errors.confirmPassword}</span>

                                </div>
                                {/* <Link type="submit" className="btn btn-primary btn-block" to="/feedback">continue</Link> */}
                                <button
                                    type='submit'
                                    className='btn btn-primary btn-block'
                                    onClick={this.resetPassword}
                                >
                                    {isLoading ? <Spinner color={'#fff'} /> : <span>Submit</span>}
                                </button>
                                {/* <button
                                    type='button'
                                    className='btn btn-warning'
                                    onClick={() => this.props.history.push('/')}
                                >
                                    Cancel
                                    </button> */}
                            </form>
                        </div>

                        <div className="col-xl-7 col-lg-6 text-center d-lg-block d-none">
                            <div className="set-passcode-img-block">
                                <img src="images/set-passcode-bgi-img.svg" alt="" />
                                <div className="set-passcode-touche-1">
                                    <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={true} animationInDelay={1100}>
                                        <img src="images/set-passcode-touch-1-img.svg" alt="" />
                                    </Animated>
                                </div>
                                <div className="set-passcode-touche-2">
                                    <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={true} animationInDelay={1150}>
                                        <img src="images/set-passcode-touch-2-img.svg" alt="" />
                                    </Animated>
                                </div>
                                <div className="set-passcode-touche-3">
                                    <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={true} animationInDelay={1300}>
                                        <img src="images/set-passcode-touch-3-img.svg" alt="" />
                                    </Animated>
                                </div>
                                <div className="set-passcode-hand-touch-block">
                                    <Animated animationIn="zoomIn   " animationOut="fadeOut" isVisible={true} animationInDelay={100}>
                                        <img src="images/set-passcode-hand-touch-img.svg" alt="" />
                                    </Animated>
                                </div>
                                <div className="set-passcode-bgi-bottom-img-block">
                                    <img src="images/set-passcode-bgi-bottom-img.svg" alt="" />
                                </div>
                                <div className="set-passcode-wifi-signals-1">
                                    <Animated animationIn="flash" animationOut="fadeOut" isVisible={true} animationInDelay={2300}>
                                        <img src="images/set-passcode-wifi-signal-1-img.svg" alt="" />
                                    </Animated>
                                </div>
                                <div className="set-passcode-wifi-signals-2">
                                    <Animated animationIn="flash" animationOut="fadeOut" isVisible={true} animationInDelay={2400}>
                                        <img src="images/set-passcode-wifi-signal-2-img.svg" alt="" />
                                    </Animated>
                                </div>
                            </div>
                            {/* <img src="/images/set-passcode-img.svg" alt="" /> */}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default withRouter(connect(null, { callApi })(SetPasscode))
