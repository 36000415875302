import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Button, Modal } from 'react-bootstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Animated } from "react-animated-css";
import common from '../../store/reducers/commons';
import { callApi, setRegisterData } from '../../store/reducers/commonFunction'
import errorMessages from '../../store/reducers/errorMessages'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
class CompanyRegistration extends Component {

    /**
    |--------------------------------------------------
    | constructor
    |--------------------------------------------------
    */

    constructor(props) {
        super(props);
        this.state = {
            contact_dial_code: "",
            contact_email: "",
            contact_designation: "",
            contact_phone: "",
            company_name: "",
            contact_first_name: "",
            contact_last_name: "",
            company_email: "",
            company_website: "",
            company_phone: "",
            company_dial_code: "",
            isFormValid: true,
            errors: {
                company_email: '',
                contact_first_name: '',
                contact_last_name: '',
                contact_email: "",
            },
            registerModal: false,
            show: false,
            dialcode: '',
            dialcodes: '',
            rawPhone: '',
            rawPhones: '',
        }
        this.handleChange = this.handleChange.bind(this)

    }

    /**
    |--------------------------------------------------
    | companyRegister
    |--------------------------------------------------
    */

    companyRegister = async (event) => {
        event.preventDefault();
        try {
            let { company_website, file, dialcode, rawPhone, dialcodes, rawPhones, contact_email, contact_first_name, contact_last_name, company_name, company_email, contact_designation } = this.state;

            if (this.validateForm()) {
                const formData = new FormData()
                if (file) {
                    formData.append('image', file)
                }
                formData.append('company_name', company_name)
                formData.append('company_email', company_email)
                formData.append('company_website', company_website)
                formData.append('company_phone', rawPhone)
                formData.append('contact_designation', contact_designation)
                formData.append('contact_first_name', contact_first_name)
                formData.append('contact_last_name', contact_last_name)
                formData.append('company_dial_code', dialcode)
                formData.append('contact_email', contact_email)
                formData.append('contact_phone', rawPhones)
                formData.append('contact_dial_code', dialcodes)

                const response = await this.props.callApi('/registerCompany', formData, 'post', null, true);

                this.props.setRegisterData(response);
                if (response.data.code === 200) {
                    this.setState({
                        show: true,
                        companydetails: response.data, user_email: response.data.contact_email
                    });
                }
            }
        } catch (error) {
            throw (error)
        }
    }

    /**
    |--------------------------------------------------
    | handleChange 
    |--------------------------------------------------
    */

    handleChange(event) {
        let { errors, isFormValid } = this.state;
        if (!isFormValid) {
            if (event.target.name === 'company_email') {
                // if (!company_email.trim()) errors.company_email = errorMessages.PROVIDE_EMAIL;
                if (!common.validateEmail(event.target.value)) errors.company_email = errorMessages.PROVIDE_VALID_EMAIL;
                else errors.email = '';
            }
            else if (event.target.name === 'contact_email') {
                // if (!contact_email.trim()) errors.contact_email = errorMessages.PROVIDE_EMAIL;
                if (event.target.value) errors.contact_email = errorMessages.PROVIDE_VALID_EMAIL;
                else errors.password = '';
            }
        }
        this.setState({ [event.target.name]: event.target.value, errors });
    }

    /**
    |--------------------------------------------------
    | validateForm
    |--------------------------------------------------
    */

    validateForm() {
        let { contact_email, contact_first_name, company_email, errors, isFormValid } = this.state;
        // for contact_email
        if (!contact_email) errors.contact_email = errorMessages.PROVIDE_EMAIL;
        else if (!common.validateEmail(contact_email)) errors.contact_email = errorMessages.PROVIDE_VALID_EMAIL;
        else errors.contact_email = '';
        // for contact_first_name
        if (!contact_first_name) errors.contact_first_name = errorMessages.PROVIDE_FIRST_NAME;
        else errors.contact_first_name = '';
        if (!company_email) errors.company_email = errorMessages.PROVIDE_EMAIL;
        else if (!company_email) errors.company_email = errorMessages.PROVIDE_VALID_EMAIL;
        else errors.company_email = '';

        if (errors.contact_email !== '' || errors.contact_first_name !== "" || errors.contact_last_name !== "") isFormValid = false;
        if (errors.contact_email === "" && errors.contact_first_name === "" && errors.company_email !== "") isFormValid = true;
        this.setState({ errors, isFormValid });
        return isFormValid;
    }

    /**
    |--------------------------------------------------
    | fileUpload
    |--------------------------------------------------
    */

    fileUpload = async (event) => {
        let reader = new FileReader()
        let file = event.target.files[0]
        reader.onloadend = () => {
            this.setState({
                file: file,
                photo_url: reader.result,
                fileVisible: true
            })
        }
        reader.readAsDataURL(file)

    }

    /**
    |--------------------------------------------------
    | handleClose
    |--------------------------------------------------
    */

    handleClose = (modelName) => {
        this.setState({ show: false });
    }

    /**
    |--------------------------------------------------
    | handleShow
    |--------------------------------------------------
    */

    handleShow = (modelName) => {
        this.setState({ show: true });
    }

    /**
    |--------------------------------------------------
    | handleOnChange
    |--------------------------------------------------
    */

    handleOnChange = (value, data, event, formattedValu) => {
        let dialnum = data.dialCode;
        let mobilenum = value.slice(data.dialCode.length);
        this.setState({
            dialcode: dialnum,
            rawPhone: mobilenum
        })
    }

    /**
    |--------------------------------------------------
    | handleOnChanges
    |--------------------------------------------------
    */

    handleOnChanges = (value, data, event, formattedValu) => {
        let dialnums = data.dialCode;
        let mobilenums = value.slice(data.dialCode.length);
        this.setState({
            dialcodes: dialnums,
            rawPhones: mobilenums
        })
    }

    /**
    |--------------------------------------------------
    | render
    |--------------------------------------------------
    */

    render() {
        let { contact_phone, photo_url, contact_email, contact_first_name, contact_last_name, company_name, company_email, contact_designation, errors, company_website, company_phone } = this.state;
        let imagePreview = null;
        if (photo_url) {
            imagePreview = (<img src={photo_url} alt="Icon" />);
        } else {
            imagePreview = (<img src="images/noimage.jpeg" alt="Icon" />);
        }
        return (
            <PerfectScrollbar>
                <div className="login-register-wrapper company-registration-page">
                    <Animated animationIn="fadeInRight" animationOut="fadeOut" isVisible={true} animationInDelay={500}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8 mx-auto">
                                    <h2 className="page-heading mb-2">Company Registration</h2>
                                    <p className="common-text">Provide us following details about your company and we will get back to you soon.</p>

                                    <form>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="company-logo-block">
                                                    <span className="company-logo">
                                                        {imagePreview}
                                                    </span>
                                                    <input className="file-input company-logo-changer ml-3" type="file" name="profile_photo" accept="image/*" id="file" onChange={this.fileUpload.bind(this)} />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group mb-4">
                                                    <input
                                                        className={errors.company_name ? 'form-control input-error' : 'form-control'}
                                                        name='company_name'
                                                        value={company_name}
                                                        id='company_name'
                                                        placeholder='Enter Company Name'
                                                        onChange={this.handleChange}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-lg-6">
                                                <div className="form-group mb-4">
                                                    <input type='text'
                                                        className={errors.company_email ? 'form-control input-error' : 'form-control'}
                                                        name='company_email'
                                                        value={company_email}
                                                        id='company_email'
                                                        placeholder='Enter Company Email'
                                                        onChange={this.handleChange} />
                                                    <span className='error-msg' >{errors.company_email}</span>

                                                </div>
                                            </div>

                                            <div className="col-lg-6">
                                                <div className="form-group mb-4">
                                                    <input type='text'
                                                        className={errors.company_website ? 'form-control input-error' : 'form-control'}
                                                        name='company_website'
                                                        value={company_website}
                                                        id='company_website'
                                                        placeholder='Enter Company Website'
                                                        onChange={this.handleChange} />
                                                </div>
                                            </div>


                                            <div className="col-lg-6">
                                                <div className="input-group mobile-input mb-4">
                                                    <PhoneInput
                                                        className="form-control"
                                                        name="phone"
                                                        country={'us'}
                                                        value={company_phone}
                                                        onChange={this.handleOnChange}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-12">
                                                <h6>Primary Contact Person Information.</h6>
                                                <p className="common-text">This person will be given all the access for your company.</p>
                                            </div>

                                            <div className="col-lg-6">
                                                <div className="form-group mb-4">

                                                    <input type='text'
                                                        className={errors.contact_first_name ? 'form-control input-error' : 'form-control'}
                                                        name='contact_first_name'
                                                        value={contact_first_name}
                                                        id='contact_first_name'
                                                        placeholder='First Name'
                                                        onChange={this.handleChange} />
                                                    <span className='error-msg' >{errors.contact_first_name}</span>

                                                </div>
                                            </div>

                                            <div className="col-lg-6">
                                                <div className="form-group mb-4">

                                                    <input type='text'
                                                        className={errors.contact_last_name ? 'form-control input-error' : 'form-control'}
                                                        name='contact_last_name'
                                                        value={contact_last_name}
                                                        id='contact_last_name'
                                                        placeholder='Last Name'
                                                        onChange={this.handleChange} />

                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group mb-4">
                                                    <input
                                                        className={errors.contact_designation ? 'form-control input-error' : 'form-control'}
                                                        name='contact_designation'
                                                        value={contact_designation}
                                                        id='contact_designation'
                                                        placeholder='Enter Degination'
                                                        onChange={this.handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group mb-4">

                                                    <input type='text'
                                                        className={errors.contact_email ? 'form-control input-error' : 'form-control'}
                                                        name='contact_email'
                                                        value={contact_email}
                                                        id='contact_email'
                                                        placeholder='Enter Company Email'
                                                        onChange={this.handleChange} />
                                                    <span className='error-msg' >{errors.contact_email}</span>

                                                </div>
                                            </div>

                                            <div className="col-lg-6">
                                                <div className="input-group mobile-input mb-4">
                                                    <PhoneInput
                                                        className="form-control"
                                                        name="phone"
                                                        country={'us'}
                                                        value={contact_phone}
                                                        onChange={this.handleOnChanges}
                                                    />

                                                </div>
                                            </div>

                                            <div className="col-12">
                                                <div className="checkbox-radio">
                                                    <label>
                                                        <input type="checkbox" value="" />
                                                        <span>
                                                            <span className="checkbox-style"></span>
                                                            <span className="text tnc-check">I have read <Link to="terms_conditions">Terms</Link> & <Link to="privacy_policy">Privacy Policy</Link>
                                                            </span>
                                                        </span>
                                                    </label>
                                                </div>
                                            </div>

                                            <div className="col-12 text-right pt-3">
                                                <Button variant="outline-primary" className="text-uppercase">Cancel</Button>
                                                <Button className="btn btn-primary text-uppercase ml-3" onClick={this.companyRegister}>Register</Button>
                                                <Modal className="common-modal small" centered show={this.state.show} onHide={() => this.handleClose("registerModal")}>

                                                    <button type="button" className="close fixed-close-btn" onClick={() => this.handleClose("registerModal")}>
                                                    </button>
                                                    <Modal.Body className="p-5">
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <img src="images/company-registration-thankyou.svg" alt="" />
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="modal-body-inner">
                                                                    <h4>Thank You!</h4>
                                                                    <p className="common-text">We have received your request and we would love to have you onboard.</p>
                                                                    <p className="common-text">Stay tuned, we will contact you with further updates.</p>
                                                                    <p className="common-text">For any queries, you can always reach out to hello@feedback.com</p>
                                                                    <Link
                                                                        className="btn btn-primary"
                                                                        to="/emailVerification"
                                                                    >Continue</Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Modal.Body>
                                                </Modal>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </Animated>
                </div>
            </PerfectScrollbar>
        );
    }
}
export default withRouter(connect(null, { callApi, setRegisterData })(CompanyRegistration))