import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import {  } from 'react-bootstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { callApi, setEmployeeDetails } from '../../../store/reducers/commonFunction'
import _ from 'lodash';
import { Spinner } from 'react-activity';
import 'react-activity/dist/react-activity.css';

class Leftbar extends Component {

    /**
    |--------------------------------------------------
    | constructor
    |--------------------------------------------------
    */

    constructor(props) {
        super(props);
        this.state = {
            leftbar: false,
            isLoading: false
        }
    }

    /**
    |--------------------------------------------------
    | componentDidMount
    |--------------------------------------------------
    */

    async componentDidMount() {
        this.props.onRef && this.props.onRef(this)
        await this.getEmployeeDetails()
    }

    /**
    |--------------------------------------------------
    | componentWillUnmount
    |--------------------------------------------------
    */

    componentWillUnmount() {
        this.props.onRef && this.props.onRef(undefined)
    }

    /**
    |--------------------------------------------------
    | getEmployeeDetails
    |--------------------------------------------------
    */

    getEmployeeDetails = async (user_id, isLoading = true) => {

        let company_id = this.props.userData.company_id ? this.props.userData.company_id : '';
        let body = {
            company_id
        }
        this.setState({
            isLoading: isLoading
        })
        const response = await this.props.callApi('/getEmployee', body, 'post', null, true);
        this.props.setEmployeeDetails(response.data);
        if (response) {
            if (user_id) {
                let selectedUser = response.data && response.data.find(x => x.user_id == user_id);
                this.props.getChildEvent(selectedUser);
            } else {
                this.props.getChildEvent(response.data[0]);
            }
        }
        this.setState({
            isLoading: false
        })
    }

    /**
    |--------------------------------------------------
    | getContactId
    |--------------------------------------------------
    */

    getContactId = (catId) => {
        if (window.location.pathname !== '/contacts') {
            this.props.history.push({ pathname: '/contacts', contactId: catId })
        }
        if (this.props && this.props.getChildEvent) {
            this.props.getChildEvent(catId);
        }
    }

    /**
    |--------------------------------------------------
    | LeftbarToggle
    |--------------------------------------------------
    */

    LeftbarToggle = () => {
        this.setState({ leftbar: !this.state.leftbar },
            function () {
                document.body.classList.remove('leftbar-open');
            });
    }

    /**
    |--------------------------------------------------
    | render
    |--------------------------------------------------
    */

    render() {

        const { employeeData, userData } = this.props;
        let role = _.get(userData, 'role') || '';
        return (
            <div className="leftbar">

                <span className="leftbar-toggler" onClick={this.LeftbarToggle}>
                    <span className="material-icons">arrow_back</span>
                </span>
                {
                    (role === 'employee' || role === 'categoryadmin') ? <div></div> :
                        <div className="btn plus-btn" onClick={() => this.props.openAddContactModal()}>+</div>
                }
                <div className="contacts-leftbar-header">
                    <h6>Hi, <span>{userData.first_name}</span></h6>
                    <span className="heading-detail">Know your collegue, find their contact information and get in touch with him/her</span>
                </div>
                <ul className="leftbar-list">
                    <PerfectScrollbar>

                        {
                            employeeData && Array.isArray(employeeData) && employeeData.map((Empolyee, key) => {

                                let listItemClassName = (Empolyee.user_id == _.get(this.props, 'selectedEmployee.user_id')) ? "leftbar-list-item active" : "leftbar-list-item"
                                let userNameClassName = _.get(userData, 'user_id') === Empolyee.user_id ? "user-name active" : "user-name"

                                return (
                                    <li className={listItemClassName} key={key} onClick={() => { this.getContactId(Empolyee) }}>

                                        {/* (Empolyee.status === 'Active')? */}

                                        <div className="leftbar-list-item-inner">
                                            <div className="user-img">
                                                <div className="user-img-wrapper">
                                                    {
                                                        (Empolyee.image === '') ? <img src="images/noimage.jpeg" alt="" /> :
                                                            <img src={Empolyee.image} alt="images/may-tucker.jpg" />
                                                    }
                                                </div>
                                            </div>
                                            <div className="user-data">
                                                <div className={userNameClassName}>{Empolyee.first_name} {Empolyee.last_name}</div>
                                                <div className="data-text text-capitalize">{Empolyee.designation}</div>
                                                <div className="data-text">{Empolyee.email}</div>
                                            </div>
                                            <div className="date-wrapper">
                                                <div className="user-date-inner">
                                                    {/* <span className="date">11-05-2020</span> */}
                                                    <span className="material-icons" onClick={() => { this.getContactId(Empolyee) }}>keyboard_arrow_right</span>
                                                </div>
                                            </div>
                                        </div>
                                        {/* :null */}

                                    </li>
                                )
                            })
                        }

                        {this.state.isLoading && <div className={'loader-block'}>
                            <Spinner />
                        </div>}

                    </PerfectScrollbar>
                </ul>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        userData: state.getUserData,
        employeeData: state.employeeList,
    }
}
export default withRouter(connect(mapStateToProps, { callApi, setEmployeeDetails })(Leftbar))