export default class EventService {

    constructor(config, axios) {
        this.config = config;
        this.axios = axios;
        this.apiUrl = config.apiUrl;
    }

    async collection() {
        const response = await this.axios.get(`${this.apiUrl}/events`)
        if (response.status !== 200) throw response;
        return response.data.success;
    }

    async show(id) {
        const response = await this.axios.get(`${this.apiUrl}/events/${id}`)
        if (response.status !== 200) throw response;
        return response.data.success.data;
    }
}