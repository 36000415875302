// *************************************************************//
//@Purpose : We can use following function to use localstorage
//*************************************************************//
export default {
    REQUIRED: "*Field Required",
    PROVIDE_EMAIL: "*Enter email",
    PROVIDE_VALID_EMAIL: "*Enter valid email",
    PROVIDE_VALID_URL: "*Enter valid URL",
    PROVIDE_PASSWORD: "*Enter  password",
    PROVIDE_CURRENT_PASSWORD: "*Enter current password",
    PROVIDE_NEW_PASSWORD: "*Enter new password",
    PROVIDE_VALID_PASSWORD: "*Password may only contain numbers.",
    PROVIDE_CONFIRM_PASSWORD: "*Enter  confirm password",
    PASSWORD_NOT_MATCHED: "Password and confirm password not matched",
    PROVIDE_FIRST_NAME: "*Enter first name",
    PROVIDE_VALID_FIRST_NAME: "*Numbers are not valid",
    PROVIDE_LAST_NAME: "*Enter last name",
    PROVIDE_VALID_LAST_NAME: "*Numbers are not valid",
    PROVIDE_MOBILE_NUMBER: "*Enter mobile number",
    PROVIDE_VALID_MOBILE_NUMBER: "*Enter valid number",
    PROVIDE_NAME: "*Enter name",
    PROVIDE_ADDRESS_LINE_1: "*Enter address line 1",
    PROVIDE_ADDRESS_LINE_2: "*Enter address line 2",
    PROVIDE_CITY: "*Enter city",
    PROVIDE_STATE: "*Enter state",
    PROVIDE_WEBSITE: "*Enter website",
    PROVIDE_VALID_WEBSITE: "*Enter enter valid website",
    PROVIDE_DESIGNATION: "*Enter designation",
    PROVIDE_ROLE: "*Please select role",
    PROVIDE_DESCRIPTION: "*Enter Description.",
    PROVIDE_KEYWORD: "*Enter Keyword/Tag.",
    PROVIDE_SUB_CAT: "*Select Sub-Category.",
    PROVIDE_MAIN_CAT: "*Select Main-Category.",
    PROVIDE_LANGUAGE: "*Enter New Language.",
    PROVIDE_REASONS: "*Enter Proper Reason.",
    PROVIDE_CATEGORY: "*Please select category.",
    PROVIDE_MESSAGE: "*Enter suggestion message.",
    PROVIDE_IDEA_MESSAGE: "*Enter idea message.",
    PROVIDE_IDEA_TAG: "*Please select tag.",
    PROVIDE_ANNOUNCEMENT: "*Please Enter Announcement.",
    PROVIDE_COMPANY_NAME: "*Enter company name",
    PROVIDE_EMPLOYEE: "*Please select an employee",
    VIDEO_LENGTH_ERROR: "Maximum file size to upload is 8MB (8192 KB)",
}